import { getCompany } from "../../helpers/commonFunctions";
import axiosInstanceCashier from "../../utils/axiosCashier";
import axiosInstance from "../../utils/axiosCompany";

// export const UpdateDateOfBusiness = async (values) => {
//     return await axiosInstanceCashier().post("cashier/order/update-date-of-business", values);
// }
export const CreateOrders = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/create-order", values);
}
export const GetOrdersList = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/list-order", values);
}
export const GetSingleOrderDetails = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/get-single-order", values);
}
export const RefundStatus = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/change-payment-status", values);
}
export const RecallOrder = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/recall-order", values);
}
export const checkoutOrder = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/checkout-order", values);
}
export const CouponValidator = async (values) => {
    return await axiosInstanceCashier().post(`cashier/order/validate-coupon`, values);
};
export const GetMealCombosOrder = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/combos", values);
}
export const GetMenuItemsOrder = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/menuitems", values);
}
export const GetAllOrders = async () => {
    return await axiosInstanceCashier().post("cashier/order/get-all-orders", {company_id : getCompany().company_id});
}

