import React, { useEffect, useState } from 'react'
import LoginLayout from '../../loginlayout'
import { Formik } from 'formik'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetResetpassword } from '../../services/LoginServices';
import { GetLogo } from '../../services/settingsService';
import { ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_NUMBER_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../utils/Constants';
import * as Yup from "yup";


const ResetPwdPage = () => {
	const [loader, setLoader] = useState('false')
	const navigate = useNavigate()
	const location = useLocation()
	const [newshow, setNewShow] = useState('false')
	const [conformshow, setConformShow] = useState('false')

	const handleshow = (event) => {
		if (event === "newpwd") {
			setNewShow(!newshow)
		}
		if (event === "confirepwd") {
			setConformShow(!conformshow)
		}
	}

	const [showDefault, setShowDefault] = useState({});

    const params = useParams()

    useEffect(() => {
        GetLogo(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, []);

	const validationSchema = Yup.object().shape({
		newpassword: Yup.string()
		.required("New password is required")
		.matches(ATLEAST_ONE_SMALL_REGEX, "Password should have at least 1 lowercase letter")
		.matches(ATLEAST_ONE_CAPITAL_REGEX, "Password should have at least 1 capital letter")
		.matches(ATLEAST_ONE_NUMBER_REGEX, "Password should have at least 1 number")
		.matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, "Password should have at least 1 special character")
		.min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long")
		.max(PASSWORD_MAX_LENGTH, "Password cannot be more than 20 characters long"),
		confirmpassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref("newpassword"), null], "Passwords don't match"),
	  })
	

	return (
		<LoginLayout>
			<Formik
				initialValues={{
					newpassword: '',
					confirmpassword: ''
				}}
				validationSchema={validationSchema}
				// validate={values => {
				// 	const errors = {};
				// 	if (!values.newpassword) {
				// 		errors.newpassword = 'Password is required';
				// 	}
				// 	if(!values.confirmpassword) {
				// 		errors.confirmpassword = 'Confirm password is required';
				// 	}
				// 	return errors;
				// }}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setLoader(true);
					let formData = new FormData();
					formData.append("password", values.newpassword);
					formData.append("confirm_password", values.confirmpassword)
					formData.append("validate_string", location.state)
					GetResetpassword(formData).then(data => {
						if(data){
							if (data && data.data && data.data.statuscode === 200) {
								toast(data.data.message);
								setTimeout(() => {
									setLoader(false);
									resetForm()
									navigate("/admin/login");
								}, 2000);
							} else if (data && data.data && data.data.statuscode !== 200) {
								toast(data.data.message);
							}
						}else{
							setLoader(false);
						}
					}).catch(error => {
						console.log("error: ", error);
					})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					// and other goodies
				}) => (
					<div className="row signpages text-center">
						<div className="col-md-12">
							<div className="card">
								<div className="row row-sm" style={{height: "28rem"}}>
									<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
									<div className="m-5 p-5 pos-absolute align-content-center" style={{height: "22rem"}}>
											{/* <img src={Logo} className="ht-120 mb-4" alt="logo" /> */}
											<img src={showDefault.logo} className="mb-2" alt="logo" />
											{/* <h2 className="mt-4 text-white">Point of sale</h2> */}
											<div className="clearfix"></div>
											<h5 className="mt-4 text-white">Reset Password</h5>
											</div>
									</div>
									<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form align-content-center">
										<div className="container-fluid">
											<div className="row row-sm">
												<div className="card-body mt-2 mb-2">
													<img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
													<div className="clearfix"></div>
													<h5 className="text-left mb-2">Reset Password</h5>
													{/* <p className="mb-4 text-muted tx-13 ml-0 text-left">It's free to signup and only takes a minute.</p> */}
													<form onSubmit={handleSubmit}>
														<div className="form-group text-left">
															<label>New Password</label>
															<div className="input-group">
																<input className={"form-control" + (errors.newpassword && touched.newpassword ? "" : "")}
																	placeholder="Enter your new password"
																	type={!newshow === true ? "text" : "password"}
																	name="newpassword"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.newpassword}
																	autoComplete="off" />
																<span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
															</div>
															<span className='text-danger text-left d-blockerr-spn'>{errors.newpassword && touched.newpassword && errors.newpassword}</span>
														</div>
														<div className="form-group text-left">
															<label>Confirm Password</label>
															<div className="input-group">
																<input className={"form-control" + (errors.confirmpassword && touched.confirmpassword ? "" : "")}
																	placeholder="Enter confirm password"
																	type={!conformshow === true ? "text" : "password"}
																	name="confirmpassword"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.confirmpassword}
																	autoComplete="off" />
																<span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
															</div>
															<span className='text-danger text-left d-blockerr-spn'>{errors.confirmpassword && touched.confirmpassword && errors.confirmpassword}</span>
														</div>

														<button className={"btn ripple btn-main-primary btn-block signbtn" + (loader === true ? " disabled" : "")} type="submit">Reset Password</button>
                                                        {loader === true ?
                                                            <div className="spinner-border text-primary mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Formik>
			{/* Error message show */}
			<ToastContainer
				position="top-right"
				hideProgressBar={false}
				autoClose={true | 1500}
				newestOnTop={true}
				closeOnClick={false}
				draggable={false}
				rtl={false}
			/>
			{/* error msg end */}
		</LoginLayout>
	)
}

export default ResetPwdPage