import axiosInstance from "../../utils/axiosCompany";

export const CreateTransferStockRequest = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/create-request", values);
}

export const GetTransferStockOutRequestsList = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/get-all-out-requests", values);
}

export const GetTransferStockInRequestsList = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/get-all-in-requests", values);
}

export const acceptTransferStockInRequest = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/accept-request", values);
}

export const rejectTransferStockInRequest = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/reject-request", values);
}

export const recievedTransferStockInRequest = async (values) => {
    return await axiosInstance().post("company/transfer-stock-request/mark-recieve-request", values);
}