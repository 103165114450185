import React from 'react'
import LoginLayout from '../loginlayout'

const Error401 = () => {
    return (
        <>
            <LoginLayout>
                <div className="page main-signin-wrapper bg-primary construction">

                    <div className="container ">
                        <div className="construction1 text-center details text-white">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <h1 className="tx-140 mb-0">401</h1>
                                </div>
                                <div className="col-lg-12 ">
                                    <h1>Oops..Stop Right There Authorization Required..</h1>
                                    <h6 className="tx-15 mt-3 mb-4 text-white-50">You may have mistyped the address or the page may have moved. Try searching below.</h6>
                                    <a className="btn ripple btn-success text-center" href="/company/dashboard">Back to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </LoginLayout>
        </>
    )
}

export default Error401