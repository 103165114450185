import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrums(props) {
  const breadcrumbs = props.data && props.data.length > 0 ? props.data : [];

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map((item, index) => {
            return (
                  <li className="breadcrumb-item"><Link
                  to={item.url}
                  key={index}
                  className={
                    item.url === "" ? "active disableclick" : ""
                  }
                >
                  {item.title}</Link>
                </li>
                  
                
            )
          })}
        </ol>
      </nav>
    </>
  )
}
export default Breadcrums