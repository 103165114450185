import axiosInstance from "../../utils/axiosCompany";

export const GetSubscriptionsList = async (values) => {
    return await axiosInstance().post("company/subscription/get-all-subscriptions", values);
}

export const GetSingleSubscription = async (values) => {
    return await axiosInstance().post("company/subscription/get-single-subscription", values);
}

export const EmailSend = async (values) => {
    return await axiosInstance().post("company/subscription/send-email-cancel-subscription", values);
}
export const RenewSubscriptionEmailSend = async (values) => {
    return await axiosInstance().post("company/subscription/send-email-renew-subscription", values);
}
export const UpgradeSubscriptionEmailSend = async (values) => {
    return await axiosInstance().post("company/subscription/send-email-upgrade-subscription", values);
}