import axiosInstance from "../utils/axios";

// export const SaveData = async (values,remove) => {
//     return await axiosInstance().post("admin/settings/save?remove="+remove, values);
// }
export const GetData = async () => {
    return await axiosInstance().get("admin/settings/get");
}

export const GetLogo = async () => {
    return await axiosInstance().get("admin/settings/get-logo");
}

export const SaveData = async (formData) => {
    return await axiosInstance().post("admin/settings/save", formData);
}