import axiosInstance from "../../utils/axiosCompany";

export const CreateCategory = async (values) => {
    return await axiosInstance().post("company/category/add-category", values);
}

export const GetCategoryList = async (values) => {
    return await axiosInstance().post("company/category/list-categories", values);
}

export const GetAllParentCategories = async (values) => {
    return await axiosInstance().post("company/category/get-all-parent-categories", values);
}

export const GetParentCategories = async (values) => {
    return await axiosInstance().post("company/category/get-parent-categories", values);
}

export const GetAllChildCategories = async (values) => {
    return await axiosInstance().post("company/category/get-all-child-categories", values);
}

export const GetSingleCategory  = async (values) => {
    return await axiosInstance().post("company/category/get-single-category", values);
}

export const editCategory = async (values) => {
    return await axiosInstance().post("company/category/update-category", values)
}

export const ChangeCategoryStatus = async (values) => {
    return await axiosInstance().post("company/category/change-category-status", values)
}

export const DeleteCategory = async (values) => {
    return await axiosInstance().post("company/category/delete-category", values);
}

export const getAllMenuCategoryItems = async (values) => {
    return await axiosInstance().post("company/menu-category/get-all-menu-categories-with-menu-items", values);
}




//menu apis
export const addMenuItems = async (values) => {
    return await axiosInstance().post("company/menu/create-menu", values);
}

export const ListMenuItems = async (values) => {
    return await axiosInstance().post("company/menu/list-menus", values);
}

export const getAllMenus = async (values) => {
    return await axiosInstance().post("company/menu/get-all-menus", values);
}

export const DeleteMenuItems = async (values) => {
    return await axiosInstance().post("company/menu/delete-menu", values);
}

export const GetMenuItems = async (values) => {
    return await axiosInstance().post("company/menu/get-single-menu", values);
}

export const EditMenuItems = async (values) => {
    return await axiosInstance().post("company/menu/update-menu", values);
}