import React, { useState, useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { GetTemplateList, GetChangeStatus, DeleteTemplate } from '../../services/emailtemplatesservice'
import CustomPagination from '../../components/common/custompagination'
import Sorting from '../../components/common/sorting'
import { DEVMODE } from '../../utils/Constants';
import { getSessionKey, removeSessionKey, setSessionKey,formateDate,TrimText} from '../../helpers/commonFunctions';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import StatusFilter from '../../components/common/statusFilter'
import CustomRangepicker from '../../components/common/rangepicker'
import { showFilterlist } from '../../utils/Constants'
import $ from 'jquery'
import toHtml from 'html-react-parser'



const EmailTemplatesTable = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false")
    const [serialno, setSerialNo] = useState("")
    const [datalength, setDataLength] = useState()
    const [itemperpage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [devmode, setDevmode] = useState(false);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Email Templates", url: "" }]
    const [resetdate, setResetDate] = useState(false)

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            GetTemplateList(formData).then(data => {
                setSerialNo(data.data.response.offset)
                setDataLength(data.data.response.count)
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setPage(data && data.data && data.data.response ? data.data.response.page : 1);
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch])

    useEffect(() => {
        /**DEV MODE >>>> */
        document.addEventListener('keydown', function (e) {
            if (e.ctrlKey && e.shiftKey && e.which == 68) {
                document.getElementById("developerModeBtn").click();
            }
            // checkDevModeSession();
        });
        document.addEventListener('mousemove', function (e) {
            // checkDevModeSession();
        });
        initDevMode();
        /**DEV MODE <<<< */
    }, [])

    const viewfunction = (row) => {
        navigate(`/admin/email-templates/view/${row.id}`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/email-templates/${params.id}/edit/${row.id}`)
    }

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row.id)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsupdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row.id);
                DeleteTemplate(formdata).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response?.data?.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            navigate(`/admin/email-templates/1`)
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.error[0].msg,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }

    /**DEV MODE >>>> */
    const activeDevMode = (e) => {
        e.preventDefault();
        let username = e.target.usernameDev.value;
        let password = e.target.passwordDev.value;
        if (DEVMODE.USERNAME === username && DEVMODE.PASSWORD === password) {
            setSessionKey('devmode', 'active');
            setSessionKey('devmodetime', String(new Date()));
            e.target.reset();
            document.getElementById("developerModeClose").click();
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: "Developer mode activated",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
            });
            initDevMode();
        } else {
            logoutDevMode();
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: "Invalid username or password",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
            });
        }
    }

    const initDevMode = () => {
        let sKey = getSessionKey('devmode');
        if (sKey && sKey === 'active') {
            setDevmode(true);
        } else {
            logoutDevMode();
        }
    }

    const checkDevModeSession = () => {
        try {
            let sKey = getSessionKey('devmodetime');
            let oldDate = new Date(sKey);
            let oldTime = oldDate.getTime();
            let newDate = new Date();
            let newTime = newDate.getTime();
            let diff = newTime - oldTime;
            if (diff <= DEVMODE.SESSION_TIME) {
                setDevmode(true);
            } else {
                logoutDevMode();
            }
        } catch (err) {
            logoutDevMode();
        }
    }

    const logoutDevMode = () => {
        removeSessionKey('devmode');
        removeSessionKey('devmodetime');
        setDevmode(true);
    }
    /**DEV MODE <<<< */

    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        // prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }


    return (
        <Layout>
            <Breadcrums data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        {/* DEV MODE >>>>  */}
                        {
                            devmode ?
                                <div className='d-flex justify-content-between align-items-center'>
                                    {/* <button type='button' className="btn btn-outline-primary exit_dev_mode" onClick={(e) => { logoutDevMode(); }}>Exit Developer Mode</button> */}
                                </div> : ""
                        }
                        {/*DEV MODE <<<< */}
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">Email Templates</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate}/>
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                {devmode ? <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/email-templates/add`)}>Add New</button> : ""}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100" style={{ tableLayout: 'fixed', overflow: 'hidden' }}>
                                <thead>
                                    <tr>
                                        <th className='sr_head' width="10%">S.No</th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Slug</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="slug" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="type" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th width="15%" className='status_head'>Status</th>
                                        <th width="10%" className='action_head'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={8}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i}>
                                                    <td>{(params.id * itemperpage) - (itemperpage) + (++i)}</td>
                                                    <td>{row.title ? TrimText(row.title,50) : "N/A"}</td>
                                                    <td>{row.slug ? TrimText(row.slug,30) : "N/A"}</td>
                                                    <td>{row.type  ? TrimText( row.type,30) : "N/A"}</td>
                                                    <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row.status == 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row, 0) }}>Active</button>
                                                        : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row, 1) }}>Inactive</button>}</td>

                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>
                                                            <button className="btn ripple btn-success mx-1" onClick={() => { goToEdit(row) }}>Edit</button>
                                                            {
                                                                devmode ?
                                                                    <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(row) }}>Delete</button>
                                                                    : ""
                                                            }
                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "email-templates", path: "/admin/email-templates" }]} /> : ""}
                    </div>
                </div>
            </div>
            {/* tablesection end */}
            {/*DEV MODE >>>> */}
            <button type="button" className='d-none' data-bs-toggle="modal" id='developerModeBtn' href="#developerMode" role="button"></button>
            <div className="modal fade" id="developerMode" aria-hidden="true" aria-labelledby="developerModeLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <form onSubmit={activeDevMode} className='w-100'>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="developerModeLabel">
                                    Developer Mode
                                </h1>
                                <button type="button" className="btn-close model_close_btn" id='developerModeClose' data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='usernameDev' className='text-left d-flex'>Username:<span className="requirestar">*</span></label>
                                        <input name='usernameDev' id='usernameDev' type='text' placeholder='Enter username' className='form-control' autoComplete='new-password' required />
                                    </div>
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='passwordDev' className='text-left d-flex'>Password:<span className="requirestar">*</span></label>
                                        <input name='passwordDev' id='passwordDev' placeholder='********' type='password' className='form-control' autoComplete='new-password' required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" type='submit'>Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/*DEV MODE <<<< */}
        </Layout>
    )
}

export default EmailTemplatesTable