import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getCompany } from './helpers/commonFunctions';
import { globalLoader } from './helpers/global';
import { GetPermission } from './services/company_panel/LoginCompanyServices';
import Header from './components/company_panel/common/header';
import SideBar from './components/company_panel/common/sidebar';
import Footer from './components/company_panel/common/footer'
import { SOCKET_CLIENT } from './utils/socket';



const CompanyLayout = () => {
  useEffect(()=>{
    globalLoader(true);
    Promise.all([
      import("./assets/company/css/bootstrap.min.css"),
      import("./assets/company/css/style.css"),
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })

    
    GetPermission(getCompany().company_id,getCompany().id).then(data => {
      localStorage.setItem("permission", JSON.stringify(data.data.response.moduleAssignedPermissions));
    }).catch(error => {
        console.log("error=====>", error)
    })

  },[]);


  useEffect(()=>{

    let joinRequest = {
        roomID: JSON.parse(localStorage.getItem("company_detail")).company_id
      };
      SOCKET_CLIENT.emit('JOIN-USER', joinRequest, (error) => {
        if (error) {
          console.error("*****Socket connection error on JOIN-USER", error);
        }
        else{
            console.log("joined")
        }
      });
}, []); 


  //indexedDb local database

  //creating database
  // async function setupDatabase() {
  //   // Open or create database db1 with version 1
  //   const db = await openDB('db', 1, {
  //     upgrade(db) {
  //       // Create object store 'store'
  //       if (!db.objectStoreNames.contains('store')) {
  //         db.createObjectStore('store', { keyPath: 'id', autoIncrement: true });
  //       }
  //       if (!db.objectStoreNames.contains('brands_with_outlets')) {
  //         db.createObjectStore('brands_with_outlets');
  //       }
  //     },
  //   });
  //   console.log('Databases and object stores created');
  //   return db;
  //   // Open or create database db2 with version 1
  //   // const db2 = await openDB('db2', 1, {
  //   //   upgrade(db) {
  //           // Create object store 'store2'
  //           // db.createObjectStore('store2');
  //   //     // Create object store 'store3' with a keyPath of 'id'
  //   //     db.createObjectStore('store3', { keyPath: 'id' });
  //   //     // Create object store 'store4' with autoIncrementing keys
  //   //     db.createObjectStore('store4', { autoIncrement: true });
  //   //     db.createObjectStore('store5', { keyPath: 'id', autoIncrement: true });
  //   //   },
  //   // });
  // }

  // Save a store in store-table
  // async function saveStore(store) {
  //   const db = await setupDatabase();
  //   await db.add('store', store);
  //   console.log('store saved');
  // }

  // Fetch all records from a specific table (for example, store)
  // async function getAllRecords(tableName) {
  //   const db = await setupDatabase();
  //   const records = await db.getAll(tableName);
  //   return records;
  // }

  // async function deleteTable(dbName, tableName) {
  //   // Open or create the database with an initial version
  //   const db = await openDB(dbName, 2, { // Increment the version from 1 to 2
  //     upgrade(db) {
  //       if (db.objectStoreNames.contains(tableName)) {
  //         db.deleteObjectStore(tableName); // Delete object store
  //       }
  //     },
  //   });
  //   console.log('Table deleted successfully');
  // }

  // function deleteDatabase(dbName) {
  //   const deleteRequest = indexedDB.deleteDatabase(dbName);
  
  //   deleteRequest.onsuccess = () => {
  //     console.log(`Database '${dbName}' deleted successfully`);
  //   };
  
  //   deleteRequest.onerror = () => {
  //     console.error(`Failed to delete the database '${dbName}'`);
  //   };
  
  //   deleteRequest.onblocked = () => {
  //     console.warn(`Deletion of database '${dbName}' is blocked`);
  //   };
  // }
  
  // async function exampleUsage() {
  //   // Save a store
  //   await saveStore({ name: 'lakhan juice center', description: 'Fresh and healthy juices' });
  //   // Fetch all stores
  //   const stores = await getAllRecords('store');
  //   console.log('All stores:', stores);
  
    
    

  //    // Save a customer
  //   //  await saveCustomer({ name: 'John Doe', email: 'john@example.com' });
  //   //  // Save a product
  //   //  await saveProduct({ name: 'Laptop', price: 999.99 });
  //   //  // Save an inventory item
  //   //  await saveInventoryItem({ productId: 1, quantity: 50 });
  //   // // Fetch all customers
  //   // const customers = await getAllRecords('customers');
  //   // console.log('All Customers:', customers);  
  //   // // Fetch all products
  //   // const products = await getAllRecords('products');
  //   // console.log('All Products:', products);

  // }
  
  // Call example usage to see everything in action
  // exampleUsage();
  
  return (
    <div className='page'>
      <Header />
      <SideBar />
      <main id="content">
        <div className="content-inner">
          <Outlet/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CompanyLayout;