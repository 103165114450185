import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetSupplierList, changeStatus, deleteSupplier } from '../../services/company_panel/supplierService'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, formateDate, getBrandId, TrimText } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany, checkPermission } from '../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignItemModal from './AssignItemModal';
import { Row } from 'react-bootstrap'
import notFound from '../../assets/company/img/no-record-found.png';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { countries } from 'countries-list';


const SupplierUserList = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);


    const [modalShow, setModalShow] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);
    const showModal = (id, list) => {
        setInventoryData({ id: id })
        setModalShow(true);
    };

    const closeModal = () => {
        setModalShow(false);
    };

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            // GetBrandList(formData).then(data => {
            //     setDataLength(data.data.response.count);
            //     setList(data && data.data && data.data.response ? data.data.response.rows : [])

            //     setLoader(false)
            // }).catch(error => {
            //     console.log("error ====> ", error);
            // })
            GetSupplierList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged])

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row);
                formdata.append("company_id", getCompany().company_id);
                deleteSupplier(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data?.data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                changeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }

    // view details functionality

    const viewFunction = (row) => {
        navigate(`/company/raw_materials/supplier_manage/view/${row}`)
    }

    // edit functionality

    const editFunction = (row) => {
        navigate(`/company/raw_materials/supplier_manage/${params.id}/edit/${row.id}`)
    }
    const copyOutletFunction = (row) => {
        navigate(`/company/raw_materials/supplier_manage/${params.id}/edit/${row.id}?copy=true`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const headerMapping = {
        "name": "Name",
        "email": "Email",
        "phone_number": "Phone Number",
        "street_address": "Street Address",
        "zip_code": "ZIP Code",
        "item_name": "Inventory Name",
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify({}))
        formData.append("search", JSON.stringify({}))
        formData.append("global_search", globalSearch)
        formData.append('brand_id', getBrandId());
        GetSupplierList(formData).then(data => {
        setDataLength(data.data.response.count);
        const listData = data && data.data && data.data.response ? data.data.response.rows : [];
        const printData = listData.map((item) => {
            const transformedItem = {};
            const Name = item?.name || 'N/A';
            const Email = formatEmailToLowercase(item?.email) || 'N/A';
            const Number = '+'  + countries[item.country_code].phone[0] + " " + item?.phone_number;
            const streetAddress = item?.street_address || 'N/A';
            const ZIPcode = item?.zip_code || 'N/A';
            
            for (const key in headerMapping) {
                if (headerMapping.hasOwnProperty(key)) {
                    const newKey = headerMapping[key];
                    switch (key) {
                        case "name":
                            transformedItem[newKey] = capitalizeFirstLetter(Name);
                            break;
                        case "email":
                            transformedItem[newKey] = Email;
                            break;
                        case "phone_number":
                            transformedItem[newKey] = Number;
                            break;
                        case "street_address":
                            transformedItem[newKey] = capitalizeFirstLetter(streetAddress);
                            break;
                        case "zip_code":
                            transformedItem[newKey] = ZIPcode;
                            break;
                        case "item_name":
                            transformedItem[newKey] = 'See separate sheet';
                            break;
                        default:
                            console.warn(`Unexpected key in headerMapping: ${key}`);
                            transformedItem[newKey] = 'N/A';
                            break;
                    }
                }
            }
            return transformedItem;
        });
    
        const inventoryData = listData.flatMap(item => {
            const Name = item?.name || 'N/A';
            return (item.supplier_inventory_relations || []).map(data => ({
                'Supplier Name': Name, 
                'Inventory Name': capitalizeFirstLetter(data.inventory?.item_name || 'N/A'),
            }));
        });
    
        const ws1 = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
        const ws2 = XLSX.utils.json_to_sheet(inventoryData, { header: ['Supplier Name', 'Inventory Name'] });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Suppliers');
        XLSX.utils.book_append_sheet(wb, ws2, 'Inventory Names');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'suppliers.xlsx');
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
    }).catch(error => {
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
        console.log("error ====> ", error);
    })
    };

    const formatEmailToLowercase = (email) => {
        if (email && email.length) {
            return email.toLowerCase();
        } else {
            return "";
        }
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Suppliers</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    <button className="btn btn-primary ms-2" onClick={handleExport} disabled={dataLength === 0}>Export Suppliers</button>
                    
                        {checkPermission('store_suppliers', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/raw_materials/supplier_manage/add`)}>Create Supplier</button>
                            : ''
                        }
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                {/* <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div> */}
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        {/* <th className="sr_head" width="15%">Sr. No</th> */}
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Name</span>

                                            </div>
                                        </th>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Email</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Phone number</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        {/* <th className="created_head" width="20%">
                                        <div className='sorting_column'>
                                            <span>Created date</span>
                                            <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                        </div>
                                    </th> */}
                                        <th className='status_head' width="10%">Status</th>
                                        <th className='action_head text-end' width="10%" style={{ paddingLeft: "3.5rem" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <>
                                                    <tr>

                                                        {/* <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td> */}
                                                        <td>{capitalizeFirstLetter(TrimText(row?.name,30))}</td>
                                                        <td>{formatEmailToLowercase(TrimText(row?.email,30))}</td>

                                                        {/* <td>{ '+' +row?.country_code  + " " + row?.phone_number}</td>
                                                         */}
                                                        <td>{'+'  + [row.country_code] + " " + row?.phone_number} &nbsp;</td>
                                                        {/* <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td> */}
                                                        <td>
                                                            <div className="form-check form-switch w-25 e_none cp" onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} >
                                                                <input className="form-check-input" type="checkbox" id="status-change" name="status" defaultChecked={row.status} />
                                                                <label className="form-check-label" for="status-change"></label>
                                                            </div>
                                                        </td>
                                                        {/* <td className='action_head' width="10%">
                                                            
                                                        </td> */}



                                                        <td className='text-end'>
                                                            {/* <button className="btn btn-primary" onClick={() => showModal(row?.id, row?.supplier_inventory_relations)} >Assign item</button> */}
                                                            <div class="dropdown  d-inline-block">
                                                                <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className='ti ti-dots-vertical'></i>
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                {checkPermission('store_suppliers', 'view') ?
                                                                    <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                    : '' }
                                                                    {checkPermission('store_suppliers', 'edit') ?
                                                                        <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                        : ''}
                                                                    {checkPermission('store_suppliers', 'delete') ?
                                                                        <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li> :
                                                                        ''}

                                                                    <li> <div className='dropdown-item' onClick={() => showModal(row?.id, row?.supplier_inventory_relations)}><i class="fas fa-tasks" aria-hidden="true"></i> Assign items</div></li>
                                                                    {checkPermission('store_suppliers', 'add') ?
                                                                    <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy supplier</div></li>
                                                                     : ''}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No records</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>

                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/raw_materials/supplier_manage/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>

            <AssignItemModal show={modalShow} handleClose={closeModal} data={inventoryData} />

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default SupplierUserList