import React from 'react';
import { Pagination } from 'rsuite';

const RsuiteCustomPagination = ({ datalength, itemperpage, setPage, currentPage }) => {
  const pageCount = Math.ceil(datalength / itemperpage);

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <Pagination
      prev
      next
      first
      last
      ellipsis
      boundaryLinks
      pages={pageCount}
      maxButtons={5} // Number of page buttons to display
      activePage={currentPage}
      onSelect={handlePageChange}
    />
  );
};

export default RsuiteCustomPagination;
