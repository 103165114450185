import axios from "axios";
import { toast } from 'react-toastify';
import { isOnline, logOutCompany, setSessionKey } from '../helpers/commonFunctions';
import Swal from "sweetalert2";

const Axios = (history = null) => {
    if(!isOnline()){
        Swal.fire({
          customClass: 'swal-wide',
          icon: 'error',
          title: 'No internet connection',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          position: 'top-right',
        });
        return null;
    }
    // const baseURL = process.env.REACT_APP_BASE_URL
    const baseURL = process.env.REACT_APP_CASHIER_APIS_BASE_URL;
    let headers = { Authorization: `Bearer ${localStorage.getItem("company_token")}` };

    const axiosInstance = axios.create({
        baseURL: baseURL,
        headers,
    });

    axiosInstance.interceptors.response.use((response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }), (error) => {
            console.error("erroree -----> ", error);
            if (error.response.status === 401) {
                setSessionKey("session_expired", "true");
                // localStorage.setItem("landingpage", window.location.pathname);
                // localStorage.setItem("landingpagesearch", window.location.search);
                logOutCompany();
            } else {
                console.log('----toasting', error.response.data.errors.message);
                toast(error.response.data.errors.message);
            }
        }
    );

    return axiosInstance;
};

export default Axios;