import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, checkPermission, formateDate, getOutletId, removeHyphens, TrimText } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany } from '../../helpers/commonFunctions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notFound from '../../assets/company/img/no-record-found.png'
import { ListCustomer } from '../../services/company_panel/customer.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ListRefund } from '../../services/company_panel/refund.services';


const RefundList = () => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                ListRefund(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, 500);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
    }

    const viewFunction = (row) => {
        navigate(`/company/transaction/refund/view/${row}`)
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const headerMapping = {
        "id": "Order ID",
        "name": "Order Type",
        "outlet_name": "Outlet Name",
        "customer_name": "Customer Name",
        "amount": "Amount",
        "refund_reason": "Reason For Refund",
        "payment_status": "Payment Status",
    };

    const formatValue = (value) => {
        if (value === null || value === undefined || value === "") {
            return 'N/A';
        }
        return value.toString().replace(/_/g, ' ');
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('outlet_id', getOutletId());
        ListRefund(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const orderID = item.order_id || 'N/A';
                const orderType = item.order_mode_configuration?.name || 'N/A';
                const outletName = item.outlet?.outlet_name || 'N/A';
                const customerName = item.customer?.customer_name || 'N/A';
                const amount = item.transaction?.amount || 'N/A';
                const refundReason = item.transaction?.refund_reason || 'N/A';
                const paymentStatus = item.transaction?.payment_status || 'N/A';

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "id":
                                transformedItem[newKey] = ((orderID));
                                break;
                            case "name":
                                transformedItem[newKey] = capitalizeFirstLetter(removeHyphens(orderType));
                                break;
                            case "outlet_name":
                                transformedItem[newKey] = capitalizeFirstLetter(outletName);
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "amount":
                                transformedItem[newKey] = amount + " " + "SAR";
                                break;
                            case "refund_reason":
                                transformedItem[newKey] = capitalizeFirstLetter(refundReason);
                                break;
                            case "payment_status":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentStatus);
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Refund.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    const showReason = (row) => {
        const transaction = row?.transaction;

        if (transaction?.refund_reason_id) {
            return TrimText(capitalizeFirstLetter(transaction?.refundReason?.reason),20);
        } else if (transaction?.refund_reason) {
            return TrimText(capitalizeFirstLetter(transaction.refund_reason),20);
        }

        return 'N/A';
    };



    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    {checkPermission('store_refund', 'add') ?
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/transaction/refund/configuration`)}>Configuration</button>
                        :''}
                        <button onClick={handleExport} className="btn btn-primary ms-2">Export Refund</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Order ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Order Mode</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_mode" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Aggregator Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="aggregator_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Ordered By</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="user_id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlet_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Customer Name </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_name" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Refunded Amount</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="refunded_amount" />
                                            </div>
                                        </th>
                                        {/* <th className="created_head" width="25%">
                                            <div className='sorting_column'>
                                                <span>Reason For Refund</span>
                                                
                                            </div>
                                        </th> */}
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Payment Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="payment_status" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="20%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className="created_head text-end" width="5%">
                                            <span>Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}>
                                                <Loader />
                                            </td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{row?.order_id}</td>
                                                    <td>{row?.order_mode_configuration?.name ? capitalizeFirstLetter(removeHyphens(row?.order_mode_configuration.name)) : 'N/A'}</td>
                                                    <td>{row?.aggregators_configuration?.name ? capitalizeFirstLetter(removeHyphens(row?.aggregators_configuration.name)) : 'N/A'}</td>
                                                    <td>{row?.user ? `${capitalizeFirstLetter(row?.user?.first_name)} ${capitalizeFirstLetter(row?.user?.last_name)}` : 'N/A'}</td>
                                                    <td>{row?.outlet?.outlet_name ? capitalizeFirstLetter(row?.outlet.outlet_name) : 'N/A'}</td>
                                                    <td>{row?.customer?.customer_name ? row?.customer.customer_name : 'N/A'}</td>
                                                    <td>{row.transaction?.refunded_amount ? (row?.transaction?.refunded_amount + " " + "SAR") : "N/A"}</td>
                                                    {/* <td>{row.transaction?.refund_reason ? capitalizeFirstLetter(TrimText(row?.transaction?.refund_reason,25)) : "N/A"}</td> */}
                                                    {/* <td>{showReason(row)}</td> */}
                                                    <td>{row.transaction?.payment_status ? capitalizeFirstLetter(row?.transaction?.payment_status) : "N/A"}</td>
                                                    <td>{row?.created_at ? formateDate(row?.created_at) : 'N/A' }</td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_refund', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                            : ''}
                                                                </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                  <td colSpan={12} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No records</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "customer", path: "/company/transaction/refund/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default RefundList