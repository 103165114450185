import React, { useEffect, useMemo, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetAllInventories } from '../../services/company_panel/inventoryService';
import { getInventoriesFromSupplier, updateSupplierInventoryRelation } from '../../services/company_panel/supplierService';
import { getBrandId, getCompany } from '../../helpers/commonFunctions';
import Swal from 'sweetalert2';

const AssignItemModal = ({ show, handleClose, data }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [filteredInventories, setFilteredInventories] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (data.id) {
            getInventoriesFromSupplier({ id: data.id }).then(response => {
                const supplierInventoriesRelations = response?.data?.response?.rows || [];
                const inventories = supplierInventoriesRelations.map(relation => relation.inventory_id);
                setSelectedItems(inventories);
            }).catch(error => {
                console.log("error=====>", error);
            });

            GetAllInventories({ brand_id: getBrandId() }).then(response => {
                const tempInventories = response?.data?.response?.rows || [];
                setInventories(tempInventories);
                setFilteredInventories(tempInventories);
            }).catch(error => {
                console.log("error=====>", error);
            });
        }
    }, [data, isBrandChanged]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredInventories(inventories);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = inventories.filter(item =>
                item.item_name.toLowerCase().includes(lowercasedQuery)
            );
            setFilteredInventories(filtered);
        }
    }, [searchQuery, inventories]);

    const handleCheckboxChange = (itemId) => (event) => {
        if (event.target.checked) {
            setSelectedItems([...selectedItems, itemId]);
        } else {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        }
    };

    const handleAssign = () => {
        Swal.fire({
            customClass: 'swal-wide',
            position: 'absolute',
            icon: 'success',
            title: "Item assigned successfully",
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            position: 'top-right',
        });

        updateSupplierInventoryRelation({
            id: data.id,
            inventories: selectedItems,
            company_id: getCompany().company_id
        }).then(response => {
            console.log('response', response);
        }).catch(error => {
            console.log("error=====>", error);
        });

        handleClose();
    };

    return (
        <div className={`modal fade ${show ? 'show d-block' : 'd-none'}`} tabIndex="-1" role="dialog"   >
            <div className="modal-dialog  custom-model" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title " style={{ fontSize: '22px' }}>Assign Item</h5>
                        <button type="button" className="close" onClick={()=>{handleClose(); setSearchQuery(''); }}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            type="text"
                            className="form-control mb-3 "
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {filteredInventories.length > 0 ? (
                            filteredInventories.map(item => (
                                <div key={item.id} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input cp"
                                        id={`item-${item.id}`}
                                        checked={selectedItems.includes(item.id)}
                                        onChange={handleCheckboxChange(item.id)}
                                    />
                                    <label className="form-check-label cp" htmlFor={`item-${item.id}`}>
                                        {item.item_name}
                                    </label>
                                </div>
                            ))
                        ) : (
                            <p className='text-danger text-center'>No raw material found</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary" onClick={() => { handleClose(); setSelectedItems([]); setSearchQuery('')}}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-primary"   disabled={selectedItems.length === 0} onClick={handleAssign}>
                            Assign
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignItemModal;
