import React, { useState,useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import { GetUsersList } from '../../services/platformUserServices';
import Swal from 'sweetalert2'
import CustomPagination from '../../components/common/custompagination'
import { formateDate } from '../../helpers/commonFunctions';
import Sorting from '../../components/common/sorting';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import StatusFilter from '../../components/common/statusFilter'
import { showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/common/rangepicker'
import $ from "jquery";

const PlatformUsersTable = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" },{ title: "Platform Users", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const companyId = queryParams.get('id');

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            GetUsersList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
         
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        // prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // view details functionality

    const viewFunction = (row) => {
        navigate(`/admin/platform-users/view/${row}`)
    }

   
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


  return (
    <Layout>
       <Breadcrums data={breadcrumbs} />
        <div className="animation_fade">
            <div className="card custom-card overflow-hidden">
            <div className="card-body">
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h6 className="main-content-label">Users List</h6>
                    <div className='d-flex align-items-center'>
                        <div className='form-group mb-0 me-3'>
                            <div className="form-group mb-0 rangepicker_container">
                                <i className="fa fa-search calender_icon"></i>
                                <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                            </div>
                        </div>
                        <div className="me-3">
                        <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate}/>
                        </div>
                        <div className="me-3">
                            <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                        </div>
                        <button type="reset" value='Reset' onClick={resetFilter}  className="btn btn-warning float-right mr-2">Reset Filter</button>
                        {/* <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/brand/add`)}>Add New</button> */}
                    </div>
                </div>
                <div className="table-responsive">
                    <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th className="sr_head" width="10%">S.No</th>
                                {/* <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Profile Image</span>
                                        
                                    </div>
                                </th> */}
                                <th width="15%">
                                    <div className='sorting_column'>
                                        <span>Name</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                    </div>
                                </th>
                                <th width="15%">
                                    <div className='sorting_column'>
                                        <span>Email</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="email" />
                                    </div>
                                </th>
                                <th width="15%">
                                    <div className='sorting_column'>
                                        <span>Company</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="company" />
                                    </div>
                                </th>
                                <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Company email</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="company-email" />
                                    </div>
                                </th>
                                <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Brand name</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="brand" />
                                    </div>
                                </th>
                                <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Role</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="role" />
                                    </div>
                                </th>
                                <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Outlet</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="outlet" />
                                    </div>
                                </th>
                                <th width="10%">
                                    <div className='sorting_column'>
                                        <span>Outlet Email</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="outlet-email" />
                                    </div>
                                </th>
                                {/* <th className="created_head" width="20%">
                                    <div className='sorting_column'>
                                        <span>Created date</span>
                                        <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                    </div>
                                </th> */}
                                {/* <th className='status_head' width="15%">Status</th> */}
                                <th className='action_head' width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loader ?
                            <tr>
                                <td colSpan={11}><Loader /></td>
                            </tr> : <>
                        {list.length ? list.map((row, i) => (
                            <tr>
                            <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td>
                            {/* <td>{row.logo?<img src={row.logo} style={{ height: "50px" }}/>:''}</td> */}
                            <td>{row.first_name} {row.last_name}</td>
                            <td>{row.email}</td>
                            <td>{row.company_name}</td>
                            <td>{row.company_email}</td>
                            <td>{row.brandname}</td>
                            <td>{row.rolename}</td>
                            <td>{row.outletname}</td>
                            <td>{row.outletemail}</td>
                            {/* <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td> */}
                           
                            <td>
                                <div className="d-flex">
                                    <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewFunction(row.id) }}>View</button>
                                </div>
                            </td>
                            </tr>
                            )) : <tr><td colSpan={11} className="text-center">No records</td></tr>}</>}
                        </tbody>
                    </table>
                </div>
                <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                {dataLength && dataLength > 0 ?
                <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "platformUsers", path: "/admin/platform-users/list" }]} /> : ""}
            </div>
            <div>
            </div>
            </div>
        </div>
    </Layout>
  );
}

export default PlatformUsersTable