import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomPagination from '../../../components/company_panel/common/custompagination';
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import * as yup from 'yup';
import { AddUom, DeleteUom, ListUom, UomDetails, UpdateUom } from '../../../services/company_panel/setting/uomConfigurationService';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, checkPermission, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import notFound from '../../../assets/company/img/no-record-found.png'
import { LOADER_TIME } from '../../../utils/Constants';

const UOMList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },];
    const [submitted, setSubmitted] = useState(false);
    const addCardModalCloseBtn = useRef();
    const params = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);;
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [showDefault, setShowDefault] = useState({});
    const [actionType, setActionType] = useState('add');
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append("company_id", getCompany().company_id)
            formData.append("brand_id", getBrandId())
            ListUom(formData).then(data => {

                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data?.data?.response?.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged])

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteUom(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'UOM deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError", error)
                })
            }
        })
    }



    // edit functionality

    const editFunction = (row) => {

        UomDetails({ id: row }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        // $('#addCardModal').show().css('opacity', 1).addClass('show');
        // $('body').append('<div className="modal-backdrop fade show"></div>')
    }

    const copyFunction = (row) => {

        UomDetails({ id: row }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('Add');
        // $('#addCardModal').show().css('opacity', 1).addClass('show');
        // $('body').append('<div className="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    // const prepareSearch = (key, value) => {
    //     let sr = { ...search };
    //     if (String(value).length > 0) {
    //         sr[key] = value;
    //     } else {
    //         delete sr[key];
    //     }
    //     setSearch(sr);
    // }
    // search or filer end




    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
        formik.setErrors({});
        formik.resetForm();
    }

    const openModel = (id, type) => {
        formik.setErrors({});
        formik.resetForm();
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div className="modal-backdrop fade show"></div>')
    }



    // Add and Edit Data
    const validationSchema = yup.object().shape({
        uom: yup.string().trim().required('UOM is required').max(50, "UOM cannot exceed 50 digits"),
        uom_code: yup.string().trim().required('UOM code is required').max(30, "UOM code cannot exceed 30 digits"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            uom: showDefault && showDefault?.uom ? showDefault?.uom : "",
            uom_code: showDefault && showDefault?.uom_code ? showDefault?.uom_code : "",
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            setSubmitted(true)
            let formData = new FormData();
            formData.append('uom', values.uom.trim());
            formData.append('uom_code', values.uom_code.trim());
            formData.append("company_id", getCompany().company_id);
            formData.append("brand_id", getBrandId());

            if (actionType === 'edit') {
                formData.append('id', showDefault.id);
                UpdateUom(formData).then(response => {

                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            // $('#addCardModal').hide();
                            // $('.modal-backdrop').remove();
                            addCardModalCloseBtn?.current?.click();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            } else {
                AddUom(formData).then(response => {


                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }
        },
    });

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='uom' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>

                            </div>
                            <div className='page-header-right ms-auto'>
                                {checkPermission('store_settings', 'add') ?
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addCardModal"
                                        // onClick={(e) => openModel('addCardModal', 'add')}
                                        onClick={() => setActionType('add')}
                                    >Add UOM</button>
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>ID  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="30%">
                                            <div className='sorting_column'>
                                                <span>UOM</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="uom" />
                                            </div>
                                        </th>
                                        <th width="30%">
                                            <div className='sorting_column'>
                                                <span>UOM Code</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="uom_code" />
                                            </div>
                                        </th>
                                        <th className='action_head text-end' width="20%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{row?.id}</td>
                                                    <td>{capitalizeFirstLetter(row?.uom)}</td>
                                                    <td>{capitalizeFirstLetter(row?.uom_code)}</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {checkPermission('store_settings', 'edit') ?
                                                                    <li><div className='dropdown-item' type="button" data-bs-toggle="modal" data-bs-target="#addCardModal" onClick={() => { editFunction(row.id) }}><i className="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                    : ''}
                                                                {
                                                                    row?.slug != 'default' || checkPermission('store_settings', 'delete') ?
                                                                        <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i className="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                        : null
                                                                }
                                                                {checkPermission('store_settings', 'add') ?
                                                                    <li> <div className='dropdown-item' type="button" data-bs-toggle="modal" data-bs-target="#addCardModal" onClick={() => { copyFunction(row?.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy UOM</div></li>
                                                                    : ''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div className="" id="example1_info table-result" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/setting/order-mode-configuration/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>

                {/*  Modal*/}

                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} UOM</h2>
                                <button type="button" className="btn-close" ref={addCardModalCloseBtn} onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="uom" className="col-form-label">UOM<span className=' requirestar'>*</span></label>
                                        <input type="text" className="form-control" id="uom" name="uom" placeholder='Enter UOM' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.uom} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.uom && formik.touched.uom && formik.errors.uom}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="uom_code" className="col-form-label">UOM Code<span className=' requirestar'>*</span></label>
                                        <input type="text" className="form-control" name="uom_code" id="uom_code" placeholder='Enter UOM Code' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.uom_code} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.uom_code && formik.touched.uom_code && formik.errors.uom_code}</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default UOMList;