import axiosInstance from "../utils/axios";

export const GetSubscriptionsList = async (values) => {
    return await axiosInstance().post("admin/subscription/get-all-subscriptions", values);
}

export const GetSingleSubscription = async (values) => {
    return await axiosInstance().post("admin/subscription/get-single-subscription", values);
}

export const editSubscription = async (values) => {
    return await axiosInstance().post("admin/subscription/update-subscription", values);
}

export const GetSubscriptionFromCompanyId = async (values) => {
    return await axiosInstance().post("admin/subscription/get-subscription-from-company-id", values);
}
export const GetSubscriptionsHistory = async (values) => {
    return await axiosInstance().post("admin/subscription/subscription-history", values);
}
export const CancelSubscription = async (values) => {
    return await axiosInstance().post("admin/subscription/cancel-subscription", values);
}
export const RenewSubscription = async (values) => {
    return await axiosInstance().post("admin/subscription/renew-subscription", values);
}
export const UpgradeSubscription = async (values) => {
    return await axiosInstance().post("admin/subscription/upgrade-subscription", values);
}