import axiosInstance from "../../../utils/axiosCompany";

export const CreateAggregator = async (values) => {
    return await axiosInstance().post("company/aggregator-configuration/add-aggregator", values);
}

export const GetAggregatorList = async (values) => {
    return await axiosInstance().post("company/aggregator-configuration/get-all-aggregators", values);
}

export const GetEditAggregator  = async (values) => {
    return await axiosInstance().put("company/aggregator-configuration/update-aggregator", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/aggregator-configuration/change-status", values)
}

export const DeleteAggregator = async (id) => {
    return await axiosInstance().delete(`company/aggregator-configuration/delete-aggregator/${id}`)
}

export const GetAggregatorDetail = async (id) => {
    return await axiosInstance().get(`company/aggregator-configuration/get-single-aggregator/${id}`);
};

export const GetAggregatorAllDetails = async (values) => {
    return await axiosInstance().post(`company/aggregator-configuration/get-aggregators`, values);
};

