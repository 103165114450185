import React, { useEffect, useState } from 'react'
import Layout from '../layout'
import PageHeaders from '../components/common/pageHeader'
import { useMemo } from 'react';
import { Bar } from "react-chartjs-2";

const Dashboard = () => {


	return (
		<>
			<Layout>
				<PageHeaders pageName={"Welcome Admin"} subHeading={"Home"} path={[{ name: "dashboard", url: "/admin/dashboard" }]} />
				    <div className="row row-sm animation_fade">
        <div className="col-sm-12 col-lg-12 col-xl-12">
          <div className="row row-sm">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          Company
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                          </h4>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16v9zm-4.785-6.145a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43c0 .238.192.425.43.425zm.336.563h-.672v4.105h.672V8.418zm-6.867 4.105v-2.3h2.261v-.61H4.684V7.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V9.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          Device Management
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                          </h4>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6">
              <div className="card custom-card mg-b-20">
                <div className="card-body">
                  <div className="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                    <div>
                      <label className="main-content-label mb-4">
                        Registered Company
                      </label>
                    </div>
                    <div className="ml-auto d-flex align-items-center">
                        <div className="form-group mb-0 ms-2 rangepicker_container filter_design">
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
			</Layout>
		</>
	)
}

export default Dashboard
