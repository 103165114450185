import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import $ from "jquery";
import { Link } from 'react-router-dom';
// import Logo from "../../assets/img/brand/Mask Group 1.png"
import { getUser, logOutUser } from '../../helpers/commonFunctions';
import { GetLogo } from '../../services/settingsService';
import { useNavigate, useParams } from 'react-router-dom';

const SideBar = () => {
    const loc = useLocation();
    const params= useParams();
    
    const [showDefault, setShowDefault] = useState({});
    useEffect(() => {
        GetLogo(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const toggleDropdown = (e) => {
        e.preventDefault();
        const dropDown = e.target.parentElement;
        if (dropDown.classList.contains('show')) {
            dropDown.classList.remove('show');
        } else {
            dropDown.classList.add('show');
        }
    }

    const hoverSidebar = (e) => {
        if ($("body").hasClass("main-sidebar-hide")) {
            $("body").addClass("main-sidebar-open");
        } else {
            $("body").removeClass("main-sidebar-open");
        }
    }

    const removeHoverSidebar = (e) => {
        $("body").removeClass("main-sidebar-open");
    }

    return (
        <div className="main-sidebar main-sidebar-sticky side-menu ps ps--active-y" id='app_sidebar' onMouseEnter={hoverSidebar} onMouseLeave={removeHoverSidebar}>
            <div className="sidemenu-logo">
                <a className="main-logo" href="/admin/dashboard">
                    {/* <img src={Logo} className="header-brand-img desktop-logo" alt="logo" style={{ height: "35px", width: "35px" }} />
                    <img src={Logo} className="header-brand-img icon-logo" alt="logo" style={{ height: "40px", width: "40px" }} /> */}
                    {/* <h3 className="mt-4 text-white " style={{textTransform: 'none'}}> Point of sale</h3> */}
                    <img alt="logo" src={showDefault.logo} className="header-brand-img desktop-logo" style={{}}/>
                    {/* <img src="../assets/img/brand/logo.png" className="header-brand-img desktop-logo theme-logo" alt="logo" />
                    <img src="../assets/img/brand/icon.png" className="header-brand-img icon-logo theme-logo" alt="logo" /> */}
                </a>
            </div>
            <div className="main-sidebar-body">
                <ul className="nav">
                    <li className={"nav-item" + (loc.pathname === "/admin/dashboard" ? " active" : "")}>
                        <Link className="nav-link" to="/admin/dashboard">
                            <i className="ti-home sidemenu-icon"></i>
                            <span className="sidemenu-label">Dashboard</span>
                        </Link>
                    </li>

                    {/* cms section start */}
                    {/* <li className={`nav-item ${loc.pathname.includes("/admin/content") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/admin/content/list/${1}`}>
                            <i className="ti-book sidemenu-icon"></i> 
                            <span className="sidemenu-label">Pages</span>
                        </Link>
                    </li> */}
                    
                    <li className={`nav-item ` + (loc.pathname.includes("/company") || 
                    loc.pathname.includes("/company-request") || loc.pathname.includes("/platform-users") ? "show active" : "")}>
                        <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"#"}>
                            <span className="shape1"></span><span className="shape2"></span>
                            <i className="ti-bag sidemenu-icon"></i> 
                            <span className="sidemenu-label">Companies</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </a>
                        <ul className="nav-sub">
                            <li className={"nav-sub-item" + (loc.pathname.includes("/admin/company") || loc.pathname.includes("/brand") || loc.pathname.includes("/outlet-management") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/admin/company/list/${1}`}>
                                    Company</Link>
                            </li>
                            <li className={"nav-sub-item" + (loc.pathname.includes("/admin/company-request") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/admin/company-request/list/${1}`}>
                                    Company Inquiry</Link>
                            </li>
                            {/* <li className={"nav-sub-item" + (loc.pathname.includes("/admin/platform-users") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/admin/platform-users/list/${1}`}>
                                    Platform Users</Link>
                            </li> */}

                            {/* <li className={"nav-sub-item" + (loc.pathname.includes("admin/brand") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/admin/brand/list/${1}`}>
                                Brand</Link>
                            </li>
                            <li className={"nav-sub-item" + (loc.pathname.includes("/admin/outlet-management") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/admin/outlet-management/list/${1}`}>
                                Outlets</Link>
                            </li> */}
                        </ul>
                    </li>


                     {/* <li className={"nav-item" + (loc.pathname.includes("/admin/outlet-management") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/outlet-management/list/${1}`}>
                            <i className="ti-bar-chart sidemenu-icon"></i>
                            <span className="sidemenu-label">Outlet management</span>
                        </Link>
                    </li> */}
                    {/* <li className={"nav-item" + (loc.pathname.includes("/admin/banner") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/banner/list/${1}`}>
                            <i className="ti-flag sidemenu-icon"></i> 
                            <span className="sidemenu-label">Banner</span>
                        </Link>
                    </li> */}
                    <li className={"nav-item" + (loc.pathname.includes("/admin/email-templates") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/email-templates/${1}`}>
                            <i className="ti-email sidemenu-icon"></i>
                            <span className="sidemenu-label">Email Templates</span>
                        </Link>
                    </li>
                    <li className={"nav-item" + (loc.pathname.includes("/admin/subscription") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/subscription`}>
                            <i className="ti-email sidemenu-icon"></i>
                            <span className="sidemenu-label">Subscriptions</span>
                        </Link>
                    </li>
                    <li className={"nav-item" + (loc.pathname.includes("/admin/devices-management") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/devices-management/list/1`}>
                            <i className="ti-email sidemenu-icon"></i>
                            <span className="sidemenu-label">Devices Management</span>
                        </Link>
                    </li> 
                    <li className={"nav-item" + (loc.pathname.includes("/admin/settings") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/settings`}>
                            <i className="ti-settings sidemenu-icon"></i> 
                            <span className="sidemenu-label">Global Settings</span>
                        </Link>
                    </li>

                    <li className={"nav-item" + (loc.pathname.includes("/admin/socket") ? " active" : "")}>
                        <Link className="nav-link" to={`/admin/socket`}>
                            <i className="ti-settings sidemenu-icon"></i> 
                            <span className="sidemenu-label">Socket</span>
                        </Link>
                    </li>
                            {/* <li className={"nav-sub-item" + (loc.pathname.includes("/terms") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/terms/${1}`}>Terms</Link>
                            </li> */}
                            {/* <li className={"nav-sub-item" + (loc.pathname.includes("/country") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/country/${1}`}>Country</Link>
                            </li> */}
                            {/* <li className={"nav-sub-item" + (loc.pathname.includes("/languages") ? " active" : "")}>
                                <Link className="nav-sub-link" to={`/languages/${1}`}>Languages</Link>
                            </li> */}
                    
                    {/* cms section end */}

                </ul>
            </div >
        </div >
    )
}

export default SideBar;