import React, { useState,useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import { GetChangeStatus, GetBrandList, GetDeleteBrand } from '../../services/brandService'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/common/custompagination'
import { formateDate } from '../../helpers/commonFunctions';
import Sorting from '../../components/common/sorting';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import StatusFilter from '../../components/common/statusFilter'
import { showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/common/rangepicker'
import $ from "jquery";
import ViewAllDetails from '../company/viewAllDetails'

const BrandTable = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Companies", url: "/admin/company/list/1" },{ title: "Brands", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);

    const companyId = params.id;
    const pageNo= queryParams.get('id')
    
    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", pageNo)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append("company_id", companyId)
            GetBrandList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        // prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                GetDeleteBrand(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Record deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // view details functionality

    const viewFunction = (row) => {
        navigate(`/admin/brand/view/${row}?id=${companyId}`)
    }

    // edit functionality

    const editFunction = (row) => {
        navigate(`/admin/brand/${pageNo}/edit/${row.id}`)
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


  return (
    <Layout>
       <Breadcrums data={breadcrumbs} />
        <div className="animation_fade">
            <div className="card custom-card overflow-hidden">
            <div className="card-body">
                <ViewAllDetails tab='brands'/>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div className='d-flex align-items-center'>
                        <div className='form-group mb-0 me-3'>
                            <div className="form-group mb-0 rangepicker_container">
                                <i className="fa fa-search calender_icon"></i>
                                <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                            </div>
                        </div>
                        <div className="me-3">
                        <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate}/>
                        </div>
                        <div className="me-3">
                            <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                        </div>
                        <button type="reset" value='Reset' onClick={resetFilter}  className="btn btn-warning float-right mr-2">Reset Filter</button>
                        {/* <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/brand/add`)}>Add New</button> */}
                    </div>
                </div>
                <div className="table-responsive">
                    <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th className="sr_head" width="10%">S.No</th>
                                <th width="20%">
                                    <div className='sorting_column'>
                                        <span>Logo</span>
                                        
                                    </div>
                                </th>
                                <th width="25%">
                                    <div className='sorting_column'>
                                        <span>Name</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                    </div>
                                </th>
                                <th className="created_head" width="20%">
                                    <div className='sorting_column'>
                                        <span>Created date</span>
                                        <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                    </div>
                                </th>
                                <th className='status_head' width="15%">Status</th>
                                <th className='action_head' width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loader ?
                            <tr>
                                <td colSpan={11}><Loader /></td>
                            </tr> : <>
                        {list.length ? list.map((row, i) => (
                            <tr>
                            <td>{(pageNo * itemPerPage) - (itemPerPage) + (++i)}</td>
                            <td>{row.logo?<img src={row.logo} style={{ height: "50px" }}/>:''}</td>
                            <td>{row.name}</td>
                            <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                            {/* <td>
                            {row.status == 1 ? <button className="btn ripple btn-main-primary signbtn">Active</button>
                                                            : <button className="btn ripple btn-secondary" >Inactive</button>}                                                                
                            </td>  */}
                            <td>
                            {row.status == 1 ? <span>Active</span> : <span >Inactive</span>}                                                                
                            </td> 
                            <td>
                                <div className="d-flex">
                                    <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewFunction(row.id) }}>View</button>
                                </div>
                            </td>
                            </tr>
                            )) : <tr><td colSpan={11} className="text-center">No records</td></tr>}</>}
                        </tbody>
                    </table>
                </div>
                <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                {dataLength && dataLength > 0 ?
                <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "brand", path: "/admin/brand/list" }]} /> : ""}
            </div>
            <div>
            </div>
            </div>
        </div>
    </Layout>
  );
}

export default BrandTable