import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GetProfileDetail } from '../../services/company_panel/LoginCompanyServices';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { capitalizeAllFirstLetters } from '../../helpers/commonFunctions';


const ViewProfile = () => {
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "My Profile", url: "" }]

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("company_user"))
        GetProfileDetail(id.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [])


    return (
        <>
            <div className='page-header d-flex align-items-center'>
                <div className='page-header-left'>
                    <h4>My Profile</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div className='page-header-right ms-auto'>
                    <Link className="btn btn-primary" to={"/company/profile/edit"}>
                        Edit Profile
                    </Link>
                </div>
            </div>
            <div className="card custom-card">
                <div className='card-body'>
                    <div class="brand-banner">
                        <div class="avatar avatar-xl mx-auto">
                            {showdefault && showdefault.profile_image ? <img src={showdefault.profile_image}/> : ""}
                        </div>
                        <h4> {showdefault && showdefault.first_name ? capitalizeAllFirstLetters(showdefault.first_name) : ""} {showdefault && showdefault.last_name ? capitalizeAllFirstLetters(showdefault.last_name) : ""} </h4>
                    </div>
                    <div className='profile-info row mt-5'> 
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Name</h5>
                                <p>{showdefault && showdefault.first_name ? capitalizeAllFirstLetters(showdefault.first_name) : ""} {showdefault && showdefault.last_name ? capitalizeAllFirstLetters(showdefault.last_name) : ""}</p>
                            </div>
                            <div className='profile-info-in mt-3'>
                                <h5>Phone Number</h5>
                                <p>{showdefault && showdefault.country_code?'+'+showdefault.country_code:''} {showdefault && showdefault.mobile ? showdefault.mobile : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Name (Arabic)</h5>
                                <p>{showdefault && showdefault.first_name_ar ? capitalizeAllFirstLetters(showdefault.first_name_ar) : ""} {showdefault && showdefault.last_name_ar ? capitalizeAllFirstLetters(showdefault.last_name_ar) : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Email</h5>
                                <p>{showdefault && showdefault.email ? showdefault.email : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewProfile