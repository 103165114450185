import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from './auth';

const AdminPublicRoute = ({ component: Component }) => {
    const token = getToken();
    
    return (
        !token ? <Component /> : <Navigate to={{ pathname: "/admin/dashboard" }} />
    )

}

export default AdminPublicRoute;