import axiosInstance from "../../../utils/axiosCompany";

export const CreateTender = async (values) => {
    return await axiosInstance().post("company/tender-configuration/add-tender", values);
}

export const GetTenderList = async (values) => {
    return await axiosInstance().post("company/tender-configuration/get-all-tenders", values);
}

export const GetEditTender  = async (values) => {
    return await axiosInstance().put("company/tender-configuration/update-tender", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/tender-configuration/change-status", values)
}

export const DeleteTender = async (id) => {
    return await axiosInstance().delete(`company/tender-configuration/delete-tender/${id}`)
}

export const GetTenderDetail = async (id) => {
    return await axiosInstance().get(`company/tender-configuration/get-single-tender/${id}`);
};

