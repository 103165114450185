import React, { useEffect, useMemo, useState } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { getCompany, getOutletId, isOnline, logOutCompany } from '../../../helpers/commonFunctions';
import { GetBrandOutletsList, GetBrandWithOutlets, GetBrandAndOutletList } from '../../../services/company_panel/outletService';
import { Dropdown } from 'semantic-ui-react';
import { getAllBrandWithOutlets, getBrandWithOutlets, saveBrandWithOutlets } from '../../../helpers/indexedDB';


const Header = ({ updateHeader = null }) => {
	const [userData, setUserData] = useState({});
	const [outletList, setOutletList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [isBrandChanged, setIsBrandChanged] = useState(false);
	const [isOutletChanged, setIsOutletChanged] = useState(false);
	const [isOutletListModified, setIsOutletListModified] = useState(false);
	const [isBrandListModified, setIsBrandListModified] = useState(false);

	const [brandId, setBrandId] = useState(JSON.parse(localStorage.getItem('brand_id')));
	const [outletId, setOutletId] = useState(JSON.parse(localStorage.getItem('outlet_id')));

	const handleReload = () => {
		window.location.reload();
	};

	useEffect(() => {

		if(isOnline()){
			GetBrandWithOutlets(getCompany().company_id).then(data => {
				const allBrands = data && data.data && data.data.response ? data.data.response : [];
				const brandDropdown = allBrands.map((res) => ({
					key: res.id,
					text: res.name.charAt(0).toUpperCase() + res.name.slice(1),
					value: res.id,
				}))
				setBrandList(brandDropdown);
				if (allBrands.length) {
					const selectedBrandId = localStorage.getItem('brand_id');
					if (selectedBrandId) {
						const selectedBrand = allBrands.filter(item => item.id === Number(selectedBrandId))[0];
						if (selectedBrand) {
							setBrandId(Number(selectedBrandId));
						} else {
							setBrandId(allBrands[0].id);
							localStorage.setItem('brand_id', allBrands[0].id);
						}
					} else {
						setBrandId(allBrands[0].id);
						localStorage.setItem('brand_id', allBrands[0].id);
					}
				}
				GetBrandOutletsList({ brand_id: JSON.parse(localStorage.getItem('brand_id')) }).then(data => {
					const allOutlet = data && data.data && data.data.response ? data.data.response : []
					const outletDropdown = allOutlet.map((res) => ({
						key: res.id,
						text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
						value: res.id,
					}))
					setOutletList(outletDropdown)
					if (allOutlet.length) {
						// setOutletId(localStorage.getItem('outlet_id') ? JSON.parse(localStorage.getItem('outlet_id')) : allOutlet[0].id );
						// localStorage.setItem(
						// 	'outlet_id', 
						// 	localStorage.getItem('outlet_id') ? JSON.parse(localStorage.getItem('outlet_id')) : allOutlet[0].id
						// );
						const selectedId = localStorage.getItem('outlet_id');
						if (selectedId) {
							const selectedOutlet = allOutlet.filter(item => item.id === Number(selectedId))[0];
							if (selectedOutlet) {
								setOutletId(Number(selectedId));
							} else {
								setOutletId(allOutlet[0].id);
								localStorage.setItem('outlet_id', allOutlet[0].id);
							}
						} else {
							setOutletId(allOutlet[0].id);
							localStorage.setItem('outlet_id', allOutlet[0].id);
						}
					}
				}).catch(error => {
					console.log("error=====>", error)
				})
			}).catch(error => {
				console.log("brand with outlets error=====>", error)
			})
		}else{
			//IndexedDB service
			getAllBrandWithOutlets().then((brandsWithOutlets)=>{
				const brandDropdown = brandsWithOutlets.map((res) => ({
					key: res.brand_id,
					text: res.brand_name.charAt(0).toUpperCase() + res.brand_name.slice(1),
					value: res.brand_id,
				}))
				setBrandList(brandDropdown);
				if (brandsWithOutlets.length) {
					const selectedBrandId = localStorage.getItem('brand_id');
					if (selectedBrandId) {
						const selectedBrand = brandsWithOutlets.filter(item => item.brand_id === Number(selectedBrandId))[0];
						if (selectedBrand) {
							setBrandId(Number(selectedBrandId));
						} else {
							setBrandId(brandsWithOutlets[0].brand_id);
							localStorage.setItem('brand_id', brandsWithOutlets[0].brand_id);
						}
					} else {
						setBrandId(brandsWithOutlets[0].brand_id);
						localStorage.setItem('brand_id', brandsWithOutlets[0].brand_id);
					}
				}
	
				const selectedBrandId = localStorage.getItem('brand_id');
				const outlets = brandsWithOutlets.find(brand => brand.brand_id == selectedBrandId).outlets;
				const outletDropdown = outlets.map((res) => ({
					key: res.outlet_id,
					text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
					value: res.outlet_id,
				}))
				setOutletList(outletDropdown)
				if (outlets.length) {
					const selectedId = localStorage.getItem('outlet_id');
					if (selectedId) {
						const selectedOutlet = outlets.filter(item => item.outlet_id === Number(selectedId))[0];
						if (selectedOutlet) {
							setOutletId(Number(selectedId));
						} else {
							setOutletId(outlets[0].outlet_id);
							localStorage.setItem('outlet_id', outlets[0].outlet_id);
						}
					} else {
						setOutletId(outlets[0].outlet_id);
						localStorage.setItem('outlet_id', outlets[0].outlet_id);
					}
				}
			}).catch(error=>{
				console.log(error);
			})
		}
	}, [isBrandListModified, isOutletListModified])

	useEffect(() => {
		if(isOnline()){
			GetBrandOutletsList({ brand_id: JSON.parse(localStorage.getItem('brand_id')) }).then(data => {
				const allOutlet = data && data.data && data.data.response ? data.data.response : []
				const outletDropdown = allOutlet.map((res) => ({
					key: res.id,
					text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
					value: res.id,
				}))
				setOutletList(outletDropdown)
				if (allOutlet.length) {
					// setOutletId(allOutlet[0].id);
					// localStorage.setItem('outlet_id', allOutlet[0].id);
					const selectedId = getOutletId();
					if (selectedId) {
						const selectedOutlet = allOutlet.filter(item => item.id === Number(selectedId))[0];
						if (selectedOutlet) {
							setOutletId(Number(selectedId));
						} else {
							setOutletId(allOutlet[0].id);
							localStorage.setItem('outlet_id', allOutlet[0].id);
						}
					} else {
						setOutletId(allOutlet[0].id);
						localStorage.setItem('outlet_id', allOutlet[0].id);
					}
					// handleReload();
					const evt = new CustomEvent('brand-changed', { detail: { brandID: data } });
					document.dispatchEvent(evt);
				}
	
			}).catch(error => {
				console.log("error=====>", error)
			})
		}else{
			const selectedBrandId = JSON.parse(localStorage.getItem('brand_id'));
			getBrandWithOutlets(selectedBrandId).then((brandWithOutlets)=>{
				const outletDropdown = brandWithOutlets.outlets.map((res) => ({
					key: res.outlet_id,
					text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
					value: res.outlet_id,
				}))
				setOutletList(outletDropdown)
				if (brandWithOutlets.outlets.length) {
					const selectedId = getOutletId();
					if (selectedId) {
						const selectedOutlet = brandWithOutlets.outlets.filter(item => item.outlet_id === Number(selectedId))[0];
						if (selectedOutlet) {
							setOutletId(Number(selectedId));
						} else {
							setOutletId(brandWithOutlets.outlets[0].outlet_id);
							localStorage.setItem('outlet_id', brandWithOutlets.outlets[0].outlet_id);
						}
					} else {
						setOutletId(brandWithOutlets.outlets[0].outlet_id);
						localStorage.setItem('outlet_id', brandWithOutlets.outlets[0].outlet_id);
					}
					// handleReload();
					const evt = new CustomEvent('brand-changed', { detail: { brandID: brandWithOutlets.outlets } });
					document.dispatchEvent(evt);
				}
			}).catch(error=>{
				console.log(error);
			})
		}
	}, [isBrandChanged, isBrandListModified, isOutletListModified]);

	//this is for offline mode
	useEffect(()=>{
		GetBrandAndOutletList().then(data => {
			const brands = data && data.data && data.data.response ? data.data.response : []
			
			let storeData=[];
			brands.forEach(brand=>{
				let outlets=[];
				brand.outlets.forEach(outlet=>{
					outlets.push({outlet_name : outlet.outlet_name, outlet_id : outlet.id})
				})
				storeData.push({brand_id : brand.id, brand_name : brand.name, outlets : outlets});
			})
			saveBrandWithOutlets(storeData)
			.then(() => {
				console.log('All brands and outlets saved.');
			})
			.catch((error) => {
				console.error('Error saving data:', error);
			});
		}).catch(error => {
			console.log("error=====>", error)
		})
	},[])

	const logout = () => {
		Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You want to logout!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#2f9bff',
			cancelButtonColor: '#dc3545',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.isConfirmed) {
				logOutCompany();
			}
		})

	}
	useEffect(() => {
		setUserData(getCompany());
	}, [updateHeader])



	const toggleSidebar = (e) => {
		e.preventDefault();
		if ($("body").hasClass("main-sidebar-hide")) {
			$("body").removeClass("main-sidebar-hide")
		} else {
			$("body").addClass("main-sidebar-hide")
		}
	}

	$(document).ready(function () {
		$('.showpop').click(function () {
			$('.main-profile-menu').addClass("show");
		})
		$('.fixed_bg').click(function () {
			$('.main-profile-menu').removeClass("show");
		})
	});
	function handleChangeBrand(data) {
		setBrandId(data);
		localStorage.setItem('brand_id', data);
		setTimeout(() => {
			setIsBrandChanged(!isBrandChanged);
			// const evt = new CustomEvent('brand-changed', { detail: { brandID: data } });
			// document.dispatchEvent(evt);
		}, 100)
	}
	function handleChangeOutlet(data) {
		setOutletId(data);
		localStorage.setItem('outlet_id', data);
		setTimeout(() => {
			const evt = new CustomEvent('outlet-changed', { detail: { outletID: data } });
			document.dispatchEvent(evt);
		}, 100)
	}

	useMemo(() => {
		// document.addEventListener('brand-changed', function () {
		// 	setIsBrandChanged(!isBrandChanged);
		// });
		document.addEventListener('outlet-changed', function () {
			setIsOutletChanged(!isOutletChanged);
		});
	});

	useMemo(() => {
		document.addEventListener('outlet-list-modified', function () {
			setIsOutletListModified(!isOutletListModified);
		});
	});

	useMemo(() => {
		document.addEventListener('brand-list-modified', function () {
			setIsBrandListModified(!isBrandListModified);
		});
	});

	return (
		<>
			{/* <!-- Main Header--> */}
			<header className="justify-content-between d-flex align-items-center">
				<div className="header-in d-flex w-100 justify-content-between align-items-center">
					<div className="header-search">
						{/* <input className="form-control" type="text" placeholder="Default input" aria-label="default input example"> */}
					</div>
					<div className="header-right">
						<ul className="header-right-menu d-flex gap-2 m-0">
							<li>
								<div className='form-group header-drop'>
									<Dropdown
										className='form-control'
										id="brand_id"
										placeholder='Select Brand'
										search
										fluid
										selection
										options={brandList}
										value={brandId}
										onChange={(event, data) => { handleChangeBrand(data.value); }}
									/>
								</div>
							</li>
							<li>
								<div className='form-group header-drop'>
									<Dropdown
										className='form-control'
										id="outlet_id"
										placeholder='Select Outlet'
										search
										fluid
										selection
										options={outletList}
										value={outletId}
										onChange={(event, data) => { handleChangeOutlet(data.value); }}
									// onChange={(event, data) => { localStorage.setItem('outlet_id', data.value); setOutletId(data.value); handleReload(); }}
									/>
								</div>
							</li>
							<li>
								<div className="dropdown">
									<button className="btn p-0 d-flex align-items-center dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										<div className='avatar'>
											<img alt="avatar" src={userData.profile_image} />
										</div>
									</button>
									<ul className="dropdown-menu">
										<li><Link className="dropdown-item " to={"/company/profile"}><i className="ti ti-user"></i> My Profile</Link></li>
										<li><Link className="dropdown-item " to={"/company/company-profile"}><i className="ti ti-building-skyscraper"></i> Company Profile</Link></li>
										<li><button className="dropdown-item " onClick={() => logout()}><i className="ti ti-logout"></i> Log Out</button></li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</header>
			{/* <div className="main-header side-header">
				<div className="container-fluid">
					<div className="main-header-left">
						<a className="main-header-menu-icon e_none" href="#" id="mainSidebarToggle" onClick={toggleSidebar}><span></span></a>
					</div>
					<div className="main-header-center">
						<div className="responsive-logo">
							
						</div>
					</div>
					<div className="main-header-right">
						<div className={`dropdown main-profile-menu d-flex align-items-center`}>
							
							<button className="d-flex border-0 bg-white showpop">
								<span className="main-img-user"><img alt="avatar" src={userData.profile_image} /></span>
							</button>
							<div className="dropdown-menu">
								<span className='fixed_bg'></span>
								<div className='position-relative headerdropdown' style={{ zIndex: "99999" }}>
									<div className="header-navheading">
										<h4 className="main-notification-title">{userData.full_name}</h4>
										<p className="main-notification-text">{userData.role == 1 ? "Admin" : "Company"}</p>
									</div>
									<Link className="dropdown-item " to={"/company/profile"}>
										<i className="fe fe-edit"></i> My Profile
									</Link>
									<Link className="dropdown-item " to={"/company/company-profile"}>
										<i className="fe fe-edit"></i> Company Profile
									</Link>
									<button className="dropdown-item " onClick={() => logout()}>
										<i className="fe fe-power"></i> Log Out
									</button>
								</div>
							</div>
						</div>
						<button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
							aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
							<i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
						</button>
					</div>
				</div>
			</div> */}
			{/* <!-- End Main Header--> */}
		</>
	)
}

export default Header;