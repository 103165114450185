import axiosInstance from "../../utils/axiosCompany";

// http://172.16.11.142:5001/webservice/company/routing-stations/groups/add

export const CreateGroups = async (values) => {
    return await axiosInstance().post("company/routing-stations/groups/add", values);
}

export const ListGroups = async (values) => {
    return await axiosInstance().post("company/routing-stations/groups/list", values);
}

export const UpdateGroups = async (values) => {
    return await axiosInstance().post("company/routing-stations/groups/edit", values);
}

export const DeleteGroups = async (values) => {
    return await axiosInstance().post("company/routing-stations/groups/delete", values);
}

export const DetailsGroups = async (id) => {
    return await axiosInstance().get(`company/routing-stations/groups/details?id=${id}`);
}

// -------------- assign item  --------------------


export const GetAvailableItems = async (id, brand_id) => {
    return await axiosInstance().get(`company/routing-stations/groups/available-items?id=${id}&brand_id=${brand_id}`);

}
export const AssignItems = async (data) => {
    return await axiosInstance().post(`company/routing-stations/groups/assign-items`, data);
}
export const GetAssignedItems = async (id) => {
    return await axiosInstance().get(`company/routing-stations/groups/assigned-items?id=${id}`);
}


// ===================== Station ================

export const CreateStation = async (values) => {
    return await axiosInstance().post("company/routing-stations/stations/add", values);
}

export const ListStation = async (values) => {
    return await axiosInstance().post("company/routing-stations/stations/list", values);
}

export const UpdateStation = async (values) => {
    return await axiosInstance().post("company/routing-stations/stations/edit", values);
}

export const DeleteStation = async (values) => {
    return await axiosInstance().post("company/routing-stations/stations/delete", values);
}

export const DetailsStation = async (id) => {
    return await axiosInstance().get(`company/routing-stations/stations/details?id=${id}`);
}

// -------------- assign groups  --------------------

export const GetAvailableGroups = async (id, brand_id) => {
    return await axiosInstance().get(`company/routing-stations/stations/available-groups?id=${id}&brand_id=${brand_id}`);
}
export const AssignGroup = async (data) => {
    return await axiosInstance().post(`company/routing-stations/stations/assign-groups`, data);
}
export const GetAssignedGroups = async (id) => {
    return await axiosInstance().get(`company/routing-stations/stations/assigned-groups?id=${id}`);
}

// ----------------------station flow -------------------

// http://172.16.11.237:17201/webservice/company/routing-stations/station/create-flow

export const AddStationFlow = async (values) => {
    return await axiosInstance().post(`company/routing-stations/station/create-flow`,values);
}

// company/routing-stations/station/flow/?station_id=2

export const GetStationFlowList = async (values) => {
    return await axiosInstance().get(`company/routing-stations/station/flow/?station_id=${values}`);
}

// http://172.16.11.237:17201/webservice/company/routing-stations/station/List-for-flow

export const FlowList = async (values) => {
    return await axiosInstance().post(`company/routing-stations/station/List-for-flow`,values);
}

export const DeleteStationFlow = async (values) => {
    return await axiosInstance().post(`company/routing-stations/station/delete-flow`,values);
}

