import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, checkPermission, getBrandId, getOutletId, removeHyphens, TrimText } from '../../helpers/commonFunctions';
import notFound from '../../assets/company/img/no-record-found.png'
import { DeleteWaste, ListWaste } from '../../services/company_panel/wasteManagement.services';
import { LOADER_TIME } from '../../utils/Constants';

const WasteManagementList = () => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("");
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);


    useEffect(() => {
        if (search) {
            setLoader(true);
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('brand_id', getBrandId());
                formData.append('outlet_id', getOutletId());

                ListWaste(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, LOADER_TIME);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged]);


    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
    }

    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteWaste(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Waste deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }

    const viewFunction = (row) => {
        navigate(`/company/store_manager/waste_management/view/${row}`)
    }
    const editFunction = (row) => {
        navigate(`/company/store_manager/waste_management/${params?.id}/edit/${row.id}`)
    }
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Waste management</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/store_manager/waste_management/reasons/list/1`)}>Reason</button>
                        {checkPermission('store_waste_management', 'add') ?
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/store_manager/waste_management/add`)}>Add Waste</button>
                        : ''}
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="11%">
                                            <div className='sorting_column'>
                                                <span>Item Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_type" />
                                            </div>
                                        </th>
                                        <th width="11%">
                                            <div className='sorting_column'>
                                                <span>Item Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_name" />
                                            </div>
                                        </th>
                                        {/* <th width="11%">
                                            <div className='sorting_column'>
                                                <span>SKU</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                            </div>
                                        </th> */}
                                        <th width="11%">
                                            <div className='sorting_column'>
                                                <span>Quantity</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="quantity" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Reason for wastage</span>
                                                <Sorting sort={sorting} handleSort={handleSort}  column="reason_for_wastage" />
                                            </div>
                                        </th>
                                        <th width="4%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeAllFirstLetters(removeHyphens(row.item_type))}</td>
                                                    <td>{capitalizeAllFirstLetters(row.item_type == "raw-material" ? (row?.inventory?.item_name) : row?.menu_item?.item_name)}</td>
                                                    {/* <td>{row.sku}</td> */}
                                                    <td>{row.quantity}</td>
                                                    <td>{row?.waste_management_reason?.reason ? capitalizeFirstLetter(TrimText(row?.waste_management_reason?.reason,30)) : 'N/A'}</td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_waste_management', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                : '' }
                                                            {checkPermission('store_waste_management', 'edit') ?
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                : '' }
                                                            {checkPermission('store_waste_management', 'delete') ?
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                : ''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record.</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "waste_management", path: "/company/store_manager/waste_management/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WasteManagementList