import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toHtml from 'html-react-parser';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getBrandId, TrimText } from '../../../helpers/commonFunctions';
import Loader from '../../../components/company_panel/common/loader';
import Swal from 'sweetalert2';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { DetailsMeals } from '../../../services/company_panel/mealService';


const ViewMealsAndCombos = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());
    const breadcrumbs = [{ title: "Menu", url: "" }, { title: "Meals/Combos", url: "/company/menu/menu/meals_combos/list/1" }, { title: "Meal Details", url: "" }]

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/meals_combos/list/${1}`);
        }
    });

    useEffect(() => {
        DetailsMeals({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const editFunction = () => {
        navigate(`/company/menu/menu/meals_combos/${params.id}/edit/${params.id}`)
    }

    const showData = (data) => {
        return (
            <div className='d-flex flex-wrap'>
                {data?.map((option, i) => {
                    return (
                        <span key={i} className="me-1 badge btn-primary d-inline-flex align-items-center justify-content-center text-capitalize">{option?.item_type}</span>
                    )
                })}
            </div>
        )
    }

    return (
        <>

            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <h4>Meals/Combos</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        {showDefault && Object.keys(showDefault).length > 0 ?
                            <div className="card-body">
                                <div>
                                    <h3 className="main-content-label mb-3">Meal Details</h3>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? capitalizeAllFirstLetters(showDefault.name) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name (Arabic)</th>
                                                    <td>{showDefault && showDefault.name_ar ? capitalizeAllFirstLetters(showDefault.name_ar) : "N/A"}</td>
                                                </tr>
                                                {/* <tr>
                                                <th>Menu Item Type</th>
                                                <td>{showDefault && showDefault.menu_meal_combo_items ? showData(showDefault.menu_meal_combo_items) : "N/A"}</td>
                                            </tr> */}
                                                <tr>
                                                    <th>Price</th>
                                                    <td>{showDefault && showDefault.price ? `${showDefault.price} SAR` : "N/A"}</td>
                                                </tr>
                                                {/* <tr>
                                                <th>Quantity</th>
                                                <td>{showDefault && showDefault.quantity ? showDefault.quantity : "N/A"}</td>
                                            </tr> */}
                                                <tr>
                                                    <th>Description</th>
                                                    <td>{showDefault && showDefault.description ? showDefault.description : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Description (Arabic)</th>
                                                    <td>{showDefault && showDefault.description_ar ? showDefault.description_ar : "N/A"}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {showDefault?.menu_meal_combo_items?.length > 0 &&
                                            <ul className="list-unstyled mt-4">
                                                <h2>Items Details</h2>
                                                {showDefault?.menu_meal_combo_items?.map((item, idx) => (
                                                    item.menu_meal_combo_menu_items.length ?
                                                    <li key={idx} className="mb-3 p-3 border rounded">

                                                        <div className="row mb-2" style={{ fontSize: "1.2rem" }}>
                                                            <div className="col-md-6">
                                                                <p className="font-weight-bold mb-1 " ><strong>Item Type:</strong> {capitalizeFirstLetter(item?.item_type)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <p className="font-weight-bold mb-1"><strong>Quantity:</strong> {item?.quantity}</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                            </div>
                                                        </div>
                                                        <ol className="mt-3">
                                                            {item?.menu_meal_combo_menu_items?.map((menuItem, idy) => {
                                                                const filteredItem = showDefault?.menu_items?.find(item => item.id === menuItem?.menu_item_id);

                                                                return (
                                                                    <li key={idy} className="mb-2">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <p className="mb-1">
                                                                                    <strong>Item Name:</strong> {capitalizeFirstLetter(filteredItem?.item_name)}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <p className="mb-1">
                                                                                    <strong>Price:</strong> {filteredItem?.default_price} SAR
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}

                                                        </ol>
                                                    </li> : null
                                                ))}
                                            </ul>
                                        }

                                        <div className="">
                                            <button className="btn btn-primary mt-3" type='button' onClick={() => { editFunction() }}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> : <Loader />}
                    </div>
                </div>
            </div>

        </>
    )
}

export default ViewMealsAndCombos;