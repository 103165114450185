import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { GetEditBrand, GetBrandDetail } from '../../services/brandService'
import Swal from "sweetalert2"
import Layout from '../../layout'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toHtml from 'html-react-parser';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import $ from 'jquery';

const EditBrand = () => {
    const navigate = useNavigate()
    const [previewImage, setPreviewImage] = useState("");
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [
        {
            title: "Dashboard",
            url: "/admin/dashboard"
        },
        {
            title: "Pages",
            url: "/admin/cms/brand/list/1"
        },
        {
            title: "Edit",
            url: ""
        },
    ]

    useEffect(() => {
        GetBrandDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: showDefault && showDefault.name ? showDefault.name : "",
                        logo : showDefault && showDefault.logo ? showDefault.logo : "",
                        short_description: showDefault && showDefault.short_description ? showDefault.short_description : "" ,
                        long_description:showDefault && showDefault.long_description ? showDefault.long_description : "",
                    }}

                    validate={values => {
                        const error = {};
                        if (values.logo == undefined || values.logo.type || !values.logo) {
                          if (values.logo == undefined || !values.logo) {
                            error.logo = 'Image is required';
                            $("#errortext").text("Image is required")
                          } else if (!values?.logo?.name.match(/\.(jpg|jpeg|png|webp|avif|gif)$/)) {
                            error.logo = 'Invalid Image Format';
                          } else if (values.logo) {
                            $("#errortext").text("")
                          }
                        }
                        if (!values.name) error.name = "Name is required";
                       
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append("id", params.id)
                        formData.append('logo', values.logo);
                        formData.append('name', values.name);
                        formData.append('short_description', values.short_description);
                        formData.append('long_description', values.long_description);
                        GetEditBrand(formData).then(response => {
                            setSubmitting(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/admin/cms/brand/list/${params.pgno}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Edit Brand</h6>
                                        </div>
                                        {showDefault && Object.keys(showDefault).length > 0 ?
                                        <div className="row row-sm">
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='logo' className='text-left d-flex'>Logo:<span className="requirestar">*</span> </label>
                                                <input className='form-control imgInput' id="logo" name="logo" accept="image/*" type="file" 
                                                onChange={(event) => {
                                                setFieldValue("logo", event.currentTarget.files[0] || "");
                                                { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                }}
                                                />
                                                <span className='text-danger d-flex text-left' id='errortext'>
                                                    {errors.logo && touched.logo && errors.logo}
                                                </span>
                                                <div className='d-flex justify-content-start mt-3'>
                                                    <div className={previewImage ? "" : "d-none"}>
                                                        <img src={previewImage} style={{ height: "100px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='name' className='text-left d-flex'>Name:<span className="requirestar">*</span></label>
                                                <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group required'>
                                                <label htmlFor='short_description' className='text-left d-flex'>Short description  :<span className="requirestar">*</span> </label>
                                                <textarea name='short_description' id="short_description" rows="2" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.short_description}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Long description :<span className="requirestar">*</span></label>
                                                {
                                                    Boolean(showDefault) ?
                                                        <>
                                                            {/* <CKEditor
                                                                initData={showDefault.long_description ? toHtml(showDefault.long_description) : ""}
                                                                data={showDefault.long_description ? toHtml(showDefault.long_description): ""}
                                                                onChange={({ editor }) => {
                                                                    setFieldValue("long_description", editor.getData());
                                                                }}
                                                                onInstanceReady={({ editor }) => {
                                                                    editor.setData(toHtml(values.long_description));
                                                                }}
                                                            /> */}
                                                             <CKEditor
                                                                editor={ ClassicEditor }
                                                                // config={ editorConfiguration }
                                                                data={values.long_description}
                                                                onReady={ editor => {
                                                                    console.log( 'Editor is ready to use!', editor );
                                                                } }
                                                                onChange={ ( event, editor ) => {
                                                                    setFieldValue("long_description", editor.getData());
                                                                } }
                                                                onBlur={ ( event, editor ) => {
                                                                    console.log( 'Blur.', editor );
                                                                } }
                                                                onFocus={ ( event, editor ) => {
                                                                    console.log( 'Focus.', editor );
                                                                } }
                                                            />
                                                        </>
                                                        : ""
                                                }
                                                <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                            </div> : <Loader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            </Layout>
        </>

    )
}

export default EditBrand