import React from 'react';
import { TagPicker } from 'rsuite';

export default function CustomMultiSelectWithImage({
    data = [],
    form = null,
    name = "",
    overFlowVisibleOptions = 5,
    menuClassName = ""
}) {
    const selectAllChecked = data.length === form?.values[name]?.length;

    // Handle Select All toggle
    const handleSelectAll = () => {
        if (selectAllChecked) {
            form.setFieldValue(name, []);
        } else {
            form.setFieldValue(name, data.map(record => record?.value));
        }
    };

    return (
        <TagPicker
            menuClassName={menuClassName}
            data={data} // We are only passing the actual data, not "Select All" in this list
            defaultValue={form.values[name]}
            menuStyle={{ maxHeight: 200, overflowY: 'auto' }}
            className='form-control form-control-select'
            renderMenu={menu => {
                return (
                    <>
                        <div className={"rs-picker-list-item cp"}>
                            <div className={"rs-check-item rs-checkbox e_none" + (selectAllChecked ? " rs-checkbox-checked" : "")} onClick={handleSelectAll}>
                                <div className="rs-checkbox-checker">
                                    <label htmlFor='select-all'>
                                        <span className="rs-checkbox-control">
                                            <input type="checkbox" id='select-all' checked={selectAllChecked} readOnly />
                                            <span className="rs-checkbox-inner"></span>
                                        </span>
                                        <span className="rs-checkbox-label">Select All</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {menu}
                    </>
                );
            }}
            renderValue={(value, items) => {
                const visibleOptions = overFlowVisibleOptions;
                const displayItems = items.slice(0, visibleOptions);
                const hiddenCount = items.length - displayItems.length;
                const removeTag = (i) => {
                    let allValues = [...form?.values[name]];
                    allValues.splice(i, 1);
                    form.setFieldValue(name, allValues);
                };
                return (
                    <>
                        {displayItems.map((item, i) => {
                            return (
                                <span className="rs-tag-text" key={i}>
                                    <div role="option" title="" className="rs-tag rs-tag-md rs-tag-default rs-tag-closable">
                                        {/* Image */}
                                        {item.img && <img src={item.img} alt={item.value} style={{ width: "20px", height: "20px", marginRight: "8px" }} />}
                                        {/* Label text */}
                                        <span className="rs-tag-text">{item.label}</span>
                                        <button type="button" className="rs-tag-icon-close rs-btn-close" aria-label="Remove" onClick={() => { removeTag(i) }}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-icon" aria-label="close" data-category="application"><path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path></svg>
                                        </button>
                                    </div>
                                </span>
                            );
                        })}
                        {items.length > visibleOptions ? <span className='d-block ms-2 mt-1'> {hiddenCount} more ...</span> : null}
                    </>
                );
            }}
            cleanable={false}
            value={form.values[name]}
            onChange={(e) => {
                form.setFieldValue(name, e);
            }}
            placeholder='Please select'
        />
    );
}
