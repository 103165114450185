import React from 'react'

const Loader = () => {
  return (
    <div className="text-center position-relative" style={{height:"2rem"}}>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default Loader