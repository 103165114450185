// import { combineReducers } from "redux";
// import userReducer from "./reducers/user";

// const rootReducer = combineReducers({
//     user: userReducer
// })

// export default rootReducer;


import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';

const store = configureStore({
    reducer: {
        user: userReducer
    }
})

export default store;