import axiosInstance from "../../../utils/axiosCompany";
const path = "company/waste-configurations";

export const GetWasteConfigurationDetails = async (values) => {
    return await axiosInstance().post(`${path}/get-waste-configurations`, values);
}

export const AddWasteConfiguration = async (values) => {
    return await axiosInstance().post(`${path}/add-waste-configurations`, values);
}

export const StatusWasteConfiguration = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values);
}