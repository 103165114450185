
import React, { useState, useEffect } from 'react'
import { Field, Formik } from 'formik';
import { CreateBrand } from '../../services/brandService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Layout from '../../layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toHtml from 'html-react-parser';
import Breadcrums from '../../components/common/breadcrumbs';
import $ from 'jquery';

const BrandForm = () => {

  const breadcrumbs = [
      {
          title: "Dashboard",
          url: "/admin/dashboard"
      },
      {
          title: "Brand",
          url: "/admin/brand/list/1"
      },
      {
          title: "Add",
          url: ""
      },
  ]
  const navigate = useNavigate()
  const [previewImage, setPreviewImage] = useState("");
  const [submitted, setSubmitted] = useState(false)

  return (
    <Layout>
      <Breadcrums data={breadcrumbs} />
        <Formik
        initialValues={{
          logo : '',
          name : '',
          short_description: '' ,
          long_description:'',
    
        }}
        validate={values => {
          const error = {};
          if (values.logo == undefined || values.logo.type || !values.logo) {
            if (values.logo == undefined || !values.logo) {
              error.logo = 'Image is required';
              $("#errortext").text("Image is required")
            } else if (!values?.logo?.name.match(/\.(jpg|jpeg|png|webp|avif|gif)$/)) {
              error.logo = 'Invalid Image Format';
            } else if (values.logo) {
              $("#errortext").text("")
            }
          }
          if (!values.name) error.name = "Name is required";
   
          return error;
        }}

        onSubmit={(values, { setSubmitting}) => {
          setSubmitted(true)
          let formData = new FormData();
          formData.append('logo', values.logo);
          formData.append('name', values.name);
          formData.append('short_description', values.short_description);
          formData.append('long_description', values.long_description);
 
          CreateBrand(formData).then(response => {
            setSubmitting(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                  customClass: 'swal-wide',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  position: 'top-right',
              });
              setTimeout(() => {
                  navigate(`/admin/brand/list/${1}`)
              }, 1000);
            } else {
              Swal.fire({
                  customClass: 'swal-wide',
                  icon: 'error',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 5000,
                  toast: true,
                  position: 'top-right',
              })
              setSubmitted(false)
            }
          }).catch(error => {
              console.log("error ====> ", error);
          })
        }}
        
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
      }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">Add Brand</h6>
                    </div>
                    <div className="row row-sm">
                      <div className='col-lg-6 text-center form-group'>
                        <label htmlFor='logo' className='text-left d-flex'>Logo:<span className="requirestar">*</span> </label>
                        <input className='form-control imgInput' id="logo" name="logo" accept="image/*" type="file" 
                        onChange={(event) => {
                          setFieldValue("logo", event.currentTarget.files[0] || "");
                          { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                        }}
                          />
                        <span className='text-danger d-flex text-left' id='errortext'>
                          {errors.logo && touched.logo && errors.logo}
                        </span>
                        <div className='d-flex justify-content-start mt-3'>
                          <div className={previewImage ? "" : "d-none"}>
                            <img src={previewImage} style={{ height: "100px" }} />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 text-center form-group'>
                          <label htmlFor='name' className='text-left d-flex'>Name :<span className="requirestar">*</span></label>
                          <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name}  className='form-control' />
                          <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                      </div>
                      <div className='col-lg-12 text-center form-group required'>
                        <label htmlFor='short_description' className='text-left d-flex'>Short description  :<span className="requirestar">*</span> </label>
                        <textarea name='short_description' id="short_description" rows="2" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.short_description}  className='form-control' />
                        <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                      </div>
                      <div className='col-lg-12 text-center form-group'>
                        <label htmlFor='subject' className='text-left d-flex'>Long description :<span className="requirestar">*</span></label>
                        {/* <CKEditor
                            initData={toHtml(values.long_description)}
                            onChange={({ editor }) => {
                                setFieldValue("long_description", editor.getData());
                            }}
                            onInstanceReady={({ editor }) => {
                                editor.setData(toHtml(values.long_description));
                            }}
                        /> */}
                         <CKEditor
                          editor={ ClassicEditor }
                          // config={ editorConfiguration }
                          data={values.long_description}
                          onReady={ editor => {
                              console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                              setFieldValue("long_description", editor.getData());
                          } }
                          onBlur={ ( event, editor ) => {
                              console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              console.log( 'Focus.', editor );
                          } }
                        />
                        <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                      </div>
                      <div className="">
                        <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            Submit
                        </button>
                        <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        </Formik>
    </Layout>
  );
}

export default BrandForm