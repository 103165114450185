import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import ViewAllDetails from './viewAllDetails'
import { GetSingleOutletHardware } from '../../services/outletManagementService'


const ViewOutletHardware = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})

    const location = useLocation()
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const companyId = queryParams.get('id');

    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{ title: "Company", url: "/admin/company/list/1" },{title: "Outlet hardwares",url:  `/admin/company/outlet-hardwares/${companyId}?id=1`},{title: "Outlet hardware",url: ""}]

    useEffect(() => {
        GetSingleOutletHardware({id: params.id, company_id: companyId}).then(data => {
            console.log('--------------',data.data.response);
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Outlet Hardware</h6>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Outlet</th>
                                                    <td>{showDefault && showDefault.outlet ? showDefault.outlet.outlet_name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Hardware Used</th>
                                                    <td>{showDefault && showDefault.hardware_used ? showDefault.hardware_used : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Printer Type</th>
                                                    <td>{showDefault && showDefault.printer_type ? showDefault.printer_type : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Routing Groups</th>
                                                    <td>{showDefault && showDefault.routing_groups ? showDefault.routing_groups : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? showDefault.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>SDK Id</th>
                                                    <td>
                                                    <div className="editorcontant">{toHtml(showDefault && showDefault.sdk_id ? showDefault.sdk_id : "N/A")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Registration Id</th>
                                                    <td>{showDefault && showDefault.registration_id ? showDefault.registration_id : "N/A"}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <th>Company</th>
                                                    <td>{showDefault && showDefault.company ? showDefault.company.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet</th>
                                                    <td>{showDefault && showDefault.outlet ? showDefault.outlet.outlet_name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status == 0 ? ("Inactive") : ("Active")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewOutletHardware