import React from 'react';
import ReactDOM from 'react-dom/client';
/**CSS PATHS >>>>>*/
import 'rsuite/dist/rsuite.min.css'
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/plugins/select2/css/select2.min.css";
import "./assets/plugins/multipleselect/multiple-select.css";
import "./assets/plugins/summernote/summernote-bs4.css";
import "./assets/plugins/fileuploads/css/fileupload.css";
import "./assets/plugins/fancyuploder/fancy_fileupload.css";

/**CSS PATHS <<<<<*/


/**JS PATHS >>>>>*/
import "bootstrap/dist/js/bootstrap.bundle.js";
import "jquery-ui/dist/jquery-ui.js";
import "jquery-ui/ui/widgets/datepicker.js";
import "./assets/admin/bootstrap-daterangepicker/daterangepicker.js"
/**JS PATHS <<<<<*/

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store/store';
import { RouterProvider } from "react-router-dom";
import router from './App';


// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
      <>
      <div className='global-loader' id='global_loader' style={{display:'flex'}}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <RouterProvider router={router} />
      </>
  // </React.StrictMode>
);

reportWebVitals();
