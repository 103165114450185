import axiosInstance from "../../utils/axiosCompany";

export const ListCustomer = async (values) => {
    return await axiosInstance().post("company/customers/list-customer", values);
}
export const CustomerDetails = async (values) => {
    return await axiosInstance().post("company/customers/get-customer", values);
}
export const CustomerDelete = async (values) => {
    return await axiosInstance().post("company/customers/delete-customer", values);
}