import React from 'react'
import banner from '../assets/company/img/banner.png'
import partner1 from '../assets/company/img/part1.png'
import partner2 from '../assets/company/img/part2.png'
import partner3 from '../assets/company/img/part3.png'
import partner4 from '../assets/company/img/part4.png'
import partner5 from '../assets/company/img/part5.png'
import partner6 from '../assets/company/img/part6.png'
import laptop from '../assets/company/img/laptop.png'
import Contact from '../assets/company/img/contact.svg'
import Feature3 from '../assets/company/img/feature3.jpg'
import Feature2 from '../assets/company/img/feature2.jpg'
import Feature1 from '../assets/company/img/feature1.jpg'
import About from '../assets/company/img/about.jpg'
import CompanyEnquiry from '../company_panel/company/add'
import Layout from './common/layout'
import $ from 'jquery';
import {Link } from 'react-router-dom';

const HomePage = () => {

  
  
  return (
    <Layout>
      <div className='home-body'>
        <section className='home-banner'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5 d-flex align-items-center'>
                <div className="banner-text">
                  <h2>Fully Integrated Point-of-sale System</h2>
                  <h1>The Simple, Secure, and Scalable Way to Run Your Business</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua ut</p>
                  <Link className='btn btn-primary mt-4' to="/company/login">Login</Link>
                </div>
              </div>
              <div className='col-md-6 home-banner-img ms-auto'>
                <figure className='mb-0'>
                  <img src={banner}/>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="logos-sec">
          <div className="container">
            <div className="logos-in row">
              <figure className="col-2">
                <img src={partner1}/>
              </figure>
              <figure className="col-2">
                <img src={partner2}/>
              </figure>
              <figure className="col-2">
                <img src={partner3}/>
              </figure>
              <figure className="col-2">
                <img src={partner4}/>
              </figure>
              <figure className="col-2">
                <img src={partner5}/>
              </figure>
              <figure className="col-2">
                <img src={partner6}/>
              </figure>
            </div>
          </div>
        </section>
        <section className="info-sec">
          <div className="container">
            <div className="row align-items-center">
              <figure className="info-sec-img m-0">
                <img src={laptop}/>
              </figure>
              <div className="col-md-5 ms-auto">
                <div className="info-contetn-area">
                  <div className="info-sec-text">
                    <h4>Enhance Shop Management Smoother and More Efficient</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-sec section-padding" id='about-us'>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-md-5">
                     <div className="about-text">
                        <span>About us</span>
                        <h4>Commitment to Streamline Your Business Operations</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                     </div>
                  </div>
                  <div className="col-md-6 ms-auto">
                    <figure className="m-0">
                      <img src={About} width="100%"/>
                    </figure>
                  </div>
               </div>
            </div>
        </section>
        <section className="feature-sec section-padding">
          <div className="container">
            <div className="about-text about-text-dark text-center mb-5">
              <span>Our Auction Industry Leading Platform</span>
              <h4>Run Better Online Auctions</h4>
           </div>
           <div className="feature-sec-blog">
            <div className="row">
              <div className="col-md-5">
                <article>
                  <h4>Empowering Your Retail Business Success</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                  <ul className="list-check-bullet">
                    <li>White-labeled mobile bidding</li>
                    <li>Your brand, not a marketplace brand</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                  </ul>
                </article>
              </div>
              <div className="col-md-7">
                <figure className="m-0">
                  <img src={Feature1} width="100%"/>
                </figure>
              </div>
             </div>
             <div className="row">
              <div className="col-md-7">
                <figure className="m-0">
                  <img src={Feature2} width="100%"/>
                </figure>
              </div>
              <div className="col-md-5">
                <article>
                  <h4>All Feature Manage Your  Small Business Needs</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                  <ul className="list-check-bullet">
                    <li>White-labeled mobile bidding</li>
                    <li>Your brand, not a marketplace brand</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                  </ul>
                </article>
              </div>
             </div>
             <div className="row">
              <div className="col-md-5">
                <article>
                  <h4>Simplify Your Process Orders with Automation Features</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book.</p>
                  <ul className="list-check-bullet">
                    <li>White-labeled mobile bidding</li>
                    <li>Your brand, not a marketplace brand</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                  </ul>
                </article>
              </div>
              <div className="col-md-7">
                <figure className="m-0">
                  <img src={Feature3} width="100%"/>
                </figure>
              </div>
             </div>
           </div>
          </div>
        </section>
        <section className="contact-sec" id="company-inquiry">
          <div className="contact-sec-in">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <figure className="m-0">
                    <img src={Contact} width="100%"/>
                  </figure>
                </div>
                <div className="col-md-5 ms-auto">
                  <CompanyEnquiry />
                  {/* <div className="contact-form-home">
                    <div className="about-text about-text-dark text-center mb-4">
                      <h4>Get in touch with us</h4>
                    </div>
                    <div className="contact-form-body">
                      <div className="form-group mb-3">
                        <label className="col-form-label">Name</label>
                        <input type="text" className="form-control" placeholder="Enter your name"/>
                      </div>
                      <div className="form-group mb-3">
                        <label className="col-form-label">Email address</label>
                        <input type="text" className="form-control" placeholder="Enter your email address"/>
                      </div>
                      <div className="form-group mb-3">
                        <label className="col-form-label">Subject</label>
                        <select className="form-control">
                          <option selected disabled>Choose subject</option>
                          <option>asdasd</option>
                          <option>asdasd</option>
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label className="col-form-label">Subject</label>
                        <textarea className="form-control" placeholder=""></textarea>
                      </div>
                      <button className="btn btn-primary w-100">Submit</button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  );
}

export default HomePage