
import React, { useState, useEffect, useMemo } from 'react'
import { Field, useFormik } from 'formik';
import { GetModulesAndPermissions, CreateRole } from '../../../services/company_panel/platform_user/platformUserService';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import $ from 'jquery';
import { capitalizeFirstLetter, getBrandId, getCompany, getOutletId } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button, Card, Container, Row, Col, Table, Modal, InputGroup, FormControl } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GetAllInventories, getOutletInventoryStockList } from '../../../services/company_panel/inventoryService';
import { GetBrandOutletsList, GetOutletAllList } from '../../../services/company_panel/outletService';
import { CreateTransferStockRequest } from '../../../services/company_panel/transferStockService';

const TransferOutAdd = () => {

  const breadcrumbs = [
    {
      title: "Transfer stock",
      url: "/company/store_manager/transfer_stock/transfer_out/list/1"
    },
    {
      title: "Transfer out",
      url: "/company/store_manager/transfer_stock/transfer_out/list/1"
    },
    {
      title: "Create Transfer",
      url: ""
    },
  ]
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState('');
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [arrayData, setArrayData] = useState([]);
  const [isItem, setIsItem] = useState(false);

  const [outlets, setOutlets] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [searchableInventories, setSearchableInventories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checkedInventories, setCheckedInventories] = useState([]);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [isOutletChanged, setIsOutletChanged] = useState(false);
  const [currentOutlet] = useState(getOutletId());

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
    document.addEventListener('outlet-changed', function () {
      setIsOutletChanged(!isOutletChanged);
    });
    setTimeout(() => {
      if(currentOutlet != getOutletId()){
        navigate(`/company/store_manager/transfer_stock/transfer_out/list/${1}`)
      }
    }, 500);
  });
  // useEffect(() => {
  //   // Fetch outlet list from API
  //   axios.get('/api/outlet-list')
  //     .then(response => setOutlets(response.data))
  //     .catch(error => console.error('Error fetching outlet list:', error));
  // }, []);

  useEffect(() => {
    if (searchTerm) {
      let data = inventories.filter(inventory => inventory.text.toLowerCase().includes(searchTerm.toLowerCase()));
      data = data.map((item => {
        return { ...item, checked: (arrayData.filter(record => record.key === item.key)).length > 0 };
      }))
      setSearchableInventories(data);
    } else {
      let data = [...inventories];
      data = data.map((item => {
        return { ...item, checked: (arrayData.filter(record => record.key === item.key)).length > 0 };
      }))
      setSearchableInventories(data);
    }
  }, [searchTerm, arrayData]);




  const handleSearch = () => {
    // Filter items based on search term
  };
  const handleIncrement = (index) => {
    const newItems = [...arrayData];
    newItems[index].quantity += 1;
    setArrayData(newItems);
  };

  const handleDecrement = (index) => {
    const newItems = [...arrayData];
    if (newItems[index].quantity > 1) {
      newItems[index].quantity -= 1;
      setArrayData(newItems);
    }
  };

  // const handleRemoveItem = (index) => {
  //   const newItems = arrayData.filter((_, i) => i !== index);
  //   setArrayData(newItems);
  // };

  const handleRemoveItem = (index) => {
    const itemToRemove = arrayData[index];
    const newItems = arrayData.filter((_, i) => i !== index);
    const newCheckedInventories = checkedInventories.filter(
      (item) => item.inventory_id !== itemToRemove.inventory_id
    );
    setArrayData(newItems);
    setCheckedInventories(newCheckedInventories);
    formik.setFieldValue('items', newCheckedInventories.map(item => item.inventory_id));
  };



  const handleAddItem = () => {
    setArrayData(searchableInventories.filter(item => item.checked));
    setShowModal(false);
  };

  const handleAddHandler = (e, inventory) => {
    setSearchableInventories(existingData => {
      return existingData.map(item => {
        if (item.key === inventory.key) {
          return { ...item, checked: e.target.checked };
        }
        return item;
      });
    });
  };


  useEffect(() => {
    setTimeout(() => {
      getOutletInventoryStockList({ outlet_id: getOutletId() }).then(data => {
        const tempInventories = data && data.data && data.data.response ? data.data.response.rows : [];
        const mappedInventories = tempInventories.map((res) => ({
          key: res.inventory.id,
          text: res.inventory.item_name,
          value: res.inventory.id,
          old_quantity: Number(res.quantity),
          quantity: 1,
          minimum_stock_limit: res.inventory.minimum_stock_limit,
          transfer_unit: res.inventory.inventory_unit_converisons.filter((item) => {
            return item.unit_type == 'Transfer Unit'
          })[0]
        })).filter((inventory) => {
          return inventory.old_quantity > inventory.minimum_stock_limit &&
            Math.floor((inventory.old_quantity - inventory.minimum_stock_limit) / inventory.transfer_unit.conversion) > 0
        });

        setInventories(mappedInventories.map((item) => { return { ...item, checked: false } }));
        setSearchableInventories(mappedInventories.map((item) => { return { ...item, checked: false } }));
        setLoader(false)
      }).catch(error => {
        console.log("error=====>", error);
      });
    }, 1000)

    setTimeout(() => {
      GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
        let allOutlet = data && data.data && data.data.response ? data.data.response : [];

        const outlet_id = getOutletId();

        allOutlet = allOutlet.filter((outlet) => {
          return outlet.id != outlet_id;
        })

        const outletDropdown = allOutlet.map((res) => ({
          key: res.id,
          text: `${res.outlet_name} ${ res.short_code ? `(${res.short_code})` : "" }`,
          value: res.id,
        }))
        setOutlets(outletDropdown)
        setLoader(false)
      }).catch(error => {
        console.log("error=====>", error);
      })
    }, 1000)
  }, [isBrandChanged, isOutletChanged])



  // const handleQuantityChange = (e, index) => {
  //   const newArrayData = [...arrayData];
  //   console.log('event', e.target.value);
  //   const newQuantity = parseInt(e.target.value, 10);

  //   const maxTransferableQuantity = (newArrayData[index].old_quantity - newArrayData[index].minimum_stock_limit)/newArrayData[index]?.transfer_unit?.conversion;
  //   console.log(maxTransferableQuantity, newQuantity);
  //   if (newQuantity >=0 && newQuantity <= maxTransferableQuantity) {
  //     newArrayData[index].quantity = newQuantity;
  //     setArrayData(newArrayData);
  //   }
  // };
  ///

  const handleQuantityChange = (e, index) => {
    const newArrayData = [...arrayData];
    const newQuantity = parseInt(e.target.value);
    const maxTransferableQuantity = Math.floor((newArrayData[index].old_quantity - newArrayData[index].minimum_stock_limit) / newArrayData[index]?.transfer_unit?.conversion);
    if (newQuantity <= maxTransferableQuantity) {
      newArrayData[index].quantity = newQuantity;
      newArrayData[index].validationError = false;
      setArrayData(newArrayData);
    }
  };

  // const validateQuantity = (e, index) => {
  //   const newArrayData = [...arrayData];
  //   const newQuantity = parseFloat(e.target.value);

  //   if (!newQuantity ||  
  //     newQuantity > (newArrayData[index].old_quantity - newArrayData[index].minimum_stock_limit)/newArrayData[index]?.transfer_unit?.conversion) {
  //     // If the quantity is invalid or not entered, set a validation error and reset to a default value
  //     newArrayData[index].quantity = 0.1;
  //     newArrayData[index].validationError = true;
  //   } else {
  //     newArrayData[index].validationError = false;
  //   }
  //   setArrayData(newArrayData);
  // };


  // Add and Edit Data
  const validationSchema = yup.object().shape({
    outlet_id: yup.string().required('Outlet is required'),
    // items: yup.array().min(1, 'At least one item is required').required('Items are required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      outlet_id: "",
      // items: [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // if (values.items.length === 0) {
      //   formik.setFieldError('items', 'At least one item is required');
      //   return;
      // }
      setSubmitted(true);
      let formData = new FormData();
      formData.append('outlet_id', values.outlet_id);
      formData.append('inventories', JSON.stringify(arrayData));
      formData.append('from_outlet_id', getOutletId());
      formData.append('company_id', getCompany().company_id);

      CreateTransferStockRequest(formData).then(response => {
        if (response.data.status === 1 || response.data.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/store_manager/transfer_stock/transfer_out/list/${1}`);
          }, 1000);
        } else {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            position: 'top-right',
          });
          setSubmitted(false);
        }
      }).catch(error => {
        console.log("error ====> ", error);
      });
    }
  });

  return (
    <>
      <div>
        <div className="page-header d-flex align-items-center">
          <div className="page-header-left">
            <Breadcrums data={breadcrumbs} />
            <h4>Transfer Stock</h4>
          </div>
        </div>
        <div className="row row-sm">

          <div className="col-lg-12">
            <Card className="custom-card">
              <Card.Body>
                {outlets.length === 0 && (loader == false || loader == 'false') ?
                  <div className="alert alert-warning text-center">
                    There is no outlet created yet, Please{' '}
                    <Link to="/company/business/outlet/add">
                      create a outlet
                    </Link>{' '}
                    to assign to transfer stock
                  </div>
                  : null}
                {inventories.length === 0 && (loader == false || loader == 'false') ?
                  <div className="alert alert-warning text-center">
                    There is no stock quantity available, Please{' '}
                    <Link to="/company/store_manager/purchase_order/add">
                      create a purchase order
                    </Link>{' '}
                    to assign to transfer stock
                  </div>
                  : null}
                <div className="transfer-form-container">
                  <Container fluid className="p-0">
                    <Row className="m-0">
                      <Col lg={6} md={12} className="p-0">
                        <Card style={{ border: 'none' }}>
                          <Card.Body>
                            <Form onSubmit={formik.handleSubmit}>
                              <Form.Group controlId="outletSelect">
                                <Form.Label>Outlet</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="outlet_id"
                                  value={selectedOutlet}
                                  onChange={(e) => {
                                    setSelectedOutlet(e.target.value);
                                    formik.setFieldValue('outlet_id', e.target.value);
                                  }}
                                  isInvalid={formik.touched.outlet_id && formik.errors.outlet_id}
                                >
                                  <option value="">Select an outlet</option>
                                  {outlets.map((outlet) => (
                                    <option key={outlet.key} value={outlet.key}>{outlet.text}</option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.outlet_id}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                                <h2 style={{ fontSize: "18px" }}>Items List</h2>
                                <b onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px', cursor: 'pointer' }} /> Browse Items
                                </b>
                              </div>

                              <table className='table table-custom'>
                                <thead>
                                  <tr>
                                    <th>Item Name</th>
                                    <th>Quantity</th>
                                    <th>Max transferable quantity</th>
                                    <th>Transfer unit (In)</th>
                                    <th className='text-end pe-9' width='10%'>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {arrayData.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ border: 'none' }}>{item.text}</td>
                                      <td className="d-flex align-items-center" style={{ border: 'none' }}>
                                        <Button variant="outline-secondary" size="sm" onClick={() => handleDecrement(index)} disabled={item.quantity === 1}>-</Button>
                                        <span className="mx-2" style={{ border: 'none' }}> <input
                                          type="number"
                                          value={item.quantity}
                                          onChange={(e) => handleQuantityChange(e, index)}
                                          // onBlur={(e) => validateQuantity(e, index)}
                                          className="form-control mx-2"
                                          style={{ width: '60px' }}
                                          min="1" // Set minimum to allow decimal values starting from 0.1
                                        // step="0.01" // Allow decimal input with steps of 0.01
                                        // max={(item?.old_quantity - item?.minimum_stock_limit)/item?.transfer_unit?.conversion}
                                        />
                                          {item.validationError && (
                                            <div className="text-danger">Quantity must be greater than 0 and within the valid range.</div>
                                          )}</span>
                                        <Button variant="outline-secondary" size="sm" onClick={() => handleIncrement(index)}
                                          disabled={
                                            item.quantity === Math.floor((item?.old_quantity - item?.minimum_stock_limit) / item?.transfer_unit?.conversion)
                                          }
                                        >+</Button>
                                      </td>

                                      <td style={{ border: 'none' }}>{Math.floor((item?.old_quantity - item?.minimum_stock_limit) / item?.transfer_unit?.conversion)}</td>
                                      <td style={{ border: 'none' }}>{capitalizeFirstLetter(item?.transfer_unit?.uomDetails?.uom)}</td>
                                      <td className='text-end' style={{ border: 'none' }}>
                                        <button className='btn btn-btn-danger' onClick={() => handleRemoveItem(index)}>
                                          <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px', cursor: 'pointer' }} />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {isItem && formik.errors.items && (
                                <div className="text-danger mb-3">
                                  {formik.errors.items}
                                </div>
                              )}

                              <Button type="submit" className='btn btn-primary mt-4' disabled={submitted || arrayData.length === 0}>
                                {submitted ? 'Submitting...' : 'Save'}
                              </Button>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '22px' }}>Inventory List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">

            <FormControl
              placeholder="Search items"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {/* <InputGroup.Text >Search</InputGroup.Text> */}
          </InputGroup>
          <div>
            {searchableInventories.length === 0 ? (
              <div className="text-danger">No data found</div>
            ) : (
              searchableInventories.map((inventory) => (
                <div key={inventory.key} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    id={`inventory-${inventory.key}`}
                    label={inventory.text}
                    checked={inventory.checked}
                    onChange={(e) => handleAddHandler(e, inventory)}
                  />
                </div>
              ))
            )}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-primary' onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <Button variant="primary" onClick={handleAddItem} disabled={(searchableInventories.filter(item => item.checked)).length === 0}>
            Add Items
          </Button>
        </Modal.Footer>
      </Modal>


      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default TransferOutAdd