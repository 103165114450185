import axiosInstance from "../../utils/axiosCompany";

export const LoginService = async (values) => {    
    return await axiosInstance().post("company/login", values);
}

export const LogoutUserService = async (values) => {    
    console.log('--------values', values);
    return await axiosInstance().post("company/logout-user", values);
}

export const LoginOtpverification = async (values) => {
    return await axiosInstance().post("company/verifyotp", values);
}

export const ResendotpVerification = async (values) => {
    return await axiosInstance().post("company/resendotp", values)
}

export const Getforgetpassword = async (values) => {
    return await axiosInstance().post("company/forgetpassword", values)
}

export const GetResetpassword = async (values) => {
    return await axiosInstance().post("company/resetpassword", values)
}

export const GetEditProfile = async (values) => {
    return await axiosInstance().post("company/editprofile", values)
}


export const GetProfileDetail = async (id) => {
    return await axiosInstance().get(`company/get-edit-profile?id=${id}`);
};

export const ChangePassword = async (values) => {
    return await axiosInstance().post("company/change-password", values)
}

export const GetPermission = async (company_id,id) => {
    return await axiosInstance().get(`company/get-permission?company_id=${company_id}&id=${id}`);
}

