import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import ViewAllDetails from './viewAllDetails'


const ViewCompany = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Companies",url: "/admin/company/list/1"},{title: "Company details",url: ""}]

    useEffect(() => {
        GetCompanyDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <ViewAllDetails tab='company-details'/>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Logo</th>
                                                    <td>{showDefault && showDefault.logo ? <img src={showDefault.logo} style={{ height: "100px" }}/> : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? showDefault.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company id</th>
                                                    <td>{showDefault && showDefault.company_id ? showDefault.company_id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Short description</th>
                                                    <td>{showDefault && showDefault.short_description ? showDefault.short_description : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Long description</th>
                                                    <td>
                                                    <div className="editorcontant">{toHtml(showDefault && showDefault.long_description ? showDefault.long_description : "N/A")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Established year</th>
                                                    <td>{showDefault && showDefault.established_year ? showDefault.established_year : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Official address</th>
                                                    <td>{showDefault && showDefault.official_address ? showDefault.official_address : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Official email</th>
                                                    <td>{showDefault && showDefault.official_email ? showDefault.official_email : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Official phone number</th>
                                                    <td>+{showDefault.country_code} {showDefault && showDefault.official_phone_number ? showDefault.official_phone_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Licence number</th>
                                                    <td>{showDefault && showDefault.licence_number ? showDefault.licence_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Vat number</th>
                                                    <td>{showDefault && showDefault.vat_number ? showDefault.vat_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Subscription access</th>
                                                    <td>{showDefault && showDefault.subscription_access == 1 ? 'Yes' : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showDefault && showDefault.createdAt ? formateDate(showDefault.createdAt) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status == 0 ? ("Inactive") : ("Active")}</td>
                                                </tr>
                                                <tr>
                                                    <th>Database name</th>
                                                    <td>{showDefault && showDefault.database_name ? showDefault.database_name : "N/A"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewCompany