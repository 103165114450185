import axiosInstance from "../../../utils/axiosCompany";

{/* -----------------------------  CustomerInvoice  ------------------------- */}

export const CreateCustomerInvoice = async (values) => {
    return await axiosInstance().post("company/invoice-settings/add-invoice", values);
}

export const GetCustomerInvoice = async (values) => {
    return await axiosInstance().post("company/invoice-settings/get-invoice", values);
}

{/**   -------------------- kitchen invoice ----------------- */}

export const CreateKitchenInvoice = async (values) => {
    return await axiosInstance().post("company/invoice-settings/add-invoice-kitchen", values);
}

export const GetKitchenInvoice = async (values) => {
    return await axiosInstance().post("company/invoice-settings/get-invoice-kitchen", values);
}
