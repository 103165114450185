import axiosInstance from "../../utils/axiosCompany";


export const UpdateCompanyProfile = async (value) => {
    return await axiosInstance().post('company/edit-company-profile',value);
};

export const GetCompanyProfileDetail = async (id) => {
    return await axiosInstance().get(`company/get-edit-company-profile?id=${id}`);
};






