import React from 'react'
import {Link } from 'react-router-dom';
import Logo from '../../assets/company/img/logo.png'


const Header = () => {
	return (
		<>
			{/* <!-- Main Header--> */}
      <div className="header">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
              <div className="header-in">
                <a className="navbar-brand" href="#"><img src={Logo}/></a><button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item"><a className="nav-link " aria-current="page" href="#">Home</a></li>
                      <li className="nav-item"><a className="nav-link active" href="#about-us">About us</a></li>
                      {/* <li className="nav-item"><a className="nav-link" href="#">Contact us</a></li> */}
                    </ul>
                    <div className="ms-auto">
                      <Link className='btn btn-primary' to="/company/login">Login</Link>
                      <a href="#company-inquiry" className='btn btn-primary ms-4'>Want to join ?</a>
                    </div>
                </div>
              </div>
          </div>
        </nav>
      </div>
			{/* <!-- End Main Header--> */}
		</>
	)
}

export default Header;