import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const ConfigurationTab = ({ tab: Tab }) => {

    return (
        <ul className='d-flex setting w-100 mb-0 p-0'>
            <li className={Tab === 'tax' ? 'active' : ''}>
                <Link to={`/company/setting/tax-configuration/list/${1}`} className="btn">
                    Tax
                </Link>
            </li>
            <li className={Tab === 'order-mode' ? 'active' : ''}>
                <Link to={`/company/setting/order-mode-configuration/list/${1}`} className="btn">
                    Order Mode
                </Link>
            </li>
            <li className={Tab === 'tender' ? 'active' : ''}>
                <Link to={`/company/setting/tender-configuration/list/${1}`} className="btn">
                    Tender
                </Link>
            </li>
            <li className={Tab === 'aggregator' ? 'active' : ''}>
                <Link to={`/company/setting/aggregator-configuration/list/${1}`} className="btn">
                    Aggregator
                </Link>
            </li>
            <li className={Tab === 'kitchen' ? 'active' : ''}><Link to={`/company/setting/kitchen-invoice/setting`} className="btn">Kitchen Invoice</Link></li>
            <li className={Tab === 'customer' ? 'active' : ''}><Link to={`/company/setting/customer-invoice/setting`} className="btn">Customer Invoice</Link></li>
            <li className={Tab === 'tip' ? 'active' : ''}>
                <Link to={`/company/setting/tip-configuration/list/${1}`} className="btn">
                    Tip
                </Link>
            </li>
            <li className={Tab === 'currency' ? 'active' : ''}>
                <Link to={`/company/setting/currency-configuration/list/${1}`} className="btn">
                    Currency
                </Link>
            </li>

            <li className={Tab === 'area' ? 'active' : ''}>
                <Link to={`/company/setting/add_area/list/${1}`} className="btn">
                    Area
                </Link>
            </li>
            <li className={Tab === 'uom' ? 'active' : ''}>
                <Link to={`/company/setting/uom/list/${1}`} className="btn">
                    UOM
                </Link>
            </li>
            <li className={Tab === 'waste_configuration' ? 'active' : ''}>
                <Link to={`/company/setting/waste-configuration`} className="btn">
                    Waste Configuration
                </Link>
            </li>
        </ul>
    );
}

export default ConfigurationTab
