import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionKey } from '../helpers/commonFunctions';

const ResetPasswordGuard = ({ component: Component }) => {
    const token = getSessionKey("reset_password_token");
    
    return (
        token && token.length ? <Component /> : <Navigate to={{ pathname: "/company/login" }} />
    )
}

export default ResetPasswordGuard;