
import React, { useState, useEffect, useMemo } from 'react'
import { Formik } from 'formik';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllUoms } from '../../services/company_panel/setting/uomConfigurationService';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany, getOutletId } from '../../helpers/commonFunctions';
import { GetAllInventories, GetSingleInventory } from '../../services/company_panel/inventoryService';
import { GetAllChildCategories, GetParentCategories } from '../../services/company_panel/categoryService';
import { AllWasteReason, UpdateWaste, WasteDetails } from '../../services/company_panel/wasteManagement.services';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllMenuItems } from '../../services/company_panel/mealService';

const EditWaste = () => {
  const breadcrumbs = [{ title: "Waste Management", url: "/company/store_manager/waste_management/list/1" }, { title: "Edit Waste", url: "" },]
  const [submitted, setSubmitted] = useState(false)
  const [menuItemData, setMenuItemsData] = useState([]);
  const [uomList, setUOMList] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showDefault, setShowDefault] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [currentBrand] = useState(getBrandId());
  const [currentOutlet] = useState(getOutletId());
  const [usageData, setUsageData] = useState('');
  const [inventoryData, setInventoryData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [isOutletChanged, setIsOutletChanged] = useState(false);

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
    document.addEventListener('outlet-changed', function () {
      setIsOutletChanged(!isOutletChanged);
    });
    if (currentOutlet != getOutletId()) {
      navigate(`/company/store_manager/waste_management/list/${1}`);
    }
  });

  useEffect(() => {
    WasteDetails({ id: params.id }).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : {});
      const formData = new FormData();
      if (data.data.response.item_type == 'raw-material') {
        formData.append('id', data?.data?.response?.inventory_id);
      }
      if (data.data.response.item_type == 'production') {
        formData.append('id', data?.data?.response?.menu_item_id);
      }
      formData.append('outlet_id', getOutletId());
      GetSingleInventory(formData).then(data => {
        const usageUnit = data.data.response?.inventory_unit_converisons?.find(unit => unit?.unit_type === "Usage Unit");
        const UsageData = capitalizeFirstLetter(usageUnit?.uomDetail?.uom)
        setUsageData(UsageData || '');
      }).catch(error => {
        console.log("error ====> ", error);
      });
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    const formData = new FormData()
    formData.append('brand_id', getBrandId());
    GetAllInventories(formData).then(data => {
      setInventoryData(data && data.data && data.data.response ? data.data.response.rows : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  }, [isBrandChanged])

  const generateSKU = (length = 8, setFieldValue) => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let sku = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters?.length);
      sku += characters[randomIndex];
    }
    setFieldValue('sku', sku);
  }

  const getData = (id) => {
    if (!id) {
      setUsageData('');
      return;
    }
    const formData = new FormData();
    formData.append('id', id);
    formData.append('outlet_id', getOutletId());
    GetSingleInventory(formData).then(data => {
      const usageUnit = data.data.response?.inventory_unit_converisons?.find(unit => unit?.unit_type === "Usage Unit");
      const UsageData = capitalizeFirstLetter(usageUnit?.uomDetail?.uom)
      setUsageData(UsageData || '');
    }).catch(error => {
      console.log("error ====> ", error);
    });
  };

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getAllMenuItems({ brand_id: getBrandId() }).then(data => {
      if (data && data.data && data.data.response) {
        setMenuItemsData(data.data.response.rows);
      }
    }).catch(error => {
      console.log("error=====>", error);
    });
  }, [isBrandChanged]);

  useEffect(() => {
    AllWasteReason({ brand_id: getBrandId() }).then(data => {
      if (data && data.data && data.data.response) {
        setReasonData(data.data.response);
      }
    }).catch(error => {
      console.log("error=====>", error);
    });
  }, [isBrandChanged]);




  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
        </div>
        <div class="page-header-right ms-auto">
        </div>
      </div>
      <Formik
        initialValues={{
          item_type: showDefault?.item_type ? showDefault.item_type : '',
          item_name: showDefault?.inventory_id ? showDefault.inventory_id : '',
          menu_item_name: showDefault?.menu_item_id ? showDefault.menu_item_id : '',
          // sku:  showDefault?.sku ? showDefault.sku : '',
          quantity: showDefault?.quantity ? showDefault.quantity : '',
          reason_for_waste: showDefault?.reason_for_waste ? showDefault?.reason_for_waste : '',
        }}
        enableReinitialize={true}
        validate={values => {
          const error = {};
          if (!values?.item_type) error.item_type = "Item type is required";
          if (values?.item_type == 'raw-material') {
            if (!values.item_name) error.item_name = "Item name is required";
          }
          if (values?.item_type == 'production') {
            if (!values.menu_item_name) error.menu_item_name = "Menu item name is required";
          }
          // if (!values.sku.trim()) error.sku = "SKU is required";
          //   if (!values.sku.trim()) {
          //     error.sku = "SKU is required";
          // } else if (values.sku.length > 50) {
          //     error.sku = "SKU cannot be greater than 50 characters";
          // }
          // if (!values.quantity) {
          //   error.quantity = "Quantity is required";
          // } 
          // if (!values.reason_for_waste) error.reason_for_waste = "Reason for waste is required";
          if (!values?.quantity) {
            error.quantity = "Quantity is required";
          } else if (values?.quantity < 0) {
            error.quantity = "Quantity cannot be negative";
          } else if (!Number.isInteger(Number(values.quantity))) {
            error.quantity = "Quantity must be a whole number";
          } else if (values?.quantity?.toString().length > 8) {
            error.quantity = "Quantity cannot be more than 8 digits";

          }

          if (!values?.reason_for_waste) {
            error.reason_for_waste = "Reason for waste is required";
          } 
          return error;
        }}
        onSubmit={(values) => {
          setSubmitted(true)
          let formData = new FormData();
          formData.append('id', params.id);
          formData.append('item_type', values.item_type);
          if (values?.item_type == 'raw-material') {
            formData.append('inventory_id', values.item_name);
          }
          if (values?.item_type == 'production') {
            formData.append('menu_item_id', values.menu_item_name);
          }
          // formData.append('sku', values.sku.trim());
          formData.append('quantity', values.quantity);
          formData.append('reason_for_waste', values.reason_for_waste);
          formData.append('company_id', getCompany().company_id);
          formData.append('outlet_id', getOutletId());
          UpdateWaste(formData).then(response => {
            setSubmitted(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
              });
              setTimeout(() => {
                navigate(`/company/store_manager/waste_management/list/1`)
              }, 1000);
            } else {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
              })
              setSubmitted(false)
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="card custom-card">
            <div className="card-body">
              <div className="row row-sm">
                <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='item_type' className='text-left d-flex'>Item Type<span className="requirestar">*</span></label>
                  <select
                    id="item_type"
                    className='form-control'
                    name='item_type'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.item_type}
                  >
                    <option value="">Select Item Type</option>
                    <option value="raw-material">Raw material</option>
                    <option value="production">Production</option>
                  </select>
                  <span className='text-danger d-flex text-left' id='errortext'>{errors.item_type && touched.item_type && errors.item_type}</span>
                </div>
                {values?.item_type == 'raw-material' ?
                  <div className='col-lg-6 text-center form-group mb-3'>
                    <label htmlFor='item_name' className='text-left d-flex'>Item Name<span className="requirestar">*</span></label>
                    <select id="item_name" className='form-control' name='item_name' onChange={(event) => { handleChange(event); getData(event.target.value) }} onBlur={handleBlur} value={values.item_name}>
                      <option value="">Select Item Name</option>
                      {inventoryData.length ? inventoryData.map((row) => (
                        <option value={row.id}>{capitalizeAllFirstLetters(row.item_name)}</option>
                      )) : ''}
                    </select>
                    <span className='text-danger d-flex text-left' id='errortext'>{errors.item_name && touched.item_name && errors.item_name}</span>
                  </div>
                  : null}
                {values?.item_type == 'production' ?
                  <div className='col-lg-6 text-center form-group mb-3'>
                    <label htmlFor='item_name' className='text-left d-flex'>Menu Item Name<span className="requirestar">*</span></label>
                    <select id="menu_item_name" className='form-control' name='menu_item_name' onChange={handleChange} onBlur={handleBlur} value={values.menu_item_name}>
                      <option value="">Select Item Name</option>
                      {menuItemData.length ? menuItemData.map((row) => (
                        <option value={row.id}>{capitalizeAllFirstLetters(row.item_name)}</option>
                      )) : ''}
                    </select>
                    <span className='text-danger d-flex text-left' id='errortext'>{errors.menu_item_name && touched.menu_item_name && errors.menu_item_name}</span>
                  </div>
                  : null}
                {/* <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='sku' className='text-left d-flex'>SKU<span className="requirestar">*</span></label>
                  <div className="set-plus-icon-input">
                    <input type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={handleChange} onBlur={handleBlur} value={values.sku} autoComplete='off' />
                    <div className="icon-box">
                      <a onClick={(e) => generateSKU(8, setFieldValue)}><i className="fa fa-rotate-right"></i></a>
                    </div>
                  </div>
                  <span className='text-danger d-flex text-left' id='errortext'>{errors.sku && touched.sku && errors.sku}</span>
                </div> */}
                {values.item_type === "raw-material" ?
                  <div className='col-lg-6 text-center form-group mb-3'>
                    <label htmlFor='unit_of_usage' className='text-left d-flex'>Unit Of Usage</label>
                    <input name='unit_of_usage' id="unit_of_usage" type='text' placeholder='Enter unit of usage' onChange={handleChange} onBlur={handleBlur} value={usageData} disabled={!values.item_name || usageData} className='form-control' />
                  </div>
                  : null}
                <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='quantity' className='text-left d-flex'>Quantity<span className="requirestar">*</span></label>
                  <input name='quantity' id="quantity" type='number' placeholder='Enter Quantity' onChange={handleChange} onBlur={handleBlur} value={values.quantity} className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.quantity && touched.quantity && errors.quantity}</span>
                </div>
                {/* <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='reason_for_waste' className='text-left d-flex'>Reason for waste<span className="requirestar">*</span></label>
                  <input name='reason_for_waste' id="reason_for_waste" placeholder='Enter Reason' type='text' onChange={handleChange} onBlur={handleBlur} value={values.reason_for_waste} className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.reason_for_waste && touched.reason_for_waste && errors.reason_for_waste}</span>
                </div> */}
                <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='reason_for_waste' className='text-left d-flex'>Reason For waste<span className="requirestar">*</span></label>
                  <select id="reason_for_waste" className='form-control' name='reason_for_waste' onChange={handleChange} onBlur={handleBlur} value={values.reason_for_waste}>
                    <option value="">Select Reason</option>
                    {reasonData?.length ? reasonData.map((row) => (
                      <option value={row.id}>{capitalizeAllFirstLetters(row.reason)}</option>
                    )) : ''}
                  </select>
                  <span className='text-danger d-flex text-left' id='errortext'>{errors.reason_for_waste && touched.reason_for_waste && errors.reason_for_waste}</span>
                </div>
                <div className='col-lg-6'></div>
                <div className="mt-2">
                  <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default EditWaste