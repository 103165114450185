import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getBrandId, getCompany, getOutletId, TrimText } from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/no-record-found.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { ListCustomerReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LOADER_TIME } from '../../utils/Constants';


const CustomerReportList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const [list, setList] = useState([])
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);

    const params = useParams();

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
          setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
          setIsOutletChanged(!isOutletChanged);
        })
      });

    useEffect(() => {
        const getData = setTimeout(() => {
            if (search) {
                setLoader(true)
                setTimeout(() => {
                    const formData = new FormData()
                    formData.append("page", params.id)
                    formData.append("per_page", itemPerPage)
                    formData.append("sort", JSON.stringify(sorting))
                    formData.append("search", JSON.stringify(search))
                    formData.append("global_search", globalSearch)
                    formData.append('company_id', getCompany().company_id);
                    formData.append('brand_id', getBrandId());
                    formData.append('outlet_id',getOutletId());
                    ListCustomerReport(formData).then(data => {
                        setDataLength(data.data.response.count);
                        setList(data && data.data && data.data.response ? data.data.response.rows : [])
                        setLoader(false);
                    }).catch(error => {
                        console.log("error ====> ", error);
                        setLoader(false);
                    })
                }, 500);
            }
        }, 300);
        return () => clearTimeout(getData);
    }, [sorting, search, globalSearch,page])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // $("#defaultstatus")[0].selectedIndex = 0
    }

    const headerMapping = {
        "customer_id": "Customer ID",
        "customer_name": "Customer Name",
        "customer_number": "Customer Number",
        "customer_address": "Customer Address",
        "total_order": "Total Order",
        "total_spent": "Total Spent",
        "first_order_date": "First Order Date",
        "last_order_date": "Last Order Date",
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('company_id', getCompany().company_id);
        formData.append('brand_id', getBrandId());
        formData.append('outlet_id',getOutletId());
        ListCustomerReport(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData?.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                // const OrderID = item?.id || 'N/A';
                const customerID = item?.customer_id || 'N/A';
                const customerName = item?.customer_name || 'N/A';
                const customerNumber = item?.customer_number || 'N/A';
                const customerAddress = item?.customer_address || 'N/A';
                const TotalOrder = item?.total_orders || 'N/A';
                const totalSpent = item?.total_spent + " " + "SAR"  || 'N/A';
                const firstOrderDate = formateDate(item?.first_order_date)   || 'N/A';
                const lastOrderDate = formateDate(item?.last_order_date)   || 'N/A';

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "customer_id":
                                transformedItem[newKey] = '#'+ (customerID);
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "customer_number":
                                transformedItem[newKey] = capitalizeFirstLetter(customerNumber);
                                break;
                            case "customer_address":
                                transformedItem[newKey] = capitalizeFirstLetter(customerAddress);
                                break;
                            case "total_order":
                                transformedItem[newKey] = (TotalOrder);
                                break;
                            case "total_spent":
                                transformedItem[newKey] = (totalSpent);
                                break;
                            case "first_order_date":
                                transformedItem[newKey] = (firstOrderDate);
                                break;
                            case "last_order_date":
                                transformedItem[newKey] = (lastOrderDate);
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Customer-Report.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Report</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationReportTab tab='customer' />
                            </div>
                        </div>
                    </div>
                     <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            {/* <div className='page-header-left'></div> */}
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='page-header-right ms-auto'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span> Customer ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.id" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.customer_name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Number</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Address</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Total Order</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="total_orders" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Total Spent</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.total_amount" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>First Order Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.first_order_date" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Last Order Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.last_order_date" />
                                            </div>
                                        </th>
                                        {/* <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Brand Name</span>
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                            </div>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list?.map((row, i) => (
                                                <tr>
                                                    <td>#{row?.customer_id}</td>
                                                    <td>{capitalizeFirstLetter(TrimText(row?.customer_name,30))}</td>
                                                    <td>{row?.customer_number ? row?.customer_number : "-"}</td>
                                                    <td>{capitalizeFirstLetter(TrimText(row?.customer_address,30))}</td>
                                                    <td>{((row?.total_orders))}</td>
                                                    <td>{((row?.total_spent ? row?.total_spent + " " +  "SAR" : 'N/A'))}</td>
                                                    <td>{row?.first_order_date ? (formateDate(row?.first_order_date))  : 'N/A'}</td>
                                                    <td>{row?.last_order_date ? (formateDate(row?.last_order_date)) : 'N/A'}</td>
                                                    {/* <td>{capitalizeFirstLetter(row?.brand_name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.outlet_name)}</td> */}
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "report", path: "/company/report/customer_report/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default CustomerReportList