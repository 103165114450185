import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, formateDate, getOutletId } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany, checkPermission } from '../../helpers/commonFunctions';
import { GetPurchaseOrderList, changePurchaseOrderStatus, deletePurchaseOrder, changeStatus, orderCancel } from '../../services/company_panel/purchaseOrderService';
import notFound from '../../assets/company/img/no-record-found.png'


const OrderList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },];
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                GetPurchaseOrderList(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])

                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, 1000)
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isOutletChanged, isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData()
                formData.append("id", row?.id)
                formData.append("supplier_id", row?.supplier_id)
                formData.append("outlet_id", getOutletId())
                formData.append("order_id", row?.id)
                deletePurchaseOrder(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Order deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                changeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }

    const changeOrderStatus = (row, value) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("order_status", value)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                changePurchaseOrderStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }
    // view details functionality

    const viewFunction = (row) => {
        navigate(`/company/store_manager/purchase_order/view/${row}`)
    }

    // edit functionality

    const editFunction = (row) => {
        navigate(`/company/store_manager/purchase_order/${params.id}/edit/${row.id}`)
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


    const CancelOrder = (row) => {
        const formData = new FormData()
        formData.append("id", row?.id)
        formData.append("supplier_id", row?.supplier_id)
        formData.append("outlet_id", getOutletId())
        formData.append("order_id", row?.id)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                orderCancel(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Purchase Order</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        {checkPermission('store_purchase_order', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/store_manager/purchase_order/add`)}>Create Order</button>
                            : ''}
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                {/* <button type="reset" value='Reset' onClick={resetFilter}  className="btn btn-primary mr-2">Reset Filter</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>

                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Reference ID  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Supplier</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="supplier_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Outlet </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlet_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Created Date  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>

                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Order Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort}  column="order_status" />
                                            </div>
                                        </th>
                                        {/* <th width="11%">
                                            <div className='sorting_column'>
                                                <span>Status</span>
                                            </div>
                                        </th> */}
                                        <th className='text-end' width="10%">Action</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    {/* <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td> */}

                                                    <td>#Purchase id {row.id}</td>
                                                    <td>{capitalizeFirstLetter(row?.supplier?.name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.outlet?.outlet_name)} { row?.outlet?.short_code ? `(${row?.outlet?.short_code})` : ""  }</td>

                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    {/* <td>
                                                        {row.order_status ? 'Completed' : 'Pending'}
                                                    </td> */}
                                                    <td>
                                                        {(!row.order_status && !row.status) ? 'Cancelled' : (row.order_status ? 'Completed' : 'Pending')}
                                                    </td>


                                                    {/* <td>
                                                        <div className="form-check form-switch w-25">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td> */}
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                {checkPermission('store_purchase_order', 'view') ?
                                                                    <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                    : ''}
                                                                {/* {checkPermission('outlets', 'edit') ?
                                                                    <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                    : ''} */}
                                                                {/* {row.order_status === false || (!row.order_status == true && !row.status == true)  ?
                                                                    <li> <div className='dropdown-item' onClick={() => { deleteFunction(row) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                    : ''} */}
                                                                {checkPermission('store_purchase_order', 'delete') &&
                                                                    (row.order_status === false || (row.order_status !== true && row.status !== true)) &&
                                                                    <li>
                                                                        <div className='dropdown-item' onClick={() => { deleteFunction(row) }}>
                                                                            <i className="ti ti-trash" aria-hidden="true"></i> Delete
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {row?.order_status === false && row?.status === true ? (
                                                                    <li>
                                                                        <div className='dropdown-item' onClick={() => { CancelOrder(row) }}>
                                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                                            Cancel
                                                                        </div>
                                                                    </li>
                                                                ) : null}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record.</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/store_manager/purchase_order/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderList