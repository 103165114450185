import axiosInstance from "../../../utils/axiosCompany";

export const CreateTip = async (values) => {
    return await axiosInstance().post("company/tip-configuration/add-tip", values);
}

export const GetTipList = async (values) => {
    return await axiosInstance().post("company/tip-configuration/get-all-tips", values);
}

export const GetEditTip  = async (values) => {
    return await axiosInstance().put("company/tip-configuration/update-tip", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/tip-configuration/change-status", values)
}

export const DeleteTip = async (id) => {
    return await axiosInstance().delete(`company/tip-configuration/delete-tip/${id}`)
}

export const GetTipDetail = async (id) => {
    return await axiosInstance().get(`company/tip-configuration/get-single-tip/${id}`);
};

