import axiosInstance from "../../utils/axiosCompany";

//refund order apis
export const ListRefund = async (values) => {
    return await axiosInstance().post("company/refund/list-refund", values);
}

export const GetSingleRefund = async (values) => {
    return await axiosInstance().post("company/refund/get-single-refund", values);
}

//refund reasons apis
export const ListRefundReason = async (values) => {
    return await axiosInstance().post("company/refund/list-refund-reason", values);
}
export const RefundAddReason = async (values) => {
    return await axiosInstance().post("company/refund/add-refund-reason", values);
}
export const RefundDetailsReason  = async (values) => {
    return await axiosInstance().post("company/refund/get-refund-reason", values);
}
export const DeleteRefundReason = async (values) => {
    return await axiosInstance().post("company/refund/delete-refund-reason", values);
}
export const UpdateRefundReason = async (values) => {
    return await axiosInstance().post("company/refund/update-refund-reason", values);
}
export const ChangeStatusRefundReason = async (values) => {
    return await axiosInstance().post("company/refund/change-status-refund-reason", values)
}
export const AllRefundReasons = async (values) => {
    return await axiosInstance().post("company/refund/all-refund-reasons", values)
}

// refund configuration apis

export const CreateRefundConfiguration = async (values) => {
    return await axiosInstance().post("company/refund/add-refund-configuration", values)
}

export const getRefundConfiguration = async (values) => {
    return await axiosInstance().post("company/refund/get-refund-configuration", values)
}