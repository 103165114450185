import axiosInstance from "../utils/axios";


export const AddTemplate = async (values) => {
    return await axiosInstance().post("admin/email-templates/save", values);
}
export const GetTemplateList = async (values) => {
    return await axiosInstance().post("admin/email-templates/list", values);
}
export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("admin/email-templates/changestatus", values)
}

export const GetTempDetail  = async (id) => {
    return await axiosInstance().get("admin/email-templates/edit?id="+id);
}

export const EditTempDetail  = async (values) => {
    return await axiosInstance().post("admin/email-templates/edit",values);
}

export const DeleteTemplate = async (values) => {
    return await axiosInstance().post("admin/email-templates/delete", values)
}

export const GetBannerDetail = async (id) => {
    return await axiosInstance().get(`admin/geteditbanner?id=${id}`);
};