import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Formik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import $ from "jquery";
import { capitalizeFirstLetter, checkPermission, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import { Dropdown } from 'semantic-ui-react';
import StatusFilter from '../../../components/company_panel/common/statusFilter';
import { showFilterlist } from '../../../utils/Constants';
import { GetAllParentCategories, GetCategoryList, getParentCategories } from '../../../services/company_panel/menuCategoryService';
import { DeleteCategory } from '../../../services/company_panel/menuCategoryService';
import { editCategory } from '../../../services/company_panel/menuCategoryService';
import { ChangeCategoryStatus } from '../../../services/company_panel/menuCategoryService';
import { CreateCategory } from '../../../services/company_panel/menuCategoryService';
import { GetSingleCategory } from '../../../services/company_panel/menuCategoryService';
import notFound from '../../../assets/company/img/no-record-found.png'
import defaultImage from '../../../assets/company/img/default_img.png';


const MenuCategoryList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },];
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const params = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [childDataLength, setChildDataLength] = useState([]);
    const [itemPerPage, setItemPerPage] = useState(12);
    const [secondaryItemPerPage, setSecondaryItemPerPage] = useState(12);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [secondaryPage, setSecondaryPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [taxType, setTaxType] = useState('Primary category');
    const [name, setName] = useState('Primary category');
    const [showDefault, setShowDefault] = useState({});
    const [actionType, setActionType] = useState('add');
    const [editCategoryId, setEditCategoryId] = useState(null);
    const selectRef = useRef(null);
    // const [orderMode, setOrderMode] = useState([]);
    const [primaryTaxList, setPrimaryTaxList] = useState([]);
    const [preview, setPreview] = useState(null); // State to store the image preview
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState("");
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    // Handle file change and preview
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(selectedFile);
            setFile(selectedFile);
        } else {
            setPreview(null);
            setFile(null);
        }
    };

    // Custom handleChange function to handle file input
    const handleChange = (event) => {
        // Additional logic to handle the file, e.g., validation or state updates
    };

    // const handleChangeOrderMode = (selected) => {
    //     setOrderMode(selected);
    // };


    // useEffect(() => {
    //     GetActiveOrderModes().then(data => {
    //         setOrderModeList(data && data.data && data.data.response ? data.data.response : [])
    //     }).catch(error => {
    //         console.log("error ====> ", error);
    //     })
    // }, [])
    useEffect(() => {

        if (search) {
            setLoader(true)
            const formData = new FormData()

            formData.append('brand_id', getBrandId());
            getParentCategories(formData).then(data => {

                if (taxType == 'Secondary category') {
                    const primaryTaxOptions = data.data.response.rows;
                    let primaryTaxMapped = [];
                    primaryTaxMapped.push({
                        key: "",
                        text: "Please select",
                        value: "",
                    })
                    const options = primaryTaxOptions.map((option) => ({
                        key: option.id,
                        text: capitalizeFirstLetter(option.name),
                        value: option.id,
                    }))
                    primaryTaxMapped = [...primaryTaxMapped, ...options];
                    setPrimaryTaxList(primaryTaxMapped);
                }


                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, taxType, isBrandChanged])

    const [categoryData, setCategoryData] = useState([]);
    let categoryFilterData;
    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, [isBrandChanged]);

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        getParentCategories(formData).then(data => {

            setCategoryData(data && data.data && data.data.response ? data.data.response.rows : [])
            categoryFilterData = data && data.data && data.data.response ? data.data.response.rows : []
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])

    useEffect(() => {

        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", page)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            GetAllParentCategories(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, taxType, isBrandChanged])

    const getChildCategories = () => {
        const formData = new FormData()
        formData.append("page", secondaryPage)
        formData.append("per_page", secondaryItemPerPage)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('brand_id', getBrandId());
        GetCategoryList(formData).then(data => {
            setChildDataLength(data.data.response.count);
            setChildCategories(data && data.data && data.data.response ? data.data.response.rows : [])
            setLoader(false)
        }).catch(error => {
            console.log("error ====> ", error);
        })

    }
    useEffect(() => {
        getChildCategories()
    }, [location, statsUpdate, sorting, search, globalSearch, taxType, isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // setCategoryData([])
        if (selectRef.current) {
            selectRef.current.selectedIndex = 0;
        }
        $("#defaultstatus")[0].selectedIndex = 0
    }


    // Delete functionality
    function deleteFunction(row, categoryType) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "Please note that if you delete this, all related secondary categories and menu items will also be delete and cannot be restored.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteCategory(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: `${categoryType} category deleted successfully`,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        getChildCategories()
                        setStatusUpdate(!statsUpdate)

                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeCategoryStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                        getChildCategories()
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {
        setEditCategoryId(row);
        GetSingleCategory({ 'id': row }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setPreviewImage(data?.data?.response?.image);
            // setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
            getChildCategories()
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end



    // addMore 

    const taxConfiguration = (value) => {
        setTaxType(value);

        let nameEn = name;

        if (value === 'Primary category') {
            nameEn = 'Primary category';

        } else {
            nameEn = 'Secondary category';
        }
        setName(nameEn);
    }




    const closeModel = (id) => {

        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)

    }

    const openModel = (id, type) => {
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
        // setOrderMode([]);
    }


    const handleFilterChange = (e) => {
        if (e?.target.value) {
            prepareSearch("category_type", e.target.value);
        } else {
            prepareSearch("category_type", "");
        }
    };


    return (
        <>
            {/* <Breadcrums data={breadcrumbs} /> */}
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Menu</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='categories' />
                            </div>
                        </div>
                    </div>

                    <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                        {checkPermission('store_menu_category', 'view') ?
                            <li className={taxType === 'Primary category' ? 'active' : ''} onClick={(e) => { taxConfiguration('Primary category'); resetFilter(e) }}>Primary category</li>
                            : ''}
                        {checkPermission('store_menu_subcategory', 'view') ?
                            <li className={taxType === 'Secondary category' ? 'active' : ''} onClick={(e) => { taxConfiguration('Secondary category'); getChildCategories(); resetFilter(e); }}>Secondary category</li>
                        :'' }
                            </ul>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>
                                {
                                    taxType === 'Primary category' && (
                                        <div className='gap-2'>
                                            <div className='filter-header d-flex justify-content-between align-items-center'>
                                                <div className='d-flex gap-3 align-items-center'>
                                                    <div className="form-group mb-0 rangepicker_container">
                                                        <input
                                                            type="search"
                                                            className="form-control"
                                                            value={globalSearch}
                                                            placeholder="Search"
                                                            onChange={(e) => { setGlobalSearch(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-4 align-items-center' style={{ marginLeft: '10px' }} >

                                                    <StatusFilter
                                                        data={showFilterlist}
                                                        prepareSearch={prepareSearch}
                                                    />
                                                    {/* <div className="date-input">
                                                        <CustomRangepicker
                                                            GetDateRange={(e) => { prepareSearch("created", e) }}
                                                            resetdate={resetdate}
                                                        />
                                                    </div> */}
                                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    taxType === 'Secondary category' && (
                                        <div className='gap-2'>
                                            <div className='filter-header d-flex justify-content-between align-items-center'>
                                                <div className='d-flex gap-3 align-items-center'>
                                                    <div className="form-group mb-0 rangepicker_container">
                                                        <input
                                                            type="search"
                                                            className="form-control"
                                                            value={globalSearch}
                                                            placeholder="Search"
                                                            onChange={(e) => { setGlobalSearch(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-4 align-items-center' style={{ marginLeft: '10px' }} >

                                                    <StatusFilter
                                                        data={showFilterlist}
                                                        prepareSearch={prepareSearch}
                                                    />
                                                    <div className="me-3">
                                                        <div className="form-group mb-0 filter_icon_container filter_design">
                                                            {/* <div className="select-down-arrow"> */}
                                                                <select
                                                                    ref={selectRef}
                                                                    className={`form-control cp ${categoryData && categoryData.length > 5 ? 'scrollable-select' : ''}`}
                                                                    onChange={handleFilterChange}
                                                                    id="resetFilter"
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    {categoryData &&
                                                                        categoryData.length > 0 &&
                                                                        categoryData.map((option, i) => {
                                                                            return (
                                                                                <option key={i} value={option?.id}>
                                                                                    {capitalizeFirstLetter(option?.name)}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </div>


                                                        {/* </div> */}
                                                    </div>
                                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div className='page-header-right ms-auto'>
                                {name === 'Primary category' ? 
                                ( checkPermission('store_menu_category', 'add') ? <button className="btn btn-primary" type="button" onClick={(e) => { setShowDefault({}); openModel('addCardModal', 'add') }}>Add {name}</button> : null) 
                                : ( checkPermission('store_menu_subcategory', 'add') ? <button className="btn btn-primary" type="button" onClick={(e) => { setShowDefault({}); openModel('addCardModal', 'add') }}>Add {name}</button> : null )
                                }   
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">

                        <div className="table-responsive">

                            <div className='card gap-20'>
                                <div className='row plan-row'>
                                    <div className="row">
                                        {taxType === 'Primary category' ? (
                                            list.length ? (
                                                list.map((row, i) => (
                                                    <div className="col-md-4 mb-4" key={i}>
                                                        <div className="plan-box">
                                                            <div className="plan-head d-flex justify-content-between">
                                                                <div className="plan-left">
                                                                    <h4>{capitalizeFirstLetter(row?.name)} </h4>

                                                                </div>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={`status-change-${i}`}
                                                                        name="status"
                                                                        checked={row.status}
                                                                        onChange={() => { ChangeStatus(row.id, row.status ? 0 : 1) }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`status-change-${i}`}></label>
                                                                </div>
                                                            </div>
                                                            <div className="plan-body">
                                                                {/* <p>{moment(row.created_at).format('DD-MM-YYYY')}</p> */}
                                                            </div>
                                                            {row.name !== 'Company Admin' && (
                                                                <div className="plan-footer d-flex gap-3">
                                                                    {checkPermission('store_menu_category', 'edit') ?
                                                                    <button className="btn btn-primary" onClick={() => { editFunction(row.id) }}>Edit</button>
                                                                    : '' }
                                                                     {checkPermission('store_menu_category', 'delete') ?
                                                                    <button className="btn btn-danger" onClick={() => { deleteFunction(row.id, 'Primary') }}>Delete</button>
                                                                    : '' }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className='text-center'>
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No categories available.</p>
                                                </div>
                                            )
                                        ) : (
                                            childCategories.length ? (
                                                childCategories.map((row, i) => (
                                                    <div className="col-md-4 mb-4" key={i}>
                                                        <div className="plan-box">
                                                            <div className="plan-head d-flex justify-content-between">
                                                                <div className="plan-left">
                                                                    <h4>{capitalizeFirstLetter(row?.name)}</h4>
                                                                </div>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={`status-change-child-${i}`}
                                                                        name="status"
                                                                        checked={row.status}
                                                                        onChange={() => { ChangeStatus(row.id, !row.status) }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`status-change-child-${i}`}></label>
                                                                </div>
                                                            </div>
                                                            <div className="plan-body">
                                                                <h2>{capitalizeFirstLetter(row?.parentcategory)}</h2>
                                                            </div>
                                                            {row.name !== 'Company Admin' && (
                                                                <div className="plan-footer d-flex gap-3">
                                                                     {checkPermission('store_menu_subcategory', 'edit') ?
                                                                    <button className="btn btn-primary" onClick={() => { editFunction(row.id) }}>Edit</button>
                                                                    :''}
                                                                    {checkPermission('store_menu_subcategory', 'edit') ?
                                                                    <button className="btn btn-danger" onClick={() => { deleteFunction(row.id, "Secondary") }}>Delete</button>
                                                                    :''}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className='text-center'>
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No child categories available.</p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                    <b>Total Records: {taxType === 'Primary category' ? (dataLength ? dataLength : "0") : (childDataLength ? childDataLength : "0")}</b>
                                </div>

                                <>
                                    {taxType === 'Primary category' && dataLength && dataLength > 0 ? (
                                        <CustomPagination
                                            datalength={dataLength}
                                            itemperpage={itemPerPage}
                                            setPage={setPage}
                                            currentPage={page}
                                            pageRoute={[{ name: "outlet-management", path: "/company/menu/menu/categories/list" }]}
                                        />
                                    ) : taxType === 'Secondary category' && childDataLength && childDataLength > 0 ? (
                                        <CustomPagination
                                            datalength={childDataLength}
                                            itemperpage={secondaryItemPerPage}
                                            setPage={setSecondaryPage}
                                            currentPage={secondaryPage}
                                            pageRoute={[{ name: "child-management", path: "/company/menu/menu/categories/list" }]}
                                        />
                                    ) : ""}
                                </>

                            </div>
                        </div>

                    </div>
                </div>
                {/*  Modal*/}
                <Formik
                    enableReinitialize
                    initialValues={{
                        logo: showDefault && showDefault?.image ? showDefault?.image : "",
                        name: showDefault && showDefault?.name ? showDefault?.name : "",
                        name_ar: showDefault && showDefault?.name_ar ? showDefault?.name_ar : "",
                        primary_tax: showDefault && showDefault?.parent_id ? showDefault?.parent_id : "",
                        status: showDefault && showDefault?.status ? showDefault?.status : "",
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.name.trim()) {
                            error.name = `Name is required`;
                        } else if (values.name.length < 3 || values.name.length > 30) {
                            error.name = `Name must be between 3 and 30 characters`;
                        }

                        // Validate 'name_ar' field (Arabic name)
                        if (!values.name_ar.trim()) {
                            error.name_ar = `Name (Arabic) is required`;
                            // error.name_ar = `${name} (Arabic) is required`;
                        } else if (values.name_ar.length < 3 || values.name_ar.length > 30) {
                            error.name_ar = `Name (Arabic) must be between 3 and 30 characters`;
                        }

                        if (taxType === 'Secondary category' && !values.primary_tax) {
                            error.primary_tax = "Primary category is required";
                        }
                        // if (taxType === 'Primary category' && !values.image) {
                        //     error.image = "Image is required";
                        // }
                        console.log(error);
                        // if (taxType=='Tax Groups' && !values.secondary_tax) error.secondary_tax = "Secondary tax is required";
                        // if (taxType=='Tax Groups' && !Number.isInteger(Number(values.secondary_tax))) error.secondary_tax = "Secondary tax must be an integer";
                        // if (taxType=='Tax Groups' && values.secondary_tax > 100) error.secondary_tax = "Secondary tax must between 1 to 100.";


                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        if (name === 'Primary category') {
                            formData.append('name', values?.name?.trim());
                            formData.append('image', values?.logo);
                            formData.append('name_ar', values?.name_ar?.trim());
                            formData.append('brand_id', getBrandId());
                            formData.append('company_id', getCompany().company_id);
                        } else {
                            formData.append('name', values?.name?.trim());
                            formData.append('name_ar', values?.name_ar?.trim());
                            formData.append('parent_id', values.primary_tax);
                            formData.append('brand_id', getBrandId());
                            formData.append('company_id', getCompany().company_id);
                        }
                        if (actionType === 'edit') {
                            let formData = new FormData();
                            if (name === 'Primary category') {
                                formData.append('image', values?.logo);
                                formData.append('name', values?.name?.trim());
                                formData.append('name_ar', values?.name_ar?.trim());
                                formData.append('id', editCategoryId);
                                formData.append('brand_id', getBrandId());
                            } else {
                                formData.append('name', values?.name?.trim());
                                formData.append('name_ar', values?.name_ar?.trim());
                                formData.append('parent_id', values.primary_tax);
                                formData.append('id', editCategoryId);
                                formData.append('brand_id', getBrandId());
                            }
                            editCategory(formData).then(response => {
                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                    getChildCategories()

                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)

                                }
                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        } else {
                            CreateCategory(formData).then(response => {
                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                    setPreviewImage('')
                                    getChildCategories();
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)
                                }

                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        }

                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                }) => {

                    const handleCancel = () => {
                        resetForm();
                        // setPreview(null)
                        $('#addCardModal').hide();
                        $('.modal-backdrop').remove();
                        setPreviewImage('')
                    };

                    return (
                        <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" style={{ fontSize: '1.5rem' }} id="addCardModalLabel" >{actionType === 'edit' ? 'Edit' : 'Add'} {name}</h5>
                                        <button type="button" className="btn-close" onClick={handleCancel} data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-body">
                                            {name !== 'Secondary category' && (<div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                                <div class="avatar avatar-xl">

                                                    {previewImage ?

                                                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                                        :
                                                        <img alt="avatar" src={defaultImage} />
                                                    }

                                                </div>
                                                <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" accept="image/*" type="file" onBlur={handleBlur} onChange={(event) => {
                                                    setFieldValue("logo", event.currentTarget.files[0] || "");
                                                    { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                }}
                                                /></div>
                                            </div>)}
                                            {/* <div className="mb-3">
                                                <label htmlFor="image" className="col-form-label">
                                                    Image<span className="requirestar">*</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control "
                                                    id="image"
                                                    name="image"
                                                    placeholder="Upload File"
                                                    onChange={(e) => { handleChange(e); handleFileChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    accept="image/*"
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    {errors.image && touched.image && errors.image}
                                                </span>
                                            </div> */}

                                            <div className="mb-3">
                                                <label htmlFor="name" className="col-form-label">Name<span className="requirestar">*</span></label>
                                                <input type="text" className="form-control" id="name" name="name" placeholder='Enter Name' onChange={handleChange} onBlur={handleBlur} value={values?.name} autoComplete='off' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="name_ar" className="col-form-label">Name (Arabic)<span className="requirestar">*</span></label>
                                                <input type="text" className="form-control" name="name_ar" id="name_ar" placeholder='Enter Name (Arabic)' onChange={handleChange} onBlur={handleBlur} value={values?.name_ar} autoComplete='off' />
                                                <span className='text-danger d-flex text-left'>{errors.name_ar && touched.name_ar && errors.name_ar}</span>
                                            </div>
                                            {name === 'Primary category' ?
                                                ""
                                                :
                                                <div>
                                                    <div className="mb-3">
                                                        <label htmlFor="primary_tax" className="col-form-label">Primary Category<span className="requirestar">*</span></label>
                                                        <Dropdown
                                                            className='form-control'
                                                            name="primary_tax"
                                                            id="primary_tax"
                                                            placeholder='Select primary category'
                                                            search
                                                            fluid
                                                            selection
                                                            options={primaryTaxList}
                                                            value={values?.primary_tax}
                                                            onChange={(event, data) => { setFieldValue("primary_tax", data.value) }}
                                                        />
                                                        <span className='text-danger d-flex text-left'>{errors.primary_tax && touched.primary_tax && errors.primary_tax}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            <button type="reset" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={handleCancel}>Cancel</button>
                                            <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    );
                }}
                </Formik>


                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default MenuCategoryList