import React, { useEffect } from 'react'
import Header from './header'
import Footer from './footer'
import { globalLoader } from '../../helpers/global'
import $ from 'jquery';

const Layout = ({ children, updateHeader }) => {
  
  useEffect(()=>{
    globalLoader(true);
    Promise.all([
      import("../../assets/company/css/style.css"),
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })
  },[]);

  $(window).scroll(function(){var body=$("body"),scroll=$(window).scrollTop();if(scroll>=5)body.addClass("fixed");else body.removeClass("fixed");});

  return (
    <div className='homepage-wrap'>
      <Header updateHeader={updateHeader} />
      <>
        {children}
      </>
      <Footer />
    </div>
  )
}

export default Layout