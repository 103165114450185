import axiosInstance from "../../../utils/axiosCompany";

export const CreateOrderMode = async (values) => {
    return await axiosInstance().post("company/order-mode-configuration/add-order-mode", values);
}

export const GetOrderModeList = async (values) => {
    return await axiosInstance().post("company/order-mode-configuration/get-all-order-modes", values);
}

export const GetEditOrderMode = async (values) => {
    return await axiosInstance().put("company/order-mode-configuration/update-order-mode", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/order-mode-configuration/change-status", values)
}

export const DeleteOrderMode = async (id) => {
    return await axiosInstance().delete(`company/order-mode-configuration/delete-order-mode/${id}`)
}

export const GetOrderModeDetail = async (id) => {
    return await axiosInstance().get(`company/order-mode-configuration/get-single-order-mode/${id}`);
};

export const GetActiveOrderModes = async (brand_id) => {
    return await axiosInstance().get(`company/order-mode-configuration/get-active-order-modes?brand_id=${brand_id}`);
};

export const GetOrderModeAllDetails = async (values) => {
    return await axiosInstance().post(`company/order-mode-configuration/get-order-modes`, values);
};
