import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetSingleCategory } from '../../services/company_panel/categoryService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'


const CategoryView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Pages", url: "/company/raw_materials/category/list/1" }, { title: "View", url: "" }]

    useEffect(() => {
        GetSingleCategory({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const editFunction = () => {
        navigate(`/company/raw_materials/category/1/edit/${params.id}`)
    }


    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">
                    <button className='btn btn-primary' onClick={() => { editFunction() }}>Edit Category</button>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        {/* <div className='brand-banner'>
                            <h4>{showDefault && showDefault.parent_id ? showDefault.category.name : "N/A"} </h4>
                        </div> */}
                        <div className='profile-info mt-5'>
                            <div className='profile-info-in'>
                                <h5>Name</h5>
                                <p>{showDefault && showDefault.name ? showDefault.name : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Created date</h5>
                                <p>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Company </h5>
                                <p>{showDefault && showDefault.company_id ? showDefault.company.name : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Status </h5>
                                <p>{showDefault && showDefault.status ? "Active" : "Un-active"}</p>
                            </div>
                        </div>

                    </div>
                    : <Loader />}
            </div>
        </>
    )
}

export default CategoryView