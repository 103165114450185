import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const ConfigurationTab = ({ tab: Tab }) => {

    return (
        <ul className='d-flex setting w-100 mb-0 p-0'>
            <li className={Tab === 'categories' ? 'active' : ''}>
                <Link to={`/company/menu/menu/categories/list/${1}`} className="btn">
                    Categories
                </Link>
            </li>
            <li className={Tab === 'menuItem' ? 'active' : ''}>
          
                <Link to={`/company/menu/menu/menu_items/list/${1}`} className="btn">
                   Menu items
                </Link>
            </li>
            <li className={Tab === 'menu' ? 'active' : ''}>
                <Link to={`/company/menu/menu/menu_card/list/${1}`} className="btn">
                 Menu
                </Link>
            </li>
            {/* <li className={Tab === 'Add-Ons' ? 'active' : ''}><a className="btn"></a></li> */}
            <li className={Tab === 'addOns' ? 'active' : ''}>
                <Link to={`/company/menu/menu/add_ons/list/${1}`} className="btn">
                    Add-Ons
                </Link>
            </li>
            
            <li className={Tab === 'mealsCombos' ? 'active' : ''}><Link to={`/company/menu/menu/meals_combos/list/${1}`} className="btn">Meals/Combos</Link></li>
            {/* <li className={Tab === 'tip' ? 'active' : ''}>
                <Link to={`/company/setting/tip-configuration/list/${1}`} className="btn">
                    Tip Configuration
                </Link>
            </li> */}
            {/* <li className={Tab === 'currency' ? 'active' : ''}>
                <Link to={`/company/setting/currency-configuration/list/${1}`} className="btn">
                    Currency Configuration
                </Link>
            </li> */}
            {/* <li  className={Tab === 'tax'?'active':''}>
        <Link  to={`/company/setting/tax-configuration/list/${1}`} className="btn">
            Tax Configuration
        </Link>
        </li> */}
        </ul>
    );
}

export default ConfigurationTab
