import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';

const ViewAllDetails = () => {
  const params = useParams();
  const loc = useLocation();

  return (
    <>
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="">
              <div className="tabs-wrap">
                <div className="profile-tab tab-menu-heading company-data">
                  <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/view/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/view/${params.id}`}
                    >
                     Company Details
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/view/subscription-history/list/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/view/subscription-history/list/${params.id}/1`}
                    >
                    Subscription History
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/brands/list/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/brands/list/${params.id}?id=${1}`}
                    >
                      Brands
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/outlets/list/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/outlets/list/${params.id}?id=${1}`}
                    >
                     Outlets
                    </Link>
                    {/* <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/subscription/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/subscription/${params.id}`}
                    >
                     Subscription
                    </Link> */}
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === `/admin/company/outlet-hardwares/${params.id}` ? "active" : "")}
                      aria-current="page"
                      to={`/admin/company/outlet-hardwares/${params.id}?id=${1}`}
                    >
                     Outlet Hardwares
                    </Link>
                  </nav>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAllDetails;

