import axiosInstance from "../../utils/axiosCompany";

export const GetAllCountries = async () => {
    return await axiosInstance().post("company/country/get-all-countries");
}
export const GetAllStates = async (values) => {
    return await axiosInstance().post("company/country/get-all-states", values);
}
export const GetAllCities = async (values) => {
    return await axiosInstance().post("company/country/get-all-cities", values);
}
