import axiosInstance from "../../utils/axiosCompany";

export const CreateSupplier = async (values) => {
    return await axiosInstance().post("company/supplier/add-supplier", values);
}

export const GetSupplierList = async (values) => {
    return await axiosInstance().post("company/supplier/list-suppliers", values);
}

export const GetAllSuppliers = async (values) => {
    return await axiosInstance().post("company/supplier/get-all-suppliers", values);
}

export const GetSupplierDetail = async (values) => {
    return await axiosInstance().post(`company/supplier/get-single-supplier`,values);
};

export const GetEditSupplier = async (values) => {
    return await axiosInstance().post("company/supplier/update-supplier", values);
}

export const changeStatus = async (values) => {
    return await axiosInstance().post("company/supplier/change-supplier-status", values);
}

export const deleteSupplier = async (values) => {
    return await axiosInstance().post("company/supplier/delete-supplier", values);
}

export const updateSupplierInventoryRelation = async (values) => {
    return await axiosInstance().post("company/supplier/update-supplier-inventory-relations", values);
}

export const getInventoriesFromSupplier = async (values) => {
    return await axiosInstance().post("company/supplier/get-inventories-from-supplier", values);
}