import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const PageHeaders = (props) => {
	
	return (
		<div className="page-header">
			<div>
				<h2 className="main-content-title tx-24 mg-b-5">{props && props.pageName ? props.pageName : ""}</h2>
				<ol className="breadcrumb">
					<Link className="breadcrumb-item" to={props && props.path ? props.path[0].url : "/#"}>{props && props.subHeading ? props.subHeading : ""}</Link>
				</ol>
			</div>
		</div>

	)
}

export default PageHeaders