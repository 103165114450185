import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from './auth';



const AdminPrivateRoute = ({ component: Component }) => {
    const token = getToken();
    const location = useLocation()
    

    // if(!token && location.pathname){
    //     localStorage.setItem("landingpage", location.pathname);
    //     localStorage.setItem("landingpagesearch", location.search);
    // }

    return (
        token  ? <Component /> : <Navigate to="/admin/login" />
    )

}

export default AdminPrivateRoute;