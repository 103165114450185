import React, { useEffect, useMemo, useState } from 'react'
import { Field, useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeFirstLetter, formateDate, formatTime, getBrandId, slugToReadable, TrimText } from '../../../helpers/commonFunctions'
import Breadcrums from '../../../components/company_panel/common/breadcrumbs'
import Loader from '../../../components/company_panel/common/loader'
import $ from 'jquery'
import { GetSingleMenuItems } from '../../../services/company_panel/menuItems'
import { GetBrandOutletsList } from '../../../services/company_panel/outletService'

const MenuItemsView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [statsUpdate, setStatusUpdate] = useState('false')
    const [groupedData, setGroupedData] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/menu_items/list/${1}`);
        }
    });

    const breadcrumbs = [
        { title: 'Menu', url: '/company/menu/menu/menu_items/list/1' },
        { title: 'Menu Items Details', url: '' },
    ]

    useEffect(() => {
        GetSingleMenuItems({ id: params?.id })
            .then(data => {
                setShowDefault(
                    data && data.data && data.data.response ? data.data.response : [],
                )
            })
            .catch(error => {
                console.log('error=====>', error)
            })
    }, [statsUpdate])



    const editFunction = () => {
        navigate(`/company/menu/menu/menu_items/edit/${params.id}`)
    }

    useEffect(() => {
        GetBrandOutletsList({ brand_id: getBrandId() })
            .then((response) => {
                setOutletList(response?.data?.response);
            })
            .catch((error) => {
                console.log("Error==>", error);
            })
    }, [])

    const groupByOutletId = (data) => {
        return Object.entries(data?.reduce((acc, item) => {
            if (!acc[item.outlet_id]) {
                acc[item.outlet_id] = [];
            }
            acc[item.outlet_id].push(item);
            return acc;
        }, {})).map(([outlet_id, items]) => ({
            outlet_id, // Ensure outlet_id is an integer if needed
            items
        }));
    };

    useEffect(() => {
        if (showDefault?.menu_item_outlet_specific_prices) {
            const data = groupByOutletId(showDefault.menu_item_outlet_specific_prices);
            setGroupedData(data);
        }
    }, [showDefault]);



    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Menu items</h4>
                </div>
                <div class="page-header-right ms-auto">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            editFunction()
                        }}
                    >
                        Edit Menu Item
                    </button>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ? (
                    <div className="card-body mb-4">
                        <div className="brand-banner">
                            <div className="avatar avatar-xl mx-auto">
                                {showDefault && showDefault?.logo ? (
                                    <img src={showDefault?.logo} />
                                ) : (
                                    'N/A'
                                )}
                            </div>
                            <h4>
                                {showDefault && showDefault.item_name
                                    ? capitalizeFirstLetter(showDefault.item_name)
                                    : 'N/A'}{' '}
                            </h4>

                            <h4>
                                {' '}
                                {showDefault && showDefault.item_name_ar
                                    ? ' ' + capitalizeFirstLetter(showDefault.item_name_ar)
                                    : 'N/A'}
                            </h4>

                            <p>
                                {showDefault && showDefault.created_at
                                    ? formateDate(showDefault.created_at)
                                    : 'N/A'}
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Primary Category</h5>
                                        <p>
                                            {showDefault &&
                                                showDefault?.menu_category?.menu_category?.name
                                                ? capitalizeFirstLetter(showDefault?.menu_category?.menu_category?.name)
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Secondary Category</h5>
                                        <p>
                                            {showDefault && showDefault?.menu_category?.name
                                                ? capitalizeFirstLetter(showDefault?.menu_category?.name)
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Availability </h5>
                                        <p>
                                            {showDefault && showDefault?.availability
                                                ? showDefault?.availability
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Short description </h5>
                                        <p>
                                            {showDefault && showDefault?.short_description
                                                ? capitalizeFirstLetter(showDefault?.short_description)
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Short description (Arabic)</h5>
                                        <p>
                                            {showDefault && showDefault?.short_description_ar
                                                ? capitalizeFirstLetter(showDefault?.short_description_ar)
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Calories </h5>
                                        <p>
                                            {showDefault && showDefault?.calories
                                                ? showDefault?.calories
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="profile-info mt-4">
                                    <div className="profile-info-in">
                                        <h5>Cooking minutes </h5>
                                        <p>
                                            {showDefault && showDefault?.cooking_minutes
                                                ? showDefault?.cooking_minutes
                                                : '0'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-info mt-4">
                            <div className="profile-info-in">
                                <div className="row">
                                    {/*need to show more details*/}
                                    <div className="col-4">
                                        <div className="profile-info ">
                                            <div className="profile-info-in ">
                                                <h5>Status </h5>
                                                <p>
                                                    {showDefault && showDefault?.status == true
                                                        ? 'Enable'
                                                        : 'Disable'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="profile-info ">
                                            <div className="profile-info-in">
                                                <h5>{capitalizeFirstLetter(showDefault?.tax_configuration?.primary_tax_details?.name ? showDefault?.tax_configuration?.primary_tax_details?.name : "Tax")}</h5>
                                                <p>
                                                    {showDefault &&
                                                        showDefault?.tax_configuration?.primary_tax_details?.percentage

                                                        ? `${showDefault?.tax_configuration?.primary_tax_details?.percentage} % (${showDefault?.tax_configuration?.primary_tax_details?.tax_type})`

                                                        : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-info-in mt-4">
                            <h5>Availability</h5>
                            <div class="border p-3 mt-3">
                                <div class="row">
                                    <div className="col my-1">
                                        <p className=" fw-bold m-0">Start Date</p>
                                        <p>{formateDate(showDefault?.start_date)}</p>
                                    </div>
                                    <div className="col my-1">
                                        <p className=" fw-bold m-0">End Date</p>
                                        <p>{formateDate(showDefault?.end_date)}</p>
                                    </div>
                                    <div className="col my-1">
                                        <p className=" fw-bold m-0">Start Time</p>
                                        <p>{formatTime(showDefault?.start_time)}</p>
                                    </div>
                                    <div className="col my-1">
                                        <p className=" fw-bold m-0">End Time</p>
                                        <p>{formatTime(showDefault?.end_time)}</p>
                                    </div>
                                    <div className="col-4 my-1">
                                        <p className=" fw-bold m-0">Week Days</p>
                                        <p>{showDefault?.week?.map((day, i) => capitalizeFirstLetter(day)).join(', ')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showDefault?.menu_item_add_ons?.length > 0 && <div className="profile-info-in mt-4">
                            <h5>Add-On</h5>
                            <div class="row">
                                {showDefault?.menu_item_add_ons?.map((items, i) => (
                                    <div key={i} class="col-lg-4 mb-3">
                                        <div class="border p-3 mt-3">
                                            <h5 class="mb-2">{items?.menu_add_on_category?.name || "N/A"}</h5>
                                            <div class="d-flex">
                                                <span className=' me-5'>Min: {items?.min || '-'}</span>
                                                <span className=' me-5'>Max: {items?.max || '-'}</span>
                                                <span className=' me-5'>Free: {items?.free || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        }

                        {showDefault?.menu_item_reciepes?.length > 0 && <div className="profile-info-in mt-2">
                            <h5>Recipe</h5>
                            <div class="row">
                                {showDefault?.menu_item_reciepes?.map((items, index) => (
                                    <div key={index} class="col-lg-6 mb-3">
                                        <div class="border p-3 mt-3">
                                            <h5>{capitalizeFirstLetter(items?.inventory?.item_name) || '-'}</h5>
                                            <div className="d-flex ml-10">
                                                <div>
                                                    {items?.inventory?.inventory_unit_converisons
                                                        .filter(item => item?.unit_ty === 'Usage Unit')
                                                        .map((filteredItem, idx) => {
                                                            const uomCode = showDefault?.uoms?.find(uom => uom?.id === filteredItem?.uom)?.uom_code || '-';
                                                            return (
                                                                <div key={idx}>
                                                                    <span className=' me-5'>
                                                                        Quantity: {items?.quantity || '-'}
                                                                    </span>
                                                                    <span className=' me-5'>
                                                                        Unit of Measure: {uomCode}
                                                                    </span>
                                                                    <span className=' me-5'>
                                                                        Price: {items?.quantity * filteredItem?.price || '-'}
                                                                    </span>
                                                                    <span className=' me-1'>
                                                                        Calories: {filteredItem?.calorie || '-'}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        }
                        {showDefault?.menu_item_outlet_default_prices?.length > 0 && <div className="profile-info-in mt-2">
                            <h3>Pricing</h3>
                            <div class="border p-3 mt-3" style={{ color: '#434544' }}>
                                <div className="row">
                                    {showDefault?.menu_item_outlet_default_prices
                                        ?.filter(priceItem => priceItem?.price_type === "orderMode")
                                        ?.map((priceItem, index) => (
                                            <div className="col-3 my-1" key={index}>
                                                <h5 className="">{capitalizeFirstLetter(slugToReadable(priceItem?.order_mode_name))}</h5>
                                                <p>{priceItem?.price} SAR</p>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div class="border p-3 mt-3">
                                <div className="row">
                                    {showDefault?.menu_item_outlet_default_prices
                                        ?.filter(priceItem => priceItem?.price_type === "aggregator")
                                        ?.map((priceItem, index) => (
                                            <div className="col-3 my-1" key={index}>
                                                <h5 className="">{capitalizeFirstLetter(slugToReadable(priceItem?.aggregator_name))}</h5>
                                                <p>{priceItem?.price} SAR</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>}

                        {groupedData?.length > 0 && <div className="profile-info-in mt-2">
                            {
                                groupedData[0]?.outlet_id?.length ?
                                <h3 className='mt-4'>Outlet Pricing</h3> :
                                null
                            }
                            {groupedData?.map((outlet) => {
                                return outlet?.outlet_id?.length ?
                                <div className='mt-3' style={{ color: '#434544' }}>
                                    <h4>
                                        {
                                            outlet.outlet_id.split(',').map(outletId => 
                                            capitalizeFirstLetter(outletList?.find((OutletData) => OutletData.id === Number(outletId))?.outlet_name)
                                            ).filter(Boolean).join(', ')
                                        }
                                    </h4>
                                    {/* <h5>{outlet.outlet_id}</h5> */}
                                    <div className='row'>
                                        <div className='col-6 '>
                                            <div className="border p-3 mt-1" key={outlet.outlet_id}>
                                                <div className="row">
                                                    {outlet.items
                                                        ?.filter(priceItem => priceItem?.price_type === "orderMode")
                                                        .map((priceItem) => (
                                                            <div className="col-4 my-1" key={priceItem.id}>
                                                                <h5 className="">
                                                                    {capitalizeFirstLetter(slugToReadable(priceItem?.order_mode_name))}
                                                                </h5>
                                                                <p>{priceItem?.price} SAR</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 '>
                                            <div className="border p-3 mt-1" key={outlet.outlet_id}>
                                                <div className="row">
                                                    {outlet?.items
                                                        ?.filter(priceItem => priceItem?.price_type === "aggregator")
                                                        ?.map((priceItem) => (
                                                            <div className="col-4 my-1" key={priceItem.id}>
                                                                <h5 className="">
                                                                    {capitalizeFirstLetter(slugToReadable(priceItem?.aggregator_name))}
                                                                </h5>
                                                                <p>{priceItem?.price} SAR</p>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                            }
                            )}
                        </div>}

                    </div>
                ) : (
                    <Loader />
                )}
            </div >
        </>
    )
}

export default MenuItemsView
