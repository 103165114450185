import React, { useEffect, useState } from 'react'
import Telegram from '../../assets/company/img/telegram.png'
import Discord from '../../assets/company/img/discord.png'
import Instagram from '../../assets/company/img/instagram.png'
import Twitter from '../../assets/company/img/twitter.png'
import LogoWhite from '../../assets/company/img/logo-white.png'
import { useNavigate,Link } from 'react-router-dom';
import { GetLogo } from '../../services/settingsService'

const Footer = () => {

  const navigate = useNavigate()

  const [showDefault, setShowDefault]= useState({});

  useEffect(() => {
    GetLogo().then(data => {
        setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        console.log(data.data.response);
    }).catch(error => {
        console.log("error=====>", error)
    })
  }, [])


	return (
		<>
			{/* <!-- Main Footer--> */}
      <div className="footer">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-info">
                  <a href="#">
                    <img src={LogoWhite}/>
                  </a>
                  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</p>
                </div>
              </div>
              <div className="col-md-3 ms-auto">
                <h4 className="footer-headings">Legal</h4>
                <ul className="footer-link">
                  <li>
                    <Link to="/privacy-policy" target="_blank">Privacy policy</Link>
                  </li>
                  <li>
                    <Link to="/term-condition" target="_blank">Terms and condition</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <h4 className="footer-headings">Connect with us</h4>
                <div className="footer-social">
                  <Link to={showDefault.messenger_link} target='_blank'>
                    <img src={Twitter}/>
                  </Link>
                  <Link to={showDefault.instagram_link} target='_blank'>
                    <img src={Instagram}/>
                  </Link>
                  <Link to={showDefault.discord_link} target='_blank'>
                    <img src={Discord}/>
                  </Link>
                  <Link to={showDefault.linkedin_link} target='_blank'>
                    <img src={Telegram}/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights-text text-center">
          Copyright © 2024. All Rights Reserved. POS system
        </div>
      </div>
			{/* <!-- End Main Footer--> */}
		</>
	)
}

export default Footer;
