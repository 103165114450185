import axiosInstance from "../utils/axios";

export const CreateCompany = async (values) => {
    return await axiosInstance().post("admin/create-company", values);
}

export const GetCompanyRequestList = async (values) => {
    return await axiosInstance().post("admin/list-company-request", values);
}



export const GetCompanyRequestDetail = async (id) => {
    return await axiosInstance().get(`admin/get-edit-company-request?id=${id}`);
};






