import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import LoginLayout from '../../companyloginlayout'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { LoginOtpverification, ResendotpVerification } from '../../services/company_panel/LoginCompanyServices';
import Logo from '../../assets/company/img/logo.png';
import loginBg from '../../assets/company/img/login2.jpeg';
import { setSessionKey } from '../../helpers/commonFunctions';
import { GetAllOrders } from '../../services/company_panel/orderService';
import { saveOrders } from '../../helpers/indexedDB';

const CompanyOtpVerificationPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { email } = useParams();
    const [loader, setLoader] = useState('false')
    const [type, setType] = useState('')
    const [searchParams] = useSearchParams();


    useEffect(() => {
        setType(searchParams.get('type'))
    }, [])

    const resentotpfunct = () => {
        let formData = new FormData();
        formData.append("email", email)
        formData.append("type", type);
        formData.append("company_id", searchParams.get('company_id'));

        ResendotpVerification(formData).then(data => {
            if (data && data.data && data.data.statuscode === 200) {
                toast(data.data.message);
            } else if (data && data.data && data.data.statuscode !== 200) {
                toast(data.data.message);
            }
        }).catch(error => {
            console.log("error: ", error);
        })
    }

    return (
        <LoginLayout>
            <Formik
                initialValues={{ otp: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.otp) {
                        errors.otp = 'OTP is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", email)
                    formData.append("otp", values.otp);
                    formData.append("type", type);
                    formData.append("company_id", searchParams.get('company_id'));

                    LoginOtpverification(formData).then(data => {
                        if (data) {
                            if (data.data.statuscode === 200 && location.pathname.includes("forgot")) {
                                toast(data.data.message);
                                setTimeout(() => {
                                    setLoader(false);
                                    setSessionKey('reset_password_token', data.data.response.validate_string);
                                    navigate(`/company/resetpassword?company_id=${searchParams.get('company_id')}`);
                                }, 2000);
                            } else {
                                if (data && data.data && data.data.statuscode === 200) {

                                    const brands = data && data.data && data.data.response ? data.data.response.brands : [];
                                    localStorage.setItem('outlet_id', data.data.response.company_user.outlet_id);
                                    if (brands.length > 1) {

                                        const loginDetail = {
                                            'company_token': data.data.response.token,
                                            'company_user': data.data.response,
                                            'company_detail': data.data.response.companyDetail,
                                            'permission': data.data.response.roleDetails.moduleAssignedPermissions,
                                        }

                                        localStorage.setItem("brand_list", JSON.stringify(brands));
                                        localStorage.setItem("login_detail", JSON.stringify(loginDetail));

                                        setTimeout(() => {
                                            setLoader(false)
                                            navigate('/company/brand')
                                        }, 2000);

                                    } else {

                                        localStorage.setItem("company_token", data.data.response.token);
                                        localStorage.setItem("company_user", JSON.stringify(data.data.response));
                                        localStorage.setItem("company_detail", JSON.stringify(data.data.response.companyDetail));
                                        localStorage.setItem("permission", JSON.stringify(data.data.response.roleDetails.moduleAssignedPermissions));
                                        toast(data.data.message);
                                        if (brands.length == 1) {
                                            localStorage.setItem("brand_id", brands[0].id);
                                        }

                                        setTimeout(() => {
                                            setLoader(false)
                                            navigate(`/company/dashboard`)
                                        }, 2000);
                                    }

                                    //fetching orders to store in indexedDB
                                    GetAllOrders().then(data => {
                                        //IndexedDB service
                                        saveOrders(data.data.response)
                                            .then(() => {
                                                console.log('All orders are saved.');
                                            })
                                            .catch((error) => {
                                                console.error('Error saving orders data:', error);
                                            });
                                    }).catch(error => {
                                        console.log("error ====> ", error);
                                    })
                                } else if (data && data.data && data.data.statuscode !== 200) {
                                    toast(data.data.message);
                                    setTimeout(() => {
                                        setLoader(false)
                                    }, 2000);
                                }
                            }
                        } else {
                            setLoader(false);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className="page">
                        <div className="login-wraper">
                            <div className="login-wraper-in">
                                <div className='login-left'>
                                    <figure>
                                        <img src={loginBg} />
                                    </figure>
                                    <div className='login-text'>
                                        <h4>Efficiency at your fingertips! </h4>
                                        <p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
                                    </div>
                                </div>
                                <div className='login-right'>
                                    <div className='login-right-in'>
                                        <div class="login-head">
                                            <Link to="/"> <img src={Logo} /> </Link>
                                            <div class="login-head-text">
                                                <h2>OTP Verification</h2>
                                                <p>Enter the OTP sent to your email</p>
                                            </div>
                                        </div>
                                        <div className='login-body'>
                                            <div className='form-box'>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group text-left">
                                                        <label>OTP</label>
                                                        <input className={"form-control"}
                                                            placeholder="Enter OTP"
                                                            type="number"
                                                            name="otp"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.otp}
                                                            autoComplete="off"
                                                            autoFocus
                                                        />
                                                        <span className='text-danger text-left d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                                                    </div>
                                                    <button className={"btn ripple btn-primary signbtn w-100 position-relative mt-4" + (loader === true ? " disabled" : "")} type="submit">Continue
                                                        {loader === true ?
                                                            <div className="spinner-border text-white mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
                                                    </button>

                                                </form>
                                                <div className="text-center mt-3 ml-0">
                                                    <div className="mb-1">Didn't receive OTP? <button className='' onClick={resentotpfunct}>Resend OTP</button></div>
                                                </div>
                                                <div className="mt-2 text-center">
                                                    <Link to="/company/login">Back to login</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </LoginLayout>
    )
}

export default CompanyOtpVerificationPage