import React from 'react'
import banner from '../assets/company/img/banner.png'
import partner1 from '../assets/company/img/part1.png'
import partner2 from '../assets/company/img/part2.png'
import partner3 from '../assets/company/img/part3.png'
import partner4 from '../assets/company/img/part4.png'
import partner5 from '../assets/company/img/part5.png'
import partner6 from '../assets/company/img/part6.png'
import Layout from './common/layout'
import {Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  
  return (

    <Layout>
      <div className='home-body'>
        {/* <section className='home-banner'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5 d-flex align-items-center'>
                <div className="banner-text">
                  <h2>Fully Integrated Point-of-sale System</h2>
                  <h1>The Simple, Secure, and Scalable Way to Run Your Business</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua ut</p>
                  <Link className='btn btn-primary mt-4' to="/company/login">Get Started</Link>
                </div>
              </div>
              <div className='col-md-6 home-banner-img ms-auto'>
                <figure className='mb-0'>
                  <img src={banner}/>
                </figure>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="logos-sec">
          <div className="container">
            <div className="logos-in row">
              <figure className="col-2">
                <img src={partner1}/>
              </figure>
              <figure className="col-2">
                <img src={partner2}/>
              </figure>
              <figure className="col-2">
                <img src={partner3}/>
              </figure>
              <figure className="col-2">
                <img src={partner4}/>
              </figure>
              <figure className="col-2">
                <img src={partner5}/>
              </figure>
              <figure className="col-2">
                <img src={partner6}/>
              </figure>
            </div>
          </div>
        </section> */}
        <section className="info-sec mt-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 ms-auto">
                <div className="info-contetn-area">
                  <div className="info-sec-text">
                    <h4 className='text-center'>Privacy Policy</h4>
                    <p>Please read these terms and conditions ("terms and conditions", "terms") carefully
                    before using "https://pointofsales.devtechnosys.tech/" website (“website”, "service") operated by POS
                    ("us", 'we", "our").</p>
                    <h3>Conditions of use</h3>
                    <p>By using this website, you certify that you have read and reviewed this Agreement and
                    that you agree to comply with its terms. If you do not want to be bound by the terms of
                    this Agreement, you are advised to stop using the website accordingly. POS
                    only grants use and access of this website, its products, and its services to those who
                    have accepted its terms.</p>
                    <h3>Age restriction</h3>
                    <p>You must be at least 18 (eighteen) years of age before you can use this website. By
                    using this website, you warrant that you are at least 18 years of age and you may
                    legally adhere to this Agreement. POS assumes no responsibility for
                    liabilities related to age misrepresentation.</p>
                    <h3>Intellectual property</h3>
                    <p>You agree that all materials, products, and services provided on this website are the
                    property of POS, its affiliates, directors, officers, employees, agents,
                    suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and
                    other intellectual property. You also agree that you will not reproduce or redistribute the
                    POS’s intellectual property in any way, including electronic, digital, or new
                    trademark registrations.</p>
                    <p>You grant POS a royalty-free and non-exclusive license to display, use,
                    copy, transmit, and broadcast the content you upload and publish. For issues regarding
                    intellectual property claims, you should contact the company in order to come to an
                    agreement.</p>
                    <h3>User accounts</h3>
                    <p>As a user of this website, you may be asked to register with us and provide private
                    information. You are responsible for ensuring the accuracy of this information, and you
                    are responsible for maintaining the safety and security of your identifying information. </p>
                    <p>You are also responsible for all activities that occur under your account or password.
                    If you think there are any possible issues regarding the security of your account on the
                    website, inform us immediately so we may address them accordingly.
                    We reserve all rights to terminate accounts, edit or remove content and cancel orders at
                    our sole discretion.</p>
                    <h3>Applicable law</h3>
                    <p>By using this website, you agree that the laws of the [your location], without regard to
                    principles of conflict laws, will govern these terms and conditions, or any dispute of any
                    sort that might come between POS and you, or its business partners and
                    associates.</p>
                    <h3>Disputes</h3>
                    <p>Any dispute related in any way to your use of this website or to products you purchase
                    from us shall be arbitrated by state or federal court [your location] and you consent to
                    exclusive jurisdiction and venue of such courts.</p>
                    <h3>Indemnification</h3>
                    <p> You agree to indemnify POS and its affiliates and hold POS
                    harmless against legal claims and demands that may arise from your use or misuse of
                    our services. We reserve the right to select our own legal counsel.</p>
                    <h3>Limitation on liability</h3>
                    <p>POS is not liable for any damages that may occur to you as a result of your
                    misuse of our website.</p>
                    <p> POS reserves the right to edit, modify, and change this Agreement at any
                    time. We shall let our users know of these changes through electronic mail. This
                    Agreement is an understanding between POS and the user, and this
                    supersedes and replaces all prior agreements regarding the use of this website. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
   
  );
}

export default PrivacyPolicy