import React, { useState,useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import { GetCompanyRequestList } from '../../services/companyPanelService'
import CustomPagination from '../../components/common/custompagination'
import { formateDateWithTime } from '../../helpers/commonFunctions';
import Sorting from '../../components/common/sorting';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import CustomRangepicker from '../../components/common/rangepicker'
import $ from "jquery";

const CompanyRequestTable = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Companies Inquiry", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            GetCompanyRequestList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
         
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
   
        setResetDate(!resetdate)
    }

 

    // view details functionality

    const viewFunction = (row) => {
        navigate(`/admin/company-request/view/${row}`)
    }

    const enrollRequest = (row)=>{
        navigate(`/admin/company/add`,{state:row})
    }
  
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


  return (
    <Layout>
       <Breadcrums data={breadcrumbs} />
        <div className="animation_fade">
            <div className="card custom-card overflow-hidden">
            <div className="card-body">
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h6 className="main-content-label">COMPANY INQUIRY LISt</h6>
                    <div className='d-flex align-items-center'>
                        <div className='form-group mb-0 me-3'>
                            <div className="form-group mb-0 rangepicker_container">
                                <i className="fa fa-search calender_icon"></i>
                                <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                            </div>
                        </div>
                        <div className="me-3">
                            <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate}/>
                        </div>
                        <button type="reset" value='Reset' onClick={resetFilter}  className="btn btn-warning float-right mr-2">Reset Filter</button>
                    </div>
                </div>
                <div className="table-responsive">
                    <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th className="sr_head">S.No</th>
                                <th>
                                    <div className='sorting_column'>
                                        <span>Logo</span>
                                        
                                    </div>
                                </th>
                                <th>
                                    <div className='sorting_column'>
                                        <span>Name</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                    </div>
                                </th>
                                <th>
                                    <div className='sorting_column'>
                                        <span>Email</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="official_email" />
                                    </div>
                                </th>
                                <th>
                                    <div className='sorting_column'>
                                        <span>Official address</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="official_address" />
                                    </div>
                                </th>
                                <th>
                                    <div className='sorting_column'>
                                        <span>phone number</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="official_phone_number" />
                                    </div>
                                </th>
                                <th className="created_head">
                                    <div className='sorting_column'>
                                        <span>Created date</span>
                                        <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                    </div>
                                </th>
                                <th className='action_head'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loader ?
                            <tr>
                                <td colSpan={11}><Loader /></td>
                            </tr> : <>
                        {list.length ? list.map((row, i) => (
                            <tr>
                            <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td>
                            <td>{row.logo?<img src={row.logo} style={{ height: "50px" }}/>:''}</td>
                            <td>{row.name}</td>
                            <td>{row.official_email}</td>
                            <td>{row.official_address}</td>
                            <td>+{row.country_code} {row.official_phone_number}</td>
                            <td>{row.createdAt ? formateDateWithTime(row.createdAt) : "N/A"}</td>
                            <td>
                                <div className="d-flex">
                                    <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewFunction(row.id) }}>View</button>
                                    {
                                        row.enrolled==true ?
                                        <button className="btn ripple btn-danger  ms-1" disabled >Enrolled</button> :
                                        <button className="btn ripple btn-success  ms-1" onClick={() => { enrollRequest(row) }}>Enroll</button>    
                                    }
                                </div>
                            </td>
                            </tr>
                            )) : <tr><td colSpan={11} className="text-center">No records</td></tr>}</>}
                        </tbody>
                    </table>
                </div>
                <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                {dataLength && dataLength > 0 ?
                <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "company", path: "/admin/company-request/list" }]} /> : ""}
            </div>
            <div>
            </div>
            </div>
        </div>
    </Layout>
  );
}

export default CompanyRequestTable