import { openDB } from 'idb';

//indexedDb local database 

//creating database
export async function setupDatabase() {
// Open or create database db1 with version 1
    const db = await openDB('db', 1, {
        upgrade(db) {
            // Create object brands_with_outlts 'store'
            if (!db.objectStoreNames.contains('brands_with_outlets')) {
                db.createObjectStore('brands_with_outlets', { keyPath: 'brand_id' });
            }
            if (!db.objectStoreNames.contains('orders')) {
                db.createObjectStore('orders', { keyPath: 'id' });
            }
        },
    });
    console.log('Databases and object stores created');
    return db;
}

//services----brands_with_outlets
export async function saveBrandWithOutlets(data) {
    const db = await setupDatabase();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction('brands_with_outlets', 'readwrite');
        const objectStore = transaction.objectStore('brands_with_outlets');

        transaction.oncomplete = function () {
            console.log('All records saved successfully.');
            resolve();
        };

        transaction.onerror = function (event) {
            console.error('Transaction error:', event.target.error);
            reject(event.target.error);
        };

        // Use Promise.all to batch the put requests
        const requests = data.map(item => objectStore.put(item));

        Promise.all(requests)
            .then(() => {
                console.log('All brands with outlets saved successfully.');
            })
            .catch((error) => {
                console.error('Error saving brands with outlets:', error);
            });
    });
}
export async function getAllBrandWithOutlets() {
    const db = await setupDatabase();
    const brandsWithOutlets = await db.getAll('brands_with_outlets');
    return brandsWithOutlets;    
}
export async function getBrandWithOutlets(brandId) {
    const db = await setupDatabase();
    const transaction = db.transaction('brands_with_outlets', 'readwrite');
    const objectStore = transaction.objectStore('brands_with_outlets');

    const brandWithOutlets = objectStore.get(brandId);
    return brandWithOutlets;    
}

//services----orders
export async function saveOrders(data) {
    const db = await setupDatabase();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction('orders', 'readwrite');
        const objectStore = transaction.objectStore('orders');

        transaction.oncomplete = function () {
            console.log('All records saved successfully.');
            resolve();
        };

        transaction.onerror = function (event) {
            console.error('Transaction error:', event.target.error);
            reject(event.target.error);
        };

        // Use Promise.all to batch the put requests
        const requests = data.map(item => objectStore.put(item));

        Promise.all(requests)
            .then(() => {
                console.log('All orders saved successfully.');
            })
            .catch((error) => {
                console.error('Error saving orders:', error);
            });
    });
}
export async function getAllOrders() {
    const db = await setupDatabase();
    const orders = await db.getAll('orders');
    return orders;    
}