import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { GetOutletManagementDetail } from '../../services/outletManagementService'
import toHtml from 'html-react-parser'
import { formateDate, formatTime, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'


const ViewOutletManagement = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const location = useLocation()

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const companyId = queryParams.get('id');

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Company", url: "/admin/company/list/1" }, { title: "Outlets", url: `/admin/company/outlets/list/${companyId}?id=1` }, { title: "View", url: "" }]

    useEffect(() => {
        GetOutletManagementDetail({ id: params.id, company_id: companyId }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-7 col-md-7 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h3 className="main-content-label mb-3">View Outlet management</h3>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Id</th>
                                                    <td>{showDefault && showDefault.id ? showDefault.id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet Name</th>
                                                    <td>{showDefault && showDefault.outlet_name ? showDefault.outlet_name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet Name (Arabic)</th>
                                                    <td>{showDefault && showDefault.outlet_name_ar ? showDefault.outlet_name_ar : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Shortcode</th>
                                                    <td>{showDefault && showDefault.short_code ? showDefault.short_code : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>VAT information</th>
                                                    <td>{showDefault && showDefault.vat_information ? showDefault.vat_information : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Brand Name</th>
                                                    <td>{showDefault && showDefault.brand ? showDefault.brand.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{showDefault && showDefault.email ? showDefault.email : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>{showDefault && showDefault.location ? showDefault.location : "N/A"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h3 className="main-content-label mb-3">Hardware Details</h3>
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>

                                                {showDefault.outlet_hardware_details.length ? showDefault.outlet_hardware_details.map((row, i) => (

                                                    <tr>
                                                        <td>
                                                            <h4>{row && row.hardware_used ? row.hardware_used.toUpperCase() : "N/A"}</h4>
                                                            <div className='row'>
                                                                <div className='col-3'>Registration ID : {row && row.registration_id ? row.registration_id : "N/A"}</div>
                                                                <div className='col-3'>Name :{row && row.name ? row.name : " N/A"}</div>
                                                                <div className='col-3'>SDK ID :{row && row.sdk_id ? row.sdk_id : " N/A"}</div>
                                                                <div className='col-3'>Printer Type : {row && row.printer_type ? row.printer_type : "N/A"}</div>

                                                            </div>
                                                        </td>
                                                    </tr>

                                                )) : ''}

                                            </tbody>
                                        </table>
                                        <h3 className="main-content-label mb-3">Store Settings</h3>
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Invoice Header Text Info</th>
                                                    <td colSpan="2">{showDefault && showDefault.invoice_header_text_info ? showDefault.invoice_header_text_info : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Invoice Footer Text Info</th>
                                                    <td colSpan="2">{showDefault && showDefault.invoice_footer_text_info ? showDefault.invoice_footer_text_info : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>End of the day configuration</th>
                                                    <td colSpan="2">{showDefault && showDefault.end_of_the_day_configuration ? showDefault.end_of_the_day_configuration : ""} {showDefault && showDefault.end_of_the_day_time ? showDefault.end_of_the_day_time : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h4>Store opening and closing time</h4>
                                                        {/* <div className='row'>
                                                            <div className='col-4'>
                                                                Monday : {formatTime(showDefault.monday_opening_time)} - {formatTime(showDefault.monday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Tuesday : {formatTime(showDefault.tuesday_opening_time)} - {formatTime(showDefault.tuesday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Wednesday : {formatTime(showDefault.wednesday_opening_time)} - {formatTime(showDefault.wednesday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Thursday : {formatTime(showDefault.thursday_opening_time)} - {formatTime(showDefault.thursday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Friday : {formatTime(showDefault.friday_opening_time)} - {formatTime(showDefault.friday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Saturday : {formatTime(showDefault.saturday_opening_time)} - {formatTime(showDefault.saturday_closing_time)}
                                                            </div>
                                                            <div className='col-4'>
                                                                Sunday : {formatTime(showDefault.sunday_opening_time)} - {formatTime(showDefault.sunday_closing_time)}
                                                            </div>
                                                        </div> */}
                                                        <div className='row'>
                                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, index) => (
                                                                <div className='col-4 mt-1' key={index}>
                                                                    {`${day.charAt(0).toUpperCase() + day.slice(1)} : `}
                                                                    {showDefault[`${day}_opening_time`] || showDefault[`${day}_closing_time`] ? (
                                                                        <>
                                                                            {showDefault[`${day}_opening_time`] ? (showDefault[`${day}_opening_time`]) : ''} -
                                                                            {showDefault[`${day}_closing_time`] ? (showDefault[`${day}_closing_time`]) : ''}
                                                                        </>
                                                                    ) : (
                                                                        <span style={{ color: 'red' }}>Closed</span>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>How many copy of the check will be print ?</th>
                                                    <td colSpan="2">{showDefault && showDefault.copy_print_count ? showDefault.copy_print_count : "N/A"}</td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 animation_fade"></div>
                </div>
            </Layout>
        </>
    )
}

export default ViewOutletManagement