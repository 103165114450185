import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const ConfigurationReportTab = ({ tab: Tab }) => {

    return (
        <ul className='d-flex setting w-100 mb-0 p-0'>
            <li className={Tab === 'sales' ? 'active' : ''}>
                <Link to={`/company/report/sales_report/list/${1}`} className="btn">
                    Sales Report
                </Link>
            </li>
            <li className={Tab === 'customer' ? 'active' : ''}>
                <Link to={`/company/report/customer_report/list/${1}`} className="btn">
                   Customer Report
                </Link>
            </li>
            <li className={Tab === 'category' ? 'active' : ''}>
                <Link to={`/company/report/primary_category_report/list/${1}`} className="btn">
                    Primary Category Report
                </Link>
            </li>
            <li className={Tab === 'inventory' ? 'active' : ''}>
                <Link to={`/company/report/inventory_report/list/${1}`} className="btn">
                   Inventory Report
                </Link>
            </li>
            <li className={Tab === 'payment' ? 'active' : ''}>
                <Link to={`/company/report/payment_report/list/${1}`} className="btn">
                    Payment Report
                </Link>
            </li>

            <li className={Tab === 'cost' ? 'active' : ''}>
                <Link to={`/company/report/item_cost/list/${1}`} className="btn">
                    Item Cost Calculations
                </Link>
            </li>
        </ul>
    );
}

export default ConfigurationReportTab
