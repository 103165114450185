import axiosInstance from "../../utils/axiosCompany";

export const getDailyData = async (values) => {
    return await axiosInstance().post("company/dashboard/get-daily-data", values);
}
export const getAllLoggedInUser = async (values) => {
    return await axiosInstance().post("company/dashboard/get-all-loggedIn-user", values);
}
export const getTodaysOrders = async (values) => {
    return await axiosInstance().post("company/dashboard/get-todays-orders", values);
}
export const getTodaysTransactions = async (values) => {
    return await axiosInstance().post("company/dashboard/get-todays-transactions", values);
}
