import axiosInstance from "../utils/axios";

export const CreateBrand = async (values) => {
    return await axiosInstance().post("admin/save-brand", values);
}

export const GetBrandList = async (values) => {
    return await axiosInstance().post("admin/list-brand", values);
}

export const GetEditBrand  = async (values) => {
    return await axiosInstance().post("admin/edit-brand", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("admin/change-status-brand", values)
}

export const GetDeleteBrand = async (values) => {
    return await axiosInstance().post("admin/delete-brand", values)
}

export const GetBrandDetail = async (values) => {
    return await axiosInstance().get(`admin/get-edit-brand?id=${values.id}&company_id=${values.company_id}`);
};





