import React from 'react';

const GlobalLoader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 start-0"
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        zIndex: 1050, // Ensure it stays on top
      }}
    >
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default GlobalLoader;
