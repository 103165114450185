    import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import $ from 'jquery';
import Swal from "sweetalert2";
import { LoginService } from '../../services/LoginServices';
import LoginLayout from '../../loginlayout';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Logo from '../../assets/img/brand/Mask Group 1.png'
import { getSessionKey, removeSessionKey } from '../../helpers/commonFunctions';
import { GetLogo } from '../../services/settingsService';
import { useNavigate, useParams } from 'react-router-dom'


const Login = () => {
    // const { tab } = useParams()
    // const dispatch = useDispatch()
    // const counter = useSelector((state) => console.log("state", state))
    const navigate = useNavigate();
    const [show, setShow] = useState('false')
    const [loader, setLoader] = useState('false')
    const [showDefault, setShowDefault] = useState({});

    const params = useParams()

    const handleshow = () => {
        setShow(!show)
    }


    useEffect(() => {
        GetLogo(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    $(document).ready(function(){
        if(getSessionKey("session_expired") && getSessionKey("session_expired") === "true"){
            Swal.fire({
                customClass: 'swal-wide',
                title: "Session expired",
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
                toast: true,
                position: 'top-right',
            });
            removeSessionKey("session_expired");
        }
    });
    return (

        <LoginLayout>
            {/* {!tab ? */}

            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    if (!values.password) {
                        errors.password = 'Password is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    // setEmailProp(values.email)
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", values.email);
                    formData.append("password", values.password);
                    LoginService(formData).then(data => {    
                      if(data){
                        if (data.data.status === 1 || data.data.status === "1") {
                            
                            if(data.data.response.two_factor){
                                toast(data.data.message)
                                setTimeout(() => {
                                    setLoader(false)
                                    navigate(`/admin/otpverification/${values.email}?type=login`)
                                }, 2000);
                            }else{
                                localStorage.setItem("token", data.data.response.token);
                                localStorage.setItem("user", JSON.stringify(data.data.response));
                                setTimeout(() => {
                                    navigate(`/admin/dashboard`)
                                }, 2000);
                            }
                        } else {
                            toast(data.data.message.message);
                            setTimeout(() => {
                                setLoader(false)
                            }, 2000);
                        }
                      }else{
                        setLoader(false);
                      }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    // and other goodies
                }) => (
                    <div className="row signpages text-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row row-sm" style={{height: "28rem"}}>
                                    <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                        <div className="m-5 p-5 pos-absolute align-content-center" style={{height: "22rem"}}>
                                            <img src={showDefault.logo} className="mb-4" alt="logo" />
                                            <div className="clearfix"></div>
                                            <h5 className="mt-4 text-white">Login Your Account</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form align-content-center">
                                        <div className="container-fluid">
                                            <div className="row row-sm">
                                                <div className="card-body mt-2 mb-2">
                                                    <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
                                                    <div className="clearfix"></div>
                                                    <form onSubmit={handleSubmit}>
                                                        <h5 className="text-left mb-2">Signin to Your Account</h5>
                                                        <p className="mb-4 text-muted tx-13 ml-0 text-left">Signin to create, discover and connect with the global community</p>
                                                        <div className="form-group text-left">
                                                            <label>Email</label>
                                                            <input className={"form-control" + (errors.email && touched.email ? "" : "")}
                                                                placeholder="Enter your email"
                                                                type="text"
                                                                name="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                                autoComplete="off" />
                                                            <span className='text-danger text-left d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                        </div>
                                                        <div className="form-group text-left">
                                                            <label>Password</label>
                                                            <div className="input-group">
                                                                <input className={"form-control" + (errors.password && touched.password ? "" : "")}
                                                                    placeholder="Enter your password"
                                                                    type={!show === true ? "text" : "password"}
                                                                    name="password"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.password}
                                                                    autoComplete="off" />
                                                                <span className='input-group-text' onClick={handleshow}>{(!show === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
                                                            </div>
                                                            <span className='text-danger text-left d-block err-spn'>{errors.password && touched.password && errors.password}</span>
                                                        </div>
                                                        
                                                        <button className={"btn ripple btn-main-primary btn-block position-relative signbtn" + (loader === true ? " disabled" : "")} type="submit">Sign In

                                                        {loader === true ?
                                                            // <div className="spinner-border text-primary position-absolute login-page" role="status">
                                                            //     <span className="sr-only">Loading...</span>
                                                            // </div> 
                                                            <div className="spinner-border position-absolute login-page" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                            </div>
                                                        :''}
                                                        
                                                        </button>
                                                      
                                                    </form>
                                                    <div className="text-left mt-5 ml-0">
                                                        <div className="mb-1"><Link to="/admin/forgot-password">Forgot password?</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
            {/* : <OtpVerificationPage Email={emailprop} />} */}

            {/* Error message show */}
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
            {/* error msg end */}
        </LoginLayout>
    )
}
export default Login;