import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { GetChangePasswordBanner } from '../../services/chngepasswordservice'
import Breadcrums from '../common/breadcrumbs'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Changepassword = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState('false')
    const [newshow, setNewShow] = useState('false')
    const [conformshow, setConformShow] = useState('false')
    const [profileid, setProfileId] = useState()
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "ChangePassword", url: "" }]

    const handleshow = (event) => {
        if (event === "oldshw") {
            setShow(!show)
        }
        if (event === "newpwd") {
            setNewShow(!newshow)
        }
        if (event === "confirepwd") {
            setConformShow(!conformshow)
        }
    }

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("user"))
        setProfileId(id.id)
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        oldpassword: "",
                        newpassword: "",
                        confirmpassword: "",
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.oldpassword) error.oldpassword = "Old password is required";        
                        if (!values.newpassword) error.newpassword = "New Password is required"
                        if (!values.confirmpassword) error.confirmpassword = "Confirm Password is required"
                        if (values.newpassword != values.confirmpassword) error.confirmpassword = "Password does not match"
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append('id', profileid);
                        formData.append('current_password', values.oldpassword);
                        formData.append('new_password', values.newpassword);
                        formData.append('confirm_password', values.confirmpassword);
                        GetChangePasswordBanner(formData).then(data => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if(data){
                                if (data && data.data && data.data.success === 1) {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: data.data.message,
                                        showConfirmButton: false,
                                        timer: 2000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    resetForm({ values: '' });
                                    setTimeout(() => {
                                        localStorage.clear();
                                        navigate('/admin/login');
                                    }, 2000);
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: data.data.error[0].msg,
                                        showConfirmButton: false,
                                        timer: 2000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                }
                            }else{
                                
                            }
                        }).catch(error => {
                            setSubmitted(false)
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
                                        <div className="profile-tab tab-menu-heading mt-5">
                                            <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
                                                <span className="nav-link pl-2" data-toggle="tab" href="#edit">CHANGE PASSWORD</span>
                                            </nav>
                                        </div>
                                        <div className="card-body border mb-5">
                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor="oldpassword">Old Password</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group">
                                                            <input
                                                                type={!show === true ? "text" : "password"}
                                                                className="form-control"
                                                                id="oldpassword"
                                                                name="oldpassword"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.oldpassword}
                                                                placeholder="Enter old password" />
                                                            <span className='input-group-text' onClick={() => { handleshow("oldshw") }}>{(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                        </div>
                                                        <span className='text-danger d-flex text-left'>{errors.oldpassword && touched.oldpassword && errors.oldpassword}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor='newpassword'>New Password</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group">
                                                            <input
                                                                type={!newshow === true ? "text" : "password"}
                                                                id="newpassword"
                                                                name='newpassword'
                                                                className="form-control"
                                                                placeholder="Enter password"
                                                                value={values.newpassword}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                            <span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                        </div>
                                                        <span className='text-danger d-flex text-left'>{errors.newpassword && touched.newpassword && errors.newpassword}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor='confirmpassword'>Confirm Password</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group">
                                                            <input
                                                                type={!conformshow === true ? "text" : "password"}
                                                                id="confirmpassword"
                                                                name='confirmpassword'
                                                                className="form-control"
                                                                placeholder="Confirm password"
                                                                value={values.confirmpassword}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                autoComplete="off" />
                                                            <span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                        </div>
                                                        <span className='text-danger d-flex text-left'>{errors.confirmpassword && touched.confirmpassword && errors.confirmpassword}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Submit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </Layout>
        </>
    )
}

export default Changepassword