import React, { useEffect } from 'react'
import Header from './components/common/header'
import SideBar from './components/common/sidebar'
import Footer from './components/common/footer'
import $ from 'jquery'
import { globalLoader } from './helpers/global'


const Layout = ({ children, updateHeader }) => {
  useEffect(()=>{
    globalLoader(true);
    Promise.all([
      import("./assets/admin/css/skins.css"),
      import("./assets/admin/css/icon-list.css"),
      import("./assets/admin/css/style.css"),
      import("./assets/admin/css/dark-style.css"),
      import("./assets/admin/css/colors/default.css"),
      import("./assets/admin/css/sidemenu/sidemenu.css"),
      import("./assets/admin/bootstrap-daterangepicker/daterangepicker.css"),
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })
  },[]);

  return (
    <div className='page'>
      <SideBar />
      <Header updateHeader={updateHeader} />
      <div className="main-content side-content 123456">
        <div className="container-fluid">
          <div className="inner-body">
            {children}
          </div>
        </div>
      </div>
      <a href="#top" id="back-to-top"><i className="fe fe-arrow-up"></i></a>
      <Footer />
    </div>
  )
}

export default Layout