import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { capitalizeAllFirstLetters, getBrandId, getCompany } from '../../../helpers/commonFunctions';
import { addMenuItems, getAllMenuCategoryItems } from '../../../services/company_panel/categoryService'
import Swal from 'sweetalert2';
import { updateMenuCategoryOrder } from '../../../services/company_panel/menuCategoryService';

const CreateMenu = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedSubCategories, setSelectedSubCategories] = useState({});
    const [selectedMenuItems, setSelectedMenuItems] = useState({});
    const navigate = useNavigate();
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    const breadcrumbs = [
        { title: "Menu ", url: "/company/menu/menu/menu_card/list/1" },
        { title: "Create Menu", url: "" },
    ];

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/menu_card/list/${1}`);
        }
    });
    useEffect(() => {
        getAllMenuCategoryItems({ brand_id: getBrandId() })
            .then(data => {
                if (data?.data?.response) {
                    const menuItems = data.data.response;
                    const filteredCategories = menuItems.map(category => ({
                        ...category,
                        SubCategories: category.SubCategories.filter(sub => sub.menu_items.length > 0).map((subCategory) => ({ ...subCategory, fetching_order: subCategory.fetching_order ? subCategory.fetching_order : 0 })),
                        fetching_order: category.fetching_order ? category.fetching_order : 0
                    })).filter(category => category.SubCategories.length > 0);
                    setCategories(filteredCategories);
                }
            })
            .catch(error => console.log("error=====>", error));
    }, [isBrandChanged]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategories(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
        if (!selectedCategories[categoryId]) {
            setSelectedSubCategories(prev => {
                const updatedSubCategories = { ...prev };
                const category = categories.find(cat => cat.id === categoryId);
                category.SubCategories.forEach(sub => {
                    updatedSubCategories[sub.id] = false;
                });
                return updatedSubCategories;
            });
            setSelectedMenuItems(prev => {
                const updatedMenuItems = { ...prev };
                const category = categories.find(cat => cat.id === categoryId);
                category.SubCategories.forEach(sub => {
                    sub.menu_items.forEach(menuItem => {
                        updatedMenuItems[menuItem.id] = false;
                    });
                });
                return updatedMenuItems;
            });
        }
    };

    const handleSubCategoryChange = (subCategoryId) => {
        setSelectedSubCategories(prev => ({
            ...prev,
            [subCategoryId]: !prev[subCategoryId]
        }));
        if (!selectedSubCategories[subCategoryId]) {
            setSelectedMenuItems(prev => {
                const updatedMenuItems = { ...prev };
                const category = categories.find(cat => cat.SubCategories.some(sub => sub.id === subCategoryId));
                const subCategory = category.SubCategories.find(sub => sub.id === subCategoryId);
                subCategory.menu_items.forEach(menuItem => {
                    updatedMenuItems[menuItem.id] = false;
                });
                return updatedMenuItems;
            });
        }
    };

    const handleMenuItemChange = (menuItemId) => {
        setSelectedMenuItems(prev => {
            const updatedMenuItems = {
                ...prev,
                [menuItemId]: !prev[menuItemId]
            };

            // Update Formik's value for menu_items_list
            const selectedItems = Object.keys(updatedMenuItems).filter(id => updatedMenuItems[id]);
            formik.setFieldValue('menu_items_list', selectedItems);

            return updatedMenuItems;
        });
    };

    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Name is required').max(20, 'Name must be 20 characters or less'),
        menu_items_list: yup.array().min(1, 'At least one item must be selected').required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            menu_items_list: [],
            // category_order: {} 
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            let formData = new FormData();
            formData.append('name', values.name);
            formData.append('menu_items_list', JSON.stringify(values.menu_items_list));
            formData.append('brand_id', getBrandId());
            formData.append('company_id', getCompany().company_id);

            updateMenuCategoryOrder({ categories: categories })
                .then((response) => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        addMenuItems(formData).then(response => {
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/menu_card/list/${1}`);
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                });
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        });
                    }
                }).catch((error) => {
                    console.log("Error===>", error)
                })
        }
    });

    const handelCategoryOrderChanges = (categoryIndex, value) => {
        // let categoryOrder = {...formik.values.category_order}
        // categoryOrder[categoryIndex] = { id : categoryId, order : value}
        // formik.setFieldValue("category_order",categoryOrder);
        let tempCategories = [...categories];
        tempCategories[categoryIndex].fetching_order = value;
        setCategories(tempCategories);
    };

    const handelSubCategoryOrderChanges = (categoryIndex, subCategoryIndx, value) => {
        let tempCategories = [...categories];
        tempCategories[categoryIndex].SubCategories[subCategoryIndx].fetching_order = value;
        setCategories(tempCategories);
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Create Menu</h4>
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row row-sm">
                    <div className="card custom-card">
                        <div className="card-body">
                            {categories?.length === 0 ?
                                <div className="alert alert-warning text-center">
                                    There is no item created yet, Please{' '}
                                    <Link to="/company/menu/menu/menu_items/add">
                                        create a menu item
                                    </Link>{' '}
                                    to create menu
                                </div> : null}

                            <div className="row row-sm">
                                <div className='col-lg-6 form-group mt-3'>
                                    <label htmlFor='name' className='text-left d-flex'>
                                        Name <span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name='name'
                                        id="name"
                                        type='text'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        placeholder='Enter name'
                                        className='form-control'
                                    />
                                    <span className='text-danger d-flex text-left'>
                                        {formik.errors.name && formik.touched.name && formik.errors.name}
                                    </span>
                                </div>
                                <div className='col-lg-6'></div>

                                <label className='col-lg-6 mt-4 ' style={{ fontWeight: '600' }}>Select Items</label>
                                {categories.map((category, index) => (
                                    <div key={category.id} className='category-label'>
                                        <label className='d-flex align-items-center cp'>
                                            <input
                                                type="checkbox"
                                                checked={!!selectedCategories[category.id]}
                                                onChange={() => handleCategoryChange(category.id)}
                                                className='form-check-input cp'
                                            />
                                            <span className='form-check-label'>{capitalizeAllFirstLetters(category.name)}</span>
                                            <input
                                                type="text"
                                                name='category_order'
                                                placeholder="Enter order"
                                                className="form-control form-control-sm mx-2"
                                                style={{ width: '85px', height: '18px' }}
                                                value={category?.fetching_order}
                                                onChange={(e) => handelCategoryOrderChanges(index, e.target.value)}
                                            />
                                        </label>
                                        {selectedCategories[category.id] && category.SubCategories.length > 0 && (
                                            <div className="subcategory-label mt-2">
                                                {category.SubCategories.map((subCategory, subCategoryIndx) => (
                                                    <div key={subCategory.id}>
                                                        <label className='d-flex align-items-center mb-2 cp'>
                                                            <input
                                                                type="checkbox"
                                                                checked={!!selectedSubCategories[subCategory.id]}
                                                                onChange={() => handleSubCategoryChange(subCategory.id)}
                                                                className='form-check-input cp'
                                                            />
                                                            <span className='form-check-label'>{capitalizeAllFirstLetters(subCategory.name)}</span>
                                                            <input
                                                                type="text"
                                                                name='sub_category_order'
                                                                placeholder="Enter order"
                                                                className="form-control form-control-sm mx-2"
                                                                style={{ width: '90px', height: '18px' }}
                                                                value={subCategory?.fetching_order}
                                                                onChange={(e) => handelSubCategoryOrderChanges(index, subCategoryIndx, e.target.value)}
                                                            />
                                                        </label>
                                                        {selectedSubCategories[subCategory.id] && subCategory.menu_items.length > 0 && (
                                                            <div className="menu-item submenu-label">
                                                                {subCategory.menu_items.map(menuItem => (
                                                                    <div key={menuItem.id}>
                                                                        <label className='mt-3 cp'>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={!!selectedMenuItems[menuItem.id]}
                                                                                onChange={() => handleMenuItemChange(menuItem.id)}
                                                                                className='form-check-input cp '
                                                                            />
                                                                            <span className='form-check-name '>{capitalizeAllFirstLetters(menuItem.item_name)} </span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {formik.errors.menu_items_list && formik.touched.menu_items_list && (
                                    <span className='text-danger d-flex text-left'>
                                        {formik.errors.menu_items_list}
                                    </span>
                                )}
                                <div className="mt-2">
                                    <button className="btn btn-primary" type="submit">
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default CreateMenu;
