import React, { useEffect, useState } from 'react'
import Layout from '../layout'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { GetProfileDetail, GetEditProfile } from '../services/profileservice'
import Swal from 'sweetalert2'
import { blockInvalidChar } from '../helpers/commonFunctions';
import { Dropdown } from 'semantic-ui-react';
import { GetCountryCodeList } from '../services/globalService';
import Breadcrums from '../components/common/breadcrumbs';
import { countries } from 'countries-list';
import { getUser } from '../helpers/commonFunctions';

const Profile = () => {
    const navigate = useNavigate()
    const [showdefault, setShowDefault] = useState({})
    const [previewimage, setPreviewImage] = useState("")
    const [profileid, setProfileId] = useState()
    const [updateData, setUpdateData] = useState('false')
    const [updateHeader, setupdateHeader] = useState(1)
    const [countrylist, setCoutryList] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "User Profile", url: "" }]
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const [userData, setUserData] = useState({});

    const options = Object.keys(countries).map(countryCode => ({
        value: `${countries[countryCode].phone}`,
      }));

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("user"))
        setProfileId(id.id)
        GetProfileDetail(id.id).then(data => {
            console.log(data);
            localStorage.setItem("user", JSON.stringify(data.data.response));
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setPreviewImage(data && data.data && data.data.response && data.data.response.profile_image ? data.data.response.profile_image : "");
        }).catch(error => {
            console.log("error ====> ", error);
        })
        
        setSelectedCountryCode(options);
    }, [updateData])

    useEffect(() => {
		setUserData(getUser());
	}, [])

    return (
        <>
            <Layout updateHeader={updateHeader}>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        profile_image: showdefault && showdefault.profile_image ? showdefault.profile_image : "",
                        Fname: showdefault && showdefault.first_name ? showdefault.first_name : "",
                        countrycode: showdefault && showdefault.country_code ? showdefault.country_code : "",
                        Mobile: showdefault && showdefault.mobile ? showdefault.mobile : "",
                        Email: showdefault && showdefault.email ? showdefault.email : "",
                        files: showdefault && showdefault.username ? showdefault.username : "",
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.Fname) error.Fname = "Full Name is required";
                        // if (!values.countrycode) error.countrycode = "Country code is required";
                        // if (!values.Mobile) {
                        //     error.Mobile = "Mobile is required"
                        // } else if (values.Mobile.length != 10) {
                        //     error.Mobile = "Mobile number should be ten digits"
                        // }
                        if (!values.Email) {
                            error.Email = 'Email is required';
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
                            error.Email = 'Invalid email address';
                        }
                        // if (values.files == undefined || values?.files?.type) {
                        //     if (!values.files || values.files == undefined) {
                        //         error.files = 'Image is required';
                        //         $('#imagefileerror').text('Image is required');
                        //     } else if (!values.files.name.match(/\.(jpg|jpeg|png|webp|avif|gif)$/)) {
                        //         error.files = 'Invalid Format';
                        //         $('#imagefileerror').text('Invalid Format');
                        //     } else {
                        //         $('#imagefileerror').text('');
                        //     }
                        // }
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append('id', profileid);
                        formData.append('username', values.Fname);
                        formData.append('phone_code', values.country_code);
                        formData.append('phone', values.Mobile);
                        formData.append('email', values.Email);
                        formData.append('profile_image', values.files);
                        GetEditProfile(formData).then(response => {
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    position: 'top-right',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true
                                });
                                resetForm({ values: '' });
                                setUpdateData(!updateData)
                                // setTimeout(() => {
                                //     setupdateHeader(Math.random());
                                //     navigate(`/profile`)
                                // }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    position: 'top-right',
                                    icon: 'error',
                                    title: response.data.error[0].msg,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true
                                })
                            }
                        }).catch(error => {
                            setSubmitted(false)
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
                                        <div className="profile-tab tab-menu-heading mt-5">
                                                <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
                                                <span className="nav-link pl-2" data-toggle="tab" href="#edit">PERSONAL INFORMATION</span>
                                            </nav>
                                        </div>
                                        <div className="card-body border mb-5">
                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor="Fname">Full Name</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type="text" className="form-control" id="Fname" name="Fname" onChange={handleChange} onBlur={handleBlur} value={values.Fname} placeholder="First Name" />
                                                        <span className='text-danger d-flex text-left'>{errors.Fname && touched.Fname && errors.Fname}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* country code drop down start */}
                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor='Mobile'>Mobile</label>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="input-group">
                                                            <select id="country_code" className='form-control col-md-2' name='country_code' id="country_code" onChange={handleChange}  onBlur={handleBlur}>
                                                                <option value=''>Select country code</option>
                                                                {selectedCountryCode.length ? selectedCountryCode.map((row) => ( 
                                                                    <option value={row.value} selected={values.countrycode}>+{row.value}</option>
                                                                )):''}
                                                            </select>
                                                            <input
                                                                type="text"
                                                                name='Mobile'
                                                                id="Mobile"
                                                                className="form-control"
                                                                placeholder="Enter Mobile"
                                                                value={values.Mobile}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                onKeyDown={blockInvalidChar}
                                                            />
                                                        </div>
                                                        <span className='text-danger d-flex text-left'>{errors.Mobile && touched.Mobile && errors.Mobile}</span>
                                                        <span className='text-danger d-flex text-left'>{errors.countrycode && touched.countrycode && errors.countrycode}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* country code drop down end */}

                                            <div className="form-group ">
                                                <div className="row row-sm">
                                                    <div className="col-md-3">
                                                        <label className="form" htmlFor='Email'>Email</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type="text" id="Email" name='Email' className="form-control" placeholder="Enter your email" value={values.Email} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                        <span className='text-danger d-flex text-left'>{errors.Email && touched.Email && errors.Email}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form-group'>
                                                <label htmlFor='files' className='text-left d-flex'>Profile Image: </label>
                                                <input className='form-control imgInput' id="files" name="files" type="file" onChange={(event) => {
                                                    setFieldValue("files", event.target.files[0]);
                                                    { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                }} />
                                                <span className='text-danger d-flex text-left' id='imagefileerror'>{errors.files && touched.files && errors.files}</span>
                                            </div>
                                            {
                                                userData ?
                                                    <div className='mb-4'>
                                                        {/* <img src={userData.profile_image} style={{ height: "100px" }} alt="profileImage" /> */}
                                                        <img src={values.profile_image}  alt="profileImage" />
                                                    </div> : ""
                                            }
                                            <div className="">
                                                <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Submit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            </Layout>
        </>
    )
}

export default Profile