import axiosInstance from "../../../utils/axiosCompany";

export const CreateCurrency = async (values) => {
    return await axiosInstance().post("company/currency-configuration/add-currency", values);
}

export const GetCurrencyList = async (values) => {
    return await axiosInstance().post("company/currency-configuration/get-all-currencies", values);
}

export const GetEditCurrency  = async (values) => {
    return await axiosInstance().put("company/currency-configuration/update-currency", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/currency-configuration/change-status", values)
}

export const DeleteCurrency = async (id) => {
    return await axiosInstance().delete(`company/currency-configuration/delete-currency/${id}`)
}

export const GetCurrencyDetail = async (id) => {
    return await axiosInstance().get(`company/currency-configuration/get-single-currency/${id}`);
};

