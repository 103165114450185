import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const ConfigurationTab = ({ tab: Tab }) => {

    return (
        <ul className='d-flex setting w-100 mb-0 p-0'>
            <li className={Tab === 'groups' ? 'active' : ''}>
                <Link to={`/company/menu/routing-station/groups/list/${1}`} className="btn">
                    Groups
                </Link>
            </li>
            <li className={Tab === 'stations' ? 'active' : ''}>
                <Link to={`/company/menu/routing-station/stations/list/${1}`} className="btn">
                   Stations
                </Link>
            </li>
            
        </ul>
    );
}

export default ConfigurationTab
