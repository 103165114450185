import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import {io} from "socket.io-client";
import Layout from '../../layout';
import { executeMigrationsChangesForCompany } from '../../services/companyService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const SampleSocket =()=>{

    const [message, setMessage]=useState("");
	const [recievedMessages, setRecievedMessages]= useState([]);

    const socketServerUrl = process.env.REACT_APP_SOCKET_SERVER_URL;
	const socket = useMemo(()=> io(socketServerUrl), []) ;
    const navigate = useNavigate();
    useEffect(()=>{
        socket.on("connect", ()=>{
            console.log('connected', socket.id);
        })
        socket.on("welcome", (data)=>{
            console.log(data);
        })
        socket.on("message", (data)=>{
            console.log(data);
            setRecievedMessages(prevMessages => [...prevMessages, data]);
        })
        return ()=>{
            socket.disconnect();
        }
    },[])

    const sendEvent=()=>{
        socket.emit('message', message);
    }


    const executeMigrations=()=>{
        executeMigrationsChangesForCompany().then(response => {
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
              });
              setTimeout(() => {
                navigate(`/admin/dashboard`)
              }, 1000);
            } else {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
              })
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
    }

    return(
        <Layout>
        <div className='d-flex col-12'>
    <div className='col-6'>
        <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Write a message' />
        <button className="btn ripple btn-main-primary signbtn" onClick={sendEvent}>Send</button>
        <p>Messages from company panel-------</p>
            {recievedMessages.map((message, index) => (
                <div key={index}>{message}</div>
            ))}
       
    </div>

    <div className='col-6 d-flex justify-content-end align-items-start'>
        <button className="btn btn-dark" onClick={executeMigrations}> Execute Migration changes for Companies</button>
    </div>
</div>

        </Layout>
    )
}

export default SampleSocket;