import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetCurrencyList, DeleteCurrency, CreateCurrency, GetCurrencyDetail, GetEditCurrency } from '../../../services/company_panel/setting/currencyConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeAllFirstLetters, checkPermission, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import ConfigurationTab from '../configurationTab';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notFound from '../../../assets/company/img/no-record-found.png'
import { LOADER_TIME } from '../../../utils/Constants';


const CurrencyList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            GetCurrencyList(formData).then(data => {

                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch,isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteCurrency(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Currency deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {

        GetCurrencyDetail(row).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const copyFunction = (row) => {

        GetCurrencyDetail(row).then(data => {
            console.log(data.data.response.id);
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('add');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end



    // addMore 

    const closeModel = (id) => {
        formik.setErrors({});
        formik.resetForm();
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
        formik.setErrors({});
    }

    const openModel = (id, type) => {
        formik.setErrors({});
        formik.resetForm();
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    // Add and Edit Data
    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Name is required').min(3, 'Name must be at least 3 letters')
            .max(30, 'Name cannot exceed 30 letters'),
        number_of_digits: yup.number().required('Number of digits is required').min(0, 'Number of digits cannot be negative'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: showDefault && showDefault.name ? showDefault.name : "",
            number_of_digits: showDefault && showDefault.number_of_digits ? showDefault.number_of_digits : "",
            status: showDefault && showDefault.status == 1 ? 'on' : "",
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            setSubmitted(true)
            let formData = new FormData();
            formData.append('name', values.name.trim());
            formData.append('number_of_digits', values.number_of_digits);
            formData.append('status', values.status == 'on' ? 1 : 0);
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            formData.append('id', showDefault && showDefault.id ? showDefault.id : "");

            if (actionType === 'edit') {
                GetEditCurrency(formData).then(response => {


                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)

                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            } else {
                CreateCurrency(formData).then(response => {


                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)

                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }
        },
    });



    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='currency' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>

                            </div>
                            <div className='page-header-right ms-auto'>
                            {checkPermission('store_settings', 'add') ?
                                <button className="btn btn-primary" type="button" onClick={(e) => openModel('addCardModal', 'add')}>Add Currency</button>
                                :''}
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Number of digit after the main number</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="number_of_digits" />
                                            </div>
                                        </th>
                                        <th className='status_head text-center' width="25%">Status</th>
                                        <th className='action_head text-end' width="25%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeAllFirstLetters(row.name)}</td>
                                                    <td>{row.number_of_digits}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_settings', 'edit') ?
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                :''}
                                                            {checkPermission('store_settings', 'delete') ?
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                :''}
                                                            {checkPermission('store_settings', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyFunction(row?.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy Currency</div></li>
                                                                :''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={4} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/setting/currency-configuration/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Modal*/}

                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} Currency</h2>
                                <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Name<span className='requirestar'>*</span></label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder='Enter Name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label for="number_of_digits" className="col-form-label">Number of digit after the main number <span className='requirestar'>*</span></label>
                                        {/* <input type="number" className="form-control" name="number_of_digits" id="number_of_digits" placeholder='Enter Number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.number_of_digits} autoComplete='off' /> */}
                                        <select
                                            className="form-control"
                                            name="number_of_digits"
                                            id="number_of_digits"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.number_of_digits}
                                        >
                                            <option value="" disabled>Select Number of Digits</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                        <span className='text-danger d-flex text-left'>{formik.errors.number_of_digits && formik.touched.number_of_digits && formik.errors.number_of_digits}</span>
                                    </div>
                                    <div className="form-check form-switch w-25">
                                        <input className="form-check-input" type="checkbox" id="status" name="status" onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.status == 'on' ? 'checked' : ''} autoComplete='off' />
                                        <label className="form-check-label" for="status">Status</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </div>
        </>
    );
}

export default CurrencyList