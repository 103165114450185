import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { capitalizeFirstLetter, getBrandId, getCompany } from '../../../helpers/commonFunctions';
import { EditMenuItems, GetMenuItems, getAllMenuCategoryItems } from '../../../services/company_panel/categoryService'
import Swal from 'sweetalert2'
import { updateMenuCategoryOrder } from '../../../services/company_panel/menuCategoryService';

const EditMenu = () => {

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedSubCategories, setSelectedSubCategories] = useState({});
    const [selectedMenuItems, setSelectedMenuItems] = useState({});
    const navigate = useNavigate();
    const params = useParams();
    const [data, setData] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    const breadcrumbs = [
        {
            title: "Menu ",
            url: "/company/menu/menu/menu_card/list/1"
        },
        {
            title: "Edit Menu",
            url: ""
        },
    ]

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/menu_card/list/${1}`);
        }
    });

    useEffect(() => {
        getAllMenuCategoryItems({ brand_id: getBrandId() }).then(data => {
            if (data && data.data && data.data.response) {
                const menuItems = data.data.response;
                let categories = [];
                for (let i = 0; i < menuItems.length; i++) {
                    let subCategories = [];
                    for (let j = 0; j < menuItems[i].SubCategories.length; j++) {
                        if (menuItems[i].SubCategories[j].menu_items.length) {
                            subCategories.push(menuItems[i].SubCategories[j]);
                        }
                    }
                    if (subCategories.length) {
                        categories.push({ ...menuItems[i], SubCategories: subCategories })
                    }
                }
                setCategories(categories);
            }
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [isBrandChanged]);

    useEffect(() => {
        GetMenuItems({ id: params.id }).then(response => {
            if (response && response.data && response.data.response) {
                setData(response.data.response);

                const selectedCategoriesData = {};
                const selectedSubCategoriesData = {};
                const selectedMenuItemsData = {};

                response.data.response['menu-item-relations'].forEach(itemRelation => {
                    const menuItem = itemRelation.menu_item;
                    const subCategoryId = menuItem.sub_category_id;
                    const categoryId = menuItem.menu_category.parent_id;

                    selectedCategoriesData[categoryId] = true;
                    selectedSubCategoriesData[subCategoryId] = true;
                    selectedMenuItemsData[menuItem.id] = true;
                });
                setSelectedCategories(selectedCategoriesData);
                setSelectedSubCategories(selectedSubCategoriesData);
                setSelectedMenuItems(selectedMenuItemsData);
            }
        }).catch(error => {
            console.error("error=====>", error);
        });
    }, [params.id]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategories(prev => {
            const newSelection = { ...prev, [categoryId]: !prev[categoryId] };
            const updatedSubCategories = { ...selectedSubCategories };
            const updatedMenuItems = { ...selectedMenuItems };

            if (!newSelection[categoryId]) {
                const category = categories.find(cat => cat.id === categoryId);
                category.SubCategories.forEach(sub => {
                    delete updatedSubCategories[sub.id];
                    sub.menu_items.forEach(menuItem => {
                        delete updatedMenuItems[menuItem.id];
                    });
                });
            } else {
                const category = categories.find(cat => cat.id === categoryId);
                category.SubCategories.forEach(sub => {
                    updatedSubCategories[sub.id] = false;
                    sub.menu_items.forEach(menuItem => {
                        updatedMenuItems[menuItem.id] = false;
                    });
                });
            }

            setSelectedSubCategories(updatedSubCategories);
            setSelectedMenuItems(updatedMenuItems);

            return newSelection;
        });
    };

    const handleSubCategoryChange = (subCategoryId) => {
        setSelectedSubCategories(prev => {
            const newSelection = { ...prev, [subCategoryId]: !prev[subCategoryId] };
            const updatedMenuItems = { ...selectedMenuItems };

            if (!newSelection[subCategoryId]) {
                const category = categories.find(cat => cat.SubCategories.some(sub => sub.id === subCategoryId));
                const subCategory = category.SubCategories.find(sub => sub.id === subCategoryId);
                subCategory.menu_items.forEach(menuItem => {
                    delete updatedMenuItems[menuItem.id];
                });
            } else {
                const category = categories.find(cat => cat.SubCategories.some(sub => sub.id === subCategoryId));
                const subCategory = category.SubCategories.find(sub => sub.id === subCategoryId);
                subCategory.menu_items.forEach(menuItem => {
                    updatedMenuItems[menuItem.id] = false;
                });
            }

            setSelectedMenuItems(updatedMenuItems);

            return newSelection;
        });
    };

    // const handleMenuItemChange = (menuItemId) => {
    //     setSelectedMenuItems(prev => ({
    //         ...prev,
    //         [menuItemId]: !prev[menuItemId]
    //     }));
    // };

    const handleMenuItemChange = (menuItemId) => {
        setSelectedMenuItems(prev => {
            const updatedMenuItems = {
                ...prev,
                [menuItemId]: !prev[menuItemId]
            };

            // Update Formik's value for menu_items_list
            const selectedItems = Object.keys(updatedMenuItems).filter(id => updatedMenuItems[id]);
            formik.setFieldValue('menu_items_list', selectedItems);

            return updatedMenuItems;
        });
    };

    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Name is required').max(20, 'Name must be 20 characters or less'),
        menu_items_list: yup.array().min(1, 'At least one item must be selected').required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data?.name || '',
            menu_items_list: Object.keys(selectedMenuItems).filter(menuItemId => selectedMenuItems[menuItemId])
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            const menuItemsList = Object.keys(selectedMenuItems).filter(menuItemId => selectedMenuItems[menuItemId]);
            let formData = new FormData();
            formData.append('id', params.id);
            formData.append('name', values.name);
            formData.append('menu_items_list', JSON.stringify(menuItemsList));
            formData.append('brand_id', getBrandId());
            formData.append('company_id', getCompany().company_id);

            updateMenuCategoryOrder({ categories: categories })
                .then((response) => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        EditMenuItems(formData).then(response => {
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/menu_card/list/${1}`);
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                });
                            }
                        }).catch(error => {
                            console.error("error ====> ", error);
                        });
                    }
                })
                .catch((error) => {
                    console.log("Error===>", error)
                })
        }
    });

    const handelCategoryOrderChanges = (categoryIndex, value) => {
        let tempCategories = [...categories];
        tempCategories[categoryIndex].fetching_order = value;
        setCategories(tempCategories);
    };

    const handelSubCategoryOrderChanges = (categoryIndex, subCategoryIndx, value) => {
        let tempCategories = [...categories];
        tempCategories[categoryIndex].SubCategories[subCategoryIndx].fetching_order = value;
        setCategories(tempCategories);
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Edit Menu</h4>
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row row-sm">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-sm">
                                <div className='col-lg-6 form-group mt-3'>
                                    <label htmlFor='name' className='text-left d-flex'>
                                        Name <span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name='name'
                                        id="name"
                                        type='text'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        placeholder='Enter name'
                                        className='form-control'
                                    />
                                    <span className='text-danger d-flex text-left'>
                                        {formik.errors.name && formik.touched.name && formik.errors.name}
                                    </span>
                                </div>
                                <label className='mt-4 ' style={{ fontWeight: '600' }}>Select Items</label>
                                {categories.map((category, index) => (
                                    <div key={category.id} className='category-label'>
                                        <label className='d-flex align-items-center cp'>
                                            <input
                                                type="checkbox"
                                                checked={!!selectedCategories[category.id]}
                                                onChange={() => handleCategoryChange(category.id)}
                                            />
                                            <span className='label-span'>{capitalizeFirstLetter(category.name)}</span>
                                            <input
                                                type="text"
                                                name='category_order'
                                                placeholder="Enter order"
                                                className="form-control form-control-sm mx-2"
                                                style={{ width: '85px', height: '18px' }}
                                                value={category?.fetching_order}
                                                onChange={(e) => {
                                                    // Allow only digits and prevent non-numeric values
                                                    const value = e.target.value;
                                                    if (/^\d+$/.test(value) || value === '') {
                                                        handelCategoryOrderChanges(index, value);
                                                    }
                                                }}
                                                pattern="\d+"  // Allows only whole numbers
                                                title="Please enter a whole and positive number"
                                            />
                                        </label>
                                        {selectedCategories[category.id] && category.SubCategories.length > 0 && (
                                            <div className="subcategory-label mt-2">
                                                {category.SubCategories.map((subCategory, subCategoryIndx) => (
                                                    <div key={subCategory.id}>
                                                        <label className='d-flex align-items-center mb-2 cp'>
                                                            <input
                                                                type="checkbox"
                                                                checked={!!selectedSubCategories[subCategory.id]}
                                                                onChange={() => handleSubCategoryChange(subCategory.id)}
                                                            />
                                                            <span className='label-span'>{capitalizeFirstLetter(subCategory.name)}</span>
                                                            <input
                                                                type="text"
                                                                name="sub_category_order"
                                                                placeholder="Enter order"
                                                                className="form-control form-control-sm mx-2"
                                                                style={{ width: '90px', height: '18px' }}
                                                                value={subCategory?.fetching_order}
                                                                onChange={(e) => {
                                                                    // Allow only digits and prevent non-numeric values
                                                                    const value = e.target.value;
                                                                    if (/^\d+$/.test(value) || value === '') {
                                                                        handelSubCategoryOrderChanges(index, subCategoryIndx, value);
                                                                    }
                                                                }}
                                                                pattern="\d+"  // Allows only whole numbers
                                                                title="Please enter a whole and positive number"
                                                            />

                                                        </label>
                                                        {selectedSubCategories[subCategory.id] && subCategory.menu_items.length > 0 && (
                                                            <div className="menu-item submenu-label">
                                                                {subCategory.menu_items.map(menuItem => (
                                                                    <div key={menuItem.id}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={!!selectedMenuItems[menuItem.id]}
                                                                                onChange={() => handleMenuItemChange(menuItem.id)}
                                                                            />
                                                                            <span className='label-span'>{capitalizeFirstLetter(menuItem.item_name)} </span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <span className='text-danger d-flex text-left'>
                                    {formik.errors.menu_items_list && formik.touched.menu_items_list && formik.errors.menu_items_list}
                                </span>
                                <div className="mt-2">
                                    <button className="btn btn-primary" type="submit">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default EditMenu