
export const globalLoader = (action)=>{
    const loader = document.getElementById("global_loader");
     if(loader){
         if(action){
             loader.classList.add('active');
         }else{
            loader.style.display="none";
            loader.classList.remove('active');
         }
     }
}