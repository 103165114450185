import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { Formik } from 'formik'
import $ from 'jquery';
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import { SaveData, GetData } from '../../services/settingsService'
import { DEVMODE } from '../../utils/Constants';
import { blockInvalidChar, getSessionKey, removeSessionKey, setSessionKey } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs';
import Loader from '../../components/common/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'semantic-ui-react';

const GlobalSettings = (props) => {

    const [options, setOptions] = useState([]);
    const [remove, setRemove] = useState({});
    const [devmode, setDevmode] = useState(false);
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Global Settings", url: "" }]


    const twoFactorAuthenticationOptions=[
        {key: 1, text: "Active", value: 1},
        {key: 0, text: "In-Active", value: 0}
    ]
    // useEffect(() => {
    //     GetData().then(data => {
    //         let resData = data && data.data && data.data.response ? data.data.response : {};
    //         delete resData._id;
    //         setFormFields(resData);
    //         setOptions(Object.keys(resData));
    //         // console.log(resData);
    //     }).catch(error => {
    //         console.log("error=====>", error)
    //     })
    //     /**DEV MODE >>>> */
    //     document.addEventListener('keydown', function (e) {
    //         if (e.ctrlKey && e.shiftKey && e.which == 68) {
    //             document.getElementById("developerModeBtn").click();
    //         }
    //         checkDevModeSession();
    //     });
    //     document.addEventListener('mousemove', function (e) {
    //         checkDevModeSession();
    //     });
    //     initDevMode();
    //     /**DEV MODE <<<< */
    // }, []);




    // const navigate = useNavigate()
    // const setOptionValue = (setFieldValue) => {
    //     const inputs = document.getElementsByClassName("option_input");
    //     let values = [];
    //     for (let i = 0; i < inputs.length; i++) {
    //         if (inputs[i].value || document.activeElement === inputs[i]) {
    //             values.push(inputs[i].value);
    //         }
    //     }
    //     setOptions(values);
    //     setFieldValue('options', values.toString());
    // }

    // const addOption = (e) => {
    //     e.preventDefault();
    //     let values = formFields;
    //     values[$('#settingsName').val()] = $('#settingValue').val();
    //     setFormFields(values);
    //     setOptions(Object.keys(values));
    //     e.target.reset();
    //     $('.model_close_btn').trigger('click');
    // }
    // const editValue = (event, opt) => {
    //     let values = formFields;
    //     values[String(opt)] = event.target.value;
    //     setFormFields(values);
    //     setOptions(Object.keys(values));
    // }
    // const removeOption = (opt) => {
    //     let values = formFields;
    //     delete values[String(opt)];
    //     setFormFields(values);
    //     setOptions(Object.keys(values));
    //     let shouldRemove = remove;
    //     shouldRemove[opt] = 1;
    //     setRemove(shouldRemove);
    // }
    // const formateName = (str) => {
    //     let newStr = "";
    //     newStr = str.replace(/_/gi, " ");
    //     return newStr;
    // }
    // /**DEV MODE >>>> */
    // const activeDevMode = (e) => {
    //     e.preventDefault();
    //     let username = e.target.usernameDev.value;
    //     let password = e.target.passwordDev.value;
    //     if (DEVMODE.USERNAME === username && DEVMODE.PASSWORD === password) {
    //         setSessionKey('devmode', 'active');
    //         setSessionKey('devmodetime', String(new Date()));
    //         e.target.reset();
    //         document.getElementById("developerModeClose").click();
    //         Swal.fire({
    //             customClass: 'swal-wide',
    //             icon: 'success',
    //             title: "Developer mode activated",
    //             showConfirmButton: false,
    //             timer: 1500,
    //             toast: true,
    //             position: 'top-right',
    //         });
    //         initDevMode();
    //     } else {
    //         logoutDevMode();
    //         Swal.fire({
    //             customClass: 'swal-wide',
    //             icon: 'error',
    //             title: "Invalid username or password",
    //             showConfirmButton: false,
    //             timer: 1500,
    //             toast: true,
    //             position: 'top-right',
    //         });
    //     }
    // }

    // const initDevMode = () => {
    //     let sKey = getSessionKey('devmode');
    //     if (sKey && sKey === 'active') {
    //         setDevmode(true);
    //     } else {
    //         logoutDevMode();
    //     }
    // }

    // const checkDevModeSession = () => {
    //     try {
    //         let sKey = getSessionKey('devmodetime');
    //         let oldDate = new Date(sKey);
    //         let oldTime = oldDate.getTime();
    //         let newDate = new Date();
    //         let newTime = newDate.getTime();
    //         let diff = newTime - oldTime;
    //         if (diff <= DEVMODE.SESSION_TIME) {
    //             setDevmode(true);
    //         } else {
    //             logoutDevMode();
    //         }
    //     } catch (err) {
    //         logoutDevMode();
    //     }
    // }

    const logoutDevMode = () => {
        removeSessionKey('devmode');
        removeSessionKey('devmodetime');
        setDevmode(false);
    }

    const [formFields, setFormFields] = useState({
        platform_logo: '',
        platform_title: '',
        system_support_email: '',
        system_email: '',
        copyright_text: '',
        order_mode_configuration: '',
        admin_tfa: '',
        company_tfa : '',
        instagram_link : '',
        messenger_link : '',
        discord_link : '',
        linkedin_link : ''
    });
    const [file, setFile]= useState(null);

    const getGLobalSettingsData=()=>{
        GetData().then((data)=>{
            console.log(data,'-----getting response');
            const res=data.data.response;
            const tempFields={
                platform_logo: res.logo,
                platform_title: res.platform_title,
                system_support_email: res.system_support_email,
                system_email: res.system_email,
                copyright_text: res.copyright_text,
                order_mode_configuration: res.order_mode_configuration,
                admin_tfa : res.admin_tfa ==true ? 1 : 0,
                company_tfa : res.company_tfa == true ? 1: 0,
                instagram_link : res.instagram_link,
                messenger_link : res.messenger_link,
                discord_link : res.discord_link,
                linkedin_link : res.linkedin_link
            }
            setFormFields(tempFields);
        }).catch((error)=>{
            console.log('error in get global-settings api', error);
        })
    }

    useEffect(()=>{
        getGLobalSettingsData();    
    },[])

    const handleChange = (e) => {
        if(e.target.name=='platform_logo'){
            const { name, files } = e.target;
            setFile(files[0]);
            // setFormFields({ ...formFields, [name]: URL.createObjectURL(files[0]) });
            const reader = new FileReader();
            reader.onload = function(event) {
              setFormFields({ ...formFields, [name]: event.target.result });
            };
            
            reader.readAsDataURL(files[0]);
        }else{
            const { name, value } = e.target;
            setFormFields({ ...formFields, [name]: value});
        }
    };

      // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formFields);
        const formData= new FormData();
        if(file){
            formData.append('image', file);
        }else{
            formData.append('image', formFields.platform_logo);
        }
        for (const field in formFields) {
            if(field!='platform_logo'){
                if(field == 'admin_tfa' || field == 'company_tfa'){
                    formData.append(field, formFields[field]==1 ? true : false);    
                }else{
                    formData.append(field, formFields[field]);
                }
                
            }
        }

        SaveData(formData).then((data)=>{
            console.log(data);
            toast(data.data.message);
            // await getGLobalSettingsData();
        }).catch((error)=>{
            console.log('error in save global-settings api', error);
        })
    };

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                
                                {
                                    devmode ?
                                        <div>
                                            <h6 className="main-content-label mb-3">Settings</h6>
                                            <button type='button' className="btn btn-outline-primary exit_dev_mode" onClick={(e) => { logoutDevMode(); }}>Exit Developer Mode</button>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="main-content-label mb-3">Settings</h6>
                                        </div>
                                }
                            
                                <form onSubmit={handleSubmit} className='w-100'>            
                                        <div className="modal-body">
                                            <div className='row'>
                                                <div className='col-md-12 form-group'>
                                                   <label htmlFor='platform_logo' className='text-left d-flex'>Logo:<span className="requirestar">*</span></label>
                                                   <input type='file' accept="image/*" name="platform_logo"  onChange={handleChange} />
                                                   <img src={formFields.platform_logo}  alt="Description of the image" width="200"/> 
                                                </div>
                                                <div className='col-md-12 text-center form-group'>
                                                    <label htmlFor='platform_title' className='text-left d-flex'>Title:<span className="requirestar">*</span></label>
                                                    <input type='text' name="platform_title" id="platform_title" value={formFields.platform_title} onChange={handleChange} placeholder='Enter Platform Title' className='form-control' required />
                                                </div>
                                                <div className='col-md-12 text-center form-group'>
                                                    <label htmlFor='system_support_email' className='text-left d-flex'>System Support Email<span className="requirestar">*</span></label>
                                                    <input type='email' name="system_support_email" id="system_support_email" value={formFields.system_support_email} onChange={handleChange} placeholder='Enter System Support Email' className='form-control' required />
                                                </div>
                                                <div className='col-md-12 text-center form-group'>
                                                    <label htmlFor='system_email' className='text-left d-flex'>System Email<span className="requirestar">*</span></label>
                                                    <input type='email' name="system_email" id="system_email" value={formFields.system_email} onChange={handleChange} placeholder='Enter System Email' className='form-control' required />
                                                </div>
                                                <div className='col-md-12 text-center form-group'>
                                                    <label htmlFor='copyright_text' className='text-left d-flex'>Copyright Text<span className="requirestar">*</span></label>
                                                    <input type='text' name="copyright_text" id="copyright_text" value={formFields.copyright_text} onChange={handleChange} placeholder='Enter Copyright Text' className='form-control' required />
                                                </div>

                                                <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='admin_tfa' className='text-left d-flex'>Admin Two-Factor-Authentication :<span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "admin_tfa"
                                                    id="admin_tfa"
                                                    placeholder='admin_tfa'
                                                    search
                                                    fluid
                                                    selection
                                                    options={twoFactorAuthenticationOptions}
                                                    value={formFields.admin_tfa}
                                                    onChange={(event, data) => { setFormFields({ ...formFields, admin_tfa: data.value }); }}
                                                />
                                                </div>

                                                <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='admin_tfa' className='text-left d-flex'>Company Two-Factor-Authentication :<span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "company_tfa"
                                                    id="company_tfa"
                                                    placeholder='company_tfa'
                                                    search
                                                    fluid
                                                    selection
                                                    options={twoFactorAuthenticationOptions}
                                                    value={formFields.company_tfa}
                                                    onChange={(event, data) => { setFormFields({ ...formFields, company_tfa: data.value }); }}
                                                />
                                                </div>

                                                <div className='col-md-6 text-center form-group'>
                                                    <label htmlFor='instagram_link' className='text-left d-flex'>Instagram</label>
                                                    <input type='text' name="instagram_link" id="instagram_link" value={formFields.instagram_link} onChange={handleChange} placeholder='Enter Instagram Link' className='form-control' required />
                                                </div>
                                                <div className='col-md-6 text-center form-group'>
                                                    <label htmlFor='messenger_link' className='text-left d-flex'>Messenger</label>
                                                    <input type='text' name="messenger_link" id="messenger_link" value={formFields.messenger_link} onChange={handleChange} placeholder='Enter Messenger Link' className='form-control' required />
                                                </div>
                                                <div className='col-md-6 text-center form-group'>
                                                    <label htmlFor='linkedin_link' className='text-left d-flex'>LinkedIn</label>
                                                    <input type='text' name="linkedin_link" id="linkedin_link" value={formFields.linkedin_link} onChange={handleChange} placeholder='Enter LinkedIn Link' className='form-control' required />
                                                </div>
                                                <div className='col-md-6 text-center form-group'>
                                                    <label htmlFor='discord_link' className='text-left d-flex'>Discord</label>
                                                    <input type='text' name="discord_link" id="discord_link" value={formFields.discord_link} onChange={handleChange} placeholder='Enter Discord Link' className='form-control' required />
                                                </div>
                                                {/* <div className='col-md-12 text-center form-group'>
                                                    <label htmlFor='order_mode_configuration' className='text-left d-flex'>Order Mode Configuration<span className="requirestar">*</span></label>
                                                    <select name="order_mode_configuration" id="order_mode_configuration" value={formFields.order_mode_configuration} onChange={handleChange}  className='form-control' required >
                                                        <option value="take_away">Take Away</option>
                                                        <option value="order_in">Order In</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}><i className="ace-icon fa fa-check bigger-110 mx-1"></i>Save</button>
                                        </div>
                                      
                                </form>
                            
                                          

                                {/* <div className='row'>
                                    <div className="col-lg-12">
                                        <Formik
                                            enableReinitialize
                                            initialValues={{

                                            }}
                                            validate={values => {
                                                const error = {};

                                                return error;
                                            }}
                                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                                setSubmitted(true)
                                                let formData = new FormData();
                                                options.forEach((item) => {
                                                    formData.append(item.toLowerCase(), formFields[item]);
                                                })
                                                SaveData(formData, JSON.stringify(remove)).then(response => {
                                                    setSubmitting(false);
                                                    if (response.data.status === 1 || response.data.status === "1") {
                                                        Swal.fire({
                                                            customClass: 'swal-wide',
                                                            icon: 'success',
                                                            title: response.data.message,
                                                            showConfirmButton: false,
                                                            timer: 1500,
                                                            toast: true,
                                                            position: 'top-right',
                                                        });
                                                        setTimeout(() => {
                                                            navigate(`/admin/cms/settings`)
                                                        }, 1000);
                                                    } else {
                                                        Swal.fire({
                                                            customClass: 'swal-wide',
                                                            icon: 'error',
                                                            title: response.data.error[0].msg,
                                                            showConfirmButton: false,
                                                            timer: 5000,
                                                            toast: true,
                                                            position: 'top-right',
                                                        })
                                                        setSubmitted(false)
                                                    }
                                                }).catch(error => {
                                                    console.log("error ====> ", error);
                                                })
                                            }}
                                        >{({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,

                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row row-sm">
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="row row-sm">
                                                            {
                                                                options.map((opt, optInd) => {
                                                                    return (
                                                                        <div className='col-md-6 text-center form-group' key={optInd}>
                                                                            <label htmlFor='title' style={{ textTransform: 'capitalize' }} className='text-left d-flex'>{formateName(opt)}: </label>
                                                                            <div className='d-flex'>
                                                                                {
                                                                                    opt == 'contact_no' ?
                                                                                        <input name='option' style={{ flex: 1 }} type='number' id={"option" + optInd}
                                                                                            onChange={(e) => { editValue(e, opt) }}
                                                                                            value={formFields[opt]} className='form-control option_input' onKeyDown={blockInvalidChar} />
                                                                                        :
                                                                                        <input name='option' style={{ flex: 1 }} type='text' id={"option" + optInd}
                                                                                            onChange={(e) => { editValue(e, opt) }}
                                                                                            value={formFields[opt]} className='form-control option_input' />
                                                                                }


                                                                                {
                                                                                    devmode ?
                                                                                        <>
                                                                                            {
                                                                                                optInd === 0 ?
                                                                                                    opt !== "" ?
                                                                                                        <button type="button" className='ml-2 op_button danger' onClick={() => { removeOption(opt) }}>
                                                                                                            <i className="ti-close"></i>
                                                                                                        </button>
                                                                                                        : ""
                                                                                                    :
                                                                                                    <button type="button" className='ml-2 op_button danger' onClick={() => { removeOption(opt) }}>
                                                                                                        <i className="ti-close"></i>
                                                                                                    </button>
                                                                                            }

                                                                                            {
                                                                                                optInd === (options.length - 1) ?
                                                                                                    <button type="button" className='ml-2 op_button success' data-bs-toggle="modal" href="#addNewSettingModal" role="button">
                                                                                                        <i className="ti-plus"></i>
                                                                                                    </button>
                                                                                                    : ""
                                                                                            }
                                                                                        </>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                            <span className='text-danger option_errors d-flex text-left'></span>
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                            {
                                                                options.length == 0 ?
                                                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                                                        <h3>No settings found</h3>
                                                                        
                                                                        {
                                                                            devmode ?
                                                                                <button className='btn btn-primary mt-4' data-bs-toggle="modal" href="#addNewSettingModal" role="button" type='button'>Add Setting</button>
                                                                                : ""
                                                                        }

                                                                    </div>
                                                                    : ""
                                                            }

                                                            <div className="mt-5">
                                                                <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                        </Formik>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />   
                </div>
                
                {/* <div className="modal fade" id="addNewSettingModal" aria-hidden="true" aria-labelledby="addNewSettingModalLabel" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <form onSubmit={addOption} className='w-100'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="addNewSettingModalLabel">
                                        Add new setting
                                    </h1>
                                    <button type="button" className="btn-close model_close_btn" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='settingsName' className='text-left d-flex'>Setting Name:<span className="requirestar">*</span></label>
                                            <input name='settingsName' id='settingsName' type='text' placeholder='setting_name' className='form-control' required />
                                        </div>
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='settingValue' className='text-left d-flex'>Setting Value:<span className="requirestar">*</span></label>
                                            <input name='settingValue' id='settingValue' placeholder='Setting value' type='text' className='form-control' required />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" type='submit'>Add Setting</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                <button type="button" className='d-none' data-bs-toggle="modal" id='developerModeBtn' href="#developerMode" role="button"></button> */}
                {/* <div className="modal fade" id="developerMode" aria-hidden="true" aria-labelledby="developerModeLabel" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <form onSubmit={activeDevMode} className='w-100'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="developerModeLabel">
                                        Developer Mode
                                    </h1>
                                    <button type="button" className="btn-close model_close_btn" id='developerModeClose' data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='usernameDev' className='text-left d-flex'>Username:<span className="requirestar">*</span></label>
                                            <input name='usernameDev' id='usernameDev' type='text' placeholder='Enter username' className='form-control' autoComplete='new-password' required />
                                        </div>
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='passwordDev' className='text-left d-flex'>Password:<span className="requirestar">*</span></label>
                                            <input name='passwordDev' id='passwordDev' placeholder='********' type='password' className='form-control' autoComplete='new-password' required />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" type='submit'>Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}
                
            </Layout>
        </>
    )
}

export default GlobalSettings