import React, { useState, useEffect, useMemo } from 'react'
import { LOADER_TIME, showFilterlist, SOCKET_EVENTS } from '../../utils/Constants';
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import StatusFilter from '../../components/company_panel/common/statusFilter';
import Sorting from '../../components/company_panel/common/sorting';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeStatusLoyaltyPoint, ListLoyaltyPoints } from '../../services/company_panel/loyaltypoints.service';
import Loader from '../../components/company_panel/common/loader';
import { capitalizeFirstLetter, checkPermission, formateDate, getBrandId, getCompany, getOutletId, isOnline, removeHyphens } from '../../helpers/commonFunctions';
import Swal from 'sweetalert2';
import notFound from '../../assets/company/img/no-record-found.png'
import { Modal, Button, Form } from 'react-bootstrap';
import { GetOrdersList, RefundStatus } from '../../services/company_panel/orderService';
import CustomPagination from '../../components/company_panel/common/custompagination';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import { GetOutletAllList } from '../../services/company_panel/outletService';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AllRefundReasons, getRefundConfiguration } from '../../services/company_panel/refund.services';
import { SOCKET_CLIENT } from '../../utils/socket';
import { countries } from 'countries-list';
import { getAllOrders } from '../../helpers/indexedDB';

const RefundOrder = () => {
    const params = useParams()
    const [search, setSearch] = useState({})
    const [page, setPage] = useState(1);
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [loader, setLoader] = useState(true)
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [refundOrderId, setRefundOrderId] = useState(null);
    const [refundReason, setRefundReason] = useState('');
    const [refundReasonText, setRefundReasonText] = useState('');
    const navigate = useNavigate();
    const [orderListData, setOrderListData] = useState([]);
    const [orderModeData, setOrderModesData] = useState([]);
    const [selectedOrderMode, setSelectedOrderMode] = useState('');
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const [selectedTransactionType, setSelectedTransactionType] = useState('');
    const [error, setError] = useState('');
    const [refundConfiguration, setRefundConfiguration] = useState([]);
    const [allRefundReasons, setAllRefundReasons] = useState([]);
    const [newOrder, setNewOrder] = useState(false)
    
    const [refundEndTime, setRefundEndTime] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
      // Update currentTime every second
      const intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if(orderListData.length){    
            getRefundConfiguration({ brand_id: getBrandId() }).then(data => {
                setRefundConfiguration(data && data.data && data.data.response ? data.data.response : []);

                let refundTime = {};
                data?.data?.response?.forEach((configuration)=>{
                    if(configuration.type == 'order_mode'){
                        refundTime[configuration.order_mode_id] = configuration.time;
                    }
                })

                let times=[];
                orderListData.forEach((order, index)=>{
                    const refundTimeMinutes = refundTime[order.order_mode_id] || 0;
                    const createdDate = new Date(order.transaction.created_at);
                    const validDate = new Date(createdDate.getTime() + (refundTimeMinutes * 60000));
                    times.push(validDate);
                })
                setRefundEndTime(times);
                

            }).catch(error => {
                console.log("error=====>", error);
            });
        }
    }, [orderListData])


    // const isRefundValid = (order) => {
    //     const configuration = refundConfiguration.filter(
    //         configuration => configuration.type == 'order_mode' && configuration.order_mode_id == order.order_mode_id
    //     );
    //     if (!configuration[0]) {
    //         return false;
    //     }
    //     const refundTimeMinutes = configuration[0].time;
    //     const createdDate = new Date(order.transaction.created_at);
    //     const validDate = new Date(createdDate.getTime() + (refundTimeMinutes * 60000));
    //     const currentDate = new Date();
    //     if (currentDate <= validDate) {
    //         return true;
    //     }
    //     return false;
    // }

    // const getRemainingRefundTime=(row)=>{
    //     if(row.transaction.payment_status != 'paid'){
    //         return 0;
    //     }
    //     const configuration = refundConfiguration.filter(
    //         configuration => configuration.type == 'order_mode' && configuration.order_mode_id == row.order_mode_id
    //     );
    //     if(!configuration[0]){
    //         return false;
    //     }
    //     const refundTimeMinutes = configuration[0].time;   
    //     const createdDate = new Date(row.created_at);
    //     const validDate = new Date(createdDate.getTime() + (refundTimeMinutes * 60000));
    //     const currentDate = new Date();
    //     let remainingMinutes = 0;
    //     if(validDate > currentDate){
    //         remainingMinutes = (validDate.getTime() - currentDate.getTime())/60000;
    //     }
    //     console.log('---------remaining', remainingMinutes);
    //     return remainingMinutes;
    // }


    // const [page, setPage] = useState(1);

    // useEffect(() => {
    //     if (search) {
    //         setLoader(true)
    //         const formData = new FormData()
    //         formData.append("page", params.id)
    //         formData.append("per_page", itemPerPage)
    //         formData.append("sort", JSON.stringify(sorting))
    //         formData.append("search", JSON.stringify(search))
    //         formData.append("global_search", globalSearch)
    //         formData.append('outlet_id', getOutletId());
    //         GetOrdersList(formData).then(data => {
    //             setDataLength(data.data.response.count);
    //             setOrderListData(data && data.data && data.data.response ? data.data.response.rows : [])
    //             setLoader(false)
    //         }).catch(error => {
    //             console.log("error ====> ", error);
    //         })
    //     }
    // }, [sorting, search, globalSearch, statsUpdate, page])

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search && isOnline()) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                GetOrdersList(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setOrderListData(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, LOADER_TIME);
        } else {
            getAllOrders().then(data => {
                console.log('-------indexedDB orders, data');
                setDataLength(data.length);
                setOrderListData(data && data.length ? data : []);
                setLoader(false)
            }).catch(error => {
                console.log(error);
            })
        }
    }, [sorting, search, globalSearch, statsUpdate, page, isBrandChanged, isOutletChanged, newOrder])



    useEffect(() => {
        let current_outlet_id = localStorage.getItem("outlet_id");
        console.log(current_outlet_id)
        SOCKET_CLIENT.on('GET-DATA', async (response) => {
            if (response) {
                if (response?.event === SOCKET_EVENTS.ORDER_CREATED) {
                    console.log(response?.data?.outlet_id, current_outlet_id)
                    if (Number(response?.data?.outlet_id) === Number(current_outlet_id)) {
                        console.log("socket event listened", "refreshed");
                        setNewOrder(!newOrder);
                    }
                }
                if (response?.event === SOCKET_EVENTS.ORDER_MARKED_READY) {
                    if (response?.data?.outlet_id === Number(current_outlet_id)) {
                        console.log("socket event listened", "refreshed");
                        setNewOrder(!newOrder);
                    }
                }
                if (response?.event === SOCKET_EVENTS.REQUEST_ANSWERED) {
                    if (response?.data?.outlet_id === Number(current_outlet_id)) {
                        console.log("socket event listened", "refreshed");
                        if (response?.data?.slug === "refund_request") {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'absolute',
                                icon: 'success',
                                title: `order refund request is accepted for ${response?.data?.order_id}`,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            })
                        }
                    }
                }
            }
        });

    }, [orderListData])


    // search or filter function
    const prepareSearch = (key, value) => {
        console.log("preparing search", key, value)
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        setSelectedOrderMode('');
        setSelectedOrderStatus('');
        setSelectedTransactionType('');

        console.log("Filters reset");
        // $("#defaultstatus")[0].selectedIndex = 0
    }


    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    const viewFunction = (id) => {
        navigate(`/company/transaction/order/view/${id}`);
    };

    const printFunction = (id) => {
        // Implement your print logic here
        console.log(`Printing details for order ${id}`);
    };

    useEffect(() => {
        AllRefundReasons({ brand_id: getBrandId() })
            .then((data) => {
                setAllRefundReasons(data && data?.data && data?.data?.response ? data?.data?.response : [])
            })
            .catch((error) => {
                console.log("errors===>", error)
            })
    }, []);

    const handleRefundSubmit = () => {
        if (refundReasonText.length > 250) {
            setError('Reason cannot exceed 250 characters');
            return;
        }
        // Implement your refund logic here
        const formData = new FormData()
        formData.append("id", refundOrderId)
        formData.append("refund_reason_id", refundReason);
        if (refundReason === 'other') {
            formData.append("refund_reason", refundReasonText);
        }
        formData.append("payment_status", "refunded");
        RefundStatus(formData).then(response => {
            setShowRefundModal(false);
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            })
            setRefundReason('')
            setRefundReasonText('')
            setStatusUpdate(!statsUpdate)
        }).catch(error => {
            console.log("error ====> ", error);
        })

    };

    const recallOrder = (order) => {
        navigate(`/company/transaction/order/add/${order.order_mode_id}`,
            { state: { recallOrderId: order.id, aggregator_id: order.aggregator_id } }
        );
    }
    const checkoutOrder = (orderId) => {
        navigate(`/company/transaction/order/continueOrder?orderId=${orderId}`);
    }
    // useEffect(()=>{
    //     handleRefundSubmit();
    // },[])

    const ChangeStatus = (row, Num) => {
        // const formData = new FormData()
        // formData.append("id", row)
        // formData.append("status", Num)
        // Swal.fire({
        //     customClass: 'swal-wide',
        //     title: 'Are you sure?',
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#403fad',
        //     cancelButtonColor: '#f1388b',
        //     confirmButtonText: 'Yes'
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         ChangeStatusLoyaltyPoint(formData).then(data => {
        //             if (data.status === 200) {
        //                 Swal.fire({
        //                     customClass: 'swal-wide',
        //                     position: 'absolute',
        //                     icon: 'success',
        //                     title: data.data.message,
        //                     showConfirmButton: false,
        //                     timer: 2000,
        //                     toast: true,
        //                     position: 'top-right',
        //                 })
        //                 setStatusUpdate(!statsUpdate)
        //             }
        //         }).catch(error => {
        //             console.log("error===>")
        //         })
        //     }
        // })
    }
    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, []);
    // useEffect(() => {
    //     GetOutletAllList(getCompany().company_id).then(data => {
    //         setOutletFilterData(data && data.data && data.data.response ? data.data.response : []);
    //     }).catch(error => {
    //         console.log("error=====>", error);
    //     });
    // }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log('filter changed------>', name, value)
        if (name === 'order-status') {
            setSelectedOrderStatus(value);
            prepareSearch("order_status", value);
        } else if (name === 'transaction_type') {
            setSelectedTransactionType(value);
            prepareSearch("transaction_type", value);
        } else {
            setSelectedOrderMode(value);
            prepareSearch("order_mode_id", value);
        }
    };

    const headerMapping = {
        "order_id": "Order ID",
        "customer_name": "Customer Name",
        "customer_number": "Customer Number",
        "customer_address": "Customer Address",
        "outlet_name": "Outlet Name",
        "location": "Outlet Location",
        "total_price": "Price",
        "order_status": "Order Status",
        "discount_price": "Discount Price",
        "payment_method": "Payment Method",
        "payment_status": "Payment Status",
        "refund_reason": "Refund Reason",
        "name": "Order Mode Name",
        "menu_item": "Menu Item Name",
        "add_on": "Menu Item Add On",
    };
    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify({}))
        formData.append("search", JSON.stringify({}))
        formData.append("global_search", globalSearch)
        formData.append('outlet_id', getOutletId());
        GetOrdersList(formData).then(data => {
            setDataLength(data.data.response.count);
            const exportData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = exportData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const orderID = item?.order_id || 'N/A';
                const customerName = item.customer?.customer_name || 'N/A';
                const customerNumber = item.customer?.customer_number || 'N/A';
                const customerAddress = item.customer?.customer_address || 'N/A';
                const outlet = item.outlet?.outlet_name || 'N/A';
                const location = item.outlet?.location || 'N/A';
                const totalPrice = item?.total_price || '0';
                const orderStatus = item?.order_status || 'N/A';
                const discountPrice = item?.discount_price || '0';
                const paymentMethod = capitalizeFirstLetter(item?.transaction?.payment_method) || 'N/A';
                const paymentStatus = capitalizeFirstLetter(item?.transaction?.payment_status) || 'N/A';
                const refundReason = capitalizeFirstLetter(item?.transaction?.refund_reason) || 'N/A';
                const orderMode = capitalizeFirstLetter(item?.order_mode_configuration?.name) || 'N/A';
                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "order_id":
                                transformedItem[newKey] = capitalizeFirstLetter(orderID);
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "customer_number":
                                transformedItem[newKey] = customerNumber;
                                break;
                            case "customer_address":
                                transformedItem[newKey] = capitalizeFirstLetter(customerAddress);
                                break;
                            case "outlet_name":
                                transformedItem[newKey] = capitalizeFirstLetter(outlet);
                                break;
                            case "location":
                                transformedItem[newKey] = capitalizeFirstLetter(location);
                                break;
                            case "total_price":
                                transformedItem[newKey] = (totalPrice + " " + "SAR");
                                break;
                            case "order_status":
                                transformedItem[newKey] = capitalizeFirstLetter(orderStatus);
                                break;
                            case "discount_price":
                                transformedItem[newKey] = (discountPrice + " " + "SAR");
                                break;
                            case "payment_method":
                                transformedItem[newKey] = (paymentMethod);
                                break;
                            case "payment_status":
                                transformedItem[newKey] = (paymentStatus);
                                break;
                            case "refund_reason":
                                transformedItem[newKey] = (refundReason);
                                break;
                            case "name":
                                transformedItem[newKey] = capitalizeFirstLetter(orderMode);
                                break;
                            case "menu_item":
                                transformedItem[newKey] = 'See separate sheet';
                                break;
                            case "add_on":
                                transformedItem[newKey] = 'See separate sheet';
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });
            const menuItemsData = exportData.flatMap(item => {
                const ID = item?.order_id || 'N/A';
                const Name = item.customer?.customer_name || 'N/A';
                return (item.customer_order_menu_item_relations || []).map(data => ({
                    'Order ID': ID,
                    'Customer Name': Name,
                    'Menu Items': capitalizeFirstLetter(data?.menu_item?.item_name || 'N/A'),
                    'Quantity': (data?.quantity || 'N/A'),
                }));
            });
            const addOnData = exportData.flatMap(item => {
                const ID = item?.order_id || 'N/A';
                const Name = item.customer?.customer_name || 'N/A';
                return (item.customer_order_add_on_relations || []).map(data => ({
                    'Order ID': ID,
                    'Customer Name': Name,
                    'Menu Item Name': capitalizeFirstLetter(data?.menu_item?.item_name || 'N/A'),
                    'Menu Item Add On': capitalizeFirstLetter(data?.menu_add_on?.name || 'N/A'),
                    'Add On Price': (parseInt(data?.menu_add_on?.price) + " SAR" || '0 SAR'),
                    'Quantity': data?.quantity || 'N/A'
                }));
            });
            const ws1 = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const ws2 = XLSX.utils.json_to_sheet(menuItemsData, { header: ['Order ID', 'Customer Name', 'Menu Items', 'Quantity'] });
            const ws3 = XLSX.utils.json_to_sheet(addOnData, { header: ['Order ID', 'Customer Name', 'Menu Item Name', 'Menu Item Add On', 'Add On Price', 'Quantity'] });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, 'Orders');
            XLSX.utils.book_append_sheet(wb, ws2, 'Menu Items');
            XLSX.utils.book_append_sheet(wb, ws3, 'Menu Item Add On');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'orders.xlsx');

        }).catch(error => {
            console.log("error ====> ", error);
        })
    };

    function getLocation(data) {
        if (data.location) {
            return data.location;
        } else if (data.city_id) {
            return data.city_id.name;
        } else {
            return data.state_id.name;
        }
    }

    const createOrder = () => {
        navigate(`/company/transaction/order/order-mode`);
        // UpdateDateOfBusiness({ outlet_id : getOutletId() }).then(response => {
        //     navigate(`/company/transaction/order/order-mode`)
        // }).catch(error => {
        //     console.log("error ====> ", error);
        // })
    }

    const generateOrderId = (orderId) => {
        let id = orderId;
        let countOfDigits = 0;
        while (id) {
            id = Math.floor(id / 10);
            countOfDigits += 1;
        }

        if (countOfDigits >= 4) {
            return orderId
        }
        let zerosCount = 4 - countOfDigits;
        let generatedZeros = '';
        while (zerosCount) {
            generatedZeros += '0';
            zerosCount -= 1;
        }
        return `${generatedZeros}${orderId}`;
    }

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Orders</h4>
                    </div>

                    <div className='page-header-right ms-auto'>
                        <button className="btn btn-primary ms-2" onClick={handleExport} disabled={dataLength === 0}>Export Orders</button>
                        {checkPermission('store_orders', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => { createOrder() }}>Create Order</button>
                            : ''}
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search here" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <div className="">
                                            <select
                                                name='order-mode'
                                                placeholder="order-mode"
                                                className={`form-control cp `}
                                                onChange={handleFilterChange}
                                                value={selectedOrderMode}
                                                id="resetFilter"
                                            >
                                                <option value=''>Select Order mode</option>
                                                {orderModeData.map((mode, index) => (
                                                    <option key={index} value={mode.id}>
                                                        {mode.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>


                                    </div>
                                </div>
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <div className="">
                                            <select
                                                name='order-status'
                                                placeholder="Order Status"
                                                className={`form-control cp `}
                                                onChange={handleFilterChange}
                                                value={selectedOrderStatus}

                                                id="resetFilter"
                                            >
                                                <option value=''>Select Order status</option>
                                                <option value="pending">Pending</option>
                                                <option value="ready">Ready</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <div className="">
                                            <select
                                                name='transaction_type'
                                                placeholder="Transactions"
                                                className={`form-control cp `}
                                                onChange={handleFilterChange}
                                                value={selectedTransactionType}
                                                id="resetFilter"
                                            >
                                                <option value=''>Select transaction</option>

                                                <option value="cash">Cash</option>
                                                <option value="card">Card</option>
                                                <option value="credit">Credit</option>
                                                <option value="online">Online</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>
                                <div className="">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        {/*   <div className="">
                                           <select
                                                placeholder="Outlet name"
                                                className={`form-control cp `}
                                                onChange={handleFilterChange}
                                                id="resetFilter"
                                            >
                                                {outletFilterData &&
                                                    outletFilterData.length > 0 &&
                                                    outletFilterData.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option?.id}>
                                                                {capitalizeFirstLetter(option?.name)}
                                                            </option>
                                                        );
                                                    })}
                                            </select> 
                                        </div>*/}


                                    </div>
                                </div>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                {/* <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} /> */}
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th style={{ width: '8%' }}>
                                            <div className='sorting_column'>
                                                <span>Order Id</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="profile_image" />
                                            </div>
                                        </th>
                                        <th style={{ width: '9%' }} className='action_head'>Order Mode</th>
                                        <th style={{ width: '9%' }} className='action_head'>Aggregator Name</th>
                                        <th style={{ width: '9%' }} className='action_head'>Ordered By</th>
                                        <th style={{ width: '9%' }}>
                                            <div className='sorting_column'>
                                                <span>Customer</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="first_name" />
                                            </div>
                                        </th>
                                        <th style={{ width: '8%' }}>
                                            <div className='sorting_column'>
                                                <span>Contact</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="first_name" />
                                            </div>
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="email" />
                                            </div>
                                        </th>
                                        {/* <th style={{ width: '10%' }}>
                                            <div className='sorting_column'>
                                                <span>Outlet Location</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brandname" />
                                            </div>
                                        </th> */}
                                        <th style={{ width: '12%' }} className="created_head">
                                            <div className='sorting_column'>
                                                <span>Date & Time</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th style={{ width: '7%' }} className=''>Price</th>
                                        <th style={{ width: '10%' }} className='action_head'>Payment Method</th>
                                        <th style={{ width: '8%' }} className='action_head'>Payment Status</th>
                                        <th style={{ width: '10%' }} className='action_head'>Status</th>
                                        <th style={{ width: '10%' }} className='action_head text-end'>Action</th>
                                    </tr>
                                </thead>

                                {/* <tbody>
                                    {orderListData.length > 0 ? (
                                        orderListData.map((row, index) => (
                                            <tr key={index}>
                                                <td >{row?.customer_name}</td>
                                                <td >{row?.customer_number}</td>
                                                <td >{row?.outlet?.outlet_name}</td>
                                                <td >{row.outlet?.location ? row.outlet?.location : '-'}</td>
                                                <td >
                                                    {new Date(row?.created_at).toLocaleString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                    }).replace(',', '')}
                                                </td>

                                                <td className=' ' >{row?.total_price}</td>
                                                <td className='text-center'>{row?.payment_method}</td>
                                                <td className='text-center'>{row?.order_payment_status}</td>
                                                <td className='text-end'>{row?.order_status}</td>
                                                <td className='text-end'>
                                                    <div className="dropdown d-inline-block">
                                                        <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='ti ti-dots-vertical'></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <div className='dropdown-item' onClick={() => { viewFunction(row?.id) }}>
                                                                    <i className="ti ti-eye" aria-hidden="true"></i> View details
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item' onClick={() => { printFunction(row.id) }}>
                                                                    <i className="ti ti-printer" aria-hidden="true"></i> Print
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item' onClick={() => { setShowRefundModal(true); setRefundOrderId(row?.order_id); }}>
                                                                    <i className="ti ti-receipt-refund sidemenu-icon"></i> Refund
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={9} className="text-center">
                                                <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                <p>No record found</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody> */}
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> :
                                        <>
                                            {orderListData.length > 0 ? (
                                                orderListData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{`#order ${generateOrderId(row?.id)}`}</td>
                                                        <td className=''>{capitalizeFirstLetter(row?.order_mode_configuration?.name)}</td>
                                                        <td className=''>{row?.aggregators_configuration?.name ? capitalizeFirstLetter(row?.aggregators_configuration?.name) : "N/A"}</td>
                                                        <td >{capitalizeFirstLetter(row?.user ? `${capitalizeFirstLetter(row?.user?.first_name)} ${capitalizeFirstLetter(row?.user?.last_name)}` : "N/A")}</td>
                                                        <td >{capitalizeFirstLetter(row?.customer_name)}</td>
                                                        {/* <td >{row?.customer?.country_code ? `+${countries[row?.customer?.country_code].phone[0]} ` : null} {row?.customer?.customer_number}</td> */}
                                                        <td >{row?.customer?.customer_number ? row?.customer?.customer_number : "-"}</td>
                                                        <td >{capitalizeFirstLetter(row?.outlet?.outlet_name)}</td>
                                                        {/* <td >{capitalizeFirstLetter(getLocation(row.outlet))}</td> */}
                                                        <td >
                                                            {new Date(row?.created_at).toLocaleString('en-GB', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true,
                                                            }).replace(',', '')}
                                                        </td>

                                                        <td className=' ' >{row?.total_price + " " + "SAR"}</td>
                                                        <td className=''>{row?.transaction?.payment_method ? capitalizeFirstLetter(row?.transaction?.payment_method) : '-'}</td>
                                                        <td className=''>{capitalizeFirstLetter(row?.transaction?.payment_status)}</td>
                                                        <td className=''>{
                                                            row?.order_status == 'ready' ?
                                                                'Done' :
                                                                (row.order_status == "pending" ? "In kitchen" : capitalizeFirstLetter(row.order_status))
                                                        }</td>
                                                        <td className='text-end'>
                                                            <div className="dropdown d-inline-block">
                                                                <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className='ti ti-dots-vertical'></i>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {checkPermission('store_orders', 'view') ?
                                                                        <li>
                                                                            <div className='dropdown-item' onClick={() => { viewFunction(row?.id) }}>
                                                                                <i className="ti ti-eye" aria-hidden="true"></i> View details
                                                                            </div>
                                                                        </li>
                                                                        : ''}
                                                                    <li>
                                                                        <div className='dropdown-item' onClick={() => { printFunction(row.id) }}>
                                                                            <i className="ti ti-printer" aria-hidden="true"></i> Print
                                                                        </div>
                                                                    </li>
                                                                    {
                                                                        row?.transaction?.payment_status === 'paid' && row?.order_status === 'pending' && currentTime <= refundEndTime[index] ?
                                                                        <li>
                                                                            <div className='dropdown-item' onClick={() => { setShowRefundModal(true); setRefundOrderId(row?.id); }}>
                                                                                <i className="ti ti-receipt-refund sidemenu-icon"></i> Refund
                                                                            </div>
                                                                        </li>
                                                                        : null
                                                                    }
                                                                    {
                                                                        row?.transaction?.payment_status == 'pending' ?
                                                                            <li>
                                                                                <div className='dropdown-item' onClick={() => { recallOrder(row); }}>
                                                                                    <i className="ti ti-receipt-refund sidemenu-icon"></i> Recall order
                                                                                </div>
                                                                            </li> : null
                                                                    }
                                                                    {
                                                                        row?.transaction?.payment_status == 'pending' ?
                                                                            <li>
                                                                                <div className='dropdown-item' onClick={() => { checkoutOrder(row?.id); }}>
                                                                                    <i className="ti ti-receipt-refund sidemenu-icon"></i> Checkout Order
                                                                                </div>
                                                                            </li> : null
                                                                    }
                                                                </ul>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={11} className="text-center">
                                                        <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                        <p>No record found</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "order", path: "/company/transaction/order/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showRefundModal} onHide={() => { setShowRefundModal(false); setRefundReason(''); setRefundReasonText(''); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="refundReason" className='mb-3'>
                            <Form.Label>Select reason to refund order</Form.Label>
                            <div className='cp select-down-arrow'>
                                <Form.Control
                                    className=''
                                    as="select"
                                    rows={3}
                                    value={refundReason}
                                    onChange={(e) => setRefundReason(e.target.value)}
                                    placeholder="Select reason"
                                >
                                    <option value="">Select a reason</option>
                                    {allRefundReasons?.map((reason) => (
                                        <option key={reason.id} value={reason.id}>
                                            {reason.reason}
                                        </option>
                                    ))}
                                </Form.Control>
                            </div>
                            {/* {error && <small className="text-danger">{error}</small>} */}
                        </Form.Group>
                        {refundReason === 'other' &&
                            <Form.Group controlId="refundReasonText">
                                <Form.Label>Enter reason to refund order</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={refundReasonText}
                                    onChange={(e) => setRefundReasonText(e.target.value)}
                                    placeholder="Enter reason"
                                />
                                {error && <small className="text-danger">{error}</small>}
                            </Form.Group>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary" onClick={() => { setShowRefundModal(false); setRefundReason(''); setRefundReasonText('') }} >
                        Close
                    </button>
                    <Button variant="primary" onClick={handleRefundSubmit} disabled={!refundReason || (refundReason === 'other' && !refundReasonText)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default RefundOrder