import axiosInstance from "../../utils/axiosCompany";

export const CreateMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/create-menu-item", values);
}

export const GetMenuItemsList = async (values) => {
    return await axiosInstance().post("company/menu-item/list-items", values);
}



export const GetSingleMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/get-single-menu-item", values);
}

export const editMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/update-menu-item", values)
}

export const ChangeMenuItemsStatus = async (values) => {
    return await axiosInstance().post("company/menu-item/change-menu-item-status", values)
}

export const DeleteMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/delete-menu-item", values);
}

export const ImportedMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/validate-import-file", values);
}

export const ExportMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/export-all-menu-items", values);
}
export const GetMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/get-menu-items", values);
}
export const GetMenuItemsWithReciepes = async (values) => {
    return await axiosInstance().post("company/menu-item/get-all-menu-items-with-reciepes", values);
}