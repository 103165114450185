import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany, getOutletId } from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/no-record-found.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { ListPrimaryCategoryReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LOADER_TIME } from '../../utils/Constants';
import { GetMenuItems } from '../../services/company_panel/menuItems'


const PrimaryCategoryReportList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },];
    const [list, setList] = useState([]);
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const params = useParams();
    const [menuItemsData, setMenuItemsData] = useState({});
    const [data,setData] = useState({});

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    // useMemo(() => {
    //     document.addEventListener('brand-changed', function () {
    //       setIsBrandChanged(!isBrandChanged);
    //     });
    //     document.addEventListener('outlet-changed', function () {
    //       setIsOutletChanged(!isOutletChanged);
    //     })
    //   });

    useEffect(() => {
        const getData = setTimeout(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('company_id', getCompany().company_id);
                // formData.append('brand_id', getBrandId());
                // formData.append('outlet_id',getOutletId());
                ListPrimaryCategoryReport(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setData(data && data.data && data.data.response ? data.data.response : {})
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, 500);
        }
    }, 300);
    return () => clearTimeout(getData);
    }, [sorting, search, globalSearch, page,isBrandChanged,isOutletChanged])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // $("#defaultstatus")[0].selectedIndex = 0
    }

    const headerMapping = {
        "category_id": "Category ID",
        "category_name": "Category Name",
        "total_order": "Total Order",
        "total_revenue": "Total Revenue",
        "items": "Top Selling Items",
        "order_percentage": "Percentage Of Total Order",
        "revenue_percentage": "Percentage Of Total Revenue",
        "brand_name" : "Brand Name"
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('company_id', getCompany().company_id);
        formData.append('brand_id', getBrandId());
        formData.append('outlet_id',getOutletId());
        ListPrimaryCategoryReport(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const percentageData = data && data.data && data.data.response ? data.data.response : [];
            const printData = listData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const categoryID = item?.menu_category_id || 'N/A';
                const categoryName = item?.menu_category_name || 'N/A';
                const totalOrder = item?.total_orders || 'N/A';
                const totalRevenue = item?.total_revenue + " " + "SAR" || 'N/A';
                const brandName = capitalizeFirstLetter(item?.brand_name)
                const menuItemNames = Array.isArray(item.top_selling_menu_items)
                ? item.top_selling_menu_items.slice(0,5).map(item => {
                    const menuId = item[0]; 
                    const menuItem = menuItemsData.find(menu => menu.id === Number(menuId));
                    return menuItem ? menuItem.item_name : null; 
                }).filter(name => name !== null).join(', ') 
                : 'N/A'; 

                    const totalOrdersCount = percentageData?.total_orders_count || 0; 
                    const totalOrders = item.total_orders || 0; 
                    const percentage = totalOrdersCount > 0 ? ((totalOrders / totalOrdersCount) * 100).toFixed(2) : 0;

                    const totalRevenueCount = percentageData?.total_orders_revenue || 0; 
                    const totalRevenueData = item.total_revenue || 0; 
                    const percentageRevenue = totalRevenueCount > 0 ? ((totalRevenueData / totalRevenueCount) * 100).toFixed(2) : 0;
                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "category_id":
                                transformedItem[newKey] = '#' + (categoryID);
                                break;
                            case "category_name":
                                transformedItem[newKey] = capitalizeFirstLetter(categoryName);
                                break;
                            case "total_order":
                                transformedItem[newKey] = (totalOrder);
                                break;
                            case "total_revenue":
                                transformedItem[newKey] = (totalRevenue);
                                break;
                            case "items":
                                transformedItem[newKey] = (menuItemNames || 'N/A');
                                break;
                            case "order_percentage":
                                transformedItem[newKey] = (totalOrdersCount > 0 ? `${percentage}%` : 'N/A');
                                break;
                            case "revenue_percentage":
                                transformedItem[newKey] = (totalRevenueCount > 0 ? `${percentageRevenue}%`  : 'N/A');
                                break;
                           case "brand_name":
                                    transformedItem[newKey] = brandName;
                                    break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Primary-Category-Report.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    useEffect(() => {
        GetMenuItems({ company_id : getCompany().company_id }).then(data => {
            if (data && data.data && data.data.response) {
                setMenuItemsData(data.data.response.rows);
            }
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, []);

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Report</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationReportTab tab='category' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            {/* <div className='page-header-left'></div> */}
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='page-header-right ms-auto'>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className="date-input">
                                        {/* <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} /> */}
                                    </div>
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Category ID</span>
                                                 <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.id" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Category Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Total Order</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.total_orders" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Total Revenue</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.total_revenue" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Top Selling Item</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Percentage Of Total Order</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.total_orders" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Percentage Of Total Revenue</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="menu_categories.total_revenue" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Brand Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brands.name" />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ? (
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr>
                                    ) : (
                                        <>
                                            {list.length ? (
                                                list.map((row, i) => {
                                                    const menuItemNames = Array.isArray(row.top_selling_menu_items)
                                                        ? row.top_selling_menu_items.slice(0,5).map(item => {
                                                            const menuId = item[0]; 
                                                            const menuItem = menuItemsData.find(menu => menu.id === Number(menuId));
                                                            return menuItem ? menuItem.item_name : null; 
                                                        }).filter(name => name !== null).join(', ') 
                                                        : 'N/A'; 
                                                    // Calculate percentage
                                                    const totalOrdersCount = data?.total_orders_count || 0; 
                                                    const totalOrders = row.total_orders || 0; 
                                                    const percentage = totalOrdersCount > 0 ? ((totalOrders / totalOrdersCount) * 100).toFixed(2) : 0;

                                                    const totalRevenueCount = data?.total_orders_revenue || 0; 
                                                    const totalRevenue = row.total_revenue || 0; 
                                                    const percentageRevenue = totalRevenueCount > 0 ? ((totalRevenue / totalRevenueCount) * 100).toFixed(2) : 0;

                                                    return (
                                                        <tr key={i}>
                                                            <td>#{row.menu_category_id}</td>
                                                            <td>{capitalizeFirstLetter(row?.menu_category_name)}</td>
                                                            <td>{totalOrders ? totalOrders : 'N/A'}</td>
                                                            <td>{totalRevenue ? totalRevenue + " SAR" : 'N/A'}</td>
                                                            <td>{menuItemNames || 'N/A'}</td>
                                                            <td>{totalOrdersCount > 0 ? `${percentage}%` : 'N/A'}</td> 
                                                            <td>{totalRevenueCount > 0  ? `${percentageRevenue}%` : 'N/A'}</td>
                                                            <td>{capitalizeFirstLetter(row?.brand_name)}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={11} className="text-center">
                                                        <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                        <p>No record found</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "report", path: "/company/report/primary_category_report/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default PrimaryCategoryReportList