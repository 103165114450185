import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChangeCategoryStatus, DeleteCategory, GetCategoryList, GetParentCategories } from '../../services/company_panel/categoryService'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, formateDate, getBrandId } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import {  checkPermission } from '../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notFound from '../../assets/company/img/no-record-found.png'

const SubCategoryList = () => {

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(()=>{
		document.addEventListener('brand-changed', function(){
			setIsBrandChanged(!isBrandChanged);
		});
	});

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            GetCategoryList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
        $("#resetFilter")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                const formdata = new FormData()
                formdata.append("id", row);
                DeleteCategory(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Sub category deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }

    // Change Status functionality
    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeCategoryStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error);
                })
            }
        })
    }

    const editFunction = (row) => {
        navigate(`/company/raw_materials/sub_category/${params.id}/edit/${row.id}`)
    }
    const copyOutletFunction = (row) => {
        navigate(`/company/raw_materials/sub_category/${params.id}/edit/${row.id}?copy=true`);
    }
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetParentCategories(formData).then(data => {
            setCategoryData(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [])

    const handleFilterChange = (e) => {
        if (e?.target.value) {
            prepareSearch("category_type", e.target.value);
        } else {
            prepareSearch("category_type", "");
        }
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Sub Category List</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        {checkPermission('store_inventory_subcategory', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/raw_materials/sub_category/add`)}>Create Sub Category</button>
                            : ''
                        }
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <div className="select-down-arrow">
                                            <select
                                                className="form-control cp"
                                                onChange={handleFilterChange}
                                                id="resetFilter"
                                                style={{ width: "180px" }}
                                            >
                                                <option value="">Select Category</option>
                                                {categoryData &&
                                                    categoryData.length > 0 &&
                                                    categoryData.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option?.id}>
                                                                {capitalizeFirstLetter(option?.name)}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="parent_id" />
                                            </div>
                                        </th>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Sub category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="20%">
                                            <div className='sorting_column ' style={{ width: '200px' }}>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='status_head' width="15%">Status</th>
                                        <th className='action_head text-end' width="10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={6}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeFirstLetter(row?.parentcategory)}</td>
                                                    <td>{capitalizeFirstLetter(row?.name)}</td>
                                                    <td>{row?.created_at ? formateDate(row?.created_at) : "N/A"}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 e_none cp" onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }}>
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" defaultChecked={row.status} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                {checkPermission('store_inventory_subcategory', 'edit') ?
                                                                    <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                    : ''}
                                                                {checkPermission('store_inventory_subcategory', 'delete') ?
                                                                    <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li> :
                                                                    ''}
                                                                 {checkPermission('store_inventory_subcategory', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy sub category</div></li>
                                                                    :''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={11} className="text-center">
                                                <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                <p>No records</p>
                                            </td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/raw_materials/sub_category/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default SubCategoryList;