import React, { useEffect, useMemo, useState } from "react";
import ConfigurationTab from "../configurationTab";
import { ToastContainer } from "react-toastify";
import { FONT_SIZE, FONT_STYLE, IMG_MAX_SIZE, IMG_TYPE } from "../../../utils/Constants";
import { useFormik } from "formik";
import { CreateCustomerInvoice, GetCustomerInvoice } from "../../../services/company_panel/setting/invoiceServices";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { capitalizeAllFirstLetters, checkPermission, getBrandId, getExtension } from "../../../helpers/commonFunctions";
import { GetAllActiveCoupons } from "../../../services/company_panel/coupons.service";
import { QRCodeSVG } from 'qrcode.react';

const CustomerInvoiceSetting = () => {

    const [previewImage, setPreviewImage] = useState("");
    const [showDefault, setShowDefault] = useState({});
    const [coupons, setCoupons] = useState([]);
    const [couponCode, setCouponCode] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        GetCustomerInvoice({ brand_id: getBrandId() }).then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : {})
            setPreviewImage(data?.data?.response?.logo)
        }).catch(error => {
            console.log("error=====>", error)
        });

        GetAllActiveCoupons({ brand_id: getBrandId() }).then(res => {
            setCoupons(res?.data?.response ? res?.data?.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        });
    }, [isBrandChanged]);


    const validateSchema = Yup.object().shape({
        heading_text: Yup.string().trim().max(50, "Heading cannot exceed 50 characters"),
        start_invoice_prefix: Yup.string().trim().max(50, "Start invoice prefix cannot exceed 50 characters"),
        vat_no: Yup.string().trim().max(15, "VAT No cannot exceed 15 characters"),
        footer_text: Yup.string().trim().max(150, "Footer cannot exceed 150 characters"),
        logo: Yup.mixed()
            .required('Image is required')
            .test("file_types", (value) => {
                if (value && value.name) {
                    const fileType = getExtension(value?.name);
                    return IMG_TYPE.includes(fileType);
                }
                return true;
            })
            .test("file_size", `Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`, (value) => {
                if (value && value.size) {
                    return value.size <= IMG_MAX_SIZE.value
                }
                return true;
            }),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            logo: showDefault && showDefault?.logo ? showDefault?.logo : '',
            line_separator: showDefault && showDefault?.use_separator ? showDefault?.use_separator : 'solid',
            heading_text: showDefault && showDefault?.heading_text ? showDefault?.heading_text : '',
            heading_font_size: showDefault && showDefault?.heading_font_size ? showDefault?.heading_font_size : '',
            heading_font_style: showDefault && showDefault?.heading_font_style ? showDefault?.heading_font_style : '',
            start_invoice_prefix: showDefault && showDefault?.start_invoice_prefix ? showDefault?.start_invoice_prefix : '',
            start_invoice_prefix_font_size: showDefault && showDefault?.start_invoice_prefix_font_size ? showDefault?.start_invoice_prefix_font_size : '',
            start_invoice_prefix_font_style: showDefault && showDefault?.start_invoice_prefix_font_style ? showDefault?.start_invoice_prefix_font_style : '',
            vat_no: showDefault && showDefault?.vat_no ? showDefault?.vat_no : '',
            vat_no_font_size: showDefault && showDefault?.vat_no_font_size ? showDefault?.vat_no_font_size : '',
            vat_no_font_style: showDefault && showDefault?.vat_no_font_style ? showDefault?.vat_no_font_style : '',
            address_font_size: showDefault && showDefault?.address_font_size ? showDefault?.address_font_size : '',
            address_font_style: showDefault && showDefault?.address_font_style ? showDefault?.address_font_style : '',

            order_number: showDefault && showDefault?.order_number ? showDefault?.order_number : '',
            order_number_font_size: showDefault && showDefault?.order_number_font_size ? showDefault?.order_number_font_size : '',
            order_number_font_style: showDefault && showDefault?.order_number_font_style ? showDefault?.order_number_font_style : '',
            order_id_font_size: showDefault && showDefault?.order_id_font_size ? showDefault?.order_id_font_size : '',
            order_id_font_style: showDefault && showDefault?.order_id_font_style ? showDefault?.order_id_font_style : '',
            date_font_size: showDefault && showDefault?.date_font_size ? showDefault?.date_font_size : '',
            date_font_style: showDefault && showDefault?.date_font_style ? showDefault?.date_font_style : '',
            time_font_size: showDefault && showDefault?.time_font_size ? showDefault?.time_font_size : '',
            time_font_style: showDefault && showDefault?.time_font_style ? showDefault?.time_font_style : '',
            bill_to_font_size: showDefault && showDefault?.bill_to_font_size ? showDefault?.bill_to_font_size : '',
            bill_to_font_style: showDefault && showDefault?.bill_to_font_style ? showDefault?.bill_to_font_style : '',
            from_font_size: showDefault && showDefault?.from_font_size ? showDefault?.from_font_size : '',
            from_font_style: showDefault && showDefault?.from_font_style ? showDefault?.from_font_style : '',
            sales_person_font_size: showDefault && showDefault?.sales_person_font_size ? showDefault?.sales_person_font_size : '',
            sales_person_font_style: showDefault && showDefault?.sales_person_font_style ? showDefault?.sales_person_font_style : '',
            item_name_font_size: showDefault && showDefault?.item_name_font_size ? showDefault?.item_name_font_size : '',
            item_name_font_style: showDefault && showDefault?.item_name_font_style ? showDefault?.item_name_font_style : '',
            qty_font_size: showDefault && showDefault?.qty_font_size ? showDefault?.qty_font_size : '',
            qty_font_style: showDefault && showDefault?.qty_font_style ? showDefault?.qty_font_style : '',
            rate_font_size: showDefault && showDefault?.rate_font_size ? showDefault?.rate_font_size : '',
            rate_font_style: showDefault && showDefault?.rate_font_style ? showDefault?.rate_font_style : '',
            rate_font_size: showDefault && showDefault?.rate_font_size ? showDefault?.rate_font_size : '',
            rate_font_style: showDefault && showDefault?.rate_font_style ? showDefault?.rate_font_style : '',
            line_total_font_size: showDefault && showDefault?.line_total_font_size ? showDefault?.line_total_font_size : '',
            line_total_font_style: showDefault && showDefault?.line_total_font_style ? showDefault?.line_total_font_style : '',
            subtotal_font_size: showDefault && showDefault?.subtotal_font_size ? showDefault?.subtotal_font_size : '',
            subtotal_font_style: showDefault && showDefault?.subtotal_font_style ? showDefault?.subtotal_font_style : '',
            discount_font_size: showDefault && showDefault?.discount_font_size ? showDefault?.discount_font_size : '',
            discount_font_style: showDefault && showDefault?.discount_font_style ? showDefault?.discount_font_style : '',
            tax_font_size: showDefault && showDefault?.tax_font_size ? showDefault?.tax_font_size : '',
            tax_font_style: showDefault && showDefault?.tax_font_style ? showDefault?.tax_font_style : '',
            total_font_size: showDefault && showDefault?.total_font_size ? showDefault?.total_font_size : '',
            total_font_style: showDefault && showDefault?.total_font_style ? showDefault?.total_font_style : '',
            cash_tendered_font_size: showDefault && showDefault?.cash_tendered_font_size ? showDefault?.cash_tendered_font_size : '',
            cash_tendered_font_style: showDefault && showDefault?.cash_tendered_font_style ? showDefault?.cash_tendered_font_style : '',
            change_due_font_size: showDefault && showDefault?.change_due_font_size ? showDefault?.change_due_font_size : '',
            change_due_font_style: showDefault && showDefault?.change_due_font_style ? showDefault?.change_due_font_style : '',
            footer_text: showDefault && showDefault?.footer_text ? showDefault?.footer_text : '',
            footer_font_size: showDefault && showDefault?.footer_font_size ? showDefault?.footer_font_size : '',
            footer_font_style: showDefault && showDefault?.footer_font_style ? showDefault?.footer_font_style : '',

            sub_invoice: showDefault && showDefault?.sub_invoice ? showDefault?.sub_invoice : false,
            sub_invoice_text: showDefault && showDefault?.sub_invoice_text ? showDefault?.sub_invoice_text : '',
            sub_invoice_text_font_size: showDefault && showDefault?.sub_invoice_text_font_size ? showDefault?.sub_invoice_text_font_size : '',
            sub_invoice_text_font_style: showDefault && showDefault?.sub_invoice_text_font_style ? showDefault?.sub_invoice_text_font_style : '',
            coupon_code: showDefault && showDefault?.coupon_code ? showDefault?.coupon_code : '',
        },
        validationSchema: validateSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const formData = new FormData();
            formData.append("invoice_type", 'customer_invoice');
            formData.append("logo", values.logo);
            formData.append("use_separator", values.line_separator);
            formData.append("heading_text", values.heading_text);
            formData.append("heading_font_size", values.heading_font_size);
            formData.append("heading_font_style", values.heading_font_style);
            formData.append("start_invoice_prefix", values.start_invoice_prefix);
            formData.append("start_invoice_prefix_font_size", values.start_invoice_prefix_font_size);
            formData.append("start_invoice_prefix_font_style", values.start_invoice_prefix_font_style);
            formData.append("vat_no", values.vat_no);
            formData.append("vat_no_font_size", values.vat_no_font_size);
            formData.append("vat_no_font_style", values.vat_no_font_style);
            formData.append("address_font_size", values.address_font_size);
            formData.append("address_font_style", values.address_font_style);

            formData.append("order_number", values.order_number);
            formData.append("order_number_font_size", values.order_number_font_size);
            formData.append("order_number_font_style", values.order_number_font_style);
            formData.append("order_id_font_size", values.order_id_font_size);
            formData.append("order_id_font_style", values.order_id_font_style);
            formData.append("date_font_size", values.date_font_size);
            formData.append("date_font_style", values.date_font_style);
            formData.append("time_font_size", values.time_font_size);
            formData.append("time_font_style", values.time_font_style);
            formData.append("bill_to_font_size", values.bill_to_font_size);
            formData.append("bill_to_font_style", values.bill_to_font_style);
            formData.append("from_font_size", values.from_font_size);
            formData.append("from_font_style", values.from_font_style);
            formData.append("sales_person_font_size", values.sales_person_font_size);
            formData.append("sales_person_font_style", values.sales_person_font_style);
            formData.append("qty_font_size", values.qty_font_size);
            formData.append("qty_font_style", values.qty_font_style);
            formData.append("rate_font_size", values.rate_font_size);
            formData.append("rate_font_style", values.rate_font_style);
            formData.append("line_total_font_size", values.line_total_font_size);
            formData.append("line_total_font_style", values.line_total_font_style);
            formData.append("subtotal_font_size", values.subtotal_font_size);
            formData.append("subtotal_font_style", values.subtotal_font_style);
            formData.append("discount_font_size", values.discount_font_size);
            formData.append("discount_font_style", values.discount_font_style);
            formData.append("tax_font_size", values.tax_font_size);
            formData.append("tax_font_style", values.tax_font_style);
            formData.append("total_font_size", values.total_font_size);
            formData.append("total_font_style", values.total_font_style);
            formData.append("cash_tendered_font_size", values.cash_tendered_font_size);
            formData.append("cash_tendered_font_style", values.cash_tendered_font_style);
            formData.append("change_due_font_size", values.change_due_font_size);
            formData.append("change_due_font_style", values.change_due_font_style);
            formData.append("footer_text", values.footer_text);
            formData.append("footer_font_size", values.footer_font_size);
            formData.append("footer_font_style", values.footer_font_style);
            formData.append("item_name_font_size", values.item_name_font_size);
            formData.append("item_name_font_style", values.item_name_font_style);

            formData.append("sub_invoice", values.sub_invoice);
            formData.append("sub_invoice_text", values.sub_invoice_text);
            formData.append("sub_invoice_text_font_size", values.sub_invoice_text_font_size);
            formData.append("sub_invoice_text_font_style", values.sub_invoice_text_font_style);
            formData.append("coupon_code", values.coupon_code);
            formData.append("brand_id", getBrandId());
            CreateCustomerInvoice(formData)
                .then((response) => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                })
                .catch((error) => {
                    console.log("Error==>", error)
                })
        }
    });

    // useEffect(()=>{
    //     const currentCoupon = coupons?.filter((coupon) => coupon.id == formik.values.coupon_code)
    //     console.log("currentCoupon-->",currentCoupon);

    // },[formik.values.coupon_code])

    useEffect(() => {
        const currentCoupon = coupons?.find((coupon) => coupon.id == formik.values.coupon_code);
        setCouponCode(currentCoupon);
    }, [formik.values.coupon_code]);

    return (
        <>
            <div className="animation_fade">
                <div className="page-header d-flex align-items-center">
                    <div className="page-header-left">
                        <h4>Settings</h4>
                    </div>
                    <div className="page-header-right ms-auto"></div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className="gap-20 border-bottom">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <ConfigurationTab tab="customer" />
                            </div>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="w-100 p-4">
                            <div className="row">
                                <div className="col-8">
                                    <div className="card p-3">

                                        <div className="row">
                                            <div class="col-lg-12 mb-3">
                                                <h3>Settings</h3>
                                            </div>
                                            <div class="col-lg-12 mb-3">
                                                <h4>Separator</h4>
                                            </div>
                                            <label className="">Line Separator</label>
                                            <div class="col-lg-12 text-center form-group mb-3">
                                                <select name="line_separator" id="line_separator" class="form-control" value={formik.values.line_separator} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    {/* <option value="">Select Line Separator</option> */}
                                                    <option value="solid">Solid</option>
                                                    <option value="dashed">Dashed</option>
                                                    <option value="dotted">Dotted</option>
                                                </select>
                                            </div>

                                            <div class="col-lg-12 mb-3">
                                                <h4>Header</h4>
                                            </div>
                                            <label className="">Heading</label>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <input
                                                    name="heading_text"
                                                    id="heading_text"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter Heading Text"
                                                    autocomplete="off"
                                                    value={formik.values.heading_text}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span className='text-danger d-flex text-left'>{formik.errors.heading_text && formik.touched.heading_text && formik.errors.heading_text}</span>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="heading_font_size" class="form-control" value={formik.values.heading_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="heading_font_style" class="form-control" value={formik.values.heading_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <label className="">Start invoice Prefix</label>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <input name="start_invoice_prefix" id="start_invoice_prefix" value={formik.values.start_invoice_prefix} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" class="form-control" placeholder="Enter Start invoice Prefix" autocomplete="off" />
                                                <span className='text-danger d-flex text-left'>{formik.errors.start_invoice_prefix && formik.touched.start_invoice_prefix && formik.errors.start_invoice_prefix}</span>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="start_invoice_prefix_font_size" class="form-control" value={formik.values.start_invoice_prefix_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="start_invoice_prefix_font_style" class="form-control" value={formik.values.start_invoice_prefix_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label>VAT No.</label>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <input name="vat_no" id="vat_no" value={formik.values.vat_no} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" class="form-control" placeholder="Enter VAT No." autocomplete="off" />
                                                <span className='text-danger d-flex text-left'>{formik.touched.vat_no && formik.errors.vat_no}</span>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="vat_no_font_size" value={formik.values.vat_no_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} class="form-control">
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="vat_no_font_style" value={formik.values.vat_no_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} class="form-control">
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Address</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="address_font_size" class="form-control" value={formik.values.address_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="address_font_style" class="form-control" value={formik.values.address_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Logo</label>
                                            <div class="upload-logo d-flex align-items-center gap-3 mb-3">
                                                <div class="avatar avatar-xl">
                                                    <img alt="avatar" src={previewImage ? previewImage : "https://pointofsalesapi.devtechnosys.tech/dummy_images/dummy_company_image.png"} />
                                                </div>
                                                <div class="btn btn-black btn-file-type">Upload logo
                                                    <input
                                                        id="logo"
                                                        type="file"
                                                        name="logo"
                                                        accept="image/*"
                                                        onChange={(event) => {
                                                            formik.setFieldValue("logo", event.target.files[0] || "");
                                                            { event.target.files.length == 1 ? setPreviewImage(URL.createObjectURL(event.target.files[0])) : setPreviewImage("") }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        formik.setFieldValue("logo", "");
                                                        setPreviewImage("");
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                                <span className='text-danger d-flex text-left'>{formik.errors.logo && formik.touched.logo && formik.errors.logo}</span>
                                            </div>

                                            <h4>Bill Details</h4>

                                            <label>Order Number</label>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <input name="order_number" id="order_number" value={formik.values.order_number} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" class="form-control" placeholder="Enter initial order number" autocomplete="off" />
                                                <span className='text-danger d-flex text-left'>{formik.touched.order_number && formik.errors.order_number}</span>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="order_number_font_size" value={formik.values.order_number_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} class="form-control">
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="order_number_font_style" value={formik.values.order_number_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} class="form-control">
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Order ID</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="order_id_font_size" class="form-control" value={formik.values.order_id_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="order_id_font_style" class="form-control" value={formik.values.order_id_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Date</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="date_font_size" class="form-control" value={formik.values.date_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="date_font_style" class="form-control" value={formik.values.date_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Time</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="time_font_size" class="form-control" value={formik.values.time_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="time_font_style" class="form-control" value={formik.values.time_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Billed To</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="bill_to_font_size" class="form-control" value={formik.values.bill_to_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="bill_to_font_style" class="form-control" value={formik.values.bill_to_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">From</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="from_font_size" class="form-control" value={formik.values.from_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="from_font_style" class="form-control" value={formik.values.from_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Sales Person</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="sales_person_font_size" class="form-control" value={formik.values.sales_person_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="sales_person_font_style" class="form-control" value={formik.values.sales_person_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <h4>Item Details</h4>

                                            <label className="">Item Name</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="item_name_font_size" class="form-control" value={formik.values.item_name_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="item_name_font_style" class="form-control" value={formik.values.item_name_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Qty</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="qty_font_size" class="form-control" value={formik.values.qty_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="qty_font_style" class="form-control" value={formik.values.qty_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Rate</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="rate_font_size" class="form-control" value={formik.values.rate_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="rate_font_style" class="form-control" value={formik.values.rate_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Line Total</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="line_total_font_size" class="form-control" value={formik.values.line_total_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="line_total_font_style" class="form-control" value={formik.values.line_total_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Subtotal</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="subtotal_font_size" class="form-control" value={formik.values.subtotal_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="subtotal_font_style" class="form-control" value={formik.values.subtotal_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Discount</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="discount_font_size" class="form-control" value={formik.values.discount_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="discount_font_style" class="form-control" value={formik.values.discount_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Tax</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="tax_font_size" class="form-control" value={formik.values.tax_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="tax_font_style" class="form-control" value={formik.values.tax_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Total</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="total_font_size" class="form-control" value={formik.values.total_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="total_font_style" class="form-control" value={formik.values.total_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Cash Tendered</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="cash_tendered_font_size" class="form-control" value={formik.values.cash_tendered_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="cash_tendered_font_style" class="form-control" value={formik.values.cash_tendered_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Change Due</label>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="change_due_font_size" class="form-control" value={formik.values.change_due_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="change_due_font_style" class="form-control" value={formik.values.change_due_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <h4>Footer</h4>

                                            <label className="">Footer</label>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <input
                                                    name="footer_text"
                                                    id="footer_text"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter Footer Text"
                                                    autocomplete="off"
                                                    value={formik.values.footer_text}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span className='text-danger d-flex text-left'>{formik.touched.footer_text && formik.errors.footer_text}</span>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="footer_font_size" class="form-control" value={formik.values.footer_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-4 text-center form-group mb-3">
                                                <select name="footer_font_style" class="form-control" value={formik.values.footer_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* --------------------------sub invoice------------------------------------ */}

                                            <div className="my-2">
                                                <input className="form-check-input" type="checkbox" name="sub_invoice" id="sub_invoice" checked={formik.values.sub_invoice} onChange={() => formik.setFieldValue("sub_invoice", !formik.values.sub_invoice)} onBlur={formik.handleBlur} />
                                                <label className=" ps-2 fw-bold" htmlFor="sub_invoice">Sub Invoice</label>
                                            </div>
                                            <div class="col-lg-12 text-center form-group mb-3">
                                                <textarea
                                                    name="sub_invoice_text"
                                                    id="sub_invoice_text"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter sub invoice text"
                                                    autocomplete="off"
                                                    value={formik.values.sub_invoice_text}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span className='text-danger d-flex text-left'>{formik.touched.sub_invoice_text && formik.errors.sub_invoice_text}</span>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="sub_invoice_text_font_size" class="form-control" value={formik.values.sub_invoice_text_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div class="col-lg-6 text-center form-group mb-3">
                                                <select name="sub_invoice_text_font_style" class="form-control" value={formik.values.sub_invoice_text_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Coupon code</label>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <select
                                                    id="coupon_code"
                                                    className='form-control'
                                                    name='coupon_code'
                                                    value={formik.values.coupon_code}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option value="">Select coupon code</option>
                                                    {coupons?.length ? coupons.map((coupon, i) => (
                                                        <option key={i} value={coupon.id}>{coupon.name}</option>
                                                    )) : ''}
                                                </select>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card p-3 fw-bold">
                                        <h2>Preview</h2>
                                        <div className=" m-4 p-4">
                                            <div className="text-center">
                                                {previewImage &&
                                                    <div class="avatar avatar-xl">
                                                        <img alt="LOGO" src={previewImage} />
                                                    </div>
                                                }
                                                <h3 style={{ fontSize: formik.values.heading_font_size, fontFamily: formik.values.heading_font_style }} >{formik.values.heading_text}</h3>
                                                <h2 style={{ fontSize: formik.values.start_invoice_prefix_font_size, fontFamily: formik.values.start_invoice_prefix_font_style }} >{formik.values.start_invoice_prefix}</h2>
                                                <p style={{ fontSize: formik.values.vat_no_font_size, fontFamily: formik.values.vat_no_font_style }}>VAT NO: {formik.values.vat_no}</p>
                                                <p style={{ fontSize: formik.values.address_font_size, fontFamily: formik.values.address_font_style }}>Some address<br />mpav, fd, 23rf<br />Phone: +333333333333</p>
                                                {/* <h4>SALE</h4> */}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <img className="w-50" src="https://img.freepik.com/free-psd/barcode-illustration-isolated_23-2150584086.jpg" alt="Barcode" />
                                            </div>

                                            <div>
                                                <div className="row" style={{ fontSize: formik.values.order_number_font_size, fontFamily: formik.values.order_number_font_style }}>
                                                    <p className="col-6">Order Number#: </p>
                                                    <p className="col-6">{formik.values.order_number}</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.order_id_font_size, fontFamily: formik.values.order_id_font_style }}>
                                                    <p className="col-6">Order ID#: </p>
                                                    <p className="col-6">010900269558</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.bill_to_font_size, fontFamily: formik.values.bill_to_font_style }}>
                                                    <p className="col-6">Sold To:</p>
                                                    <p className="col-6">Walk-In</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.from_font_size, fontFamily: formik.values.from_font_style }}>
                                                    <p className="col-6">From:</p>
                                                    <p className="col-6">Person abc</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.date_font_size, fontFamily: formik.values.date_font_style }}>
                                                    <p className="col-6">Order Date:</p>
                                                    <p className="col-6">03/31/17</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.time_font_size, fontFamily: formik.values.time_font_size }}>
                                                    <p className="col-6">Order Time:</p>
                                                    <p className="col-6">1:03:23 PM</p>
                                                </div>
                                                <div className="row" style={{ fontSize: formik.values.sales_person_font_size, fontFamily: formik.values.sales_person_font_style }}>
                                                    <p className="col-6">Sales Person:</p>
                                                    <p className="col-6">Admin Admin</p>
                                                </div>
                                                {/* <div className="row">
                                                    <p className="col-6">Register:</p>
                                                    <p className="col-6">1</p>
                                                </div>
                                                <div className="row">
                                                    <p className="col-6">Order type:</p>
                                                    <p className="col-6">Quick Sale</p>
                                                </div> */}
                                            </div>
                                            <div style={{ border: `1px ${formik.values.line_separator} black` }}></div>
                                            <div className="row">
                                                <div className="col-4" style={{ fontSize: formik.values.item_name_font_size, fontFamily: formik.values.item_name_font_style }}>Red Banana</div>
                                                <div className="col-2" style={{ fontSize: formik.values.qty_font_size, fontFamily: formik.values.qty_font_style }}>x3</div>
                                                <div className="col-3 text-right" style={{ fontSize: formik.values.rate_font_size, fontFamily: formik.values.rate_font_style }}>$21.6</div>
                                                <div className="col-3 text-right" style={{ fontSize: formik.values.line_total_font_size, fontFamily: formik.values.line_total_font_style }}>$64.8</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4" style={{ fontSize: formik.values.item_name_font_size, fontFamily: formik.values.item_name_font_style }}>Apple</div>
                                                <div className="col-2" style={{ fontSize: formik.values.qty_font_size, fontFamily: formik.values.qty_font_style }}>x5</div>
                                                <div className="col-3 text-right" style={{ fontSize: formik.values.rate_font_size, fontFamily: formik.values.rate_font_style }}>$45</div>
                                                <div className="col-3 text-right" style={{ fontSize: formik.values.line_total_font_size, fontFamily: formik.values.line_total_font_style }}>$225</div>
                                            </div>
                                            <div style={{ border: `1px ${formik.values.line_separator} black` }}></div>
                                            <div className="row" style={{ fontSize: formik.values.subtotal_font_size, fontFamily: formik.values.subtotal_font_style }}>
                                                <div className="col-9">Subtotal</div>
                                                <div className="col-3 text-right">$100.23</div>
                                            </div>
                                            <div className="row" style={{ fontSize: formik.values.discount_font_size, fontFamily: formik.values.discount_font_style }}>
                                                <div className="col-9">Discount</div>
                                                <div className="col-3 text-right">($5.95)</div>
                                            </div>
                                            <div className="row" style={{ fontSize: formik.values.tax_font_size, fontFamily: formik.values.tax_font_style }}>
                                                <div className="col-9">12 %</div>
                                                <div className="col-3 text-right">$11.32</div>
                                            </div>
                                            <div className="row" style={{ fontSize: formik.values.total_font_size, fontFamily: formik.values.total_font_style }}>
                                                <div className="col-9 font-weight-bold">Total</div>
                                                <div className="col-3 text-right font-weight-bold">$105.60</div>
                                            </div>
                                            <div style={{ border: `1px ${formik.values.line_separator} black` }}></div>
                                            <div className="row" style={{ fontSize: formik.values.cash_tendered_font_size, fontFamily: formik.values.cash_tendered_font_style }}>
                                                <div className="col-9">Cash Tendered</div>
                                                <div className="col-3 text-right">$105.60</div>
                                            </div>
                                            <div className="row" style={{ fontSize: formik.values.change_due_font_size, fontFamily: formik.values.change_due_font_style }}>
                                                <div className="col-9 font-weight-bold">CHANGE DUE</div>
                                                <div className="col-3 text-right font-weight-bold">$0.00</div>
                                            </div>
                                            <div style={{ border: `1px ${formik.values.line_separator} black` }}></div>

                                            <div className="text-center mt-5" style={{ fontSize: formik.values.footer_font_size, fontFamily: formik.values.footer_font_style }}>
                                                <p>Special package</p>
                                                <p>{formik.values.footer_text}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <h2 className="mt-3">Sub Invoice Preview</h2>
                                    <div className="card p-3 mt-2 fw-bold">
                                        <div className="row">
                                            <div className="text-center mb-3" style={{ fontSize: formik.values.sub_invoice_text_font_size, fontFamily: formik.values.sub_invoice_text_font_style }}>
                                                <div>{formik.values.sub_invoice_text}</div>
                                            </div>
                                            <div className="col-12 text-center form-group mb-3">
                                                <QRCodeSVG value={couponCode?.coupon_code} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {checkPermission('store_settings', 'add') ?
                                <div class="mt-4"><button class="btn btn-primary px-5 mr-2" type="submit">Save</button></div>
                                : ''}
                        </div>
                    </form>

                    <ToastContainer
                        position="top-right"
                        hideProgressBar={false}
                        autoClose={true | 1000}
                        newestOnTop={true}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </div>
            </div >
        </>
    )
}

export default CustomerInvoiceSetting;