import axiosInstance from "../utils/axios";

export const LoginService = async (values) => {    
    return await axiosInstance().post("admin/login", values);
}

export const LoginOtpverification = async (values) => {
    return await axiosInstance().post("admin/verifyotp", values);
}

export const ResendotpVerification = async (values) => {
    return await axiosInstance().post("admin/resendotp", values)
}

export const Getforgetpassword = async (values) => {
    return await axiosInstance().post("admin/forgetpassword", values)
}

export const GetResetpassword = async (values) => {
    return await axiosInstance().post("admin/resetpassword", values)
}

