import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetRoleList, DeleteRole, GetRoleDetail } from '../../../services/company_panel/platform_user/platformUserService';
import Swal from 'sweetalert2'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, checkPermission, getCompany } from '../../../helpers/commonFunctions';
import notFound from '../../../assets/company/img/no-record-found.png'
import { Button, Modal } from 'rsuite';

const RoleList = () => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({ status: 1 })
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [show, setShow] = useState(false);
    const [showDefault, setShowDefault] = useState([]);
    const [roleId, setRoleId] = useState(null);


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('company_id', getCompany().company_id);
            GetRoleList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({ status: 1 })
        setResetDate(!resetdate)
    }

    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteRole(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Role deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError", error);
                })
            }
        })
    }

    const editFunction = (row) => {
        navigate(`/company/business/platform-User/role/edit/${row}`)
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const onOkClick = (roleId) => {
        try {
            GetRoleDetail(roleId).then(data => {
                const permissions = data && data.data && data.data.response
                    ? data.data.response.moduleAssignedPermissions
                    : [];
                setShowDefault(permissions);
                setShow(true);
            }).catch(error => {
                console.log("error=====>", error)
                setShow(false);
            })
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const groupPermissions = (permissions) => {
        if (!permissions || !Array.isArray(permissions)) return {};
        return permissions.reduce((acc, permission) => {
            const { module_group, module_name, permission_name } = permission;
            if (!module_group || !module_name || !permission_name) return acc;
            if (!acc[module_group]) {
                acc[module_group] = {};
            }
            if (!acc[module_group][module_name]) {
                acc[module_group][module_name] = [];
            }
            acc[module_group][module_name].push(permission_name);
            return acc;
        }, {});
    };


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Platform User </h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        {checkPermission('store_roles', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/business/platform-User/role/add`)}>Create Role</button>
                            : ''}
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className=""><Link to={`/company/business/platform-User/outlet-user/list/${1}`} className="btn">Outlet Users</Link></li>
                                <li className="ms-3 active"><Link to={`/company/business/platform-User/role/list/${1}`} className="btn btn-primary">Roles</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className='card gap-20'>
                        <div className='row plan-row'>
                            {list.length ? list.map((row, i) => (
                                <div className='col-md-4 mb-4'>
                                    <div className='plan-box h-100'>
                                        <div className='plan-head'>
                                            <div className='plan-left'>
                                                <h4>{capitalizeAllFirstLetters(row.name)}</h4>
                                            </div>
                                        </div>
                                        <div className='plan-body'>
                                            <ul className='plan-details'>
                                                <li>Accounts management, Dashboard management </li>
                                            </ul>
                                        </div>
                                        {(!(row.slug == 'company_admin' || row.slug == 'area_manager' || row.slug == 'above_store_manager')) ?
                                            <div className='plan-footer d-flex gap-3'>
                                                {checkPermission('store_roles', 'edit') ?
                                                    <button className='btn btn-primary' onClick={() => { editFunction(row.id) }}>Edit Role</button>
                                                    : ''}
                                                {checkPermission('store_roles', 'delete') ?
                                                    <button className='btn btn-border' onClick={() => { deleteFunction(row.id) }}>Delete Role</button>
                                                    : ''}
                                            </div>
                                            :
                                            <div className='plan-footer d-flex gap-3'>
                                                {/* {checkPermission('store_roles', 'edit') ? */}
                                                <button className='btn btn-primary' onClick={() => { onOkClick(row.id) }}>View Role</button>
                                                {/* :''} */}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )) : (
                                <div className='text-center'>
                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                    <p>No Record.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size={"lg"}
                open={show}
                onClose={() => setShow(false)}
                backdrop={'static'}
                className="modal-height-auto"
            >
                <Modal.Header className="mb-3">
                    <Modal.Title>Role Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border p-4 rounded">
                        {showDefault && showDefault.length > 0 ? (
                            Object.keys(groupPermissions(showDefault)).map((moduleGroup, idx) => (
                                <div key={idx} className="mb-4">
                                    <div className="border p-3 rounded">
                                        <h2 className="mb-3">{capitalizeFirstLetter(moduleGroup)}</h2> {/* Module Group Heading */}
                                        {Object.keys(groupPermissions(showDefault)[moduleGroup]).map((moduleName, subIdx) => (
                                            <div key={subIdx} className="border p-3 rounded mb-3">
                                                <p className="fw-bold">{moduleName}</p> {/* Module Name */}
                                                <div className="d-flex flex-wrap">
                                                    {groupPermissions(showDefault)[moduleGroup][moduleName].map((permission, permIdx) => (
                                                        <span
                                                            key={permIdx}
                                                            className="me-2 mb-2"
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            {capitalizeFirstLetter(permission)}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No permissions available.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RoleList