import axiosInstance from "../../utils/axiosCompany";

export const getAllMenuItems = async (values) => {
    return await axiosInstance().post("company/menu-item/get-all-menu-items", values);
}
export const AddMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/create-menu-meal-combo", values);
}
export const ListMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/list-menu-meal-combos", values);
}
export const DeleteMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/delete-menu-meal-combo", values);
}
export const ChangeStatusMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/change-menu-meal-combo-status", values);
}
export const DetailsMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/get-single-menu-meal-combo", values);
}
export const updateMeals = async (values) => {
    return await axiosInstance().post("company/menu-meal-combo/update-menu-meal-combo", values);
}


