import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { GetSingleUser } from '../../services/platformUserServices'

const ViewPlatformUser = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Platform Users",url: "/admin/platform-users/list/1"},{title: "View",url: ""}]

    useEffect(() => {
        GetSingleUser({id: params.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Company</h6>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                {/* <tr>
                                                    <th>Logo</th>
                                                    <td>{showDefault && showDefault.logo ? <img src={showDefault.logo} style={{ height: "50px" }}/> : "N/A"}</td>
                                                </tr> */}
                                                <tr>
                                                    <th>First Name</th>
                                                    <td>{showDefault && showDefault.first_name ? showDefault.first_name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Last Name</th>
                                                    <td>{showDefault && showDefault.last_name ? showDefault.last_name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>First Name (Arabic)</th>
                                                    <td>{showDefault && showDefault.first_name_ar ? showDefault.first_name_ar : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Last Name (Arabic)</th>
                                                    <td>{showDefault && showDefault.last_name ? showDefault.last_name_ar : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{showDefault && showDefault.email ? showDefault.email : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company</th>
                                                    <td>
                                                    <div className="editorcontant">{toHtml(showDefault && showDefault.company_name ? showDefault.company_name: "N/A")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Company Email</th>
                                                    <td>
                                                    <div className="editorcontant">{toHtml(showDefault && showDefault.company_email ? showDefault.company_email: "N/A")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Role</th>
                                                    <td>{showDefault && showDefault.rolename ? showDefault.rolename : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet</th>
                                                    <td>{showDefault && showDefault.outletname ? showDefault.outletname : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet Location</th>
                                                    <td>{showDefault && showDefault.outletlocation ? showDefault.outletlocation : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outlet Email</th>
                                                    <td>{showDefault && showDefault.outletemail ? showDefault.outletemail : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Brand</th>
                                                    <td>{showDefault && showDefault.brandname ? showDefault.brandname : "N/A"}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>Official email</th>
                                                    <td>{showDefault && showDefault.official_email ? showDefault.official_email : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Official phone number</th>
                                                    <td>+{showDefault.country_code} {showDefault && showDefault.official_phone_number ? showDefault.official_phone_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Licence number</th>
                                                    <td>{showDefault && showDefault.licence_number ? showDefault.licence_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Vat number</th>
                                                    <td>{showDefault && showDefault.vat_number ? showDefault.vat_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Subscription access</th>
                                                    <td>{showDefault && showDefault.subscription_access == 1 ? 'Yes' : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showDefault && showDefault.createdAt ? formateDate(showDefault.createdAt) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status == 0 ? ("Inactive") : ("Active")}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewPlatformUser