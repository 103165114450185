import axiosInstance from "../../utils/axiosCompany";

export const ListCoupon = async (values) => {
    return await axiosInstance().post("company/coupons/list-coupon", values);
}
export const AddCoupon = async (values) => {
    return await axiosInstance().post("company/coupons/add-coupon", values);
}
export const CouponDetails = async (values) => {
    return await axiosInstance().post("company/coupons/get-coupon", values);
}
export const DeleteCoupon = async (values) => {
    return await axiosInstance().post("company/coupons/delete-coupon", values);
}
export const UpdateCoupon = async (values) => {
    return await axiosInstance().post("company/coupons/update-coupon", values);
}
export const ChangeStatusCoupon = async (values) => {
    return await axiosInstance().post("company/coupons/change-status", values)
}
export const GetAllActiveCoupons = async (values) => {
    return await axiosInstance().post("company/coupons/get-all-active-coupons", values)
}

export const ListPromo = async (values) => {
    return await axiosInstance().post("company/promo/promo-list", values);
}
export const AddPromo = async (values) => {
    return await axiosInstance().post("company/promo/add-promo", values);
}
export const PromoDetails = async (values) => {
    return await axiosInstance().post("company/promo/promo-detail", values);
}
export const DeletePromo = async (values) => {
    return await axiosInstance().post("company/promo/delete-promo", values);
}
export const UpdatePromo = async (values) => {
    return await axiosInstance().post("company/promo/edit-promo", values);
}
export const ChangeStatusPromo = async (values) => {
    return await axiosInstance().post("company/promo/change-status", values)
}


