
import React, { useState } from 'react'
import { Formik } from 'formik';
import { CreateBrand } from '../../services/company_panel/brandService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCompany } from '../../helpers/commonFunctions';
import defaultImage from '../../assets/company/img/default_img.png'
import * as Yup from 'yup';
import { globalLoader } from '../../helpers/global';

const BrandAdd = () => {

  const breadcrumbs = [
    {
      title: "Brands",
      url: "/company/business/brand/list/1"
    },
    {
      title: "Create Brand",
      url: ""
    },
  ]
  const navigate = useNavigate()
  const [previewImage, setPreviewImage] = useState("");
  const [submitted, setSubmitted] = useState(false)

  const validationSchema = Yup.object().shape({
    logo: Yup.mixed()
      .required('Image is required')
      .test(
        'fileFormat',
        'Invalid Image Format',
        value => value && /\.(jpg|jpeg|png|webp|avif|gif)$/.test(value.name)
      )
      .test(
        'fileSize',
        "File size cannot be more than 5MB.",
        value => value && value.size <= 5 * 1024 * 1024
      ),
    name: Yup.string()
      .trim()
      .required('Brand name is required')
      .max(40, 'Brand name should not exceed 40 characters'),
    name_ar: Yup.string()
      .trim()
      .required('Brand name (Arabic) is required')
      .max(40, 'Brand name (Arabic) should not exceed 40 characters'),
    short_description: Yup.string()
      .trim()
      .required('Short description is required')
      .max(200, 'Short description cannot exceed 200 characters'),

    long_description: Yup.string()
      .trim()
      .required('Long description is required')
      .max(500, 'Long description cannot exceed 500 characters'),

    short_description_ar: Yup.string()
      .trim()
      .required('Short description (Arabic) is required')
      .max(200, 'Short description (Arabic) cannot exceed 200 characters'),

    long_description_ar: Yup.string()
      .trim()
      .required('Long description (Arabic) is required')
      .max(500, 'Long description (Arabic) cannot exceed 500 characters'),
  });

  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Brands</h4>
        </div>
        <div class="page-header-right ms-auto">
        </div>
      </div>
      <Formik
        initialValues={{
          logo: '',
          name: '',
          short_description: '',
          long_description: '',
          name_ar: '',
          short_description_ar: '',
          long_description_ar: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitted(true)
          globalLoader(true);
          let formData = new FormData();
          formData.append('logo', values.logo);
          formData.append('name', values?.name?.trim());
          formData.append('short_description', values.short_description);
          formData.append('long_description', values.long_description);
          formData.append('name_ar', values?.name_ar?.trim());
          formData.append('short_description_ar', values.short_description_ar);
          formData.append('long_description_ar', values.long_description_ar);
          formData.append('company_id', getCompany().company_id);
          CreateBrand(formData).then(response => {
            setSubmitting(false);
            setSubmitted(false);
            globalLoader(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
              });
              setTimeout(() => {
                navigate(`/company/business/brand/list/${1}`)
              }, 500);
            } else {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
              })
              setSubmitted(false);
            }
          }).catch(error => {
            globalLoader(false);
            console.log("error ====> ", error);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="card custom-card">
            <div className="card-body">
              <div className="row row-sm">
                <div className='col-md-12'>
                  <div className='upload-logo d-flex align-items-center gap-3 mb-3'>

                    {previewImage ?
                      <div class="avatar avatar-xl">
                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                      </div>
                      :
                      <div class="avatar avatar-xl">
                        <img alt="avatar" src={defaultImage} />
                      </div>
                    }
                    <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" accept="image/*" type="file" onChange={(event) => {
                      setFieldValue("logo", event.currentTarget.files[0] || "");
                      { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                    }}
                    /></div>
                  </div>
                  <span className='text-danger d-flex text-left' id='errortext'>
                    {errors.logo && touched.logo && errors.logo}
                  </span>
                </div>
                <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='name' className='text-left d-flex'>Brand Name<span className="requirestar">*</span></label>
                  <input name='name' id="name" type='text' placeholder='Enter brand name' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                </div>
                <div className='col-lg-6 text-center form-group mb-3'>
                  <label htmlFor='name_ar' className='text-left d-flex'>Brand Name (Arabic)<span className="requirestar">*</span></label>
                  <input name='name_ar' id="name_ar" placeholder='Enter brand name (Arabic)' type='text' onChange={handleChange} onBlur={handleBlur} value={values.name_ar} className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.name_ar && touched.name_ar && errors.name_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group required mb-3'>
                  <label htmlFor='short_description' className='text-left d-flex'>Short description<span className="requirestar">*</span> </label>
                  <textarea name='short_description' placeholder='Enter short description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' style={{ height: "100px" }} />
                  <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                </div>
                <div className='col-lg-6 text-center form-group required mb-3'>
                  <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (Arabic)<span className="requirestar">*</span> </label>
                  <textarea name='short_description_ar' placeholder='Enter short description (Arabic)' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' style={{ height: "100px" }} />
                  <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group required mb-3'>
                  <label htmlFor='long_description' className='text-left d-flex'>Long description<span className="requirestar">*</span> </label>
                  <textarea name='long_description' placeholder='Enter long description' id="long_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.long_description} className='form-control' style={{ height: "100px" }} />
                  <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                </div>
                <div className='col-lg-6 text-center form-group required mb-3'>
                  <label htmlFor='long_description_ar' className='text-left d-flex'>Long description (Arabic)<span className="requirestar">*</span> </label>
                  <textarea name='long_description_ar' placeholder='Enter long description (Arabic)' id="long_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.long_description_ar} className='form-control' style={{ height: "100px" }} />
                  <span className='text-danger d-flex text-left'>{errors.long_description_ar && touched.long_description_ar && errors.long_description_ar}</span>
                </div>
                <div className="mt-2">
                  <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default BrandAdd