import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { GetEditOutletManagement, GetOutletManagementDetail,GetBrandList } from '../../services/outletManagementService'
import Swal from "sweetalert2"
import Layout from '../../layout'
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import Select from 'react-select';

const EditOutletManagement = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [brandList, setBrandList] = useState([])
    const [orderType, setOrderType] = useState([]);
    const breadcrumbs = [
        {
            title: "Dashboard",
            url: "/admin/dashboard"
        },
        {
            title: "Pages",
            url: "/admin/outlet-management/list/1"
        },
        {
            title: "Edit",
            url: ""
        },
    ]

    useEffect(() => {
        GetOutletManagementDetail(params.id).then(data => {
          
            setOrderType(data && data.data && data.data.response ? JSON.parse(data.data.response.order_type) : [] );
            if(data && data.data && data.data.response){
                var orderTypeEdit = data && data.data && data.data.response ? JSON.parse(data.data.response.order_type) : [];
                const convertedOptions = orderTypeEdit.map(value => {
                    return {
                        value: value,
                        label: value
                    };
                });
                setOrderType(convertedOptions);
            }

      
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })

        GetBrandList().then(data => {
            setBrandList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })

    }, [])

    const orderTypeList = [
        { value: 'Dine - in', label: 'Dine - in' },
        { value: 'Take out', label: 'Take out' },
        { value: 'Pick up', label: 'Pick up' },
        { value: 'Drive-thru', label: 'Drive-thru' },
        { value: 'Delivery', label: 'Delivery' },
        { value: 'Outsource (food aggregators)', label: 'Outsource (food aggregators)' },
    
    ];

    const handleChangeOrderType = (selected) => {
        setOrderType(selected);
    };

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: showDefault && showDefault.name ? showDefault.name : "",
                        email : showDefault && showDefault.email ? showDefault.email : "",
                        brand : showDefault && showDefault.brand_id ? showDefault.brand_id : "",
                        short_description: showDefault && showDefault.short_description ? showDefault.short_description : "" ,
                        outlet_location:showDefault && showDefault.address ? showDefault.address : "",
                        order_type:showDefault && showDefault.order_type ? showDefault.order_type : "",
                        hardware_type:showDefault && showDefault.hardware_type ? showDefault.hardware_type : "",
                    }}

                    validate={values => {
                        const error = {};
                        if (!values.brand) error.brand = "Brand is required";
                        if (!values.name) error.name = "Name is required";
                        // if (!values.order_type) error.order_type = "Order type is required";
                        if (!values.hardware_type) error.hardware_type = "Hardware is required";
                        if (!values.email) {
                            error.email = "Email is required"
                        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
                            error.email  = 'Invalid email address';
                        }
                       
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append("id", params.id)
                        formData.append('logo', values.logo);
                        formData.append('name', values.name);
                        formData.append('short_description', values.short_description);
                        formData.append('outlet_location', values.outlet_location);
                        formData.append('hardware_type', values.hardware_type);

                        var allValues = [];
                        if(orderType){
                            allValues = orderType.map(item => item.value);
                            formData.append('order_type', JSON.stringify(allValues));
                        }
               
                        

                        GetEditOutletManagement(formData).then(response => {
                            setSubmitting(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/admin/outlet-management/list/${params.pgno}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Edit Outlet management</h6>
                                        </div>
                                        {showDefault && Object.keys(showDefault).length > 0 ?
                                        <div className="row row-sm">
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='name' className='text-left d-flex'>Name:<span className="requirestar">*</span></label>
                                                <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='brand' className='text-left d-flex'>Brand :<span className="requirestar">*</span></label>
                                                <select id="brand" className='form-control' name='brand' id="brand" onChange={handleChange}  onBlur={handleBlur}>
                                                <option value="">Select Brand</option>

                                                {brandList.length ? brandList.map((row) => ( 
                                                    <option value={row.id} selected={values.brand == row.id?'selected':''}>{row.name}</option>
                                                )):''}
                                                </select>
                                                <span className='text-danger d-flex text-left'>{errors.brand && touched.brand && errors.brand}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='email' className='text-left d-flex'>Email address :<span className="requirestar">*</span></label>
                                                <input name='email' id="email" type='text' onChange={handleChange} onBlur={handleBlur} value={values.email} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.email && touched.email && errors.email}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group required'>
                                                <label htmlFor='short_description' className='text-left d-flex'>Short description  :<span className="requirestar">*</span> </label>
                                                <textarea name='short_description' id="short_description" rows="2" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.short_description}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='outlet_location' className='text-left d-flex'>Outlet Location :<span className="requirestar">*</span></label>
                                                <input name='outlet_location' id="outlet_location" type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_location} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.outlet_location && touched.outlet_location && errors.outlet_location}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='order_type' className='text-left d-flex'>Order type :<span className="requirestar">*</span></label>
                                                <Select
                                                    isMulti
                                                    options={orderTypeList}
                                                    value={orderType}
                                                    onChange={handleChangeOrderType}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.order_type && touched.order_type && errors.order_type}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='hardware_type' className='text-left d-flex'>Hardware :<span className="requirestar">*</span></label>
                                                <select id="hardware_type" className='form-control' name='hardware_type' id="hardware_type" onChange={handleChange}  onBlur={handleBlur}>
                                                    <option value="">Select Hardware</option>
                                                    <option value="Printer" selected={values.hardware_type == 'Printer'?'selected':''}>Printer</option>
                                                    <option value="Swipe Machine" selected={values.hardware_type == 'Swipe Machine'?'selected':''}>Swipe Machine</option>
                                                    <option value="Cash Drawer" selected={values.hardware_type == 'Cash Drawer'?'selected':''}>Cash Drawer</option>
                                                </select>
                                              
                                            </div>
                                            <div className="">
                                                <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                            </div> : <Loader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            </Layout>
        </>

    )
}

export default EditOutletManagement