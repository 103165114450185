import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getCompanyToken } from './auth';
import { checkPermission} from '../helpers/commonFunctions';


const CompanyPrivateRoute = ({ component: Component,moduleName:moduleName,permissionName:permissionName, overRulePermission = false }) => {
    const token = getCompanyToken();
    const permission = permissionName && moduleName ? checkPermission(moduleName,permissionName) : true ;

    const user = JSON.parse(localStorage.getItem('company_user'));
    const roleSlug = user?.platform_user_role?.slug;
    return (
        token && permission || (roleSlug == 'area_manager' && overRulePermission == true) ? 
        <Component /> : 
        <Navigate to="/company/login" />
    )
}

export default CompanyPrivateRoute;