import axiosInstance from "../../utils/axiosCompany";

export const ListLoyaltyPoints = async (values) => {
    return await axiosInstance().post("company/loyalty-points/list-loyalty-point", values);
}
export const LoyaltyPointsAdd = async (values) => {
    return await axiosInstance().post("company/loyalty-points/add-loyalty-point", values);
}
export const LoyaltyPointDetails  = async (values) => {
    return await axiosInstance().post("company/loyalty-points/get-loyalty-point", values);
}
export const DeleteLoyaltyPoint = async (values) => {
    return await axiosInstance().post("company/loyalty-points/delete", values);
}
export const ChangeStatusLoyaltyPoint = async (values) => {
    return await axiosInstance().post("company/loyalty-points/change-status", values)
}
export const DetailsLoyaltyPoint  = async (values) => {
    return await axiosInstance().post("company/loyalty-points/loyalty-point-details", values);
}