import axiosInstance from "../utils/axios";

export const CreateOutletManagement = async (values) => {
    return await axiosInstance().post("admin/save-outlet-management", values);
}

export const GetOutletManagementList = async (values) => {
    return await axiosInstance().post("admin/list-outlet-management", values);
}

export const GetEditOutletManagement  = async (values) => {
    return await axiosInstance().post("admin/edit-outlet-management", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("admin/change-status-outlet-management", values)
}

export const GetDeleteOutletManagement = async (values) => {
    return await axiosInstance().post("admin/delete-outlet-management", values)
}

export const GetOutletManagementDetail = async (values) => {
    return await axiosInstance().get(`admin/get-edit-outlet-management?id=${values.id}&company_id=${values.company_id}`);
};

export const GetBrandList = async (id) => {
    return await axiosInstance().get("admin/brand-list");
};



export const GetOutletHardwares = async (values) => {
    return await axiosInstance().post("admin/get-outlet-hardwares", values);
};

export const GetSingleOutletHardware = async (values) => {
    return await axiosInstance().post("admin/get-single-outlet-hardware", values);
};