import axiosInstance from "../../../utils/axiosCompany";
const path = "company/area";

export const ListArea = async (values) => {
    return await axiosInstance().post(`${path}/list-areas`,values)
}

export const getAllAreas = async (values) => {
    return await axiosInstance().post(`${path}/get-all-areas`, values);
}

export const AddArea = async (values) => {
    return await axiosInstance().post(`${path}/add-area`,values)
}

export const EditArea = async (values) => {
    return await axiosInstance().post(`${path}/update-area`,values)
}

export const DetailsArea = async (values) => {
    return await axiosInstance().post(`${path}/get-single-area`,values)
}

export const DeleteArea = async (values) => {
    return await axiosInstance().post(`${path}/delete-area`,values)
}