import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import LoginLayout from '../loginlayout'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { LoginOtpverification, ResendotpVerification } from '../services/LoginServices';
// import Logo from '../assets/img/brand/Mask Group 1.png'
// import OtpTimer from 'otp-timer'
import { GetLogo } from '../services/settingsService';

const OtpVerificationPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { email } = useParams();
    const [loader, setLoader] = useState('false')
    const [type, setType] = useState('')
    const [searchParams] = useSearchParams();

    const [showDefault, setShowDefault] = useState({});

    const params = useParams()

    useEffect(() => {
        GetLogo(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(() => {
        setType(searchParams.get('type'))
    }, [])

    const resentotpfunct = () => {
        let formData = new FormData();
        formData.append("email", email)
        formData.append("type", type);
        ResendotpVerification(formData).then(data => {
            if (data && data.data && data.data.statuscode === 200) {
                toast(data.data.message);
            } else if (data && data.data && data.data.statuscode !== 200) {
                toast(data.data.message);
            }
        }).catch(error => {
            console.log("error: ", error);
        })
    }

    return (
        <LoginLayout>
            <Formik
                initialValues={{ otp: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.otp) {
                        errors.otp = 'OTP is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", email)
                    formData.append("otp", values.otp);
                    formData.append("type", type);
                    LoginOtpverification(formData).then(data => {
                        if(data){
                            if (data.data.statuscode === 200 && location.pathname.includes("forgot")) {
                                toast(data.data.message);
                                setTimeout(() => {
                                    setLoader(false)
                                    navigate('/admin/resetpassword', {state: data.data.response.validate_string})
                                }, 2000);
                            } else {
                                if (data && data.data && data.data.statuscode === 200) {
                                    localStorage.setItem("token", data.data.response.token);
                                    localStorage.setItem("user", JSON.stringify(data.data.response));
                                    toast(data.data.message);
                                    setTimeout(() => {
                                        setLoader(false)
                                        // let landingPage = localStorage.getItem("landingpage")
                                        // let landingPageSearch = localStorage.getItem("landingpagesearch");
                                        // if (landingPage && landingPage != "") {
                                        //     // navigate(landingPage)
                                        //     navigate({ pathname: landingPage, search: landingPageSearch })
                                        //     localStorage.removeItem('landingpage')
                                        //     localStorage.removeItem('landingpagesearch');
                                        // } else {
                                            navigate(`/admin/dashboard`)
                                        // }
                                    }, 2000);
                                } else if (data && data.data && data.data.statuscode !== 200) {
                                    toast(data.data.message);
                                    setTimeout(() => {
                                        setLoader(false)
                                    }, 2000);
                                }
                            }
                        }else{
                            setLoader(false);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className="row signpages text-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row row-sm" style={{height: "28rem"}}>
                                    <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                    <div className="m-5 p-5 pos-absolute align-content-center" style={{height: "22rem"}}>
                                            <img src={showDefault.logo} className="mb-4" alt="logo" />
                                            {/* <h2 className="mt-4 text-white">Point of sale</h2> */}
                                            <div className="clearfix"></div>
                                            <h5 className="mt-2 text-white">OTP verification</h5>
                                            {/* <span className="tx-white-6 tx-14 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form align-content-center">
                                        <div className="container-fluid">
                                            <div className="row row-sm">
                                                <div className="card-body mt-4 mb-4">
                                                    <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
                                                    <div className="clearfix"></div>
                                                    <h5 className="text-left mb-2">OTP verification</h5>
                                                    <p className="mb-4 text-muted tx-13 ml-0 text-left">OTP has been sent to  {email}</p>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group text-left">
                                                            <label>OTP</label>
                                                            <input className={"form-control" + (errors.otp && touched.otp ? "" : "")}
                                                                placeholder="Enter OTP"
                                                                type="number"
                                                                name="otp"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.otp}
                                                                autoComplete="off"
                                                                autoFocus
                                                            />
                                                            <span className='text-danger text-left d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                                                        </div>
                                                        
                                                        <button className={"btn ripple btn-main-primary btn-block signbtn position-relative" + (loader === true ? " disabled" : "")} type="submit">Verify OTP

                                                        {loader === true ?
                                                            <div className="spinner-border text-primary position-absolute login-page" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> 
                                                        : ""}
                                                        
                                                        </button>
                                                       
                                                    </form>
                                                    {/* <OtpTimer seconds= {60} minutes={0} resend={resentotpfunct} textColor={"#6259ca"} buttonColor={"#fff"} background={"#ff9b21"}/> */}
                                                    <div className="text-left mt-3 ml-0">
                                                        <div className="mb-1"><button className='a' onClick={resentotpfunct}>Resend OTP?</button></div>
                                                    </div>
                                                    <div className="text-left mt-0 ml-0">
                                                        <div className="mb-1"><Link to="/admin/login">Back to Login</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </LoginLayout>
    )
}

export default OtpVerificationPage