import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';

const CustomRangepicker = (props) => {
    const [value, setValue] = useState([]);

    const startOfDay = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00
        return newDate;
    };

    const endOfDay = (date) => {
        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);  // Set time to the end of the day 23:59:59.999
        return newDate;
    };

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [startOfDay(new Date()), endOfDay(new Date())] // Today’s full day range
        },
        {
            label: 'Yesterday',
            value: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))] // Yesterday’s full day range
        },
        {
            label: 'Last 7 days',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())] // Last 7 days full range
        }
    ];

    useEffect(() => {
        setValue([]);
    }, [props.resetdate])

    function handleChange(daterange) {
        // console.log("daterange--->",daterange,daterange[1]);
        
        if (daterange && daterange.length == 2) {
            props.GetDateRange({ start: startOfDay(daterange[0]), end: endOfDay(daterange[1]) });
            setValue(daterange);
        } else {
            props.GetDateRange({});
            setValue([]);
        }
    }

    return (
        <>
            <div className="form-group mb-0 rangepicker_container">
                <i className="fa fa-calendar glyphicon glyphicon-calendar calender_icon"></i>
                <DateRangePicker
                    value={value}
                    placement='bottomEnd'
                    format="dd-MM-yyyy"
                    placeholder={"Date"}
                    cleanable={false}
                    onChange={handleChange}
                    showHeader={false}
                    ranges={predefinedBottomRanges}

                />
            </div>
        </>
    )
}

export default CustomRangepicker;