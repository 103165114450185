import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import PageHeaders from '../../components/common/pageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { GetTempDetail } from '../../services/emailtemplatesservice'
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, formateDate } from '../../helpers/commonFunctions'
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader';


const ViewEmailTemp = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [
        {
            title: "Dashboard",
            url: "/admin/dashboard"
        },
        {
            title: "Email Templates",
            url: "/admin/email-templates/1"
        },
        {
            title: "View",
            url: ""
        },
    ]

    useEffect(() => {
        GetTempDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const capitalizeLetter = (str) => {
        return str
            .split(',') 
            .map(option => option.trim()) 
            .map(option => option.charAt(0).toUpperCase() + option.slice(1)) 
            .join(', '); 
    };

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Email Template</h6>
                                </div>
                                {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Title </th>
                                                    <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Type </th>
                                                    <td>{showdefault && showdefault.type ? showdefault.type : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Slug</th>
                                                    <td>{showdefault && showdefault.slug ? showdefault.slug : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Subject </th>
                                                    <td><div className="editorcontant">{showdefault && showdefault.subject ? showdefault.subject : "N/A"}</div></td>
                                                </tr>
                                                <tr>
                                                    <th>Option </th>
                                                    <td><div className="editorcontant">{showdefault && showdefault.options ? capitalizeLetter(showdefault.options) : "N/A"}</div></td>
                                                </tr>
                                                <tr>
                                                    <th>Description </th>
                                                    <td><div className="editorcontant">{toHtml(showdefault && showdefault.description ? showdefault.description : "N/A")}</div></td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showdefault && showdefault.status == 0 ? ("Inactive") : ("Active")}</td>
                                                </tr>
                                                <tr>
                                                    <th>Modified Date</th>
                                                    <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewEmailTemp