import React, { useEffect } from 'react'
import { globalLoader } from './helpers/global'

const CompanyLoginLayout = ({ children }) => {

  useEffect(()=>{
    globalLoader(true);
    Promise.all([
  
      import("./assets/company/css/bootstrap.min.css"),
      import("./assets/company/css/style.css"),
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })
  },[]);

  return (
    <div className='page main-signin-wrapper'>
      {children}
    </div>
  )
}

export default CompanyLoginLayout