import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCompanyToken } from './auth';

const CompanyPublicRoute = ({ component: Component }) => {
    const token = getCompanyToken();
    
    return (
        !token ? <Component /> : <Navigate to={{ pathname: "/company/dashboard" }} />
    )

}

export default CompanyPublicRoute;