import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import {io} from "socket.io-client";
import Layout from '../../companyLayout';

const SampleSocketCompany =()=>{

    const [message, setMessage]=useState("");
	const [recievedMessages, setRecievedMessages]= useState([]);

    const socketServerUrl = process.env.REACT_APP_SOCKET_SERVER_URL;
	const socket = useMemo(()=> io(socketServerUrl), []) ;

    useEffect(()=>{
        socket.on("connect", ()=>{
            console.log('connected', socket.id);
        })
        socket.on("welcome", (data)=>{
            console.log(data);
        })
        socket.on("message", (data)=>{
            console.log(data);
            setRecievedMessages(prevMessages => [...prevMessages, data]);
        })
        return ()=>{
            socket.disconnect();
        }
    },[])

    const sendEvent=()=>{                                   
        socket.emit('message', message);
    }

    return(
        <>
            <input type="text" value={message} onChange={(e)=> setMessage(e.target.value)} placeholder='write a message'/>
            <button className="btn btn-primary" onClick={sendEvent}>Send</button>
            <p>Messages from admin panel-------</p>
            {recievedMessages.map((message, index) => (
                <div key={index}>{message}</div>
            ))}
        </>
    )
}

export default SampleSocketCompany;