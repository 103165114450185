import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import { GetBrandDetail } from '../../services/brandService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import Swal from 'sweetalert2'


const ViewBrand = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const location = useLocation()
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const companyId = queryParams.get('id');

    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{ title: "Company", url: "/admin/company/list/1" },{title: "Brands",url:  `/admin/company/brands/list/${companyId}?id=1`},{title: "View",url: ""}]

    useEffect(() => {
        GetBrandDetail({id: params.id, company_id: companyId}).then(data => {
            // setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            if (data && data.data && data.data.response) {
                setShowDefault(data.data.response);
            } else {
                setShowDefault([]);
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: 'Brand does not exist',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                })
                navigate(`/admin/company/brands/list/${companyId}?id=1`)
            }
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])
    


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Brand</h6>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Logo</th>
                                                    <td>{showDefault && showDefault.logo ? <img src={showDefault.logo} style={{height: "100px" }}/> : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? showDefault.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Short description</th>
                                                    <td>{showDefault && showDefault.short_description ? showDefault.short_description : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Long description</th>
                                                    <td>
                                                    <div className="editorcontant">{toHtml(showDefault && showDefault.long_description ? showDefault.long_description : "N/A")}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status == 0 ? ("Inactive") : ("Active")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewBrand