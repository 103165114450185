import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { GetSingleSubscription } from '../../services/subscriptionService'
import { GetSingleDevice } from '../../services/devicesService'

const ViewDevice = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Devices Management",url: "/admin/devices-management/list/1"},{title: "View",url: ""}]

    useEffect(() => {
        GetSingleDevice({id: params.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Subscription</h6>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Hardware Used</th>
                                                    <td>{showDefault && showDefault.hardware_used ? capitalizeAllFirstLetters( showDefault.hardware_used) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Reference Id</th>
                                                    <td>{showDefault && showDefault.ref_id ? showDefault.ref_id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Serial Number</th>
                                                    <td>{showDefault && showDefault.serial_number ? showDefault.serial_number : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company</th>
                                                    <td>{showDefault && showDefault.company ? capitalizeAllFirstLetters(showDefault.company.name) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status ? "Active" : "Inactive"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewDevice