import axiosInstance from "../../../utils/axiosCompany";
const path = "company/uom";

export const AddUom = async (values) => {
    return await axiosInstance().post(`${path}/add-uom`, values);
}

export const ListUom = async (values) => {
    return await axiosInstance().post(`${path}/list-uom`, values);
}

export const getAllUoms = async (values) => {
    return await axiosInstance().post(`${path}/get-all-uom`, values);
}

export const DeleteUom = async (values) => {
    return await axiosInstance().post(`${path}/delete-uom`, values);
}

export const UomDetails = async (values) => {
    return await axiosInstance().post(`${path}/get-single-uom`, values);
}

export const UpdateUom = async (values) => {
    return await axiosInstance().post(`${path}/update-uom`, values);
}