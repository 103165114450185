import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import { getBrandId } from '../../helpers/commonFunctions';
import { GetAggregatorAllDetails } from '../../services/company_panel/setting/aggregatorConfigurationService';
import { ToastContainer } from 'react-toastify';
import { CreateRefundConfiguration, getRefundConfiguration } from '../../services/company_panel/refund.services';
import Swal from 'sweetalert2';
import * as yup from 'yup';


const RefundConfiguration = () => {

    const breadcrumbs = [
        {
            title: "Refund ",
            url: "/company/transaction/refund/list/1"
        },
        {
            title: "Configuration",
            url: ""
        },
    ]

    const [orderModeData, setOrderModesData] = useState([]);
    const [aggregatorData, setAggregatorData] = useState([]);

    const [showDefault, setShowDefault] = useState([])
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        // if (currentBrand != getBrandId()) {
        //   navigate('/company/crm/coupons/coupon/list/1')
        // }
    });


    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])

    useEffect(() => {
        GetAggregatorAllDetails({ brand_id: getBrandId() }).then(data => {
            setAggregatorData(data && data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])

    useEffect(() => {
        getRefundConfiguration({ brand_id: getBrandId() }).then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const validationSchema = yup.object().shape({
        order_mode: yup.array().of(
            yup.object().shape({
                time: yup
                    .string()
                    .trim()
                    .notRequired()
                    .nullable()
                    .test('is-valid-number', 'Time must be a valid number', (value) => {
                        if (value === '' || value === null || !value) return true;
                        const isValidNumber = /^\d+$/.test(value); // Only numeric characters allowed
                        return isValidNumber;;
                        // const numberValue = parseFloat(value);
                        // return Number.isInteger(numberValue) && !isNaN(numberValue);
                    })
                    .test('max-time', 'Time must between 0 to 120 minutes', (value) => {
                        if (value === '' || value === null || !value) return true;

                        const numberValue = parseFloat(value);
                        return numberValue >= 0 && numberValue <= 120;
                    }),
            })
        ),

        aggregator: yup.array().of(
            yup.object().shape({
                time: yup
                    .string()
                    .trim()
                    .notRequired()
                    .nullable()
                    .test('is-valid-number', 'Time must be a valid number', (value) => {
                        if (value === '' || value === null || !value) return true;
                        const isValidNumber = /^\d+$/.test(value); // Only numeric characters allowed
                        return isValidNumber;;
                        //   const numberValue = parseFloat(value);
                        //   return Number.isInteger(numberValue) && !isNaN(numberValue); 
                    })
                    .test('max-time', 'Time must between 0 to 120 minutes', (value) => {
                        if (value === '' || value === null || !value) return true;

                        const numberValue = parseFloat(value);
                        return numberValue >= 0 && numberValue <= 120;
                    }),
            })
        ),

    });


    const formik = useFormik({
        initialValues: {
            // order_mode: orderModeData.map((orderMode) => ({ type: "order_mode", id: orderMode.id, time: '' })),
            // aggregator: aggregatorData.map(aggregator => ({type: "aggregator", id: aggregator.id, time: '' })),
            order_mode: orderModeData?.map((orderMode) => {
                const matchingResponse = showDefault?.find(
                    (res) => res.type === "order_mode" && res.order_mode_id === orderMode.id
                );

                return {
                    type: "order_mode",
                    id: orderMode.id,
                    time: matchingResponse ? matchingResponse.time : '',
                };
            }),
            aggregator: aggregatorData?.map((aggregator) => {
                const matchingResponse = showDefault && showDefault?.find(
                    (res) => res.type === "aggregator" && res.aggregator_id === aggregator.id
                );

                return {
                    type: "aggregator",
                    id: aggregator.id,
                    time: matchingResponse ? matchingResponse.time : '',
                };
            }),
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            let formData = new FormData();
            const refundConfig = [...values.order_mode, ...values.aggregator];
            formData.append('refund_configuration', JSON.stringify(refundConfig));
            formData.append('brand_id', getBrandId());
            CreateRefundConfiguration(formData).then(response => {
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    });

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <Breadcrums data={breadcrumbs} />
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className="active"><Link to={`/company/transaction/refund/configuration`} className="btn btn-primary">Refund Configuration</Link></li>
                                <li className="ms-3"><Link to={`/company/transaction/refund/reasons/list/1`} className="btn">Reasons</Link></li>
                            </ul>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row-sm">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="row row-sm">
                                        {orderModeData.map((orderMode, index) => (
                                            <div key={index} className='col-lg-6 text-center form-group mt-3'>
                                                <label htmlFor={`order-mode-${index}`} className='text-left d-flex'>
                                                    {orderMode.name} (Minutes)
                                                </label>
                                                <input
                                                    name={`order_mode[${index}].time`}
                                                    id={`order-mode-${index}`}
                                                    type='text'
                                                    placeholder={`Enter ${orderMode.name} Refund Time`}
                                                    className='form-control'
                                                    value={formik?.values?.order_mode[index]?.time || ''}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        const updatedOrderModes = [...formik.values.order_mode];
                                                        updatedOrderModes[index] = {
                                                            ...updatedOrderModes[index],
                                                            time: e.target.value,
                                                        };
                                                        formik.setFieldValue('order_mode', updatedOrderModes);
                                                    }}
                                                />
                                                <span className='text-danger d-flex text-left'>
                                                    {formik.errors?.order_mode?.[index]?.time && formik.touched?.order_mode?.[index]?.time && formik.errors?.order_mode?.[index]?.time}
                                                </span>
                                            </div>
                                        ))}

                                        {aggregatorData?.length > 0 ?
                                            <h2 className='text-left d-flex mb-2 mt-4'>Outsource</h2> : null}
                                        {aggregatorData.map((aggregator, index) => (
                                            <div key={index} className='col-lg-6 text-center form-group mt-3'>
                                                <label htmlFor={`aggregator-${index}`} className='text-left d-flex'>
                                                    {aggregator.name} (Minutes)
                                                </label>
                                                <input
                                                    name={`aggregator[${index}].time`}
                                                    id={`aggregator-${index}`}
                                                    type='text'
                                                    placeholder={`Enter ${aggregator.name} Refund Time`}
                                                    className='form-control'
                                                    value={formik?.values?.aggregator[index]?.time || ''}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        const updatedAggregator = [...formik.values.aggregator];
                                                        updatedAggregator[index] = {
                                                            ...updatedAggregator[index],
                                                            time: e.target.value,
                                                        };
                                                        formik.setFieldValue('aggregator', updatedAggregator);
                                                    }}
                                                />
                                                <span className='text-danger d-flex text-left'>
                                                    {formik.touched?.aggregator?.[index]?.time && formik.errors?.aggregator?.[index]?.time}
                                                </span>
                                            </div>
                                        ))}

                                        <div className="mt-4">
                                            <button className="btn btn-primary mr-2" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <ToastContainer
                        position="top-right"
                        hideProgressBar={false}
                        autoClose={true | 1000}
                        newestOnTop={true}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </div>
            </div>
        </>
    );
}

export default RefundConfiguration