import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import $ from "jquery";
import { SpecialCharacter, TrimText, capitalizeAllFirstLetters, capitalizeFirstLetter, checkPermission, formateDate, getBrandId, getCompany, removeCommas, removeSpecialCharacter, writeMyExcel } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import CustomPagination from '../../components/company_panel/common/custompagination';
import StatusFilter from '../../components/company_panel/common/statusFilter';
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { CUSTOMER_TYPE, LOADER_TIME, showFilterlist } from '../../utils/Constants';
import Loader from '../../components/company_panel/common/loader';
import { ChangeStatusCoupon, DeleteCoupon, ListCoupon } from '../../services/company_panel/coupons.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GetOutletAllList } from '../../services/company_panel/outletService';
import notFound from '../../assets/company/img/no-record-found.png'


const CouponsList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [customerType] = useState(
        Object.keys(CUSTOMER_TYPE).map((item) => {
            return {
                label: item,
                value: CUSTOMER_TYPE[item],
            };
        })
    );

    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            ListCoupon(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
        $("#resetFilter")[0].selectedIndex = 0;
    }

    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row);
                DeleteCoupon(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Coupon deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }



    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeStatusCoupon(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    const viewFunction = (row) => {
        navigate(`/company/crm/coupons/view/${row}`)
    }
    const editFunction = (row) => {
        navigate(`/company/crm/coupons/${params.id}/edit/${row.id}`)
    }

    const copyFunction = (row) => {
        navigate(`/company/crm/coupons/${params.id}/edit/${row.id}?copy=true`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    
    const headerMapping = {
        name: 'Name',
        coupon_code: 'Coupon Code',
        type: 'Type',
        maximum_discount_amount: 'Max Discount Amount',
        minimum_applicable_order_amount: 'Min Order Amount',
        usage_condition: 'Usage Condition',
        usage_per_customer: 'Usage Per Customer',
        customer_type: 'Customer Type',
        authentication_type: 'Authentication Type',
        authenticate_user: 'Authenticate User',
        usage_condition_number: 'Usage Condition Number',
        usage_per_customer_number: 'Usage Per Customer Number',
        start_time: 'Start Time',
        end_time: 'End Time',
        start_date: 'Start Date',
        end_date: 'End Date',
        created_at: 'Created At',
        updated_at: 'Updated At',
        week: 'Week',
        brand_id: 'Brand Name',
        percentage: 'Percentage(Discount)',
        flat_discount: 'Flat Amount(Discount)',
        applicable_on: 'Applicable On',
        outlet_id: 'Outlet Name',
        menu_item_id: 'Menu Item Name',
    };

    const formatValue = (value) => {
        if (value === null || value === undefined || value === "") {
            return 'N/A';
        }
        return value.toString().replace(/_/g, ' ');
    };
    const handleExport = () => {
          const formData = new FormData()
            formData.append("page", 1)
            formData.append("per_page", dataLength)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            ListCoupon(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
            const transformedItem = {};
            const Name = item?.name && item?.name ? capitalizeFirstLetter(item?.name) : 'N/A';
            const couponCode = item.coupon_code ? item.coupon_code : 'N/A';
            const type = item.type ? SpecialCharacter(capitalizeFirstLetter(item.type)) : 'N/A';
            const maximumDiscountAmount = item.maximum_discount_amount ? item.maximum_discount_amount + " " + "SAR": 'N/A';
            const minimumApplicableOrderAmount = item.minimum_applicable_order_amount ? item.minimum_applicable_order_amount + " " + "SAR" : 'N/A';
            const usageCondition = item.usage_condition ? SpecialCharacter(capitalizeFirstLetter(item.usage_condition)) : 'N/A';
            const usagePerCustomer = item.usage_per_customer ? SpecialCharacter(capitalizeFirstLetter(item.usage_per_customer)) : 'N/A';
            const customerType = item.customer_type ? (capitalizeFirstLetter(item.customer_type)) : 'N/A';
            const authenticationType = item.authentication_type ? SpecialCharacter(capitalizeFirstLetter(item.authentication_type)) : 'N/A';
            // const authenticateUser = item.authenticate_user ? (item.authenticate_user) : 'N/A';
            const authenticateUser = item?.authenticate_user?.map(item => item?.first_name + " " + item?.last_name).join(', ') || 'N/A';
            const usageConditionNumber = item.usage_condition_number ? item.usage_condition_number : 'N/A';
            const usagePerCustomerNumber = item.usage_per_customer_number ? item.usage_per_customer_number : 'N/A';
            const startTime = item.start_time ? item.start_time : 'N/A';
            const endTime = item.end_time ? item.end_time : 'N/A';
            const startDate = formateDate(item?.start_date);
            const endDate = formateDate(item?.end_date);
            const CreatedDate = formateDate(item?.created_at);
            const updatedDate = formateDate(item?.updated_at);
            const week = item?.week ? item.week.join(', ') : 'N/A';
            const brandName = item?.brand?.name ? capitalizeFirstLetter(item?.brand?.name) : 'N/A'
            const percentageDiscount = item?.type === 'percentage' ? (item?.percentage + " " + "%") : 'N/A'
            const flatDiscount = item?.type === 'flat' ? (item?.percentage + " " + "SAR") : 'N/A'
            const ApplicableName = item?.applicable_on?.map(item => item?.name).join(', ') || 'N/A';
            const outletName = item?.outlet_id?.map(item => item?.outlet_name).join(', ') || 'N/A';
            const MenuItemName = item?.menu_items?.map(item => item?.item_name).join(', ') || 'N/A';
            for (const key in headerMapping) {
                if (headerMapping.hasOwnProperty(key)) {
                    const newKey = headerMapping[key];
                    if (key === "name") {
                        transformedItem[newKey] = Name;
                    } else if (key === "coupon_code") {
                        transformedItem[newKey] = couponCode;
                    }
                    else if (key === "type") {
                        transformedItem[newKey] = type;
                    }
                    else if (key === "maximum_discount_amount") {
                        transformedItem[newKey] = maximumDiscountAmount;
                    }
                    else if (key === "minimum_applicable_order_amount") {
                        transformedItem[newKey] = minimumApplicableOrderAmount;
                    }
                    else if (key === "usage_condition") {
                        transformedItem[newKey] = (usageCondition);
                    }
                    else if (key === "usage_per_customer") {
                        transformedItem[newKey] = (usagePerCustomer);
                    }
                    else if (key === "customer_type") {
                        transformedItem[newKey] = (customerType);
                    }
                    else if (key === "authentication_type") {
                        transformedItem[newKey] = authenticationType;
                    }
                    else if (key === "authenticate_user") {
                        transformedItem[newKey] = capitalizeFirstLetter(authenticateUser);
                    }
                    else if (key === "usage_condition_number") {
                        transformedItem[newKey] = usageConditionNumber;
                    }
                    else if (key === "usage_per_customer_number") {
                        transformedItem[newKey] = usagePerCustomerNumber;
                    }
                    else if (key === "start_time") {
                        transformedItem[newKey] = startTime;
                    }
                    else if (key === "end_time") {
                        transformedItem[newKey] = endTime;
                    }
                    else if (key === "start_date") {
                        transformedItem[newKey] = startDate;
                    }
                    else if (key === "end_date") {
                        transformedItem[newKey] = endDate;
                    }
                    else if (key === "created_at") {
                        transformedItem[newKey] = CreatedDate;
                    }
                    else if (key === "updated_at") {
                        transformedItem[newKey] = updatedDate;
                    }
                    else if (key === "week") {
                        transformedItem[newKey] = capitalizeFirstLetter(week);
                    }
                    else if (key === "brand_id") {
                        transformedItem[newKey] = brandName;
                    }
                    else if (key === 'percentage') {
                        transformedItem[newKey] = percentageDiscount;
                    }
                    else if (key === 'flat_discount') {
                        transformedItem[newKey] = flatDiscount;
                    }
                    else if (key === 'applicable_on') {
                        transformedItem[newKey] = capitalizeFirstLetter(ApplicableName);
                    }
                    else if (key === 'outlet_id') {
                        transformedItem[newKey] = capitalizeFirstLetter( outletName);
                    }
                    else if (key === 'menu_item_id') {
                        transformedItem[newKey] = capitalizeFirstLetter( MenuItemName);
                    }
                    else {
                        transformedItem[newKey] = formatValue(item[key]);
                    }
                }
            }
            return transformedItem;
        });
        const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'coupons.xlsx');
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
    }).catch(error => {
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
        console.log("error ====> ", error);
    })
    };

    const handleFilterChange = (e) => {
        if (e.target.value) {
            prepareSearch("type", e.target.value);
        } else {
            prepareSearch("type", "");
        }
    };


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Coupons</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button className="btn btn-primary mr-2 ms-1" onClick={handleExport} disabled={dataLength === 0}> Export Coupon</button>
                        {checkPermission('store_coupons', 'add') ?
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/crm/coupons/coupon/add`)}>Create Coupon</button>
                        :''}
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                            {checkPermission('store_coupons', 'view') ?
                                <li className="active"><Link to={`/company/crm/coupons/coupon/list/${1}`} className="btn btn-primary">Coupons</Link></li>
                                : ''}
                                  {checkPermission('store_promos', 'view') ?
                                <li className="ms-3"><Link to={`/company/crm/coupons/promos/list/${1}`} className="btn">Promos</Link></li>
                                : '' }
                            </ul>
                        </div>
                    </div>
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <div className="">
                                            <select
                                                className="form-control cp"
                                                onChange={handleFilterChange}
                                                id="resetFilter"
                                                style={{ width: "180px" }}
                                            >
                                                <option value="">Customer Type</option>
                                                {customerType &&
                                                    customerType.length > 0 &&
                                                    customerType.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="12%">
                                            <div className='sorting_column'>
                                                <span>Coupon Code</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="coupon_code" />
                                            </div>
                                        </th>
                                        <th width="13%">
                                            <div className='sorting_column'>
                                                <span>Type </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="type" />
                                            </div>
                                        </th>
                                        {/* <th width="13%">
                                            <div className='sorting_column'>
                                                <span>Maximum Discount Amount</span>
                                            </div>
                                        </th> */}
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Start Date  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="start_date" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>End Date  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="end_date" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_type" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='status_head' width="10%">Status</th>
                                        <th className='action_head text-end' width="10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={9}><Loader /></td>
                                        </tr> : <>
                                            {list?.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeAllFirstLetters(TrimText(row.name, 30))}</td>
                                                    <td>{(row.coupon_code)}</td>
                                                    {/* <td>{row.type ? SpecialCharacter(capitalizeAllFirstLetters(row.type)) : 'N/A'}</td>
                                                     */}
                                                    <td> {row.type ? (row.type === 'item_free' ? ('Free Item') : (SpecialCharacter(capitalizeAllFirstLetters(row.type)))) : ("N/A")} </td>
                                                    {/* <td>{row.maximum_discount_amount ? row.maximum_discount_amount + " " + "SAR" : '0 SAR'}</td> */}
                                                    <td>{row.start_date ? formateDate(row.start_date) : 'N/A'}</td>
                                                    <td>{row.end_date ? formateDate(row.end_date) : 'N/A'}</td>
                                                    <td>{row.customer_type ? capitalizeAllFirstLetters(row.customer_type) : 'N/A'}</td>
                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_coupons', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                :''}
                                                                  {checkPermission('store_coupons', 'edit') ?
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                :''}
                                                                {checkPermission('store_coupons', 'delete') ?
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                :''}
                                                                  {checkPermission('store_coupons', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyFunction(row) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy Coupons</div></li>
                                                                :''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={9} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                    <b>Total Records : {dataLength ? dataLength : "0"}</b>
                                </div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "coupons", path: "/company/crm/coupons/coupon/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CouponsList