import axiosInstance from "../../../utils/axiosCompany";

export const CreateTax = async (values) => {
    return await axiosInstance().post("company/tax-configuration/add-tax", values);
}

export const GetTaxList = async (values) => {
    return await axiosInstance().post("company/tax-configuration/get-all-taxes", values);
}

export const GetAllActiveTaxes = async (values) => {
    return await axiosInstance().post("company/tax-configuration/get-all-active-taxes", values);
}

export const GetEditTax  = async (values) => {
    return await axiosInstance().put("company/tax-configuration/update-tax", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/tax-configuration/change-status", values)
}

export const DeleteTax = async (id) => {
    return await axiosInstance().delete(`company/tax-configuration/delete-tax/${id}`)
}

export const GetTaxDetail = async (id) => {
    return await axiosInstance().get(`company/tax-configuration/get-single-tax/${id}`);
};

export const GetTaxGroupAllList = async (brand_id) => {
    return await axiosInstance().get(`company/tax-configuration/get-tax-groups/${brand_id}`);
};



