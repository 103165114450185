import axiosInstance from "../../utils/axiosCompany";

export const CreatePurchaseOrder = async (values) => {
    return await axiosInstance().post("company/purchase-order/add-purchase-order", values);
}

export const GetPurchaseOrderList = async (values) => {
    return await axiosInstance().post("company/purchase-order/list-purchase-orders", values);
}

export const GetPurchaseOrderDetail = async (values) => {
    return await axiosInstance().post(`company/purchase-order/get-single-purchase-order`,values);
};

export const changeStatus = async (values) => {
    return await axiosInstance().post("company/purchase-order/change-status", values);
}

export const changePurchaseOrderStatus = async (values) => {
    return await axiosInstance().post("company/purchase-order/change-order-status", values);
}

export const deletePurchaseOrder = async (values) => {
    return await axiosInstance().post("company/purchase-order/delete-purchase-order", values);
}

export const confirmPurchaseOrderInventories = async (values) => {
    return await axiosInstance().post("company/purchase-order/confirm-purchase-order", values);
}
export const orderCancel = async (values) => {
    return await axiosInstance().post("company/purchase-order/cancel-order", values);
}
export const EmailSend = async (values) => {
    return await axiosInstance().post(`company/purchase-order/send-email`,values);
};