import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { GetSubscriptionFromCompanyId } from '../../services/subscriptionService'
import ViewAllDetails from './viewAllDetails'

const CompanySubscription = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Companies",url: "/admin/company/list/1"},{title: "Subscription",url: ""}]

    useEffect(() => {
        GetSubscriptionFromCompanyId({company_id: params.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    function stripHtml(html) {
        // Create a new temporary DOM element
        const tempDiv = document.createElement('div');
        // Set the innerHTML of the temporary element to the provided HTML string
        tempDiv.innerHTML = html;
        // Get the text content of the temporary element
        return tempDiv.textContent || tempDiv.innerText || '';
    }

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <ViewAllDetails tab='subscription'/>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? showDefault.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Amount</th>
                                                    <td>{showDefault && showDefault.amount ? showDefault.amount : "N/A"} SAR</td>
                                                </tr>
                                                <tr>
                                                    <th>Duration (months)</th>
                                                    <td>{showDefault && showDefault.month ? showDefault.month : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Access and Benefits</th>
                                                    <td>{showDefault && showDefault.access_and_benefits ? stripHtml(showDefault.access_and_benefits) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status ? "Active" : "In-active"}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CompanySubscription