import axiosInstance from "../utils/axios";

export const CreateCompany = async (values) => {
    return await axiosInstance().post("admin/save-company", values);
}

export const GetCompanyList = async (values) => {
    return await axiosInstance().post("admin/list-company", values);
}

//without pagination
export const GetAllCompanies = async () => {
    return await axiosInstance().get("admin/get-all-companies");
}

export const GetEditCompany  = async (values) => {
    return await axiosInstance().post("admin/edit-company", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("admin/change-status", values)
}

export const GetDeleteCompany = async (values) => {
    return await axiosInstance().post("admin/delete-company", values)
}

export const GetCompanyDetail = async (id) => {
    return await axiosInstance().get(`admin/get-edit-company?id=${id}`);
};




export const executeMigrationsChangesForCompany= async () => {
    return await axiosInstance().post("admin/execute-migrations-changes-for-companies");
}

