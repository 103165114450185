import React, { useEffect, useState } from 'react'
import LoginLayout from '../../loginlayout'
import {useNavigate, useParams ,Link} from 'react-router-dom'
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Getforgetpassword } from '../../services/LoginServices';
import { GetLogo } from '../../services/settingsService';
// import Logo from '../../assets/img/brand/Mask Group 1.png'


const ForgotPwdPage = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState('false')
    const {tab} = useParams() 

    const [showDefault, setShowDefault] = useState({});

    const params = useParams()

    useEffect(() => {
        GetLogo(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <LoginLayout>
            <Formik
                initialValues={{ email: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", values.email);
                    Getforgetpassword(formData).then(data => {
                        if(data){
                            if (data && data.data && data.data.statuscode === 200) {
                                toast(data.data.message);
                                resetForm()
                                setTimeout(() => {
                                    setLoader(false)
                                    navigate(`/admin/forgot/otpverification/${values.email}?type=forget_password`)
                                }, 2000);
                            } else if (data && data.data && data.data.statuscode !== 200) {
                                toast(data.data.message);
                                setTimeout(() => {
                                    setLoader(false)
                                }, 2000);
                            }
                        }else{
                            setLoader(false);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
              
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className="row signpages text-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row row-sm" style={{height: "28rem"}}>
                                    <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                    <div className="m-5 p-5 pos-absolute align-content-center" style={{height: "22rem"}}>
                                            {/* <img src={Logo} className="ht-100 mb-2" alt="logo" /> */}
                                            <img src={showDefault.logo} className="mb-4" alt="logo" />
                                            {/* <h2 className="mt-4 text-white">Point of sale</h2> */}
                                            <div className="clearfix"></div>
                                            <h5 className="mt-2 text-white">Forgot Password</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form align-content-center">
                                        <div className="container-fluid">
                                            <div className="row row-sm">
                                                <div className="card-body mt-2 mb-2">
                                                    <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
                                                    <div className="clearfix"></div>
                                                    <h5 className="text-left mb-2">Forgot Password</h5>
                                                    <p className="mb-4 text-muted tx-13 ml-0 text-left">OTP will be sent to your Registered Email</p>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group text-left">
                                                            <label>Email</label>
                                                            <input className={"form-control" + (errors.email && touched.email ? "" : "")}
                                                                placeholder="Enter your email"
                                                                type="text"
                                                                name="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                                autoComplete="off" />
                                                            <span className='text-danger text-left d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                        </div>
                                                        <button className={"btn ripple btn-main-primary btn-block signbtn" + (loader === true ? " disabled" : "")} type="submit">Send OTP</button>
                                                        {loader === true ?
                                                            <div className="spinner-border text-primary mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
                                                    </form>
                                                    <div className="card-footer border-top-0 pl-0 mt-3 text-left ">
                                                        <p className='mb-2'>Did you remembered your password?</p>
                                                        <p className="mb-0">Try to <Link to="/admin/login">Login</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik> 
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true|1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </LoginLayout>
    )
}

export default ForgotPwdPage