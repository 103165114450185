import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeAllFirstLetters, capitalizeFirstLetter, checkPermission, getBrandId, getCompany, removeHyphens } from '../../../helpers/commonFunctions';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import * as yup from 'yup';
import { ChangeMenuItemsStatus, DeleteMenuItems, ExportMenuItems, GetMenuItemsList, ImportedMenuItems } from '../../../services/company_panel/menuItems';
import CustomRangepicker from '../../../components/company_panel/common/rangepicker';
import StatusFilter from '../../../components/company_panel/common/statusFilter';
import { showFilterlist } from '../../../utils/Constants';
import notFound from '../../../assets/company/img/no-record-found.png'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SampleFileXlsx from '../../../assets/company/menuItemSampleFiles/MenuItemsSample.xlsx';
import SampleFileXls from '../../../assets/company/menuItemSampleFiles/MenuItemsSampleFile.xls';
import SampleFileCsv from '../../../assets/company/menuItemSampleFiles/MenuItemsSample.csv';


const MenuItemList = () => {
    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [exportData, setExportData] = useState([]);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            GetMenuItemsList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged])

    // let uoms=[];
    const [uoms, setUoms]=useState([]);
    useEffect(() => {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append('brand_id', getBrandId());
            ExportMenuItems(formData).then(data => {
                // setDataLength(data.data.response.count);
                setUoms(data && data.data && data.data.response ? data.data.response.uoms : []);
                setExportData(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
    }, [isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteMenuItems(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error)
                })
            }
        })
    }

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeMenuItemsStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error)
                })
            }
        })
    }

    const handleEdit = (id) => {
        navigate(`/company/menu/menu/menu_items/edit/${id}`);
    }
    const copyOutletFunction = (row) => {
        navigate(`/company/menu/menu/menu_items/edit/${row.id}?copy=true`);
    }
    const handleView = (id) => {
        navigate(`/company/menu/menu/menu_items/view/${id}`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const formatDefaultPrices = (defaultPrices) => {
        if (!defaultPrices || defaultPrices.length === 0) return 'N/A';
        return defaultPrices.map(price => `${price.price_slug}: ${price.price}`).join('; ');
    };

    // const handleExport = () => {
    //     const printData = exportData.map((item) => {
    //         const transformedItem = {};
    //         const PrimaryCategoryName = item?.menu_category?.menu_category?.name ? capitalizeFirstLetter(item?.menu_category?.menu_category?.name) : 'N/A';
    //         const secondaryCategoryName = item?.menu_category && item?.menu_category ? capitalizeFirstLetter(item.menu_category?.name) : 'N/A';
    //         const defaultPrices = item?.menu_item_outlet_default_prices ? formatDefaultPrices(item.menu_item_outlet_default_prices) : 'N/A';
    
    //         for (const key in headerMapping) {
    //             if (headerMapping.hasOwnProperty(key)) {
    //                 const newKey = headerMapping[key];
    //                 if (key === "menu_category") {
    //                     transformedItem[newKey] = PrimaryCategoryName;
    //                 } else if (key === "menu_secondary_category") {
    //                     transformedItem[newKey] = secondaryCategoryName;
    //                 } else if (key === "pricing_type") {
    //                     transformedItem[newKey] = formatValue(removeHyphens(capitalizeFirstLetter(item?.pricing_type)));
    //                 } else if (key === "menu_item_outlet_default_prices") {
    //                     transformedItem[newKey] = defaultPrices;
    //                 } else {
    //                     transformedItem[newKey] = formatValue(item[key]);
    //                 }
    //             }
    //         }
    //         return transformedItem;
    //     });
    //     const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(blob, 'menu-items.xlsx');
    // };

    const headerMapping = {
        "item_name": "Name",
        "item_name_ar": "Name Arabic",
        "menu_category": "Primary Category",
        "menu_secondary_category": "Secondary Category",
        "sku": "SKU",
        "calories":"Calories",
        "default_price":"Default Price",
        "updates_type": "Update Type",
        "pricing_type": "Pricing Type",
        "tax_configuration" : "Tax Group",
        "menu_item_outlet_default_prices" : "Default Outlet Prices",
        "menu_item_outlet_specific_prices" : "Specific Outlet Prices",
        "menu_item_add_ons": "Menu Items Addon",
        "menu_item_reciepes":"Menu Items Recipes",
    };

    const formatValue = (value) => {
        if (value === null || value === undefined || value === "") {
            return 'N/A';
        }
        return value.toString().replace(/_/g, ' ');
    };
  

    const handleExport = () => {
        const printData = exportData.map((item) => {
            const transformedItem = {};
            const PrimaryCategoryName = item?.menu_category?.menu_category?.name ? capitalizeFirstLetter(item?.menu_category?.menu_category?.name) : 'N/A';
            const secondaryCategoryName = item?.menu_category && item?.menu_category ? capitalizeFirstLetter(item.menu_category?.name) : 'N/A';
            const tax_configuration = item?.tax_configuration && item?.tax_configuration ? capitalizeFirstLetter(item.tax_configuration?.name) : 'N/A';
    
            for (const key in headerMapping) {
                if (headerMapping.hasOwnProperty(key)) {
                    const newKey = headerMapping[key];
                    if (key === "menu_category") {
                        transformedItem[newKey] = PrimaryCategoryName;
                    } else if (key === "menu_secondary_category") {
                        transformedItem[newKey] = secondaryCategoryName;
                    } else if (key === "pricing_type") {
                        transformedItem[newKey] = formatValue(removeHyphens(capitalizeFirstLetter(item?.pricing_type)));
                    } 
                    else if (key === "tax_configuration") {
                        transformedItem[newKey] = (tax_configuration);
                    }
                    else if (key === "menu_item_outlet_default_prices") {
                        transformedItem[newKey] = 'See separate sheet';
                    } 
                    else if (key === "menu_item_outlet_specific_prices") {
                        transformedItem[newKey] = 'See separate sheet';
                    }
                    else if (key === "menu_item_add_ons") {
                        transformedItem[newKey] = 'See separate sheet';
                    }
                    else if (key === "menu_item_reciepes") {
                        transformedItem[newKey] = 'See separate sheet';
                    }
                    else {
                        transformedItem[newKey] = formatValue(item[key]);
                    }
                }
            }
            return transformedItem;
        });
    
        const defaultPricesData = exportData.flatMap(item => {
            const menuItemName = item?.item_name
            return (item.menu_item_outlet_default_prices || []).map(price => ({
                'Menu Item Name': menuItemName, 
                'Name': capitalizeFirstLetter(removeHyphens(price.price_slug)),
                'Price Type': capitalizeFirstLetter(removeHyphens(price.price_type)),
                'Price': (price.price + " " +  "SAR")
            }));
        });

        const specificPricesData = exportData.flatMap(item => {
            const menuItemName = item?.item_name
            return (item.menu_item_outlet_specific_prices || []).map(price => ({
                'Menu Item Name': menuItemName, 
                'Name': price.price_slug ? capitalizeFirstLetter(removeHyphens(price.price_slug)) : 'N/A',
                'Price Type': capitalizeFirstLetter(removeHyphens(price.price_type)),
                'Price': price.price ? (price.price  + " " +  "SAR") : 'N/A',
            }));
        });

        const addOnData = exportData.flatMap(item => {
            const menuItemName = item?.item_name || 'N/A';
            return (item.menu_item_add_ons || []).map(addOn => {
                const addOnCategory = addOn?.menu_add_on_category || {};
                return {
                    'Menu Item Name': menuItemName,
                    'Add-On Category Name': capitalizeFirstLetter(removeHyphens(addOnCategory.name || 'N/A')),
                    'Min': addOn.min || 'N/A',
                    'Max': addOn.max || 'N/A',
                    'Free': addOn.free || 'N/A'
                };
            });
        });
        const recipeData = exportData.flatMap(item => {
            const menuItemName = item?.item_name || 'N/A';
        
            return (item.menu_item_reciepes || []).map(reciepe => {
                const qunatity = reciepe?.quantity
                const inventory = reciepe?.inventory || {};
                const usageUnit = inventory.inventory_unit_converisons?.find(conversion => conversion.unit_ty === "Usage Unit") || {};

                const uom = uoms.find(uom => uom.id == usageUnit.uom);
                return {
                    'Menu Item Name': menuItemName,
                    'Item Type': removeHyphens(capitalizeAllFirstLetters(inventory.item_type)) || 'N/A',
                    "Quantity" : qunatity || "N/A",
                    'Price': usageUnit.price || 'N/A',
                    'Calorie': usageUnit.calorie || 'N/A',
                    'Unit Of Measure': uom?.uom_code,
                };
            });
        });
    
        const ws1 = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
        const ws2 = XLSX.utils.json_to_sheet(defaultPricesData, { header: ['Menu Item Name','Name', 'Price Type', 'Price'] });
        const ws3 = XLSX.utils.json_to_sheet(specificPricesData, { header: ['Menu Item Name','Name', 'Price Type', 'Price'] });
        const ws4 = XLSX.utils.json_to_sheet(addOnData, { header: ['Menu Item Name','Add-On Category Name', 'Min', 'Max','Free'] });
        const ws5 = XLSX.utils.json_to_sheet(recipeData, { header: ['Menu Item Name','Item Type','Quantity','Price','Calorie','Unit Of Measure'] });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Menu Items');
        XLSX.utils.book_append_sheet(wb, ws2, 'Default Outlet Prices');
        XLSX.utils.book_append_sheet(wb, ws3, 'Specific Outlet Prices');
        XLSX.utils.book_append_sheet(wb, ws4, 'Menu Items Addon');
        XLSX.utils.book_append_sheet(wb, ws5, 'Menu Items Recipes');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'menu-items.xlsx');
    };
    

    const fileInputRef = useRef(null);

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("brand_id", localStorage.getItem("brand_id"))
        ImportedMenuItems(formData).then((response) => {
            setStatusUpdate(!statsUpdate);
            e.target.value = null;
            if (response.data.status === 1 || response.data.status === '1') {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                })
            }
            else if (response.data.status === 0 || response.data.status === '0') {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                })
            }
        }).catch((error) => {
            console.log("Error---->", error);
        })
    }

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-left'>
                    <div className='page-header-left'>
                        <h4>Menu</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='menuItem' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>
                                <div className='gap-2'>
                                    <div className='filter-header d-flex justify-content-between align-items-center'>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <div className="form-group mb-0 rangepicker_container">
                                                <input
                                                    type="search"
                                                    className="form-control"
                                                    value={globalSearch}
                                                    placeholder="Search"
                                                    onChange={(e) => { setGlobalSearch(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex gap-4 align-items-center' style={{ marginLeft: '10px' }} >
                                            <StatusFilter
                                                data={showFilterlist}
                                                prepareSearch={prepareSearch}
                                            />
                                            <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='page-header-left'></div>
                            <div className='page-header-right ms-auto'>
                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                    <div className="btn-group" role="group">
                                        <div class="dropdown">
                                            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Sample Files
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li><a className="dropdown-item" href={SampleFileCsv} download>Download csv file</a></li>
                                                <li><a className="dropdown-item" href={SampleFileXls} download>Download xls file</a></li>
                                                <li><a className="dropdown-item" href={SampleFileXlsx} download>Download xlsx file</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }}
                                />
                                <button className="btn btn-primary mr-2 ms-2" onClick={triggerFileInput}>Import Menu Items</button>
                                <button className="btn btn-primary mr-2 ms-2" onClick={handleExport}> Export Menu Items</button>
                                {checkPermission('store_menu_items', 'add') ?
                                <button className="btn btn-primary ms-2" type="button" onClick={(e) => navigate(`/company/menu/menu/menu_items/add`)}>Create Menu Item</button>
                                : '' }
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                    <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Item ID </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>SKU </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Name (arabic)</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_name_ar" />
                                            </div>
                                        </th>

                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Primary category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="primary_category" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Secondary category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="secondary_category" />
                                            </div>
                                        </th>
                                        <th className='status_head text-center' width="10%">Status</th>
                                        <th className='action_head text-end' width="10%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr> 
                                                    <td>#{row?.id}</td>
                                                    <td>{row?.sku}</td>
                                                    <td>{capitalizeFirstLetter(row?.item_name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.item_name_ar)}</td>
                                                    <td>{capitalizeFirstLetter(row?.menu_category?.menu_category?.name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.menu_category?.name)}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_menu_items', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { handleView(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View</div></li>
                                                                : '' }
                                                             {checkPermission('store_menu_items', 'edit') ?   
                                                                <li><div className='dropdown-item' onClick={() => { handleEdit(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                : ''}
                                                            {checkPermission('store_menu_items', 'delete') ?
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                : '' }
                                                            {checkPermission('store_menu_items', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy menu item</div></li>
                                                                : '' }

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record.</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>

                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/menu/menu/menu_items/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </div>
        </>
    );
}

export default MenuItemList