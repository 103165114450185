import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { GetOutletDetail, DeleteHardwareDevice } from '../../services/company_panel/outletService';
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getBrandId, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import Swal from 'sweetalert2'
import { GetSupplierDetail } from '../../services/company_panel/supplierService';
import { countries } from 'countries-list';

const SupplierView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Supplier", url: "/company/raw_materials/supplier_manage/list/1" }, { title: "Supplier Detail", url: "" }]
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/raw_materials/supplier_manage/list/${1}`);
        }
    });

    useEffect(() => {
        GetSupplierDetail({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [statsUpdate])

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteHardwareDevice(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Record deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError",error);
                })
            }
        })
    }
    const formatEmailToLowercase = (email) => {
        if (email && email.length) {
            return email.toLowerCase();
        } else {
            return "";
        }
    };

    return (
        <>

            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Suppliers</h4>
                </div>
                <div className="page-header-right ms-auto">

                </div>
            </div>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <h3 className="main-content-label mb-3">Supplier details</h3>
                            <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table table p-2 sss ">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Name</td>
                                                    <td>{showDefault && showDefault.name ? capitalizeFirstLetter(showDefault.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Email</td>
                                                    <td style={{
                                                        maxWidth: '90rem', 
                                                    }}>
                                                        {showDefault && showDefault.email ? formatEmailToLowercase(showDefault.email) : "N/A"}
                                                    </td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Phone number</td>
                                                    <td>
                                                        <td>{showDefault && showDefault.country_code ? '+' + showDefault.country_code : "N/A"} &nbsp;</td>
                                                        <td>{showDefault && showDefault.phone_number ? showDefault.phone_number : "N/A"}</td>
                                                    </td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Street address</td>
                                                    <td>{showDefault && showDefault.street_address ? capitalizeAllFirstLetters(showDefault.street_address) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>City</td>
                                                    <td>{showDefault && showDefault.city ? capitalizeAllFirstLetters(showDefault.city) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Zip code</td>
                                                    <td>{showDefault && showDefault.zip_code ? showDefault.zip_code : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Logo</td>
                                                    <td>
                                                        {showDefault.verified_id && (
                                                            <img src={showDefault.verified_id} alt="Verified" style={{ height: "100px", width: "100px" }} />
                                                        )}
                                                    </td>
                                                </div>
                                            </tr>
                                        </tbody>


                                    </table>
                                    {showDefault?.supplier_inventory_relations?.length > 0 && (<h2>Inventory</h2>)}
                                    <ol>
                                        {
                                            showDefault && showDefault.supplier_inventory_relations && showDefault.supplier_inventory_relations.length > 0 && showDefault.supplier_inventory_relations.map((item, index) => {

                                                return (
                                                    <li key={index}>
                                                        <span>{capitalizeFirstLetter(item.inventory.item_name)}</span>
                                                        {/* <button className="btn ripple btn-danger" type='button' onClick={() => deleteFunction(item.id)}>
                                                            <i className="ace-icon-solid ion-trash-a bigger-110 mx-1"></i>
                                                        </button> */}
                                                    </li>
                                                );
                                            })
                                        }
                                    </ol>

                                    <div className="">
                                        <Link to={`/company/raw_materials/supplier_manage/1/edit/${params.id}`} className="btn ripple btn-primary" type='button'>
                                            {/* <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i> */}
                                            Edit supplier
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-5 col-md-5 animation_fade"></div> */}
            </div>

        </>
    )
}

export default SupplierView