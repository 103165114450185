import axiosInstance from "../../utils/axiosCompany";

export const CreateAddOnCategory = async (values) => {
    return await axiosInstance().post("company/menu-add-on/create-category", values);
}

export const GetAddOnCategoryList = async (values) => {
    return await axiosInstance().post("company/menu-add-on/list-categories", values);
}

export const GetAllParentAddOnCategories = async (values) => {
    return await axiosInstance().post("company/menu-category/get-all-parent-categories", values);
}


export const GetSingleAddOnCategory = async (values) => {
    return await axiosInstance().post("company/menu-add-on/get-single-category", values);
}

export const GetAllAddOnCategory = async (values) => {
    return await axiosInstance().post("company/menu-add-on/get-all-categories", values);
}

export const editAddOnCategory = async (values) => {
    return await axiosInstance().post("company/menu-add-on/update-category", values)
}

export const ChangeAddOnCategoryStatus = async (values) => {
    return await axiosInstance().post("company/menu-add-on/change-category-status", values)
}

export const DeleteAddOnCategory = async (values) => {
    return await axiosInstance().post("company/menu-add-on/delete-category", values);
}


// addOns Api
export const CreateAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/create-add-on", values);
}

export const ListAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/list-add-ons", values);
}
export const ChangeStatusAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/change-add-on-status", values);
}
export const DeleteAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/delete-add-on", values);
}
export const EditAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/update-add-on", values);
}
export const SingleAddOn = async (values) => {
    return await axiosInstance().post("company/menu-add-on/get-single-add-on", values);
}