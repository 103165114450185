import axiosInstance from "../../utils/axiosCompany";

export const CreateBrand = async (values) => {
    return await axiosInstance().post("company/save-brand", values);
}

export const GetBrandList = async (values) => {
    return await axiosInstance().post("company/list-brand", values);
}

export const GetEditBrand = async (values) => {
    return await axiosInstance().post("company/edit-brand", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/change-status-brand", values)
}

export const GetDeleteBrand = async (values) => {
    return await axiosInstance().post("company/delete-brand", values)
}

export const GetBrandDetail = async (id) => {
    return await axiosInstance().get(`company/get-edit-brand?id=${id}`);
};

export const GetInventory = async () => {
    return await axiosInstance().get("company/inventory");
}

// function poopityScoop(){
// 	window.ononline = (event) => {
//     	console.log("Back Online")
// 	};
  
// 	window.onoffline = (event) => {
//    		console.log("Connection Lost")
// 	};
// }