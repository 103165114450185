import axiosInstance from "../utils/axios";

export const GetDevicesList = async (values) => {
    return await axiosInstance().post("admin/devices-management/get-all-devices", values);
}

export const GetSingleDevice = async (values) => {
    return await axiosInstance().post("admin/devices-management/get-single-device", values);
}

export const editDevice = async (values) => {
    return await axiosInstance().post("admin/devices-management/update-device", values);
}

export const addDevice = async (values) => {
    return await axiosInstance().post("admin/devices-management/add-device", values);
}