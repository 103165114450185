import React, { useEffect, useMemo, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetActiveOrderModes } from "../../services/company_panel/setting/orderModeConfigurationService";
import { getBrandId, getOutletId } from "../../helpers/commonFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { GetOutletDetail } from "../../services/company_panel/outletService";
import { Button, Modal } from "react-bootstrap";
// import 'bootstrap-icons/font/bootstrap-icons.css';

const OrderModeTypes = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [orderModesData, setOrderModesData] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [isBrandChanged]);

    console.log("orderModesData", orderModesData);

    const [outlet, setOutlet] = useState({});
    const [orderMode, setOrderMode] = useState({});
    const [aggregator, setAggregator] = useState(null);
    const [table, setTable] = useState(null);
    const [aggregatorModal, setAggregatorModal] = useState(false);
    const [tableModal, setTableModal] = useState(false);

    const getOutlet = async () => {
        try {
            const data = await GetOutletDetail(getOutletId());
            const outlet = data && data.data && data.data.response ? data.data.response : {};
            setOutlet(outlet);
            return outlet;
        } catch (error) {
            console.log(error);
        }
    }

    const handleTableSelection = () => {
        const data = { service_type: 'table_service', table, aggregator };
        navigate(`/company/transaction/order/add/${orderMode?.id}`, { state: data });
    }

    const handleAggregatorSelection = () => {
        if (orderMode.table_service_exists && outlet.service_type == 'table_service') {
            setTableModal(true);
        } else {
            const data = { service_type: 'quick_service', aggregator };
            navigate(`/company/transaction/order/add/${orderMode?.id}`, { state: data });
        }
    }

    const onSelectOrderMode = async (orderMode) => {
        const outlet = await getOutlet();

        if (orderMode.aggregator_exists) {
            setAggregatorModal(true);
        } else {
            if (orderMode.table_service_exists && outlet.service_type == 'table_service') {
                setTableModal(true);
            } else {
                const data = { service_type: 'quick_service' };
                navigate(`/company/transaction/order/add/${orderMode?.id}`, { state: data });
            }
        }

    }

    return (
        <>
            <div className="animation_fade">
                <div className="card custom-card overflow-hidden" >
                    <div className="container text-center my-5">
                        <h2 className="my-4">New Order</h2>
                        <p className="my-4">Select Type of Order</p>
                        <div className="row g-3 justify-content-center">
                            {orderModesData.length > 0 && orderModesData.map(order => (
                                <div className="col-6 col-md-6 col-lg-4" key={order.id} onClick={() => { setOrderMode(order); onSelectOrderMode(order) }}>
                                    <button className="order-type-btn border-0" style={buttonStyle}>
                                        <img style={{ width: "40px" }} src={order?.logo} alt="Order mode" />
                                        <div className="order-title" style={titleStyle}>
                                            {order.name}
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={aggregatorModal} onHide={() => { setAggregatorModal(false); setAggregator(null); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Select Aggregator</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <div className="choose-outlet-list">
                        <ul>
                            {orderMode?.order_mode_relations?.map((orderModeRelation, index) => (
                                <li key={index}>
                                    <label htmlFor={index}>{orderModeRelation?.aggregators_configuration?.name}</label>
                                    <input
                                        className="aggregator m-0"
                                        name="aggregator"
                                        type="radio"
                                        id={index}
                                        value={index}
                                        onClick={() => setAggregator(orderModeRelation?.aggregators_configuration)}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="outline-primary" onClick={() => { setShowModal(false); setSelectedItem(null); setEditIndex(-1); }}>Cancel</Button> */}
                    <Button variant="primary" onClick={() => { if (aggregator) { setAggregatorModal(false); handleAggregatorSelection(); } }}>Next</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={tableModal} onHide={() => { setTableModal(false); setTable(null); setAggregator(null); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Select Table</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <div className="choose-outlet-list">
                        <ul>
                            {outlet?.dine_tables?.map((dineTable, index) => (
                                <li key={index}>
                                    <label htmlFor={index}>{dineTable?.table_name} ({dineTable?.table_number})</label>
                                    <input
                                        className="table m-0"
                                        name="table"
                                        type="radio"
                                        id={index}
                                        value={index}
                                        onClick={() => setTable(dineTable)}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="outline-primary" onClick={() => { setShowModal(false); setSelectedItem(null); setEditIndex(-1); }}>Cancel</Button> */}
                    <Button variant="primary" onClick={() => { if (table) { setTableModal(false); handleTableSelection(); } }}>Next</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

// Inline Styles
const buttonStyle = {
    width: '100%',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '10px',
    backgroundColor: '#F2F6F9',
    cursor: 'pointer'
};

const iconStyle = {
    fontSize: '40px',
    color: '#007bff'
};

const titleStyle = {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#333'
};

export default OrderModeTypes;

// import 'bootstrap/dist/css/bootstrap.min.css';

// const buttonStyle = {
//     width: '100%',
//     padding: '20px',
//     textAlign: 'center',
//     borderRadius: '10px',
//     backgroundColor: '#f0f4f8',
// };

// const buttonHoverStyle = {
//     backgroundColor: '#e2e6ea',
// };

// const iconStyle = {
//     fontSize: '40px',
//     color: '#007bff',
// };

// const titleStyle = {
//     marginTop: '10px',
//     fontWeight: 'bold',
//     color: '#333',
// };

// const OrderModeTypes = () => {
//     return (
//         <>
//             <div className="animation_fade">
//                 <div className="card custom-card overflow-hidden" style={{height: "80vh"}}>
//                     <div className="container text-center my-5">
//                         <h2>New Order</h2>
//                         <p>Select Type of Order</p>
//                         <div className="row g-3 justify-content-center">

//                             {/* Dine-in */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-shop" style={iconStyle}></i>
//                                     <div style={titleStyle}>Dine - in</div>
//                                 </button>
//                             </div>

//                             {/* Take out */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-bag" style={iconStyle}></i>
//                                     <div style={titleStyle}>Take out</div>
//                                 </button>
//                             </div>

//                             {/* Pick up */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-person-check" style={iconStyle}></i>
//                                     <div style={titleStyle}>Pick up</div>
//                                 </button>
//                             </div>

//                             {/* Drive-thru */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-car-front" style={iconStyle}></i>
//                                     <div style={titleStyle}>Drive-thru</div>
//                                 </button>
//                             </div>

//                             {/* Delivery */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-truck" style={iconStyle}></i>
//                                     <div style={titleStyle}>Delivery</div>
//                                 </button>
//                             </div>

//                             {/* Outsource */}
//                             <div className="col-6 col-md-4 col-lg-2">
//                                 <button
//                                     className="order-type-btn border-0"
//                                     style={buttonStyle}
//                                     onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
//                                     onMouseLeave={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//                                 >
//                                     <i className="bi bi-people" style={iconStyle}></i>
//                                     <div style={titleStyle}>Outsource</div>
//                                 </button>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default OrderModeTypes;
