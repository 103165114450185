import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const CustomPagination = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const pages = []
  let pageno = props.custompath ? Number(params[props.custompath]) : Number(params.id)
  const totalPages = Math.ceil(props.datalength / props.itemperpage);

  useEffect(() => {
    if (props.currentPage > totalPages) {
      props.setPage(totalPages);
      navigate(props && props.pageRoute && props.pageRoute[0].path ? (props.pageRoute[0].path + '/' + totalPages) : ("/#"))
    }
  }, [totalPages])

  let start = props.currentPage || 1;
  if (start < 1) {
    start = 1;
  } else {
    if (start < 5) {
      start = 1;
    } else {
      start = start - 3;
    }
  }
  let end = start + 6;
  end = end <= totalPages ? end : totalPages;

  // pagination functions start//
  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  const handleprepage = () => {
    if (Number(pageno) !== 1) {
      props.setPage(Number(pageno - 1))
      navigate(props && props.pageRoute && props.pageRoute[0].path ? (props.pageRoute[0].path + '/' + Number(pageno - 1)) : ("/#"))
    }
  }

  const handlePagniation = (pageno) => {
    if (pageno) {
      props.setPage(Number(pageno))
      navigate(props && props.pageRoute && props.pageRoute[0].path ? (props.pageRoute[0].path + '/' + (pageno)) : ("/#"))
    }
  }

  const handleNextpage = () => {
    if (pageno < props.datalength / props.itemperpage) {
      let pageCount = Number(++pageno)
      props.setPage(pageCount)
      navigate(props && props.pageRoute && props.pageRoute[0].path ? (props.pageRoute[0].path + '/' + pageCount) : ("/#"))
    }
  }
  // pagination end

  return (
    <>
        <nav>
          <ul className="pagination">
            <li className={"page-item " + (Number(params.id) == 1 || pageno == 1 ? " disabled" : " cp")} onClick={handleprepage}>
              <button type="button" className="page-link">‹</button>
            </li>
            {start != 1 && start != 2 ?
              <>
                <li className={"page-item cp" + (Number(params.id) == 1 ? " active disabled" : " cp")} aria-current="page" onClick={() => { handlePagniation(1) }}>
                  <span className="page-link">{1}</span>
                </li>
                <li className={"page-item disabled"} aria-current="page">
                  <span className="page-link">...</span>
                </li>
              </>
              : ""
            }
            {pages.length && pages.map((pgno, i) => {
              return <li key={i} className={"page-item" + (Number(params.id) == pgno || pageno == pgno || Number(params.pgno) == props.currentPage ? " active disabled" : " cp")} aria-current="page" onClick={() => { handlePagniation(pgno) }}><span className="page-link">{pgno}</span></li>
            })}
            {end != totalPages - 1 && end != totalPages ?
              <>
                <li className={"page-item disabled"} aria-current="page">
                  <span className="page-link">...</span>
                </li>
                <li className={"page-item " + (Number(params.id) == totalPages ? " active disabled" : " cp")} aria-current="page" onClick={() => { handlePagniation(totalPages) }}>
                  <span className="page-link">{totalPages}</span>
                </li>
              </>
              : ""
            }
            <li className={"page-item " + (Number(params.id) == totalPages || pageno == totalPages ? " disabled" : " cp")} onClick={handleNextpage}>
              <button type="button" className="page-link">›</button>
            </li>
          </ul>
        </nav>
    </>
  )
}
export default CustomPagination