import React, { useEffect, useMemo, useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/company_panel/common/loader';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { CouponDetails } from '../../services/company_panel/coupons.service';
import { SpecialCharacter, capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getOutletId} from '../../helpers/commonFunctions';
import { CustomerDetails } from '../../services/company_panel/customer.services';
import { ViewTransaction } from '../../services/company_panel/transcation.services';

const TransactionView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showDefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: "Transaction", url: "/company/transaction/transaction/list/1" }, {
    title: "Transaction Detail",
    url: ""
  },]
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [isOutletChanged, setIsOutletChanged] = useState(false);
  const [currentOutlet] = useState(getOutletId());

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
        setIsBrandChanged(!isBrandChanged);
    });
    document.addEventListener('outlet-changed', function () {
        setIsOutletChanged(!isOutletChanged);
    });
    if(currentOutlet != getOutletId()){
      navigate(`/company/transaction/transaction/list/${1}`);
    }
});

  useEffect(() => {
    ViewTransaction({ id: params.id }).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Transaction</h4>
        </div>
      </div>
      <div className="card custom-card">
        {showDefault && Object.keys(showDefault).length > 0 ?
          <div className="card-body p-0">
            <div className="col-lg-12 form-group">
              <table id="simple-table" className="table table-custom  ">
                <tbody>
                  <tr>
                    <td>Transaction ID</td>
                    <td className='text-end'>{showDefault && showDefault.transaction_id ? (showDefault.transaction_id) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td >Customer Name</td>
                    <td className='text-end'>{showDefault && showDefault.customer?.customer_name? capitalizeFirstLetter(showDefault.customer?.customer_name) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td >Customer Number</td>
                    <td className='text-end'>{showDefault && showDefault.customer?.customer_number ? capitalizeFirstLetter(showDefault.customer?.customer_number) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Order ID</td>
                    <td className='text-end'>{showDefault && showDefault.customer_order.order_id ? showDefault.customer_order.order_id : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Ordered By</td>
                    <td className='text-end'>{showDefault && showDefault.customer_order.user ? `${capitalizeFirstLetter(showDefault?.customer_order?.user?.first_name)} ${capitalizeFirstLetter(showDefault?.customer_order?.user?.last_name)}` : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td className='text-end'>{showDefault && showDefault.amount  ? showDefault.amount + " " + "SAR" : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td className='text-end'>{showDefault && showDefault.payment_status ? capitalizeFirstLetter(showDefault.payment_status) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Payment Method</td>
                    <td className='text-end'>{showDefault && showDefault.payment_method ? capitalizeFirstLetter(showDefault.payment_method) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td className='text-end'>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> : <Loader />}
      </div>
    </>
  )
}

export default TransactionView