import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from './auth';

const PublicRoute = ({ component: Component }) => {
    
    
    return (
        <Component />
    )

}

export default PublicRoute;