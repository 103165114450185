import io from 'socket.io-client';

const CONNECTION_CONFIG = {
	"force new connection": true,
	"reconnectionAttempts": "Infinity",
	"timeout": 10000,
	"transports": ["websocket"]
}

export const SOCKET_CLIENT = io.connect(process.env.REACT_APP_SOCKET_SERVER_URL, CONNECTION_CONFIG);


SOCKET_CLIENT.on('connect', () => {
  console.log('Socket connected:', SOCKET_CLIENT.id); // Log the socket ID when connected
});

// Check if the socket gets disconnected
SOCKET_CLIENT.on('disconnect', () => {
  console.log('Socket disconnected');
});




export const sendNotification = (notificationData)=>{
    SOCKET_CLIENT.emit('notificationSend', notificationData, (error) => {
        /** notificationData sample 
         * 
         {
            sender_id:user._id,
            receiver_id:"650be8fe10cda2e63513231c",
            title:"New message",
            message:"Hi",
            redirection:true,
            redirect_to:"/",
            type:NOTIFICATIONS_TYPES.UNREAD_CHAT_MESSAGE
            }
         */
        if (error) {
            console.log("*****Socket connection error on notificationSend", error);
        }
    })
}